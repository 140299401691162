import React from "react";
import '../../../styles/top/TopPage.css'
import Header from "../../../components/header/header";
import BasicTable, { Row, Title } from "../../../components/table/basicTable";
import { Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import store, { RootState } from "../../../global/store/store";

export default function TopPage() {
  const nav = useNavigate();

  const gotoPage = (url: string) => {
    nav(url);
  };
  // user info
  const isLoggedIn: boolean = useSelector((state: RootState) => state.auth.isLoggedIn);
  const user: { username: string } | null = useSelector((state: RootState) => state.auth.user);

  // sample header data
  const yetReadTableHeaders: Title[] = [
    { name: '受信日時', align: 'left' },
    { name: 'タイトル', align: 'left' },
    { name: '内容', align: 'left' },
  ];

  // samaple content data
  const yetReadTableContents: Row[] = [
    {
      content: [
        { name: '2023-04-14', align: 'left' },
        { name: 'aaa', align: 'left' },
        { name: 'aaaaaa', align: 'left' },
      ]
    },
    {
      content: [
        { name: '2023-04-17', align: 'left' },
        { name: 'bbb', align: 'left' },
        { name: 'bbbbbbbbb', align: 'left' },
      ]
    },
  ];

  return (
    <div>
      <div>
        <Header />
      </div>

      <div className={'mx-5 my-3'} style={{textAlign: "center"}}>
        
        <div className="d-flex">
          <Typography
            fontWeight={'bold'}
            fontSize={'large'}
          >
            移動先ページへの権限が確認できませんでした。
          </Typography>
        </div>
        <div className="d-flex">
          <Typography
            fontWeight={'bold'}
            fontSize={'large'}
          >
            権限が付与されているアカウントでアクセスしてください。
          </Typography>
        </div>
        
        <div className="d-flex my-3" onClick={() => gotoPage('/top')}>
          <Typography
            fontSize={'large'}
            style={{ color: 'gray', cursor: 'pointer' }}
          >
            トップページへ
          </Typography>
        </div>

      </div>
    </div>
  );
};