import { Typography, Grid, Box, Breadcrumbs } from "@mui/material";
import Header from "../../../components/header/header";
import BasicTable, { Row, Title } from "../../../components/table/basicTable";
import { useEffect, useState } from "react";
import BasicButton from "../../../components/button/basicButton";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useNavigate, useLocation } from "react-router-dom";
import ClientRequest from "../../../global/http/ClientRequest";
import DownloadExcelRequest from "../../../global/http/DownloadExcelRequest";

export default function ClientListDetailPage() {
  const nav = useNavigate();
  const location = useLocation();
  const clientId = location.state?.clientId;
  const ListId = location.state?.Id;

  const gotoPage = (url: string) => {
    nav(url, { state: { clientId: clientId, tabIndex: 1 } });
  };

  const headers: Title[] = [
    { name: '会社名', align: 'left' },
    { name: '住所', align: 'left' },
    { name: 'URL', align: 'left' },
    { name: '大業種', align: 'left' },
  ];

  const [tableCount, setTableCount] = useState(0)
  const [tableData, setTableData] = useState<Row[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      const ClientItems = new ClientRequest();
      ClientItems.addStr('list_id', ListId);
      const response = await ClientItems.upload('/client/list/details');

      setTableCount(response.count)

      setTableData(response.data.map((p: any) => ({content: [
          { name: p.name, align: 'left',  type: 'TEXT', value: '', isNowrap: true },
          { name: p.address, align: 'left',type: 'TEXT', value: '', isNowrap: true },
          { name: p.URL, align: 'left', type: 'TEXT', value: '', isNowrap: true },
          { name: p.category_name, align: 'left',  type: 'TEXT', value: '', isNowrap: true },
      ]})));
    }
    fetchData();
  }, []);

  const handleDownload = async () => {
    try {
        const DownloadExcel = new DownloadExcelRequest();
        DownloadExcel.addStr('list_id', ListId);
        const response = await DownloadExcel.upload('/client/download_excel');
        const blob = await response.blob();
        const downloadUrl = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.download = "顧客確認用出力.xlsx";  // ダウンロードするファイル名
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    } catch (error) {
        console.error('ファイルのダウンロード中にエラーが発生しました。', error);
    }
  };

  const handleDownloadCsv = async () => {
    try {
        const DownloadExcel = new DownloadExcelRequest();
        DownloadExcel.addStr('list_id', ListId);
        const response = await DownloadExcel.upload('/client/download_csv');
        const blob = await response.blob();
        const downloadUrl = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.download = "出力.csv";  // ダウンロードするファイル名
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    } catch (error) {
        console.error('ファイルのダウンロード中にエラーが発生しました。', error);
    }
  };

  return (
    <div>
      <div>
        <Header />
      </div>

      {/* パンくずリスト */}
      <Box sx={{ flexGrow: 1, mb: 2, margin: '50px 50px 0px 50px' }} >
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>
            <Box display="flex" gap={1}>
              <div onClick={() => gotoPage('/client_management/detail')}>
                <Typography
                  fontWeight={'bold'}
                  fontSize={'large'}
                  style={{ color: 'gray', cursor: 'pointer' }}
                >
                  顧客情報
                </Typography>
              </div>
              <div style={{ width: '20px' }}></div>
              <ArrowForwardIcon />
              <div style={{ width: '20px' }}></div>
              <Typography fontWeight={'bold'} fontSize={'large'}>リスト詳細</Typography>

            </Box>
          </Grid>
          
          <Grid item>
            <Box display="flex" gap={1}>
              <BasicButton displayName="全情報出力" onClick={() => handleDownloadCsv()} />
              <BasicButton displayName="顧客確認出力" onClick={() => handleDownload()} />
              <BasicButton displayName="顧客確認取込" onClick={() => gotoPage('/client_management/ClientListImportPage')} />
            </Box>
          </Grid>
        </Grid>
      </Box>

      {/* 顧客リストテーブル */}
      <div className='mx-5 my-3'>
        <div>
          <Typography>リスト件数:{tableCount}</Typography>
        </div>
        <BasicTable
          height={'70vh'}
          headerColor="midnightblue"
          headers={headers}
          content={tableData}
        />
      </div>
    </div>
  );
}