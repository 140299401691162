import BasicDropdown, { Dropdown } from "./basicDropdown";
type Props = {
  titleName?: string
  value: Dropdown
  setValue: (val: Dropdown) => void
}
export default function RequestableTaskDropdown(props: Props) {
  return (
    <BasicDropdown
      fullWidth
      titleName={props.titleName ?? '依頼可能作業'}
      items={[]}
      value={props.value}
      onChange={(val: Dropdown) => props.setValue(val)}
      initialRequestUrl={'/common/master/request_task'}
    />
  );
}