import HttpData from "../../../global/http/HttpData";
import {Dropdown} from "../../../components/dropdown/basicDropdown";

export interface CompanyListCreateRequest extends HttpData {
  clientId?: string
  listName: string
  listData: string[]
}

export interface CompanyListCreateResponse extends HttpData {
  errorMessage: string
}

export interface CompanySearchRequest extends HttpData {
  clientId?: string
  // 企業名
  companyName: string
  companyCheckItems: string[]
  // ドメイン
  domain?: string
  domainCheckItems?: string[]
  // トップページURL
  topPageUrl?: string
  topPageUrlCheckItems?: string[]
  // 問い合わせページURL
  inquiryUrl?: string
  inquiryUrlCheckItems?: string[]
  // 大業種
  bigCategory: string
  bigCategoryCheckItems: string[]
  // 小業種
  smallCategory: string
  smallCategoryCheckItems: string[]
  // 従業員数
  minEmployees: string
  maxEmployees: string
  // 電話番号
  phoneNumber?: string
  phoneNumberCheckItems?: string[]
  // FAX番号
  faxNumber?: string
  faxNumberCheckItems?: string[]
  // 郵便番号
  postNumber?: string
  postNumberCheckItems?: string[]
  // 都道府県
  prefecture?: string
  prefectureCheckItems?: string[]
  // 住所
  address?: string
  addressCheckItems?: string[]
  // 事業内容
  operation?: string
  operationCheckItems?: string[]
  // 一言説明
  description?: string
  descriptionCheckItems?: string[]
  // 加盟情報
  affiliation?: string
  affiliationCheckItems?: string[]
  // スクレイピングタブ
  scrapingTab?: string
  scrapingTabCheckItems?: string[]
  // 法人番号
  corpNumber?: string
  // 資本金
  minCapital?: string
  maxCapital?: string
  // 売上高
  minSales?: string
  maxSales?: string
  // 上場区分
  exchangeClass?: string
  exchangeClassCheckItems?: string[]
  // 代表者
  representative?: string
  representativeCheckItems?: string[]
  // 設立年月
  minEstablishmentDate?: string
  maxEstablishmentDate?: string
  // 決算月
  closingMonth?: string
  closingMonthCheckItems?: string[]
  // 事務所数
  minOffices?: string
  maxOffices?: string
  // 工場数
  minFactories?: string
  maxFactories?: string
  // 店舗数
  minStores?: string
  maxStores?: string
  // 取引形態
  transactionForm?: string
  transactionFormCheckItems?: string[]
  // サブ大業種
  subBigCategory?: string
  subBigCategoryCheckItems?: string[]
  // サブ小業種
  subSmallCategory?: string
  subSmallCategoryCheckItems?: string[]
  // 全体送信禁止
  notAllSend?: string
  // 送信方法
  sendMethod?: string
}

export interface CompanySearchResponse extends HttpData {
  count: number
  companies: CompanyInfo[]
}

export class CompanyInfo {
  companyId: string = ''
  companyName: string = ''
  address: string = ''
  businessDescription: string = ''
}

export interface CompanySearchDetailResponse extends HttpData {
  companies: CompanySearchResponseInfo[]
  errorMessage: string
}

export class CompanySearchResponseInfo {
  companyId: string = ''
  companyName: string = ''
  address: string = ''
  domain: string = ''
  industryCategoryName: string = ''
}

export interface CompanyCreateRequest extends HttpData {
  companyId?: string
  companyName: string
  domain: string
  url: string
  inquiryUrl: string
  industryCategory: Dropdown
  industrySubcategory: Dropdown[] | null
  phoneNumber: string
  faxNumber: string
  postal: Dropdown
  prefecture: Dropdown
  employeesNum: string
  address: string
  businessDescription: string
  description: string
  joining: string
  scraping: string
  corpNo: string
  capital: string
  revenue: string
  listing: Dropdown
  representativeName: string
  establishDate: string
  settlementMonth: string
  officeNum: string
  factoryNum: string
  storeNum: string
  dealForm: Dropdown[] | null
  subIndustryCategory: Dropdown[] | null
  subIndustrySubcategory: Dropdown[] | null
  wholeProhibition: string
  sendType: string
}

export interface CompanyCreateResponse extends HttpData {
  companyId: string
  errorMessage: string
}

export class CompanyDetailInfo {
  companyId?: string = ''
  companyName: string = ''
  domain: string = ''
  url: string = ''
  inquiryUrl: string = ''
  industryCategory: Dropdown = {label: '', value: ''}
  industrySubcategory: Dropdown[] = []
  phoneNumber: string = ''
  faxNumber: string = ''
  postal: Dropdown = {label: '', value: ''}
  prefecture: Dropdown = {label: '', value: ''}
  employeesNum: string = ''
  address: string = ''
  businessDescription: string = ''
  description: string = ''
  joining: string = ''
  scraping: string = ''
  corpNo: string = ''
  capital: string = ''
  revenue: string = ''
  listing: Dropdown = {label: '', value: ''}
  representativeName: string = ''
  establishDate: string = ''
  settlementMonth: string = ''
  officeNum: string = ''
  factoryNum: string = ''
  storeNum: string = ''
  dealForm: Dropdown[] = []
  subIndustryCategory: Dropdown[] = []
  subIndustrySubcategory: Dropdown[] = []
  wholeProhibition: string = '0'
  sendType: string = '1'
}

export type CompanyDetailRequest = {
  companyId: string
}

export interface CompanyDetailResponse extends HttpData {
  companyDetail: CompanyDetailInfo
  errorMessage: string
}