import { Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import BasicTable, { Row, Title } from "../../components/table/basicTable";
import SearchTextField from "../../components/text/searchTextField";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import BasicButton from "../../components/button/basicButton";
import HttpData from "../../global/http/HttpData";
import HttpRequest from "../../global/http/HttpRequest";
import { useDispatch } from "react-redux";
import { showMessage } from "../../global/store/snackbar";
import ClientHeader from "../../components/header/clientHeader";
import {DownloadRequest, ListDetailRequest, ListDetailResponse} from "../../@types/client/listDetailType";

/** ダウンロード用レスポンス */
interface DownloadResponse extends HttpData {
  /** メッセージ */
  message: string
}


/**
 * 顧客向け画面 リスト詳細
 */
export default function ClientDetailPage() {
  const dispatch = useDispatch();
  const location = useLocation();
  const subjectId = location.state?.subjectId;
  const subjectName = location.state?.subjectName;
  const status = location.state?.status;

  const nav = useNavigate();
  const [headers, setHeaders] = useState<Title[]>([
    { name: '企業名', align: 'left' },
    { name: '都道府県', align: 'left' },
    { name: '送信結果', align: 'left' },
    { name: '送信日時', align: 'left' },
  ]);
  const [contents, setContents] = useState<Row[]>([]);
  const [searchValue, setSearchValue] = useState<string>('');

  useEffect(() => {
    getList('');
  }, []);

  const search = () => {
    getList(searchValue);
  };

  const getList = async (searchValue: string) => {
    const http = new HttpRequest<ListDetailRequest, ListDetailResponse>();
    http.request = {
      subjectId: subjectId,
      searchValue: searchValue
    };

    if (await http.post('/client/subject/list/detail')) {
      setContents(http.response!.lists.map((item, index) => {
        return (
          {
            content: [
              { name: item.companyName, align: 'left', type: "TEXT" },
              { name: item.prefecture, align: 'left', width: 110, type: "TEXT" },
              { name: item.sendResult, align: 'left', width: 110, type: "TEXT" },
              { name: item.sendDate, align: 'left', width: 170, type: "TEXT" },
            ],
          }
        );
      }));
    };
  };

  const gotoPageAddData = (url: string, data: string) => {
    nav(url, { state: { uuid: data } });
  };

  const download = async () => {
    const http = new HttpRequest<ListDetailRequest, Response>();
    // http.request = contents.map((item, index) => {
    //   const rowLength = contents[index].content.length;
    //   let cnt = rowLength
    //   return {
    //     companyName: item.content[rowLength - cnt--].name as string,
    //     prefecture: item.content[rowLength - cnt--].name as string,
    //     sendResult: item.content[rowLength - cnt--].name as string,
    //     sendDate: item.content[rowLength - cnt--].name as string,
    //   };
    // });
    http.request = {
      subjectId: subjectId,
      searchValue: searchValue
    };
    const res = await http.download('/client/subject/list/download', `${subjectName}-${status}.xlsx`);
    if (!res) {
      dispatch(showMessage({
        message: '予期せぬエラーが発生しました。',
        severity: 'error',
        duration: 3000,
      }));
    }
  };

  return (
    <div>
      <div>
        <ClientHeader />
      </div>

      {/* パンくずリスト */}
      <div className="d-flex" style={{ margin: '50px 50px 0px 50px' }}>
        <div onClick={() => gotoPageAddData('/client/list', '')}>
          <Typography
            fontWeight={'bold'}
            fontSize={'large'}
            style={{ color: 'gray', cursor: 'pointer' }}
          >
            案件一覧
            </Typography>
        </div>

        <div style={{ width: '20px' }}></div>
        <ArrowForwardIcon />
        <div style={{ width: '20px' }}></div>
        <Typography fontWeight={'bold'} fontSize={'large'}>リスト詳細</Typography>
      </div>

      {/* 検索フィールド */}
      <div className="mx-5 d-flex justify-content-center" style={{ alignItems: 'center', margin: '20px auto 0px auto' }}>
        <SearchTextField fullwidth label="企業名" value={searchValue} setValue={setSearchValue} onClick={search} />
        <div className='' style={{ marginLeft: '10px', margin: '0 0 3px auto' }}>
          <BasicButton displayName={'ダウンロード'} onClick={download} />
        </div>
      </div>

      {/* 案件一覧 */}
      <div className='mx-5 my-3'>
        <BasicTable
          headers={headers}
          content={contents}
          isPaging={true}
        />
      </div>
    </div>
  );
};