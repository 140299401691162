import { createSlice } from "@reduxjs/toolkit";

export type User = {
  username: string,
  type: string,
  roles: string[],
}

export interface AuthState {
  isLoggedIn: boolean
  user: User | null
  //roles: string[]
}

const initialState: AuthState = {
  isLoggedIn: false,
  user: null,
  //roles: [],
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login: (state, action) => {
      state.isLoggedIn = true;
      state.user = action.payload;
    },
    logout: (state) => {
      state.isLoggedIn = false;
      state.user = null;
    },
  },
});

export const { login, logout } = authSlice.actions;
export default authSlice.reducer;

// function createSlice(arg0: { name: string; initialState: AuthState; reducers: { login: (state: any, action: { username: string; }) => void; logout: (state: any) => void; }; }) {
//   throw new Error("Function not implemented.");
// }

