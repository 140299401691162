import { useNavigate, useLocation } from "react-router-dom";
import Header from "../../../../components/header/header";
import { Typography } from "@mui/material";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import BasicTextField from "../../../../components/text/basicTextField";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import BasicButton from "../../../../components/button/basicButton";
import BasicDropdown, { Dropdown } from "../../../../components/dropdown/basicDropdown";
import "react-datepicker/dist/react-datepicker.css";
import { showMessage } from "../../../../global/store/snackbar";
import { CompanyCreateRequest, CompanyCreateResponse, CompanyDetailInfo,
         CompanyDetailRequest, CompanyDetailResponse } from "../../../../@types/ib/companyDatabasePage/CompanyType";
import BasicCheckbox from "../../../../components/checkbox/basicCheckBox";
import BasicRadioButton from "../../../../components/radiobutton/basicRadioButton";
import PrefectureDropdown from "../../../../components/dropdown/prefectureDropdown";
import PostCodeDropdown from "../../../../components/dropdown/postCodeDropdown";
import BasicMultipleDropdown from "../../../../components/dropdown/basicMultipleDropdown";
import HttpRequest from "../../../../global/http/HttpRequest";

// 企業DB管理 -> 新規企業登録ページ 																	
export default function ClientRegistrationPage() {
  const nav = useNavigate();
  const location = useLocation();

  const gotoPage = (url: string) => {
    nav(url);
  };

  const companyId = location.state?.companyId;
  const [isUpdated, setIsUpdated] = useState(false);

  const gotoDetailPage = (id: string) => {
    nav('/company_database/company_detail', { state: { companyId: id } });
  }

  const dispatch = useDispatch();

  // state(基本情報)
  const [companyInfo, setCompanyInfo] = useState<CompanyDetailInfo>(new CompanyDetailInfo());
  const sendTypes = [
    { label: 'Automatic', value: '1' },
    { label: 'Manual', value: '2' },
  ];

  useEffect(() => {
    const fetchData = async () => {
      if(companyId){
        const http = new HttpRequest<CompanyDetailRequest, CompanyDetailResponse>()
        http.request = {
          companyId: companyId,
        }

        await http.post('/database/company/update/detail')
        const info = { ...http.response!.companyDetail }
        setCompanyInfo(info)
        if (http.response?.errorMessage) {
          dispatch(showMessage({
            message: http.response!.errorMessage,
            severity: 'error',
            duration: 3000,
          }))
          return
        }
        setIsUpdated(true)
      } else {
        setCompanyInfo(new CompanyDetailInfo())
      }
    }
    fetchData();
  }, []);
  

  // 登録
  const registration = async () => {
    const http = new HttpRequest<CompanyCreateRequest, CompanyCreateResponse>()
    http.request = {
      ...{...companyInfo},
    }

    const result = await http.post('/database/company/create')

    if (http.response?.errorMessage) {
      dispatch(showMessage({
        message: http.response!.errorMessage,
        severity: 'error',
        duration: 3000,
      }))
      return
    }
    gotoDetailPage(http.response?.companyId!)
  }

  return (
    <div>
      <div>
        <Header />
      </div>

      <div>
        {/* パンくずリスト */}
        <div className="d-flex" style={{ margin: '50px 50px 0px 50px' }}>
          <div onClick={() => gotoPage('/company_database')}>
            <Typography
              fontWeight={'bold'}
              fontSize={'large'}
              style={{ color: 'gray', cursor: 'pointer' }}
            >
              企業データベース管理
            </Typography>
          </div>

          <div style={{ width: '20px' }}></div>
          <ArrowForwardIcon />
          <div style={{ width: '20px' }}></div>
          <Typography fontWeight={'bold'} fontSize={'large'}>{isUpdated ? '編集' : '登録'}</Typography>
        </div>
      </div>

      {/* 登録フォーム */}
      <div className="mx-5 my-5">
        <div>
          {/* 基本情報 */}
          <div style={{ margin: '30px 50px 0px 50px' }}>
            <div className="d-flex my-1 w-50">
              <BasicTextField fullwidth label="法人名" 
               value={companyInfo.companyName} 
               setValue={(value: string) => setCompanyInfo({ ...companyInfo, companyName: value })} />
            </div>
            <div className="d-flex my-1 w-50">
              <BasicTextField fullwidth label="ドメインHTTPなし" 
               value={companyInfo.domain} 
               setValue={(value: string) => setCompanyInfo({ ...companyInfo, domain: value })} />
            </div>
            <div className="d-flex my-1 w-50">
              <BasicTextField fullwidth label="トップページURL"
               value={companyInfo.url} 
               setValue={(value: string) => setCompanyInfo({ ...companyInfo, url: value })} />
            </div>
            <div className="d-flex my-1 w-50">
              <BasicTextField fullwidth label="問い合わせページURL"
               value={companyInfo.inquiryUrl} 
               setValue={(value: string) => setCompanyInfo({ ...companyInfo, inquiryUrl: value })} />
            </div>
            <div className="my-1 w-50">
              <BasicDropdown
                fullWidth
                titleName={'大業種'}
                value={companyInfo.industryCategory}
                items={[]}
                onChange={(val: Dropdown) => setCompanyInfo({ ...companyInfo, industryCategory: val })}
                lazyRequestUrl={'/database/industry_category'}
              />
            </div>
            <div className="my-1 w-50">
              <BasicMultipleDropdown
                fullWidth
                titleName={'小業種'}
                value={companyInfo.industrySubcategory}
                items={[]}
                onChange={(val: Dropdown[]) => setCompanyInfo({ ...companyInfo, industrySubcategory: val })}
                lazyRequestUrl={'/database/industry_subcategory'}
              />
            </div>
            <div className="d-flex my-1 w-50">
              <BasicTextField fullwidth label="電話番号"
               value={companyInfo.phoneNumber} 
               setValue={(value: string) => setCompanyInfo({ ...companyInfo, phoneNumber: value })} />
            </div>
            <div className="d-flex my-1 w-50">
              <BasicTextField fullwidth label="FAX"
               value={companyInfo.faxNumber} 
               setValue={(value: string) => setCompanyInfo({ ...companyInfo, faxNumber: value })} />
            </div>
            <div className="my-1 w-50">
              <PostCodeDropdown
                value={companyInfo.postal}
                setValue={(selectedValue: Dropdown) => setCompanyInfo({ ...companyInfo, postal: selectedValue })}
              />
            </div>
            <div className="my-1 w-50">
              <PrefectureDropdown
                value={companyInfo.prefecture}
                setValue={(selectedValue: Dropdown) => setCompanyInfo({ ...companyInfo, prefecture: selectedValue })}
              />
            </div>
            <div className="d-flex my-1 w-50">
              <BasicTextField fullwidth label="従業員数"
               value={companyInfo.employeesNum} 
               setValue={(value: string) => setCompanyInfo({ ...companyInfo, employeesNum: value })} />
            </div>
            <div className="d-flex my-1 w-50">
              <BasicTextField fullwidth label="住所"
               value={companyInfo.address} 
               setValue={(value: string) => setCompanyInfo({ ...companyInfo, address: value })} />
            </div>
            <div className="d-flex my-1 w-50">
              <BasicTextField fullwidth label="事業内容"
               value={companyInfo.businessDescription} 
               setValue={(value: string) => setCompanyInfo({ ...companyInfo, businessDescription: value })} />
            </div>
            <div className="d-flex my-1 w-50">
              <BasicTextField fullwidth label="一言説明"
               value={companyInfo.description} 
               setValue={(value: string) => setCompanyInfo({ ...companyInfo, description: value })} />
            </div>
            <div className="d-flex my-1 w-50">
              <BasicTextField fullwidth label="加盟情報"
               value={companyInfo.joining} 
               setValue={(value: string) => setCompanyInfo({ ...companyInfo, joining: value })} />
            </div>
            <div className="d-flex my-1 w-50">
              <BasicTextField fullwidth label="スクレイピング"
               value={companyInfo.scraping} 
               setValue={(value: string) => setCompanyInfo({ ...companyInfo, scraping: value })} />
            </div>
            <div className="d-flex my-1 w-50">
              <BasicTextField fullwidth label="法人番号"
               value={companyInfo.corpNo} 
               setValue={(value: string) => setCompanyInfo({ ...companyInfo, corpNo: value })} />
            </div>
            <div className="d-flex my-1 w-50">
              <BasicTextField fullwidth label="資本金"
               value={companyInfo.capital} 
               setValue={(value: string) => setCompanyInfo({ ...companyInfo, capital: value })} />
            </div>
            <div className="d-flex my-1 w-50">
              <BasicTextField fullwidth label="売上金"
               value={companyInfo.revenue} 
               setValue={(value: string) => setCompanyInfo({ ...companyInfo, revenue: value })} />
            </div>
            <div className="my-1 w-50">
              <BasicDropdown
                fullWidth
                titleName={'上場区分'}
                value={companyInfo.listing}
                items={[]}
                onChange={(selectedValue: Dropdown) => setCompanyInfo({ ...companyInfo, listing: selectedValue })}
                initialRequestUrl={'/database/listing_class'}
              />
            </div>
            <div className="d-flex my-1 w-50">
              <BasicTextField fullwidth label="代表者"
               value={companyInfo.representativeName} 
               setValue={(value: string) => setCompanyInfo({ ...companyInfo, representativeName: value })} />
            </div>
            <div className="d-flex my-1 w-50">
              <BasicTextField fullwidth label="設立年月日(半角数字8文字)"
               value={companyInfo.establishDate} 
               setValue={(value: string) => setCompanyInfo({ ...companyInfo, establishDate: value })} />
            </div>
            <div className="d-flex my-1 w-50">
              <BasicTextField fullwidth label="決算月"
               value={companyInfo.settlementMonth} 
               setValue={(value: string) => setCompanyInfo({ ...companyInfo, settlementMonth: value })} />
            </div>
            <div className="d-flex my-1 w-50">
              <BasicTextField fullwidth label="事業所数"
               value={companyInfo.officeNum} 
               setValue={(value: string) => setCompanyInfo({ ...companyInfo, officeNum: value })} />
            </div>
            <div className="d-flex my-1 w-50">
              <BasicTextField fullwidth label="工場数"
               value={companyInfo.factoryNum} 
               setValue={(value: string) => setCompanyInfo({ ...companyInfo, factoryNum: value })} />
            </div>
            <div className="d-flex my-1 w-50">
              <BasicTextField fullwidth label="店舗数"
               value={companyInfo.storeNum} 
               setValue={(value: string) => setCompanyInfo({ ...companyInfo, storeNum: value })} />
            </div>
            <div className="my-1 w-50">              
              <BasicMultipleDropdown
                fullWidth
                titleName={'取引形態'}
                value={companyInfo.dealForm}
                items={[]}
                onChange={(val: Dropdown[]) => setCompanyInfo({ ...companyInfo, dealForm: val })}
                initialRequestUrl={'/database/deal_form'}
              />
            </div>
            <div className="my-1 w-50">
              <BasicMultipleDropdown
                fullWidth
                titleName={'サブ大業種'}
                value={companyInfo.subIndustryCategory}
                items={[]}
                onChange={(val: Dropdown[]) => setCompanyInfo({ ...companyInfo, subIndustryCategory: val })}
                lazyRequestUrl={'/database/sub_industry_category'}
              />
            </div>
            <div className="my-1 w-50">
              <BasicMultipleDropdown
                fullWidth
                titleName={'サブ小業種'}
                value={companyInfo.subIndustrySubcategory}
                items={[]}
                onChange={(val: Dropdown[]) => setCompanyInfo({ ...companyInfo, subIndustrySubcategory: val })}
                lazyRequestUrl={'/database/sub_industry_subcategory'}
              />
            </div>
            <div className="d-flex my-1 w-50">
              <div className="my-2">
                <Typography align="right">全体送信禁止：</Typography>
              </div>
              <div className="col">
                <BasicCheckbox
                  label="全体禁止"
                  checked={companyInfo.wholeProhibition === '1'}
                  onChange={(e, checked) => {
                    setCompanyInfo({ ...companyInfo, wholeProhibition: checked ? '1' : '0' }
                  )}}
                />
              </div>
            </div>
            <div className="d-flex my-1 w-50">
              <div className="my-2">
                <Typography align="right">送信方法：</Typography>
              </div>
              <div className="col">
                <BasicRadioButton
                  name="radioGroup"
                  options={sendTypes}
                  selectedValue={companyInfo.sendType}
                  onChange={(selectedValue) => setCompanyInfo({ ...companyInfo, sendType: selectedValue })}
                />
              </div>
            </div>
          </div>

          <div className="my-3" style={{ textAlign: 'center' }}>
            <BasicButton
              displayName="登録" width={'20%'}
              fontColor="white"
              onClick={registration}
            />
          </div>
        </div>
      </div>

    </div >
  );
}