import HttpData from "../global/http/HttpData";

// 送信原稿用
export class DraftEditInput {
  draft_data: string = ''
}

export class DraftInfo {
  draftId?: string = ''
  clientId?: string = ''
  draftName: string = ''
  title: string = ''
  regulart: string = ''
  short: string = ''
  noUrl: string = ''
}

export interface DraftEditRequest extends HttpData {
  draftId?: string
  clientId?: string
  draftName: string
  title: string
  regulart: string
  short: string
  noUrl: string
}

export interface DraftResponse extends HttpData {
  draftId?: string
  draftName: string
  title: string
  regulart: string
  short: string
  noUrl: string
}

export interface DraftEditResponse extends HttpData {
  errorMessage: string
  draftId: string
}

export type DraftDetailRequest = {
  draft_id: string
}