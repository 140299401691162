import { Box, IconButton, Typography, styled } from "@mui/material";
import React, { useEffect, useState } from "react";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Accept, useDropzone } from "react-dropzone";
import BasicButton from "../button/basicButton";
import BackspaceIcon from '@mui/icons-material/Backspace';
import FileUploader from "../../global/http/FileUploader";
import { useLocation } from "react-router-dom";
import { useDispatch, } from "react-redux";
import { closeMessage, showMessage } from "../../global/store/snackbar";

type Props = {
  httpUrl: string
  method: 'GET' | 'POST'
}

export default function FileUploadBox(props: Props) {
  const dispatch = useDispatch();
  const location = useLocation();
  const clientId = location.state?.clientId;
  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(80%)',
    height: 1,
    overflow: 'hidden',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });

  let { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/*': ['.jpg', '.png', '.gif'],
      'application/pdf': ['.pdf'],
      'text/plain': ['.txt', '.csv', '.docx', '.xlsx'],
    },
    noClick: true,
  });
  const [file, setFile] = useState<JSX.Element[]>([]);

  const uploadExecute = async () => {
    if (!acceptedFiles || acceptedFiles.length === 0) {
      dispatch(showMessage({
        message: '対象ファイルが見つかりません。',
        severity: 'warning',
        duration: 3000,
      }));
    } else {
      const uploader = new FileUploader()
      acceptedFiles.forEach(file => {
        uploader.addFile('file', file)
      })

      if(clientId){
        uploader.addParameter('client_id', clientId)
      }


      await uploader.upload(props.httpUrl)
      // TODO:ファイルが選択されている場合の処理(API送信)
    }
  };

  useEffect(() => {
    let temp: JSX.Element[] = [];
    temp = mapping();
    setFile(temp);
  }, [acceptedFiles]);

  const mapping = () => {
    return acceptedFiles.map((file, index) => {
      return (
        <div className="d-flex" style={{ textAlign: 'center', justifyContent: 'center' }}>
          <Typography key={`fileIndex-${index}`} width={'5vh'} align="right">{`${index + 1}.`}</Typography>
          <div style={{ width: '5vh' }}></div>
          <div style={{ width: '50vh' }}>
            <Typography key={`file-${index}`} align="left" textOverflow={'hidden'}>{`${file.name}`}</Typography>
          </div>
          <div style={{ width: '5vh' }}></div>
          <BackspaceIcon color="error" onClick={() => deleteFile(index)} />
        </div >
      );
    });
  }

  const deleteFile = (index: number) => {
    const temp = [...acceptedFiles];
    temp.splice(index, 1);
    acceptedFiles = temp;
    const temp2 = mapping();
    setFile(temp2);
  };

  return (
    <Box className="container">
      <div style={{ textAlign: 'center', margin: '10px' }}>
        <BasicButton displayName="ファイルをアップロード" startIcon={<CloudUploadIcon />} onClick={uploadExecute} />
      </div>
      <div
        {...getRootProps({ className: 'dropzone parent' })}
        style={{
          width: '100%', minHeight: '50vh', border: '2px dashed skyblue',
          textAlign: 'center', padding: '10px', flexWrap: 'wrap', justifyContent: 'space-between',

        }}
      >
        <VisuallyHiddenInput {...getInputProps()} />
        <Typography sx={{ margin: '10px' }} color={'gray'}>{file.length == 0 ? 'ここにファイルをドラッグ＆ドロップ' : `${file.length}件選択中`}</Typography>
        <div style={{ flex: '0 0 calc(50% - 10px)', boxSizing: 'border-box' }}>
          <ul style={{ padding: '10px', textAlign: 'center', margin: '5%' }}>
            {file.map((f, index) => {
              return (
                <div key={`file-${index}`} className="child">
                  {f}
                </div>
              );
            })}
          </ul>
        </div>
      </div>

      <div style={{ height: '50px' }}></div>
    </Box>
  );
}
