import HttpData from "../../../global/http/HttpData";

export class ListSearch {
  companyName: Condition = new Condition()
  domainName: Condition = new Condition()
  topPageUrl: Condition = new Condition()
  inquiryUrl: Condition = new Condition()
  industryCategory: Condition = new Condition()
  industrySubCategory: Condition = new Condition()
  phoneNumber: Condition = new Condition()
  faxNumber: Condition = new Condition()
  postalCode: Condition = new Condition()
  prefecture: Condition = new Condition()
  address: Condition = new Condition()
  minEmployee: Condition = new Condition()
  maxEmployee: Condition = new Condition()
  operation: Condition = new Condition(MatchType.PARTIAL_MATCH)
  description: Condition = new Condition(MatchType.PARTIAL_MATCH)
  joining: Condition = new Condition(MatchType.PARTIAL_MATCH)
  scrapingTab: Condition = new Condition(MatchType.PARTIAL_MATCH)
  minCapital: Condition = new Condition()
  maxCapital: Condition = new Condition()
  minSales: Condition = new Condition()
  maxSales: Condition = new Condition()
  listingClass: Condition = new Condition(MatchType.PARTIAL_MATCH)
  representative: Condition = new Condition(MatchType.PARTIAL_MATCH)
  minEstablishmentDate: Condition = new Condition()
  maxEstablishmentDate: Condition = new Condition()
  closingMonth: Condition = new Condition(MatchType.PARTIAL_MATCH)
  minOffices: Condition = new Condition()
  maxOffices: Condition = new Condition()
  minFactories: Condition = new Condition()
  maxFactories: Condition = new Condition()
  minStores: Condition = new Condition()
  maxStores: Condition = new Condition()
  dealForm: Condition = new Condition(MatchType.PARTIAL_MATCH)
  subIndustryCategory: Condition = new Condition(MatchType.PARTIAL_MATCH)
  subIndustrySubCategory: Condition = new Condition(MatchType.PARTIAL_MATCH)
  sendMethod: Condition = new Condition()
}

export class Condition {
  keyword: string = ''
  isNot: boolean = false
  matchType: MatchType = MatchType.PREFIX_MATCH

  constructor(matchType?: MatchType) {
    if (matchType) {
      this.matchType = matchType
    }
  }
}

export enum MatchType {
  PREFIX_MATCH = 'PREFIX_MATCH',
  PARTIAL_MATCH = 'PARTIAL_MATCH',
  SUFFIX_MATCH = 'SUFFIX_MATCH',
  EXACT_MATCH = 'EXACT_MATCH'
}

export interface SearchConditionRequest extends HttpData {
  clientId: string
  listName?: string
  companyName: Condition
  domainName: Condition
  topPageUrl: Condition
  inquiryUrl: Condition
  industryCategory: Condition
  industrySubCategory: Condition
  phoneNumber: Condition
  faxNumber: Condition
  postalCode: Condition
  prefecture: Condition
  address: Condition
  minEmployee: Condition
  maxEmployee: Condition
  operation: Condition
  description: Condition
  joining: Condition
  scrapingTab: Condition
  minCapital: Condition
  maxCapital: Condition
  minSales: Condition
  maxSales: Condition
  listingClass: Condition
  representative: Condition
  minEstablishmentDate: Condition
  maxEstablishmentDate: Condition
  closingMonth: Condition
  minOffices: Condition
  maxOffices: Condition
  minFactories: Condition
  maxFactories: Condition
  minStores: Condition
  maxStores: Condition
  dealForm: Condition
  subIndustryCategory: Condition
  subIndustrySubCategory: Condition
  sendMethod: Condition
}