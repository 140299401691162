import { Typography } from "@mui/material";
import Header from "../../../components/header/header";
//import BasicTable, { Row, Title } from "../../components/table/basicTable";
import PagingTable, { PagingRow, PagingTitle } from "../../../components/table/pagingTable";
import { useState } from "react";
import BasicButton from "../../../components/button/basicButton";
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from "react-router-dom";
import SearchTextField from "../../../components/text/searchTextField";
import ClientRequest from "../../../global/http/ClientRequest";
import { useDispatch } from "react-redux/es/exports";
import { showMessage } from "../../../global/store/snackbar";

export default function ClientManagementPage() {
  const nav = useNavigate();
  const dispatch = useDispatch();

  const gotoPage = (url: string) => {
    nav(url);
  };

  const gotoDetailPage = (rowIndex: number) => {
    const clientId = clientData[rowIndex].content[0].value;
    nav('/client_management/detail', { state: { clientId: clientId } });
  }

  // search text
  const [searchValue, setSearchValue] = useState<string>('');

  const headers: PagingTitle[] = [
    { name: 'ID', align: 'center' },
    { name: '顧客名', align: 'left' },
    { name: '住所', align: 'left' },
    { name: '', align: 'center' },
  ];

  const [page, setPage] = useState(0);

  let [clientData, setClientData] = useState<PagingRow[]>([])
  // 表示データ格納変数
  const [tableData, setTableData] = useState<PagingRow[]>([]);

  // TODO:検索関数(API通信して結果をtableDataに反映させる)
  const search = async () => {

    const clientSearch = new ClientRequest()
    clientSearch.addStr('search_value', searchValue)
    const response = await clientSearch.upload('/client/search')


    if (response['result'] === "FAILURE") {
      dispatch(showMessage({
        message: '対象データが見つかりません',
        severity: 'info',
        duration: 3000,
      }))
      return
    }

    if (response['result'] === "SUCCESS" && response['data'].length >= 1) {

      type CompanyData = {
        name: string;
        address: string;
        client_id: string;
      };

      clientData = response['data'].map((item: CompanyData, index: number) => ({
        content: [
          { name: index + 1, align: 'center', width: 30, type: 'TEXT', value: item.client_id },
          { name: item.name, align: 'left', width: 30, type: 'TEXT', value: item.name },
          { name: item.address, align: 'left', width: 30, type: 'TEXT', value: item.address },
          { name: <BasicButton displayName={'詳細'} onClick={() => gotoDetailPage(index)} />, align: 'center', width: 30, type: 'TEXT', value: '' }
        ]
      }));

      setTableData(clientData);

    }
  }

  return (
    <div>
      <div>
        <Header />
      </div>

      {/* パンくずリストと新規登録ボタン */}
      <div className="d-flex justify-content-between mx-5 align-items-end">
        <div style={{ margin: '50px 0px 0px 0px' }}>
          <Typography fontWeight={'bold'} fontSize={'large'}>顧客管理</Typography>
        </div>
        <div style={{ margin: '50px 0px 0px 50px', textAlign: 'end' }}>
          <BasicButton displayName="新規登録" startIcon={<AddIcon />} onClick={() => gotoPage('/client_management/new')} />
        </div>
      </div>

      {/* 検索フィールド */}
      <div className="d-flex justify-content-center" style={{ alignItems: 'center', margin: '50px 0px 0px 0px' }}>
        <SearchTextField label="顧客名" value={searchValue} setValue={setSearchValue} onClick={search} />
      </div>


      {/* 顧客リストテーブル */}
      <div className='mx-5 my-3'>
        <PagingTable
          headerColor="midnightblue"
          headers={headers}
          content={tableData}
          scale={1}
          page={page}
          setPage={setPage}
          pageSize={100}
        />
      </div>
    </div>
  );
}