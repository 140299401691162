import { useNavigate } from "react-router-dom";
import Header from "../../../components/header/header";
import { IconButton, TextField, Typography } from "@mui/material";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import BasicTextField from "../../../components/text/basicTextField";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import BasicButton from "../../../components/button/basicButton";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import PrefectureDropdown from "../../../components/dropdown/prefectureDropdown";
import BasicTable, { Row, Title } from "../../../components/table/basicTable";
import { showMessage } from "../../../global/store/snackbar";
import { Dropdown } from "../../../components/dropdown/basicDropdown";
import PostCodeDropdown from "../../../components/dropdown/postCodeDropdown";
import HttpRequest from "../../../global/http/HttpRequest";
import HttpData from "../../../global/http/HttpData";
import RequestableTaskDropdown from "../../../components/dropdown/requestableTaskDropdown";
import { DateTime } from 'luxon';
import BasicDialog from "../../../components/dialog/basicDialog";

type OperationField = {
  id: string
  selectedOpe: Dropdown;
  price: string;
};

interface RegistRequest extends HttpData {
  outsourceName: string
  prefecture: string
  postCode: string
  address: string
  phoneNumber: string
  faxNumber: string
  memo: string
  tasks: Tasks[]
  outsourceUsers: TableRow[]
}

interface Tasks {
  id: string
  selectedOpe: string
  price: string
}

interface Cell {
  id: string
  value: string
}

interface TableRow {
  content: Cell[]
}

interface RegistResponse extends HttpData {
  message: string
  outsourceId: string
  userInfo: UserInfo[]
}

interface UserInfo {
  id: string
  fullName: string
  mail: string
  password: string
}

// 外注先管理 -> 新規登録ページ
export default function OutsourcingRegistrationPage() {
  const nav = useNavigate();

  const dispatch = useDispatch();

  const gotoPage = (url: string) => {
    nav(url);
  };

  // state(基本情報)
  const [outsourcingName, setOutsourcingName] = useState<string>('');
  const [prefecture, setPrefecture] = useState<Dropdown>({ label: '', value: '' });
  const [postNumber, setPostNumber] = useState<Dropdown>({ label: '', value: '' });
  const [address, setAddress] = useState<string>('');
  const [tell, setTell] = useState<string>('');
  const [fax, setFax] = useState<string>('');
  const [operationFields, setOperationFields] = useState<OperationField[]>([{ id: DateTime.now().toString(), selectedOpe: { label: '', value: '' }, price: '' }]);
  const [memo, setMemo] = useState<string>('');

  // 確認ダイアログ
  const [open, setOpen] = useState<boolean>(false);
  const [outsourceId, setOutsourceId] = useState<string>('');
  const [userInfo, setUserInfo] = useState<Row[]>([]);

  // スクレイピング行情報
  const [scrapingRow, setScrapingRow] = useState<string[]>(['']);

  // スクレイピング＋ボタン押下処理
  const scrapingInfoAddHandler = (index: number) => {
    const temp = [...scrapingRow];
    temp.push('');
    setScrapingRow(temp);
  };
  // スクレイピング-ボタン押下処理
  const scrapingInfoDelHandler = (index: number) => {
    if (scrapingRow.length === 1) return;
    const temp = [...scrapingRow];
    temp.splice(index, 1);
    setScrapingRow(temp);
  };
  // スクレイピングテキスト処理
  const scrapingTextHandler = (index: number, value: any) => {
    const temp = [...scrapingRow];
    temp[index] = value as string;
    setScrapingRow(temp);
  };

  const addOperationField = () => {
    setOperationFields([...operationFields, { id: DateTime.now().toString(), selectedOpe: { label: '', value: '' }, price: '' }]);
  };

  // 依頼可能作業-ボタン押下処理
  const operationFieldDelHandler = (field: OperationField) => {
    if (operationFields.length === 1) return;
    const temp = [...operationFields];
    setOperationFields(operationFields.filter(f => f.id !== field.id));
  };

  const handleOperationChange = (index: number, selectedValue: Dropdown) => {
    const newFields = [...operationFields];
    newFields[index].selectedOpe = selectedValue;
    setOperationFields(newFields);
  };

  const handlePriceChange = (index: number, value: string) => {
    const newFields = [...operationFields];
    newFields[index].price = value;
    setOperationFields(newFields);
  };

  // 担当者リスト
  const headers: Title[] = [
    { name: '氏', align: 'left', width: 50 },
    { name: '名', align: 'left', width: 50 },
    { name: '氏（ひらがな）', align: 'left', width: 50 },
    { name: '名（ひらがな）', align: 'left', width: 50 },
    { name: 'Email', align: 'left', width: 50 },
    { name: '電話', align: 'left', width: 50 },
  ];
  // 担当者初期データ
  const contents: Row[] = [
    {
      content: [
        { id: 'last_name', name: <TextField label="" />, align: 'left', value: '' },
        { id: 'first_name', name: <TextField label="" />, align: 'left', value: '' },
        { id: 'last_name_hiragana', name: <TextField label="" />, align: 'left', value: '' },
        { id: 'first_name_hiragana', name: <TextField label="" />, align: 'left', value: '' },
        { id: 'mail', name: <TextField label="" />, align: 'left', value: '' },
        { id: 'phone_number', name: <TextField label="" />, align: 'left', value: '' },
      ],
    }
  ];
  const [rows, setRows] = useState<Row[]>(contents);

  // 担当者を追加ボタン
  const addHuman = () => {
    const temp = [...rows]
    const lastRow = temp[temp.length - 1];
    temp.push(contents[0]);
    setRows(temp);
  }

  // 登録
  const registration = async () => {
    if (!outsourcingName) {
      dispatch(showMessage({
        message: '外注先名を入力してください',
        severity: 'error',
        duration: 3000,
      }))
      return;
    }
    if (!prefecture) {
      dispatch(showMessage({
        message: '都道府県を選択してください',
        severity: 'error',
        duration: 3000,
      }))
      return;
    }
    if (!postNumber) {
      dispatch(showMessage({
        message: '郵便番号を選択してください',
        severity: 'error',
        duration: 3000,
      }))
      return;
    }
    if (!address) {
      dispatch(showMessage({
        message: '住所を入力してください',
        severity: 'error',
        duration: 3000,
      }))
      return;
    }
    if (!tell && tell.length > 12) {
      dispatch(showMessage({
        message: '電話番号を入力してください',
        severity: 'error',
        duration: 3000,
      }))
      return;
    }
    let errorMessage: string | null = null;
    for (const field of operationFields) {
      if (!field.selectedOpe) {
        errorMessage = "依頼可能作業を選択してください。";
        break;
      }
      if (!field.price) {
        errorMessage = "単価を入力してください。";
        break;
      } else if (isNaN(Number(field.price))) {
        errorMessage = "単価は数値である必要があります。";
        break;
      } else if (Number(field.price) <= 0) {
        errorMessage = "単価は0以上である必要があります。";
        break;
      }
    }
    if (errorMessage) {
      dispatch(showMessage({
        message: errorMessage,
        severity: 'error',
        duration: 3000,
      }))
      return;
    }

    const http = new HttpRequest<RegistRequest, RegistResponse>();
    const outsourceUsers: TableRow[] = rows.map(m => {
      const content: Cell[] = m.content.map(mm => {
        return { id: mm.id ?? '', value: mm.value ?? '' };
      });
      return { content: content };
    });
    const tasks: Tasks[] = operationFields.map(m => {
      return { id: m.id, selectedOpe: m.selectedOpe.value, price: m.price };
    });
    http.request = {
      outsourceName: outsourcingName,
      prefecture: prefecture.label,
      postCode: postNumber.label,
      address: address,
      phoneNumber: tell,
      faxNumber: fax,
      memo: memo,
      outsourceUsers: outsourceUsers,
      tasks: tasks
    };
    await http.post('/outsource/register');
    const response = http.response;
    if (!response?.message) {
      const userData: Row[] = response!.userInfo.map(user => {
        const contents: Title[] = [];
        const fullName: Title = { name: user.fullName, align: 'center', isNowrap: true, type: 'TEXT' };
        const mail: Title = { name: user.mail, align: 'center', isNowrap: true, type: 'TEXT' };
        const password: Title = { name: user.password, align: 'center', isNowrap: true, type: 'TEXT' };
        contents.push(fullName);
        contents.push(mail);
        contents.push(password);
        return { content: contents };
      });
      setOutsourceId(response!.outsourceId);
      setUserInfo(userData);
      setOpen(true)
    } else {
      dispatch(showMessage({
        message: '登録に失敗しました',
        severity: 'error',
        duration: 3000,
      }));
    }
  };

  return (
    <div>
      <div>
        <Header />
      </div>

      <div>
        {/* パンくずリスト */}
        <div className="d-flex" style={{ margin: '50px 50px 0px 50px' }}>
          <div onClick={() => gotoPage('/outsourcing_management')}>
            <Typography
              fontWeight={'bold'}
              fontSize={'large'}
              style={{ color: 'gray', cursor: 'pointer' }}
            >
              外注先管理
            </Typography>
          </div>

          <div style={{ width: '20px' }}></div>
          <ArrowForwardIcon />
          <div style={{ width: '20px' }}></div>
          <Typography fontWeight={'bold'} fontSize={'large'}>新規登録</Typography>
        </div>
      </div>

      {/* 登録フォーム */}
      <div className="mx-5 my-5">
        <div>
          {/* 基本情報 */}
          <div className="row">
            <div className="col-6" >
              <div className="my-3">
                <BasicTextField fullwidth label="外注先名" setValue={setOutsourcingName} />
              </div>
              <div className="my-3 row">
                <div className="col">
                  {/* 都道府県ドロップダウンリスト */}
                  <PrefectureDropdown value={prefecture} setValue={setPrefecture} />
                </div>
                <div className="col">
                  {/* 郵便番号 */}
                  <PostCodeDropdown value={postNumber} setValue={setPostNumber} />
                </div>
              </div>
              <div className="my-3">
                <BasicTextField fullwidth label="住所" setValue={setAddress} />
              </div>
              <div className="my-3">
                <BasicTextField fullwidth label="TELL" setValue={setTell} />
              </div>
              <div className="my-3">
                <BasicTextField fullwidth label="FAX" setValue={setFax} />
              </div>
              <div className="my-3">
                {operationFields.map((field, index) => (
                  <div key={`operations-${index}`} className="row">
                    <div className="col-5 my-2">
                      {/* 依頼可能作業 */}
                      <RequestableTaskDropdown value={field.selectedOpe} setValue={(val: Dropdown) => {
                        const tmp = [...operationFields]
                        setOperationFields(tmp.map(m => {
                          if (field.id === m.id) {
                            m.selectedOpe = val
                          }
                          return m
                        }))
                      }} />
                    </div>
                    <div className="col-5 my-2">
                      <BasicTextField
                        fullwidth
                        label="単価"
                        value={field.price}
                        setValue={(e: any) => {
                          handlePriceChange(index, e);
                        }}
                      />
                    </div>
                    <div className="col-2 my-2">
                      <IconButton
                        key={`sc-remove-button-${index}`}
                        sx={{ backgroundColor: 'red', scale: '0.8', ":hover": { backgroundColor: 'lightpink' } }}
                        onClick={() => operationFieldDelHandler(field)}
                      >
                        <RemoveIcon key={`key-sc-remove-icon-${index}`} sx={{ color: 'white' }} />
                      </IconButton>
                    </div>
                  </div>
                ))}
                <div className="my-3 row">
                  <div className="col-10">
                    <BasicButton
                      fullWidth displayName="作業を追加"
                      backColor="white" hoverBackColor="green" fontColor="black" hoverFontColor="white"
                      startIcon={<AddIcon />}
                      onClick={addOperationField}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="my-3 col-6" style={{ paddingLeft: '5%' }}>
              <BasicTextField fullwidth multiline rows={8} label="メモ" setValue={setMemo} />
            </div>
          </div>

          <div className="">

          </div>

          {/* 担当者一覧 */}
          <div>
            <div className="my-3">
              <div><Typography>担当者</Typography></div>
              <div>
                <BasicTable headerColor="midnightblue" headers={headers} content={rows} setTableData={setRows} />
              </div>
            </div>
            <div>
              <BasicButton
                fullWidth displayName="担当者を追加"
                backColor="white" hoverBackColor="green" fontColor="black" hoverFontColor="white"
                startIcon={<AddIcon />}
                onClick={addHuman}
              />
            </div>
            <div className="my-3" style={{ textAlign: 'end' }}>
              <BasicButton
                displayName="登録" width={'20%'}
                backColor="green" fontColor="white" hoverBackColor="mediumseagreen"
                onClick={registration}
              />
            </div>
          </div>
        </div>
      </div>
      <BasicDialog
        open={open}
        close={() => setOpen(false)}
        title={'ユーザー登録完了通知'}
        content={
          <div>
            <Typography>パスワードは1度しか見れませんので、ご注意ください。</Typography>
            <BasicTable
              headers={[
                { name: '氏名', align: 'center', isNowrap: true, type: 'TEXT' },
                { name: 'email', align: 'center', isNowrap: true, type: 'TEXT' },
                { name: 'パスワード', align: 'center', isNowrap: true, type: 'TEXT' },
              ]}
              content={userInfo}
            />
          </div>
        }
        positiveButtonName={'OK'}
        positiveAction={() => {
          setOpen(false);
          nav('/outsourcing_management/detail', { state: { id: outsourceId } });
        }}
      />
    </div >
  );
}