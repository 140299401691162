import BasicDropdown, { Dropdown } from "./basicDropdown";

type Props = {
  titleName?: string
  value: Dropdown;
  setValue: (val: Dropdown) => void
};
export default function PostCodeDropdown(props: Props) {
  return (
    <BasicDropdown
      fullWidth
      titleName={props.titleName ?? '郵便番号'}
      value={props.value}
      items={[]}
      onChange={(val: Dropdown) => props.setValue(val)}
      lazyRequestUrl={'/common/master/postcode'}
    />
  );
}