import { Typography } from "@mui/material";
import Header from "../../../../components/header/header";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useNavigate } from "react-router-dom";
import React from "react";
import FileUploadBox from "../../../../components/file/fileUploadBox";

// 企業データベース -> 企業取り込み（補完）ページ
export default function CompanySuggestionPage() {
  const nav = useNavigate();

  const gotoPage = (url: string) => {
    nav(url);
  };

  return (
    <div>
      <div>
        <Header />
      </div>

      <div>
        {/* パンくずリスト */}
        <div className="d-flex" style={{ margin: '50px 50px 0px 50px' }}>
          <div onClick={() => gotoPage('/company_database')}>
            <Typography
              fontWeight={'bold'}
              fontSize={'large'}
              style={{ color: 'gray', cursor: 'pointer' }}
            >
              企業データベース管理
            </Typography>
          </div>

          <div style={{ width: '20px' }}></div>
          <ArrowForwardIcon />
          <div style={{ width: '20px' }}></div>
          <Typography fontWeight={'bold'} fontSize={'large'}>企業取り込み（補完）</Typography>
        </div>

        {/* ファイルアップロード */}
        <div>
          <div className="mx-5 my-5">
            <FileUploadBox httpUrl="/database/import/complement" method="POST" />
          </div>
        </div>
      </div>
    </div >
  );
}