import { Typography } from "@mui/material"
import React from "react"
import "../../styles/report/OrderReport.css"

type Props = {
  /** 帳票番号 */
  reportNumber: number
  /** 外注先名 */
  outsourceName: string
  /** 件名 */
  title: string
  /** 送信期間（開始） */
  startDate: string
  /** 送信期間（完了） */
  endDate: string
  /** 会社名 */
  companyName: string
  /** 郵便番号 */
  postNumber: string
  /** 住所（都道府県＋市＋住所） */
  address: string
  /** TEL */
  phoneNumber: string
  /** FAX */
  faxNumber: string
  /** 外注先担当者名 */
  operatorName: string
  /** IB担当者名 */
  ibOperatorName: string
  /** リスト名 */
  listName: string
  /** 数量 */
  qty: string
  /** 単価 */
  price: string
  /** 小計 */
  subtotalAmount: string
  /** 消費税 */
  taxAmout: string
  /** 合計金額 */
  totalAmount: string
}

export default function OrderReport(props: Props) {
  return (
    <div style={{ width: '100%' }}>
      <div className="my-4" style={{ textAlign: 'center' }}>
        <Typography variant='h5' style={{ fontWeight: 'bold' }}>{'発 注 書'}</Typography>
      </div>

      {/* TOP */}
      <div className="row">
        {/* LEFT */}
        <div className="col-7 mx-3" style={{ backgroundColor: '', width: '480px' }}>
          <div className="row mx-3">
            <Typography className="col-9" variant='h6' sx={{ borderBottom: '1px solid black' }}>{props.outsourceName}</Typography>
            <Typography className="col-2" variant='h6' sx={{ whiteSpace: 'nowrap' }}>{'御中'}</Typography>
          </div>
          <div className="row mx-3 my-1 text-center">
            <Typography className="col-9" variant='inherit' sx={{ whiteSpace: 'nowrap' }}>{`ご担当： ${props.operatorName} 様`}</Typography>
          </div>
          <div className="row mx-3" style={{ marginTop: '30px', width: '400px', textAlign: 'start' }}>
            <Typography className="col" variant='h6' sx={{ borderBottom: '3px solid black', fontWeight: 'bold' }}>{`件名：${props.title}`}</Typography>
          </div>
          <div className="row mx-3 my-1 text-center">
            <Typography className="col-9" variant='inherit' sx={{ whiteSpace: 'nowrap' }}>{'下記の通り、発注いたします。'}</Typography>
          </div>
          <div className="row mx-2 my-5">
            <Typography className="col-3" variant='inherit' sx={{ whiteSpace: 'nowrap' }}>{'送信期間：'}</Typography>
            <Typography className="col-8" variant='inherit' sx={{ borderBottom: '1px solid black' }}>
              {`開始 ${props.startDate} ～ 完了 ${props.endDate}`}
            </Typography>
          </div>
          <div className="row mx-3" style={{ marginTop: '30px', width: '400px', textAlign: 'start', borderBottom: 'double black' }}>
            <Typography className="col" variant='h6' sx={{ fontWeight: 'bold' }}>{'合計金額'}</Typography>
            <Typography className="col" variant='h6' sx={{ fontWeight: 'bold', textAlign: 'center' }}>{`￥${props.totalAmount}`}</Typography>
            <Typography className="col" variant='inherit' sx={{ marginTop: '2px', textAlign: 'end' }}>{'(税込)'}</Typography>
          </div>
        </div>

        {/* RIGHT */}
        <div className="col-5 row" style={{}}>
          <div className="row my-4 mx-1">
            <Typography className="col-6" variant='inherit' sx={{}}>{'発注日'}</Typography>
            <Typography className="col-6" variant='inherit' sx={{ whiteSpace: 'nowrap', textAlign: 'center' }}>
              {(new Date()).toLocaleDateString().replace('/', '年').replace('/', '月') + '日'}
            </Typography>
          </div>
          <div className="row mx-1">
            <Typography className="col" variant='inherit' sx={{ textAlign: 'start' }}>{props.companyName}</Typography>
          </div>
          <div className="row mx-1">
            <Typography className="col" variant='inherit' sx={{ textAlign: 'start' }}>{`〒${props.postNumber}`}</Typography>
          </div>
          <div className="row mx-1">
            <Typography className="col" variant='inherit' sx={{ textAlign: 'start', wordWrap: 'break-word', width: '380px' }}>{props.address}</Typography>
          </div>
          <div className="row mx-1">
            <Typography className="col" variant='inherit' sx={{ textAlign: 'start' }}>{`TEL：${props.phoneNumber}`}</Typography>
          </div>
          <div className="row mx-1">
            <Typography className="col" variant='inherit' sx={{ textAlign: 'start' }}>{`FAX：${props.faxNumber}`}</Typography>
          </div>
          <div className="row mx-1">
            <Typography className="col" variant='inherit' sx={{ textAlign: 'start' }}>{`担当：${props.ibOperatorName}`}</Typography>
          </div>
        </div>
      </div>

      {/* BOTTOM (Table) */}
      <div className="my-4 mx-4">
        <table id={'order-table'}>
          <thead id={'order-thead'}>
            {/* Header */}
            <tr>
              <th className={'width-small'} scope={"col"}>{'No.'}</th>
              <th scope={"col"} colSpan={4}>{'摘要'}</th>
              <th className={'qty'} scope={"col"}>{'数量'}</th>
              <th scope={"col"}>{'単価'}</th>
              <th scope={"col"}>{'小計'}</th>
            </tr>
          </thead>
          <tbody id={'order-tbody'}>
          {/* Row 1 */}
          <tr>
            <td>{'1'}</td>
            <td colSpan={4}>{`${props.outsourceName}様 ${props.listName}`}</td>
            <td>{`${props.qty}件`}</td>
            <td className={'price'}>{`¥${props.price}`}</td>
            <td className={'price'}>{`¥${props.subtotalAmount}`}</td>
          </tr>
          {/* Row 2 */}
          <tr>
            <td rowSpan={11}>{''}</td>
            <td className={'width-small amount-title'}>{''}</td>
            <td className={'width-small amount-title'}>{''}</td>
            <td className={'amount-title'}>{''}</td>
            <td className={'amount-title'}>{''}</td>
            <td className={'amount-title'}>{''}</td>
            <td rowSpan={11} className={'price'}>{''}</td>
            <td rowSpan={11} className={'price'}>{''}</td>
          </tr>
          {/* Row 3 */}
          <tr>
            <td className={'width-small'}>{''}</td>
            <td className={'width-small'}>{''}</td>
            <td>{''}</td>
            <td>{''}</td>
            <td>{''}</td>
          </tr>
          {/* Row 4 */}
          <tr>
            <td className={'width-small'}>{''}</td>
            <td className={'width-small'}>{''}</td>
            <td>{''}</td>
            <td>{''}</td>
            <td>{''}</td>
          </tr>
          {/* Row 5 */}
          <tr>
            <td className={'width-small'}>{''}</td>
            <td className={'width-small'}>{''}</td>
            <td>{''}</td>
            <td>{''}</td>
            <td>{''}</td>
          </tr>
          {/* Row 6 */}
          <tr>
            <td className={'width-small'}>{''}</td>
            <td className={'width-small'}>{''}</td>
            <td>{''}</td>
            <td>{''}</td>
            <td>{''}</td>
          </tr>
          {/* Row 7 */}
          <tr>
            <td className={'width-small'}>{''}</td>
            <td className={'width-small'}>{''}</td>
            <td>{''}</td>
            <td>{''}</td>
            <td>{''}</td>
          </tr>
          {/* Row 8 */}
          <tr>
            <td className={'width-small'}>{''}</td>
            <td className={'width-small'}>{''}</td>
            <td>{''}</td>
            <td>{''}</td>
            <td>{''}</td>
          </tr>
          {/* Row 9 */}
          <tr>
            <td className={'width-small'}>{''}</td>
            <td className={'width-small'}>{''}</td>
            <td>{''}</td>
            <td>{''}</td>
            <td>{''}</td>
          </tr>
          {/* Row 10 */}
          <tr>
            <td className={'width-small'}>{''}</td>
            <td className={'width-small'}>{''}</td>
            <td>{''}</td>
            <td>{''}</td>
            <td>{''}</td>
          </tr>
          {/* Row 11 */}
          <tr>
            <td className={'width-small'}>{''}</td>
            <td className={'width-small'}>{''}</td>
            <td>{''}</td>
            <td>{''}</td>
            <td>{''}</td>
          </tr>
          {/* Row 12 */}
          <tr>
            <td className={'width-small'}>{''}</td>
            <td className={'width-small'}>{''}</td>
            <td>{''}</td>
            <td>{''}</td>
            <td>{''}</td>
          </tr>
          {/* Row 13 */}
          <tr>
            <td className={'width-small'}>{''}</td>
            <td colSpan={4} className={'width-small'}>{''}</td>
            <td>{''}</td>
            <td>{''}</td>
            <td>{''}</td>
          </tr>
          {/* subtotal amount */}
          <tr>
            <td id={'amount-area'} rowSpan={3} colSpan={5} className={'width-small'}>{''}</td>
            <td className={'amount-title'}>{'小計'}</td>
            <td colSpan={2} className={'price'}>{`¥${props.subtotalAmount}`}</td>
          </tr>
          {/* tax amount */}
          <tr>
            <td className={'amount-title'}>{'消費税'}</td>
            <td colSpan={2} className={'price'}>{`¥${props.taxAmout}`}</td>
          </tr>
          {/* total amount */}
          <tr>
            <td className={'amount-title'}>{'合計'}</td>
            <td colSpan={2} className={'price'}>{`¥${props.totalAmount}`}</td>
          </tr>
          </tbody>
        </table>

      {/* memo */}
      <div className="my-4">
        <table id={'memo-table'}>
          <tbody id={'memo-tbody'}>
          <tr>
            <td id={'memo-title'}>{'備考'}</td>
            <td>{''}</td>
          </tr>
          </tbody>
        </table>
      </div>
      </div>
    </div>
  )
}