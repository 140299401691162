import {Box, Typography} from "@mui/material";
import React, { useEffect, useState } from "react";
import BasicButton from "../../../components/button/basicButton";
import BasicDatePicker from "../../../components/datapicker/basicDatePicker";
import Header from "../../../components/header/header";
import BasicTable, { Row, Title } from "../../../components/table/basicTable";
import BasicTextField from "../../../components/text/basicTextField";
import HttpData from "../../../global/http/HttpData";
import HttpRequest from "../../../global/http/HttpRequest";
import {OutsourceHeader} from "../../../components/header/outsourceHeader";

interface ContactTableData {
  locationName: string
  userName: string
  count: number
}

interface SearchRequest extends HttpData {
  startDate: string
  endDate: string
  locationName: string
  userName: string
  subjectName: string
}

interface SearchResponse extends HttpData {
  aggregationList: ContactTableData[]
}

export default function ContactPage() {
  // APIから取得したテーブルデータ格納用
  const [tableData, setTableData] = useState<ContactTableData[]>([])
  // APIから取得したテーブルデータ格納用
  const [dispTableData, setDispTableData] = useState<Row[]>([])

  // 検索条件
  const [startDate, setStateDate] = useState<string>('')
  const [endDate, setEndDate] = useState<string>('')
  const [baseName, setBaseName] = useState<string>('')
  const [userName, setUserName] = useState<string>('')
  const [subject, setSubject] = useState<string>('')

  useEffect(() => {
    search()
  }, [])

  useEffect(() => {
    const temp: ContactTableData[] = [...tableData]
    temp.sort((b, a) => a.locationName.localeCompare(b.locationName))
    const baseList: string[] = Array.from(new Set(temp.map(m => m.locationName)))

    let rows: Row[] = []
    for (let i = 0; i < baseList.length; i++) {
      const masterData: ContactTableData[] = [...temp]
      const filterData: ContactTableData[] = masterData.filter(f => f.locationName === baseList[i])

      let total = 0
      filterData.forEach(f => total += f.count)
      const firstRow: Title[] = [
        { name: baseList[i], align: 'center', type: 'TEXT' },
        { name: '', align: 'left', type: 'TEXT' },
        { name: `${total}件`, align: 'left', type: 'TEXT' },
      ]
      rows = rows.concat({ content: firstRow })

      const tempRows: Row[] = filterData.map((m, index) => {
        const data: Title[] = [
          { name: '', align: 'center', type: 'TEXT' },
          { name: m.userName, align: 'left', type: 'TEXT' },
          { name: `${m.count}件`, align: 'left', type: 'TEXT' },
        ]
        return { content: data }
      })
      rows = rows.concat(tempRows)
    }
    setDispTableData(rows)
  }, [tableData])

  // 検索
  const search = async () => {
    const http = new HttpRequest<SearchRequest, SearchResponse>()
    http.request = {
      startDate: startDate,
      endDate: endDate,
      locationName: baseName,
      userName: userName,
      subjectName: subject,
    }
    const res = await http.post('/report/search')
    if (res) {
      setTableData(http.response!.aggregationList)
    }
  }

  return (
    <div>
      <div>
        <OutsourceHeader />
      </div>

      {/* パンくずリスト */}
      <div className="d-flex" style={{ margin: '50px 50px 0px 50px' }}>
        <div onClick={() => null}>
          <Typography fontWeight={'bold'} fontSize={'large'} style={{ cursor: 'pointer' }}>
            {'帳票'}
          </Typography>
        </div>
      </div>
      {/* パンくずリスト */}

      {/* メイン */}
      <div className="container my-3 mx-5">
        {/* 検索条件設定スペース */}
        <div className="d-flex">
          <div className="">
            <BasicDatePicker
              title={'期間（開始日）'}
              iconPosition={'startAdornment'}
              isClearable
              value={startDate}
              setValue={(val: string | Date | null) => {
                if (typeof val === 'string') {
                  setStateDate(val)
                  return
                }
                setStateDate('')
              }}
            />
          </div>
          <div className="" style={{ display: 'flex' }}>
            <Typography variant="inherit" sx={{ margin: 'auto 0', minWidth: '30px', textAlign: 'center' }}>{'～'}</Typography>
          </div>
          <div className="">
            <BasicDatePicker
              title={'期間（終了日）'}
              iconPosition={'startAdornment'}
              isClearable
              value={endDate}
              setValue={(val: string | Date | null) => {
                if (typeof val === 'string') {
                  setEndDate(val)
                  return
                }
                setEndDate('')
              }}
            />
          </div>
          <div className="" style={{ display: 'flex' }}>
            <Typography variant="inherit" sx={{ margin: 'auto 0', minWidth: '30px', textAlign: 'center' }}>{''}</Typography>
          </div>
          <div className="">
            <BasicTextField
              label={'拠点'}
              value={baseName}
              setValue={setBaseName}
            />
          </div>
          <div className="" style={{ display: 'flex' }}>
            <Typography variant="inherit" sx={{ margin: 'auto 0', minWidth: '30px', textAlign: 'center' }}>{''}</Typography>
          </div>
          <div className="">
            <BasicTextField
              label={'利用者名'}
              value={userName}
              setValue={setUserName}
            />
          </div>
          <div className="" style={{ display: 'flex' }}>
            <Typography variant="inherit" sx={{ margin: 'auto 0', minWidth: '30px', textAlign: 'center' }}>{''}</Typography>
          </div>
          <div className="">
            <BasicTextField
              label={'案件名'}
              value={subject}
              setValue={setSubject}
            />
          </div>
          <div className="" style={{ display: 'flex' }}>
            <Typography variant="inherit" sx={{ margin: 'auto 0', minWidth: '30px', textAlign: 'center' }}>{''}</Typography>
          </div>
          <div className="">
            <BasicButton
              displayName={'検索'}
              onClick={() => search()}
            />
          </div>
        </div>
        {/* 設定済み検索条件参照スペース */}
        {/*<Box className="d-flex my-4">*/}
        {/*  <BasicTextField*/}
        {/*    fullwidth*/}
        {/*    label={'検索条件'}*/}
        {/*    setValue={() => null}*/}
        {/*    InputProps={*/}
        {/*      { disabled: true }*/}
        {/*    }*/}
        {/*  />*/}
        {/*</Box>*/}

        {/* テーブル */}
        <div className="my-4">
          <BasicTable
            backgroundColor={'whitesmoke'}
            headers={[
              { name: '拠点名', align: 'center', type: 'TEXT' },
              { name: '利用者名', align: 'left', type: 'TEXT' },
              { name: '作業件数', align: 'left', type: 'TEXT' }
            ]}
            content={dispTableData}
            height={'60vh'}
            setTableData={setDispTableData}
          />
        </div>
      </div>
      {/* メイン */}

    </div>
  )
}