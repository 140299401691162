import { createSlice } from "@reduxjs/toolkit";

export interface SnackbarState {
  open: boolean;
  message: string;
  severity: "success" | "info" | "warning" | "error" | undefined;
  duration: number;
}

export const initialState: SnackbarState = {
  open: false,
  message: '',
  severity: undefined,
  duration: 3000,
}

type Payload = {
  message?: string;
  severity: "success" | "info" | "warning" | "error" | undefined;
  duration: number;
}

const snackbarSlice = createSlice({
  name: 'snackbar',
  initialState,
  reducers: {
    showMessage: (state, action: { payload: Payload }) => {
      state.open = true;
      state.message = action.payload.message || '';
      state.severity = action.payload.severity;
      state.duration = action.payload.duration;
    },
    closeMessage: (state) => {
      state.open = false;
      state.duration = 3000;
    }
  }
})

export const { showMessage, closeMessage } = snackbarSlice.actions;
export default snackbarSlice.reducer;