import React, { useState, useEffect } from 'react';

import DataRequestSender from "../http/DataRequestSender";

// ロール
export enum UserRole {
  Admin = 'update',
  User = 'User',
  Guest = 'Guest'
}

// 認証状態定義
export interface AuthState {
  isAuthenticated: boolean;
  userPermissions: string[];
  isLoading: boolean;  // ローディング状態を追加
}

// ユーザーのロールを取得
async function getUserPermissions(): Promise<string[]> {
  try {
    const DataRequestItems = new DataRequestSender();

    const response = await DataRequestItems.upload('/role/get')
    return response.data;
  } catch (error) {
    console.error('Error fetching user permissions:', error);
    return [];
  }
  // return []
}

// 認証状態管理
export function useAuthState() {
  const [authState, setAuthState] = useState<AuthState>({
    isAuthenticated: false,
    userPermissions: [],
    isLoading: true  // 初期状態はローディング中
  });

  useEffect(() => {

    const fetchPermissions = async () => {
      const permissions = await getUserPermissions();
      setAuthState({
        isAuthenticated: permissions.length > 0,
        userPermissions: permissions,
        isLoading: false });
    };

    fetchPermissions();
  }, []);

  return authState;
}