import HttpData from "../global/http/HttpData"
import { OutsourceRole } from "./roles"

export interface OutsourceLocationListRequest extends HttpData{
  operatorLocationSid: number
}

export interface OutsourceLocationRequest extends HttpData{
  location_id: string
}

export interface OutsourceLocationInputRequest extends OutsourceLocationData{
}
export interface OutsourceLocationData extends HttpData{
  locationId?: string
  locationName: string
  locationAddress: string
}
export class OutsourceLocationListResponse{
  result: string = ""
  message: string = ""
  outsourceLocationNames: OutsourceLocation[] = []
}

export class OutsourceLocationDeleteResponse{
  result: boolean = false
}

export class OutsourceLocation{
  locationId?: string
  locationName: string = ""
  locationAddress: string = ""
  locationManagerList: string[] = []
}
// 拠点管理 入力フォーム
export class OutsourceLocationInput {
  locationId?: string
  locationName: string = ""
  locationAddress: string = ""
  locationManagerList: string[] = []
}

export const BASE_ACCOUNT_ROLE = [
  OutsourceRole.BASE_CREATE,
  OutsourceRole.BASE_READ,
  OutsourceRole.BASE_UPDATE,
  OutsourceRole.BASE_DELETE
]