import { useNavigate } from 'react-router-dom';
import '../../styles/header/header.css';
import { Typography } from "@mui/material";
import HttpData from "../../global/http/HttpData";
import HttpRequest from "../../global/http/HttpRequest";
import { showMessage } from "../../global/store/snackbar";
import { useDispatch } from "react-redux";
import { logout as storeLogout } from "../../global/store/auth";
import useRole from "../../global/hook/roleHook";
import { IbRole } from "../../@types/roles";

export default function Header() {
  const nav = useNavigate();
  const dispatch = useDispatch();
  const hasRole = useRole()

  /**
   * 画面遷移用関数
   * @param url 画面遷移URL
   */
  const gotoPage = (url: string): void => {
    nav(url);
  };

  const logout = async () => {
    const http = new HttpRequest<HttpData, HttpData>()
    await http.post('/logout')
    gotoPage('/')
    dispatch(storeLogout())
    dispatch(showMessage({
      message: 'ログアウトしました',
      severity: 'success',
      duration: 3000,
    }))
  }

  return (
    <div className="d-flex" style={{ backgroundColor: '#EEEEEE' }}>
      {/* タイトル */}
      <div className="mx-2 my-2" onClick={() => gotoPage('/top')}>
        <Typography className='menu' align='left' fontWeight='bold' fontSize={'large'}>営業代行メール版管理システム</Typography>
      </div>

      {/* メニュー */}
      <div className="d-flex" style={{ marginLeft: 'auto', paddingTop: '9px' }}>
        <div className="my-1 mx-3" onClick={() => gotoPage('/system_management')}>
          <Typography className="menu" fontSize={'small'}>システム管理</Typography>
        </div>

        {hasRole([IbRole.COMPANY_READ]) &&
          <div className="my-1" onClick={() => gotoPage('/company_database')}>
            <Typography className="menu" fontSize={'small'}>企業データベース</Typography>
          </div>
        }

        <div className="my-1 mx-3" onClick={() => gotoPage('/client_management')}>
          <Typography className="menu" fontSize={'small'}>顧客管理</Typography>
        </div>

        <div className="my-1" onClick={() => gotoPage('/outsourcing_management')}>
          <Typography className="menu" fontSize={'small'}>外注先管理</Typography>
        </div>

        <div
          className="my-1 mx-3"
          style={{ marginRight: '30px' }}
          onClick={() => logout()}>
          <Typography className="menu" fontSize={'small'}>ログアウト</Typography>
        </div>

      </div>


    </div>
  );
};