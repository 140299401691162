import Typography from "@mui/material/Typography";
import BasicTable, { Row, Title } from "../../components/table/basicTable";
import React, { useEffect, useState } from "react";
import BasicButton from "../../components/button/basicButton";
import BasicTextField from "../../components/text/basicTextField";
import HttpRequest from "../../global/http/HttpRequest";
import { useDispatch } from "react-redux";
import { showMessage } from "../../global/store/snackbar";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import DoDisturbIcon from '@mui/icons-material/DoDisturb';
import {
  ACCOUNT_ROLE,
  ACCOUNT_SUPER_ROLE,
  ALL_ACCOUNT_ROLE,
  BASE_ACCOUNT_ROLE,
  BASE_RATION_ROLE,
  BASE_ROLE,
  OUTSOURCE_ROLE_KEY,
  OutsourceRoleKey,
  OutsourceUser,
  OutsourceUserData,
  OutsourceUserInput, OutsourceUserInputRequest,
  OutsourceUserListRequest,
  OutsourceUserListResponce,
  OutsourceUserRequest,
  REPORT_SELECT_ALL_ROLE, REPORT_SELECT_BASE_ROLE, REPORT_SELECT_ROLE,
  REPORT_SELECT_SUPER_ROLE, REPORT_SELECT_USER_ROLE,
  SUBJECT_RATION_ROLE,
  USER_ACCOUNT_ROLE,
  USER_RATION_ROLE
} from "../../@types/outsourceUser";
import useRole from "../../global/hook/roleHook";
import { OutsourceRole } from "../../@types/roles";
import BasicCheckbox from "../../components/checkbox/basicCheckBox";
import HttpData from "../../global/http/HttpData";
import { OutsourceHeader } from "../../components/header/outsourceHeader";
import { InputLabel, MenuItem, Select } from "@mui/material";

type RoleObject = {
  enName: string
  jaName: string
  isValid: boolean
}

export default function OutsourceUserManagementPage() {
  const hasRole = useRole()

  //// 定数 ////
  const headers: Title[] = [
    { name: '利用者氏名', align: 'left' },
  ];
  const labelWidth = '20%';
  const dataWidth = '80%';
  const dataWidthHalf = '40%';

  const [editingFlag, setEditingFlag] = useState<boolean>(true);
  const [tableData, setTableData] = useState<Row[]>([]);

  const [outsourceUserInput, setOutsourceUserInput] =
    useState<OutsourceUserInput>(new OutsourceUserInput());

  const dispatch = useDispatch();

  useEffect(() => {
    getUserList();
  }, []);

  /** 
   * ユーザー情報一覧取得
  */
  const getUserList = async () => {
    const userListHttp = new HttpRequest<OutsourceUserListRequest, OutsourceUserListResponce>();

    if (await userListHttp.get('/outsource/user_management/get_list')) {
      const response = userListHttp.response!

      // テーブルの初期データを設定
      setTableData(response.outsourceUserNames.map((p: OutsourceUser) => ({
        content: [
          { name: <div onClick={() => handleOutsourceUser(p.outsourceUserId)} style={{ width: '100%', textAlign: 'left' }}>{p.outsourceUserName}</div>, align: 'left', type: 'TEXT' },
        ]
      })));
    }
  }

  /**
   * ユーザー一覧で選択したユーザーの情報取得、反映
   * @param outsourceUserId
   */
  const handleOutsourceUser = async (outsourceUserId: string) => {
    // 選択したユーザーのユーザー名、ユーザーIDでDBを検索
    const userHttp = new HttpRequest<OutsourceUserRequest, OutsourceUserData>()
    userHttp.request = {
      outsource_user_id: outsourceUserId,
    }

    // 選択したセルのデータを入力フォームに反映
    if (await userHttp.get('/outsource/user_management/get_user_data')) {
      // 入力フォームに取得したデータを設定
      const response = userHttp.response!

      const user = {
        userId: response.userId,
        userFamilyName: response.userFamilyName,
        userName: response.userName,
        userFamilyNameHira: response.userFamilyNameHira,
        userNameHira: response.userNameHira,
        mailAddress: response.mailAddress,
        password: response.password,
        phoneNumber: response.phoneNumber,
        location: response.location,
        locked: response.locked,
        memo: response.memo,
        roles: response.roles,
        canDelete: response.canDelete,
        roleKey: response.roleKey
      }
      setOutsourceUserInput({ ...outsourceUserInput, ...user })
      // 初期フラグをFalseに変更
      setEditingFlag(false)
    }
  }

  const registration = async () => {
    const userInputHttp = new HttpRequest<OutsourceUserInputRequest, HttpData>()
    userInputHttp.request = outsourceUserInput
    if (await userInputHttp.post('/outsource/user_management/register_user_data')) {
      dispatch(showMessage({
        message: '登録が完了しました。',
        severity: "success",
        duration: 3000,
      }))
      await getUserList()
      setEditingFlag(false)
    }
  }

  /**
   * 編集ボタン押下時の処理
   */
  const editing = () => {
    // 編集フラグを有効化し、編集可能にする
    setEditingFlag(true);
  }

  const deleteUser = async () => {
    const http = new HttpRequest<OutsourceUserRequest, HttpData>()
    http.request = { outsource_user_id: outsourceUserInput.userId! }
    if (await http.post('/outsource/user_management/delete_user_data')) {
      dispatch(showMessage({
        message: '削除が完了しました。',
        severity: "success",
        duration: 3000,
      }))
      setEditingFlag(true)
      await getUserList()
    }
  }

  function selectedSomeRole(childRoles: OutsourceRole[]) {
    return outsourceUserInput.roles.some(roles => childRoles.includes(roles))
  }

  function selectedAllRole(roles: OutsourceRole[]) {
    return roles.every(role => outsourceUserInput.roles.includes(role))
  }

  function updateRoles(roles: OutsourceRole[], add: boolean) {
    add
      ? addRoles(roles)
      : removeRoles(roles)
  }

  function addRoles(roles: OutsourceRole[]) {
    const currentRoles = outsourceUserInput.roles
    const newRoles = currentRoles.concat(roles)
    setOutsourceUserInput({ ...outsourceUserInput, roles: newRoles })
  }

  function removeRoles(roles: OutsourceRole[]) {
    const currentRoles = outsourceUserInput.roles
    const newRoles = currentRoles.filter(role => !roles.includes(role))
    setOutsourceUserInput({ ...outsourceUserInput, roles: newRoles })
  }

  const renderAuthority = () => {
    return (
      <div>
        {/* アカウント作成・編集・削除 */}
        <div style={{ marginTop: '0px' }}>
          <div className="row my-1">
            <div className="col">
              {selectedSomeRole(ACCOUNT_SUPER_ROLE) ? <CheckCircleOutlineIcon color="success" /> : <DoDisturbIcon color="error" />}
              {'アカウント作成・編集・削除'}
            </div>
          </div>
          <div className="row my-2">
            <div className="offset-1 col">
              {selectedAllRole(ALL_ACCOUNT_ROLE) ? <CheckCircleOutlineIcon color="success" /> : <DoDisturbIcon color="error" />}
              {'全体管理者アカウント'}
            </div>
          </div>
          <div className="row my-2">
            <div className="offset-1 col">
              {selectedAllRole(BASE_ACCOUNT_ROLE) ? <CheckCircleOutlineIcon color="success" /> : <DoDisturbIcon color="error" />}
              {'拠点管理者アカウント'}
            </div>
          </div>
          <div className="row my-2">
            <div className="offset-1 col">
              {selectedAllRole(USER_ACCOUNT_ROLE) ? <CheckCircleOutlineIcon color="success" /> : <DoDisturbIcon color="error" />}
              {'利用者カアカウント'}
            </div>
          </div>

        </div>

        {/* 拠点作成・編集 */}
        <div style={{ marginTop: '20px' }}>
          <div className="row my-2">
            <div className="col">
              {selectedAllRole(BASE_ROLE) ? <CheckCircleOutlineIcon color="success" /> : <DoDisturbIcon color="error" />}
              {'拠点作成・編集'}
            </div>
          </div>
        </div>

        {/* 案件割り当て */}
        <div style={{ marginTop: '20px' }}>
          <div className="row my-2">
            <div className="col">
              {selectedSomeRole(SUBJECT_RATION_ROLE) ? <CheckCircleOutlineIcon color="success" /> : <DoDisturbIcon color="error" />}
              {'案件割り当て'}
            </div>
          </div>
          <div className="row my-2">
            <div className="offset-1 col">
              {selectedAllRole(BASE_RATION_ROLE) ? <CheckCircleOutlineIcon color="success" /> : <DoDisturbIcon color="error" />}
              {'拠点割り当て'}
            </div>
          </div>
          <div className="row my-2">
            <div className="offset-1 col">
              {selectedAllRole(USER_RATION_ROLE) ? <CheckCircleOutlineIcon color="success" /> : <DoDisturbIcon color="error" />}
              {'利用者割り当て'}
            </div>
          </div>
        </div>

        {/* 帳票表示 */}
        <div style={{ marginTop: '20px' }}>
          <div className="row my-2">
            <div className="col">
              {selectedSomeRole(REPORT_SELECT_SUPER_ROLE) ? <CheckCircleOutlineIcon color="success" /> : <DoDisturbIcon color="error" />}
              {'帳票表示'}
            </div>
          </div>
          <div className="row my-2">
            <div className="offset-1 col">
              {selectedAllRole(REPORT_SELECT_ALL_ROLE) ? <CheckCircleOutlineIcon color="success" /> : <DoDisturbIcon color="error" />}
              {'全体'}
            </div>
          </div>
          <div className="row my-2">
            <div className="offset-1 col">
              {selectedAllRole(REPORT_SELECT_BASE_ROLE) ? <CheckCircleOutlineIcon color="success" /> : <DoDisturbIcon color="error" />}
              {'拠点ごと'}
            </div>
          </div>
          <div className="row my-2">
            <div className="offset-1 col">
              {selectedSomeRole(REPORT_SELECT_USER_ROLE) ? <CheckCircleOutlineIcon color="success" /> : <DoDisturbIcon color="error" />}
              {'利用者ごと'}
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      <div>
        <OutsourceHeader />
      </div>

      <div>
        {/* パンくずリスト */}
        <div className="d-flex" style={{ margin: '50px 50px 0px 50px' }}>
          <div onClick={() => null}>
            <Typography
              fontWeight={'bold'}
              fontSize={'large'}
            >
              {'利用者管理'}
            </Typography>
          </div>
        </div>
      </div>

      <div className="d-flex">
        <div style={{ width: '40%', borderRadius: '4px', padding: '20px 30px 20px 30px' }}>
          <div className='my-2 form-control' >
            <BasicTable
              headerColor="midnightblue"
              headers={headers}
              content={tableData}
              setTableData={setTableData}
            />
          </div>
          <div style={{ float: "right" }}>
            {!editingFlag && <BasicButton
              displayName={"新規登録"}
              onClick={() => {
                setOutsourceUserInput(new OutsourceUserInput())
                setEditingFlag(true)
              }}
            />}
          </div>
        </div>
        {/* 初期表示・新規登録 */}
        {editingFlag &&
          <div style={{ width: '60%', height: '100%', padding: '20px 30px 20px 30px' }}>
            <div className='row my-2 form-control'>
              <Typography variant="h5" sx={{ width: '100%', marginBottom: '5px' }} align="left">
                {'新規登録'}
              </Typography>
              <div className="row">
                <div className="my-2 col">
                  <BasicTextField
                    label="氏"
                    fullwidth
                    size="small"
                    setValue={(value) => setOutsourceUserInput({ ...outsourceUserInput, userFamilyName: value })}
                    value={outsourceUserInput.userFamilyName}
                  />
                </div>
                <div className="my-2 col">
                  <BasicTextField
                    label="名"
                    fullwidth
                    size="small"
                    setValue={(value) => setOutsourceUserInput({ ...outsourceUserInput, userName: value })}
                    value={outsourceUserInput.userName}
                  />
                </div>
              </div>
              <div className="row">
                <div className="my-2 col">
                  <BasicTextField
                    label="氏(ふりがな)"
                    fullwidth
                    size="small"
                    setValue={(value) => setOutsourceUserInput({
                      ...outsourceUserInput,
                      userFamilyNameHira: value
                    })}
                    value={outsourceUserInput.userFamilyNameHira}
                  />
                </div>
                <div className="my-2 col">
                  <BasicTextField
                    label="名(ふりがな)"
                    fullwidth
                    size="small"
                    setValue={(value) => setOutsourceUserInput({ ...outsourceUserInput, userNameHira: value })}
                    value={outsourceUserInput.userNameHira}
                  />
                </div>
              </div>
              <div className="row">
                <div className="my-2 col">
                  <BasicTextField
                    label={"メールアドレス"}
                    fullwidth
                    size="small"
                    setValue={(value) => setOutsourceUserInput({ ...outsourceUserInput, mailAddress: value })}
                    value={outsourceUserInput.mailAddress}
                  />
                </div>
              </div>
              <div className="row">
                <div className="my-2 col">
                  <BasicTextField
                    label={"パスワード"}
                    fullwidth
                    size="small"
                    setValue={(value) => setOutsourceUserInput({ ...outsourceUserInput, password: value })}
                    value={outsourceUserInput.password}
                  />
                </div>
              </div>
              <div className="row">
                <div className="my-2 col">
                  <BasicTextField
                    label={"電話番号"}
                    fullwidth
                    size="small"
                    setValue={(value) => setOutsourceUserInput({ ...outsourceUserInput, phoneNumber: value })}
                    value={outsourceUserInput.phoneNumber}
                  />
                </div>
              </div>
              <div className="row">
                <div className="my-2 col">
                  <BasicTextField
                    label={"拠点名"}
                    fullwidth
                    size="small"
                    setValue={(value) => setOutsourceUserInput({ ...outsourceUserInput, location: value })}
                    value={outsourceUserInput.location}
                  />
                </div>
              </div>
              <div className="row">
                <div className="my-2 col" style={{ textAlign: 'left' }}>
                  <BasicCheckbox
                    label={'ログインロック'}
                    checked={outsourceUserInput.locked}
                    onChange={(event) => {
                      setOutsourceUserInput({ ...outsourceUserInput, locked: event.target.checked })
                    }}
                  />
                </div>
              </div>
              <hr className="my-2" />
              <div className="row">
                <div className="my-2 col" style={{ textAlign: 'left' }}>
                <InputLabel id="ibRole">役割</InputLabel>
                            <Select
                              defaultValue={outsourceUserInput.roleKey}
                              fullWidth
                              labelId="ibRole"
                              label={"役割"}
                              onChange={(e) => setOutsourceUserInput({...outsourceUserInput, roleKey: e.target.value as OutsourceRoleKey})}>
                                <MenuItem value={OUTSOURCE_ROLE_KEY.RK_OUTSOURCE}>
                                  管理者
                                </MenuItem>
                                <MenuItem value={OUTSOURCE_ROLE_KEY.SUBCONSTORACTOR_USER}>
                                 利用者
                                </MenuItem>
                            </Select>
                </div>
              </div>
              {outsourceUserInput.roleKey === OUTSOURCE_ROLE_KEY.RK_OUTSOURCE && (
              <div className="container-fluid">
                {/* ALL_ACCOUNT_CREATE権限を持っている場合 */}
                {hasRole([OutsourceRole.ALL_ACCOUNT_CREATE]) && (
                  <>
                    <div className="row align-items-center">
                      <div className="col">
                        <BasicCheckbox
                          label={'アカウント作成・編集・削除'}
                          checked={selectedSomeRole(ACCOUNT_SUPER_ROLE)}
                          onChange={(e: any, checked) => updateRoles(ACCOUNT_SUPER_ROLE, checked)}
                        />
                      </div>
                    </div>
                    <div className="row align-items-center">
                      <div className="offset-1 col">
                        <BasicCheckbox
                          label={'全体管理者アカウント'}
                          checked={selectedAllRole(ALL_ACCOUNT_ROLE)}
                          onChange={(e: any, checked) => updateRoles(ALL_ACCOUNT_ROLE, checked)}
                        />
                      </div>
                    </div>
                  </>
                )}

                {/* ALL_ACCOUNT_CREATE権限を持っていない場合 */}
                {!hasRole([OutsourceRole.ALL_ACCOUNT_CREATE]) && (
                  <>
                    <div className="row align-items-center">
                      <div className="offset-1 col">
                        <BasicCheckbox
                          label={'アカウント作成・編集・削除'}
                          checked={selectedSomeRole(ACCOUNT_ROLE)}
                          onChange={(e: any, checked) => updateRoles(ACCOUNT_ROLE, checked)}
                        />
                      </div>
                    </div>
                  </>
                )}

                <div className="row align-items-center">
                  <div className="offset-1 col">
                    <BasicCheckbox
                      label={'拠点管理者アカウント'}
                      checked={selectedAllRole(BASE_ACCOUNT_ROLE)}
                      onChange={(e: any, checked) => updateRoles(BASE_ACCOUNT_ROLE, checked)}
                    />
                  </div>
                </div>
                <div className="row align-items-center">
                  <div className="offset-1 col">
                    <BasicCheckbox
                      label={'利用者アカウント'}
                      checked={selectedAllRole(USER_ACCOUNT_ROLE)}
                      onChange={(e: any, checked) => updateRoles(USER_ACCOUNT_ROLE, checked)}
                    />
                  </div>
                </div>

                <hr className="my-2" />
                <div className="row align-items-center">
                  <div className="col">
                    <BasicCheckbox
                      label={'拠点作成・編集'}
                      checked={selectedAllRole(BASE_ROLE)}
                      onChange={(e: any, checked) => updateRoles(BASE_ROLE, checked)}
                    />
                  </div>
                </div>

                <hr className="my-2" />
                <div className="row align-items-center">
                  <div className="col">
                    <BasicCheckbox
                      label={'案件割り当て'}
                      checked={selectedSomeRole(SUBJECT_RATION_ROLE)}
                      onChange={(e: any, checked) => updateRoles(SUBJECT_RATION_ROLE, checked)}
                    />
                  </div>
                </div>
                <div className="row align-items-center">
                  <div className="offset-1 col">
                    <BasicCheckbox
                      label={'拠点割り当て'}
                      checked={selectedAllRole(BASE_RATION_ROLE)}
                      onChange={(e: any, checked) => updateRoles(BASE_RATION_ROLE, checked)}
                    />
                  </div>
                </div>
                <div className="row align-items-center">
                  <div className="offset-1 col">
                    <BasicCheckbox
                      label={'利用者割り当て'}
                      checked={selectedAllRole(USER_RATION_ROLE)}
                      onChange={(e: any, checked) => updateRoles(USER_RATION_ROLE, checked)}
                    />
                  </div>
                </div>

                <hr className="my-2" />
                {/* ALL_ACCOUNT_CREATE権限を持っている場合 */}
                {hasRole([OutsourceRole.ALL_ACCOUNT_CREATE]) && (
                  <>
                    <div className="row align-items-center">
                      <div className="col">
                        <BasicCheckbox
                          label={'帳票表示'}
                          checked={selectedSomeRole(REPORT_SELECT_SUPER_ROLE)}
                          onChange={(e: any, checked) => updateRoles(REPORT_SELECT_SUPER_ROLE, checked)}
                        />
                      </div>
                    </div>
                    <div className="row align-items-center">
                      <div className="offset-1 col">
                        <BasicCheckbox
                          label={'全体'}
                          checked={selectedAllRole(REPORT_SELECT_ALL_ROLE)}
                          onChange={(e: any, checked) => updateRoles(REPORT_SELECT_ALL_ROLE, checked)}
                        />
                      </div>
                    </div>
                  </>
                )}

                {/* ALL_ACCOUNT_CREATE権限を持っている場合 */}
                {!hasRole([OutsourceRole.ALL_ACCOUNT_CREATE]) && (
                  <>
                    <div className="row align-items-center">
                      <div className="col">
                        <BasicCheckbox
                          label={'帳票表示'}
                          checked={selectedSomeRole(REPORT_SELECT_ROLE)}
                          onChange={(e: any, checked) => updateRoles(REPORT_SELECT_ROLE, checked)}
                        />
                      </div>
                    </div>
                  </>
                )}

                <div className="row align-items-center">
                  <div className="offset-1 col">
                    <BasicCheckbox
                      label={'拠点ごと'}
                      checked={selectedAllRole(REPORT_SELECT_BASE_ROLE)}
                      onChange={(e: any, checked) => updateRoles(REPORT_SELECT_BASE_ROLE, checked)}
                    />
                  </div>
                </div>
                <div className="row align-items-center">
                  <div className="offset-1 col">
                    <BasicCheckbox
                      label={'利用者ごと'}
                      checked={selectedAllRole(REPORT_SELECT_USER_ROLE)}
                      onChange={(e: any, checked) => updateRoles(REPORT_SELECT_USER_ROLE, checked)}
                    />
                  </div>
                </div>
              </div>
              )}
              <hr className="my-2" />
              <div className="my-3">
                <BasicTextField
                  label={"メモ"}
                  fullwidth
                  multiline={true}
                  rows={6}
                  value={outsourceUserInput.memo}
                  setValue={(value: string) => setOutsourceUserInput({ ...outsourceUserInput, memo: value })}
                />
              </div>
            </div>
            <div className="row my-2 text-center">
              <BasicButton
                fullWidth
                width={window.innerWidth * 0.6 * 0.5}
                displayName="登録"
                onClick={registration}
              />
            </div>
          </div>
        }

        {/* ユーザー選択後 */}
        {!editingFlag && <div style={{ width: '60%', height: '100%', padding: '20px 30px 20px 30px' }}>
          <div className='row my-2 form-control'>
            <div className="d-flex">
              <div className="m-2">
                <Typography variant="h4" style={{ width: '100%' }} align="left">
                  {outsourceUserInput.userFamilyName} {outsourceUserInput.userName}
                </Typography>
              </div>
              {outsourceUserInput.canDelete &&
                <BasicButton
                  backColor="white"
                  fontColor="black"
                  displayName="削除"
                  onClick={deleteUser}
                />
              }
            </div>
            <div>
              <BasicTable
                headers={[]}
                content={[
                  {
                    content: [
                      { name: 'メールアドレス', align: 'left', isNowrap: true, type: 'TEXT', width: 120 },
                      { name: ':', align: 'left', isNowrap: true, type: 'TEXT', width: 20 },
                      { name: outsourceUserInput.mailAddress, align: 'left', isNowrap: true, type: 'TEXT' },
                    ]
                  },
                  {
                    content: [
                      { name: 'パスワード', align: 'left', isNowrap: true, type: 'TEXT', width: 120 },
                      { name: ':', align: 'left', isNowrap: true, type: 'TEXT', width: 20 },
                      { name: '**************', align: 'left', isNowrap: true, type: 'TEXT' },
                    ]
                  },
                  {
                    content: [
                      { name: '電話番号', align: 'left', isNowrap: true, type: 'TEXT', width: 120 },
                      { name: ':', align: 'left', isNowrap: true, type: 'TEXT', width: 20 },
                      { name: outsourceUserInput.phoneNumber, align: 'left', isNowrap: true, type: 'TEXT' },
                    ]
                  },
                  {
                    content: [
                      { name: '拠点名', align: 'left', isNowrap: true, type: 'TEXT', width: 120 },
                      { name: ':', align: 'left', isNowrap: true, type: 'TEXT', width: 20 },
                      { name: outsourceUserInput.location, align: 'left', isNowrap: true, type: 'TEXT' },
                    ]
                  },
                  {
                    content: [
                      { name: 'ログインロック', align: 'left', isNowrap: true, type: 'TEXT', width: 120 },
                      { name: ':', align: 'left', isNowrap: true, type: 'TEXT', width: 20 },
                      { name: outsourceUserInput.locked ? '有効' : '無効', align: 'left', isNowrap: true, type: 'TEXT' },
                    ]
                  },
                  {
                    content: [
                      { name: 'メモ', align: 'left', isNowrap: true, type: 'TEXT', width: 120 },
                      { name: ':', align: 'left', isNowrap: true, type: 'TEXT', width: 20 },
                      { name: outsourceUserInput.memo, align: 'left', type: 'TEXT' },
                    ]
                  },
                  {
                    content: [
                      { name: '権限', align: 'left', isNowrap: true, type: 'TEXT', width: 120, topPlacement: true },
                      { name: '', align: 'left', isNowrap: true, type: 'TEXT', width: 30 },
                      { name: renderAuthority(), align: 'left', isNowrap: true, type: 'TEXT' },
                    ]
                  },
                ]}
                setTableData={() => null}
              />
            </div>
          </div>
          <div style={{ float: "right" }}>
            {/* button */}
            <BasicButton
              displayName="編集"
              onClick={editing}
            />
          </div>
        </div>}
      </div>
    </div>
  );
}
