import Header from "../../../components/header/header";
import { IconButton, MenuItem, FormControl, InputLabel, Typography, Button, TextField, Select, ButtonGroup } from "@mui/material";
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import PhoneOutlinedIcon from '@mui/icons-material/PhoneOutlined';
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import BasicTabs from "../../../components/tab/basicTab";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import BasicButton from "../../../components/button/basicButton";
import React, { useEffect, useState } from "react";
import AddIcon from '@mui/icons-material/Add';
import BasicDropdown, { Dropdown } from "../../../components/dropdown/basicDropdown";
import BasicTextField from "../../../components/text/basicTextField";
import { showMessage } from "../../../global/store/snackbar";
import { hideLoader, showLoader } from "../../../global/store/loaderSlice";
import BasicTable, { Row, Title } from "../../../components/table/basicTable";
import HttpData from "../../../global/http/HttpData";
import HttpRequest from "../../../global/http/HttpRequest";
import SaveIcon from '@mui/icons-material/Save';
import { deepClone } from "../../../utils/objectHelpers";
import PostCodeDropdown from "../../../components/dropdown/postCodeDropdown";
import RequestableTaskDropdown from "../../../components/dropdown/requestableTaskDropdown";
import BasicDialog from "../../../components/dialog/basicDialog";
import OrderReport from "../../../components/report/orderReport";
import {OrderReportInfo, OrderReportRequest, OrderReportResponse} from "../../../@types/ib/clientManagement/OrderType";

interface BasicInfo {
  outsourceName: string;
  postCode: Dropdown;
  address: string;
  tel: string;
}

interface Operation {
  id: string
  opeName: Dropdown;
  price: string;
  isEdit?: boolean;
}

interface Memo {
  memo: string;
  updateDate: string;
}

interface User {
  userId: string;
  isEdit: boolean;
  familyName: string;
  name: string;
  fullName: string;
  familyNameHiragana: string;
  nameHiragana: string;
  fullNameHiragana: string;
  tel: string;
  mail: string;
  password?: string
}

interface UserDialog {
  open: boolean
  type: 'NEW' | 'EDIT' | 'NOTHING'
}

interface UserInfo {
  id: string
  fullName: string
  mail: string
  password: string
}

interface Order {
  orderId: string
  subjectName: string
  orderDate: string
}

interface CompDialog {
  open: boolean,
  userInfo?: UserInfo
}

/**
 * 初期データ取得用リクエスト
 */
interface InitDataRequest extends HttpData {
  outsourceId: string
}

/**
 * 初期データ取得用レスポンス
 */
interface InitDataResponse extends HttpData {
  basicInfo: BasicInfo;
  operations: Operation[];
  memo: Memo[];
  users: User[];
  orders: Order[];
}

/**
 * 基本情報アップデート用リクエスト
 */
interface UpdateBasicInfoRequest extends BasicInfo, HttpData {
  outsourceId: string
}

/**
 * 作業アップデート用リクエスト
 */
interface UpdateOperationRequest extends Operation, HttpData {
  outsourceId: string
}

/**
 * 作業デリート用リクエスト
 */
interface DeleteOperationRequest extends HttpData {
  lOperationId: string
}

/**
 * メモ追加用リクエスト
 */
interface MemoAddRequest extends HttpData {
  outsourceId: string
  memo: string
}

/**
 * ユーザーアップデート用リクエスト
 */
interface UpdateUserRequest extends User, HttpData {
  outsourceId: string
}

/**
 * ユーザー削除用リクエスト
 */
interface DeleteUserRequest extends HttpData {
  userId: string
}

/**
 * メッセージ受け取り用レスポンス
 */
interface MessageResponse extends HttpData {
  message: string
  severity: "info" | "success" | "warning" | "error"
  userInfo?: UserInfo
}

export default function OutsourcingDetailPage() {
  const nav = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const outsourceId = location.state?.id;

  // 基本情報
  const [basicInfo, setBasicInfo] = useState<BasicInfo>();
  const [basicInfoTableData, setBasicInfoTableData] = useState<Row[]>([]);
  const [tempBasicInfoTableData, setTempBasicInfoTableData] = useState<Row[]>([]);
  const [isEditBasicInfo, setIsEditBasicInfo] = useState<boolean>(false);

  // 依頼可能作業
  const [operations, setOperations] = useState<Operation[]>([]);
  const [operationsTableData, setOperationsTableData] = useState<Row[]>([]);

  // メモ
  const [memos, setMemos] = useState<Memo[]>([]);
  const [editMemo, setEditMemo] = useState<string>('');
  const [isEditMemo, setIsEditMemo] = useState<boolean>(false);
  const [memosCurrentIndex, setMemosCurrentIndex] = useState<number>(0);

  // 担当者
  const [outsourceUsers, setOutsourceUsers] = useState<User[]>([]);
  const [outsourceUsersTableData, setOutsourceUsersTableData] = useState<Row[]>([]);
  const [newUser, setNewUser] = useState<User>({
    userId: '',
    familyName: '',
    familyNameHiragana: '',
    name: '',
    nameHiragana: '',
    fullName: '',
    fullNameHiragana: '',
    mail: '',
    tel: '',
    isEdit: false,
  });
  const [editDialogOpen, setEditDialogOpen] = useState<UserDialog>({ open: false, type: 'NOTHING' });
  const [compUserInfoDialogOpen, setCompUserInfoDialogOpen] = useState<CompDialog>({ open: false, userInfo: { id: '', fullName: '', mail: '', password: '' } });

  // 発注
  const [orders, setOrders] = useState<Order[]>([]);
  const [orderTableData, setOrderTableData] = useState<Row[]>([]);
  const [orderIdForReport, setOrderIdForReport] = useState<string | null>(null);
  const [reportInfo, setReportInfo] = useState<OrderReportInfo>({
    reportNumber: 0,
    outsourceName: '',
    title: '',
    startDate: '',
    endDate: '',
    companyName: '',
    postNumber: '',
    address: '',
    phoneNumber: '',
    faxNumber: '',
    operatorName: '',
    ibOperatorName: '',
    listName: '',
    qty: '',
    price: '',
    subtotalAmount: '',
    taxAmout: '',
    totalAmount: ''
  })

  // 画面遷移
  const gotoPage = (url: string) => {
    nav(url);
  };

  // 初回起動処理
  useEffect(() => {
    dispatch(showLoader())
    fetchInitData();
    dispatch(hideLoader())
  }, []);

  // 基本情報テーブル更新
  useEffect(() => {
    const rows: Row[] = [
      {
        id: 'outsource',
        content: [
          { id: 'title', name: '外注先名：', align: 'left', isNowrap: true, type: 'TEXT', width: 70 },
          { id: 'editData', name: basicInfo?.outsourceName as string, align: 'left', isNowrap: true, type: 'TEXT' },
        ],
      },
      {
        id: 'postCode',
        content: [
          { id: 'title', name: '郵便番号：', align: 'left', isNowrap: true, type: 'TEXT', width: 70 },
          { id: 'editData', name: basicInfo?.postCode.label as string, align: 'left', isNowrap: true, type: 'TEXT' },
        ],
      },
      {
        id: 'address',
        content: [
          { id: 'title', name: '住所：', align: 'left', isNowrap: true, type: 'TEXT', width: 70 },
          { id: 'editData', name: basicInfo?.address as string, align: 'left', isNowrap: true, type: 'TEXT' },
        ],
      },
      {
        id: 'tel',
        content: [
          { id: 'title', name: '電話番号：', align: 'left', isNowrap: true, type: 'TEXT', width: 70 },
          { id: 'editData', name: basicInfo?.tel as string, align: 'left', isNowrap: true, type: 'TEXT' },
        ],
      },
    ];
    setBasicInfoTableData(rows);
  }, [basicInfo]);

  // 基本情報編集モードON
  useEffect(() => {
    if (!isEditBasicInfo) {
      const tmp = deepClone(basicInfo);
      setBasicInfo(tmp);
      return;
    }
    const tmp = [...basicInfoTableData];
    tmp.map((item, rowIndex) => {
      return item.content.map((f, colIndex) => {
        if (f.id === 'editData') {
          if (item.id === 'postCode') {
            f.name = <PostCodeDropdown
              titleName={''}
              value={basicInfo!.postCode}
              setValue={(e: Dropdown) => { basicInfoTableHandleChange(e, item.id!, f.id!, rowIndex, colIndex) }}
            />
            f.type = 'TEXT'
          } else {
            let preData = f.name as string;
            f.value = preData;
            f.name = <BasicTextField
              fullwidth
              label={''}
              defaultValue={preData}
              setValue={(e: React.ChangeEvent<HTMLInputElement>) => basicInfoTableHandleChange(e, item.id!, f.id!, rowIndex, colIndex)}
            />
          }
        }
        return f;
      });
    });
    setBasicInfoTableData(tmp);
  }, [isEditBasicInfo]);

  // 依頼可能作業描画
  useEffect(() => {
    const rows: Row[] = operations.map((ope, index) => {
      const tmp: Title[] = [
        {
          id: ope.id,
          name: ope.isEdit ?
            <RequestableTaskDropdown titleName={''} value={{ label: ope.opeName.label, value: ope.opeName.value }} setValue={(e: Dropdown) => operationTableChangeHandler(e, index)} />
            :
            ope.opeName.label as string,
          align: 'left', type: 'TEXT', isNowrap: true
        },
        {
          id: 'price',
          name: ope.isEdit ?
            <BasicTextField label={''} defaultValue={ope.price} setValue={(e: string) => operationTableChangeHandler(e, index)} />
            :
            ope.price as string,
          align: 'left', type: 'TEXT', isNowrap: true
        },
        {
          id: 'buttonAction',
          name: ope.isEdit ?
            <ButtonGroup>
              <BasicButton startIcon={<SaveIcon elevation={5} />}
                displayName='保存'
                onClick={() => operationSave(index)}
                backColor="white"
                fontColor="black"
                hoverBackColor="green"
                hoverFontColor="white"
              />
              <BasicButton
                displayName='キャンセル'
                onClick={() => cancelOperationEdit(index)}
                backColor="white"
                fontColor="black"
                hoverBackColor="red"
                hoverFontColor="white"
              />
            </ButtonGroup>
            :
            <ButtonGroup>
              <BasicButton startIcon={<EditIcon elevation={5} />}
                displayName='編集'
                onClick={() => operationEdit(index)}
                backColor="white"
                fontColor="black"
                hoverBackColor="green"
                hoverFontColor="white"
              />
              <BasicButton startIcon={<DeleteIcon elevation={5} />}
                displayName='削除'
                onClick={() => operationDelete(index)}
                backColor="white"
                fontColor="black"
                hoverBackColor="red"
                hoverFontColor="white"
              />
            </ButtonGroup>
          , align: 'center', type: 'TEXT', isNowrap: true
        },
      ]
      return { content: tmp }
    });
    setOperationsTableData(rows);
  }, [operations]);

  // 担当者描画
  useEffect(() => {
    const copyData = [...outsourceUsers];
    const rows: Row[] = copyData.map((user, index) => {
      const tmp: Title[] = [
        {
          id: 'fullName',
          name: <Typography>{user.fullName}</Typography>,
          align: 'left',
          isNowrap: true,
          type: 'TEXT',
        },
        {
          id: 'fullNameHiragana',
          name: <Typography>{user.fullNameHiragana}</Typography>,
          align: 'left',
          isNowrap: true,
          type: 'TEXT',
        },
        {
          id: 'tel',
          name: <Typography><PhoneOutlinedIcon />{user.tel}</Typography>,
          align: 'left',
          isNowrap: true,
          type: 'TEXT',
        },
        {
          id: 'mail',
          name: <Typography><MailOutlineIcon />{user.mail}</Typography>,
          align: 'left',
          isNowrap: true,
          type: 'TEXT',
        },
        {
          id: 'regeneratePassword',
          name: <BasicButton
            fullWidth
            displayName={'パスワード再発行'}
            backColor="white" hoverBackColor="green" fontColor="black" hoverFontColor="white"
            onClick={() => updatePassword(user.userId, user.fullName, user.mail)}
          />,
          align: 'center',
          isNowrap: true,
          type: 'TEXT',
        },
        {
          id: 'edit',
          name: <ButtonGroup>
            <BasicButton
              fullWidth
              displayName={'編集'}
              startIcon={<EditIcon elevation={5} />}
              backColor="white" hoverBackColor="green" fontColor="black" hoverFontColor="white"
              onClick={() => editOperator(user.userId)}
            />
            <BasicButton
              fullWidth
              displayName={'削除'}
              startIcon={<DeleteIcon elevation={5} />}
              backColor="white" hoverBackColor="red" fontColor="black" hoverFontColor="white"
              onClick={() => deleteOperator(user.userId)}
            />
          </ButtonGroup>,
          align: 'center',
          isNowrap: true,
          type: 'TEXT',
        },
      ];
      return { content: tmp };
    });
    setOutsourceUsersTableData(rows);
  }, [outsourceUsers])

  // 発注描画
  useEffect(() => {
    if (orders.length === 0) return;
    const temp: Row[] = orders.map((order: Order, index: number) => {
      const tmp: Title[] = [
        {
          id: order.orderId,
          name: <BasicButton
            displayName={order.subjectName}
            disableElevation={true}
            fontColor={'black'} backColor={'whitesmoke'} hoverBackColor={'whitesmoke'} hoverFontColor={'black'}
            onClick={() => setOrderIdForReport(order.orderId)}
          />,
          align: 'left',
          isNowrap: true,
          type: 'TEXT',
        },
        {
          id: 'orderDate',
          name: <Typography>{order.orderDate}</Typography>,
          align: 'left',
          isNowrap: true,
          type: 'TEXT',
        }
      ];
      return { content: tmp };
    });
    setOrderTableData(temp);
  }, [orders]);

  useEffect(() => {
    if (!orderIdForReport) return;
    const f = async () => {
      const http = new HttpRequest<OrderReportRequest, OrderReportResponse>()
      http.request = {
        order_id: orderIdForReport
      }
      if (await http.get('/client/order/report')) {
        const res = http.response!
        setReportInfo({
          /** 帳票番号 */
          reportNumber: 0,
          /** 外注先名 */
          outsourceName: res.outsourceName,
          /** 件名 */
          title: res.title,
          /** 送信期間（開始） */
          startDate: res.startDate,
          /** 送信期間（完了） */
          endDate: res.endDate,
          /** 会社名 */
          companyName: res.companyName,
          /** 郵便番号 */
          postNumber: res.postalCode,
          /** 住所（都道府県＋市＋住所） */
          address: res.prefecture + res.address,
          /** TEL */
          phoneNumber: res.tel,
          /** FAX */
          faxNumber: res.fax,
          /** 外注先担当者名 */
          operatorName: res.outsourcePicName,
          /** IB担当者名 */
          ibOperatorName: res.ibPicName,
          /** リスト名 */
          listName: res.listName,
          /** 数量 */
          qty: res.qty,
          /** 単価 */
          price: res.unitPrice,
          /** 小計 */
          subtotalAmount: res.subtotalPrice,
          /** 消費税 */
          taxAmout: res.tax,
          /** 合計金額 */
          totalAmount: res.totalPrice
        })
      }
    }
    f()
  }, [orderIdForReport]);

  // 初期データ取得
  const fetchInitData = async () => {
    const http = new HttpRequest<InitDataRequest, InitDataResponse>();
    http.request = { outsourceId: outsourceId };
    if (await http.post('/outsource/details')) {
      const res = http.response!;
      setBasicInfo(res.basicInfo);
      setOperations(res.operations);
      setMemos(res.memo);
      setOutsourceUsers(res.users);
      setOrders(res.orders);
    }
  };

  // 基本情報編集モード時の入力値をvalueに格納
  const basicInfoTableHandleChange = (event: React.ChangeEvent<HTMLInputElement> | Dropdown, rowId: string, colId: string, rowIndex: number, colIndex: number) => {
    const updatedData: Row[] = basicInfoTableData.map(item => {
      if (item.id === rowId) {
        const tmp = item.content.map(m => {
          if (m.id === colId) {
            if (typeof event === 'string') {
              m.value = String(event);
            } else {
              if ((event as Dropdown).label === '') {
                m.dropdownValue = basicInfo?.postCode
              } else {
                m.dropdownValue = event as Dropdown
              }
            }
            return m;
          }
          return m;
        });
        return { ...item, content: tmp };
      }
      return item;
    });
    setTempBasicInfoTableData(updatedData);
  };

  // 基本情報の編集情報を保存
  const basicInfoSave = async () => {
    const outsourceName = tempBasicInfoTableData.find(f => f.id === 'outsource')?.content.find(ff => ff.id === 'editData')?.value;
    const postCode = tempBasicInfoTableData.find(f => f.id === 'postCode')?.content.find(ff => ff.id === 'editData')?.dropdownValue;
    const address = tempBasicInfoTableData.find(f => f.id === 'address')?.content.find(ff => ff.id === 'editData')?.value;
    const tel = tempBasicInfoTableData.find(f => f.id === 'tel')?.content.find(ff => ff.id === 'editData')?.value;

    const http = new HttpRequest<UpdateBasicInfoRequest, MessageResponse>();
    http.request = {
      outsourceId: outsourceId,
      outsourceName: outsourceName ?? '',
      postCode: postCode ?? { label: '', value: '' },
      address: address ?? '',
      tel: tel ?? ''
    }
    await http.post('/outsource/update/outsource');
    const res = http.response;
    dispatch(showMessage({
      message: res?.message ?? '',
      severity: res?.severity ?? 'info',
      duration: 3000,
    }));
    if (res?.severity === 'success') {
      setBasicInfo(
        {
          outsourceName: outsourceName ?? '',
          postCode: {
            label: postCode?.label ?? '',
            value: postCode?.value ?? ''
          },
          address: address ?? '',
          tel: tel ?? ''
        }
      );
    }
  };

  // 依頼可能作業追加
  const addOperation = () => {
    const tmp = [...operations];
    tmp.push({ id: '', opeName: { label: '', value: '' }, price: '' });
    setOperations(tmp);
  };

  // 依頼可能作業編集キャンセル
  const cancelOperationEdit = (index: number) => {
    fetchInitData();
  }

  // 対象作業の削除
  const operationDelete = async (index: number) => {
    const temp = [...operations];
    const http = new HttpRequest<DeleteOperationRequest, MessageResponse>();
    http.request = {
      lOperationId: temp[index].id,
    };
    await http.post('/outsource/del/task');
    const res = http.response;
    dispatch(showMessage({
      message: res?.message ?? '',
      severity: res?.severity ?? 'error',
      duration: 3000,
    }));
    fetchInitData();
  };

  // 対象作業の編集（編集ボタン押下時）
  const operationEdit = (index: number) => {
    const temp = [...operations];
    temp[index].isEdit = true;
    setOperations(temp);
  };

  // 対象作業の編集
  const operationTableChangeHandler = (e: Dropdown | string, index: number) => {
    const temp = [...operations];
    if (typeof e === 'string') {
      temp[index].price = e;
    } else if (e.label !== '') {
      temp[index].opeName = e;
    }
  };

  // 対象作業の保存
  const operationSave = async (index: number) => {
    const temp = [...operations];
    if (temp[index].opeName.label === '') {
      dispatch(showMessage({
        message: "作業名を入力してください",
        severity: 'error',
        duration: 3000,
      }));
      return;
    }
    let priceErrorMessage = '';
    if (temp[index].price === '') {
      priceErrorMessage = "単価を入力してください。";
    } else if (isNaN(Number(temp[index].price))) {
      priceErrorMessage = "単価は数値である必要があります。";
    } else if (Number(temp[index].price) <= 0) {
      priceErrorMessage = "単価は0以上である必要があります。";
    }
    if (priceErrorMessage !== '') {
      dispatch(showMessage({
        message: priceErrorMessage,
        severity: 'error',
        duration: 3000,
      }));
      return;
    }
    const http = new HttpRequest<UpdateOperationRequest, MessageResponse>();
    http.request = {
      id: temp[index].id,
      outsourceId: outsourceId,
      opeName: temp[index].opeName,
      price: temp[index].price
    }
    await http.post('/outsource/edit/task');
    const res = http.response;
    dispatch(showMessage({
      message: res?.message ?? '',
      severity: res?.severity ?? 'error',
      duration: 3000,
    }));
    fetchInitData();
  };

  // 基本情報編集モード切替
  const renderBasicInfoEditMode = () => {
    if (isEditBasicInfo) {
      return (
        <ButtonGroup>
          <BasicButton
            startIcon={<SaveIcon elevation={5} />}
            displayName='保存'
            onClick={() => {
              basicInfoSave();
              setIsEditBasicInfo(false);
            }}
            backColor="white"
            fontColor="black"
            hoverBackColor="green"
            hoverFontColor="white"
          />
          <BasicButton
            displayName='キャンセル'
            onClick={() => setIsEditBasicInfo(false)}
            backColor="white"
            fontColor="black"
            hoverBackColor="red"
            hoverFontColor="white"
          />
        </ButtonGroup>
      );
    } else {
      return <BasicButton
        startIcon={<EditIcon elevation={5} />}
        displayName='編集'
        onClick={() => setIsEditBasicInfo(true)}
        backColor="white"
        fontColor="black"
        hoverBackColor="green"
        hoverFontColor="white"
      />
    };
  };

  // 担当者追加
  const addOperator = () => {
    setEditDialogOpen({ open: true, type: 'NEW' });
    setNewUser({
      userId: '',
      familyName: '',
      familyNameHiragana: '',
      name: '',
      nameHiragana: '',
      fullName: '',
      fullNameHiragana: '',
      mail: '',
      tel: '',
      isEdit: false,
    });
  };

  // 担当者編集
  const editOperator = (userId: string) => {
    const target = outsourceUsers.find(f => f.userId === userId);
    setNewUser({
      userId: target?.userId ?? '',
      familyName: target?.familyName ?? '',
      familyNameHiragana: target?.familyNameHiragana ?? '',
      name: target?.name ?? '',
      nameHiragana: target?.nameHiragana ?? '',
      fullName: '',
      fullNameHiragana: '',
      mail: target?.mail ?? '',
      tel: target?.tel ?? '',
      isEdit: false,
    });
    setEditDialogOpen({ open: true, type: 'EDIT' });
  };

  // 担当者削除
  const deleteOperator = async (userId: string) => {
    const http = new HttpRequest<DeleteUserRequest, MessageResponse>();
    http.request = {
      userId: userId
    };
    await http.post('/outsource/del/user');
    const res = http.response;
    dispatch(showMessage({
      message: res?.message ?? '',
      severity: res?.severity ?? 'error',
      duration: 3000,
    }));
    fetchInitData();
  };

  // 担当者パスワードアップデート
  const updatePassword = async (userId: string, fullName: string, mail: string) => {
    const http = new HttpRequest<DeleteUserRequest, MessageResponse>();
    http.request = {
      userId: userId
    };
    await http.post('/outsource/reissue/pass');
    const res = http.response;
    dispatch(showMessage({
      message: res?.message ?? '',
      severity: res?.severity ?? 'error',
      duration: 3000,
    }));
    if (res?.severity === 'success' && res.userInfo?.password) {
      const data: UserInfo = {
        id: '',
        fullName: fullName,
        mail: mail,
        password: res.userInfo.password
      }
      setCompUserInfoDialogOpen({ open: true, userInfo: data });
    }
    fetchInitData();
  };

  // 担当者情報の更新または追加
  const saveOperatorInfo = async (saveType: 'NEW' | 'EDIT' | 'NOTHING') => {
    if (saveType === 'NOTHING') return;
    if (!newUser.familyName) {
      dispatch(showMessage({
        message: '氏を入力してください',
        severity: 'error',
        duration: 3000,
      }))
      return;
    }
    if (!newUser.familyNameHiragana) {
      dispatch(showMessage({
        message: '氏（ふりがな）を入力してください',
        severity: 'error',
        duration: 3000,
      }))
      return;
    }
    if (!newUser.familyNameHiragana.match(/^[ぁ-んー　]*$/)) {
      dispatch(showMessage({
        message: '氏（ふりがな）はひらがなで入力してください。',
        severity: 'error',
        duration: 3000,
      }))
      return;
    }
    if (!newUser.name) {
      dispatch(showMessage({
        message: '名を入力してください',
        severity: 'error',
        duration: 3000,
      }))
      return;
    }
    if (!newUser.nameHiragana) {
      dispatch(showMessage({
        message: '名（ふりがな）を入力してください',
        severity: 'error',
        duration: 3000,
      }))
      return;
    }
    if (!newUser.nameHiragana.match(/^[ぁ-んー　]*$/)) {
      dispatch(showMessage({
        message: '名（ふりがな）はひらがなで入力してください。',
        severity: 'error',
        duration: 3000,
      }))
      return;
    }
    if (!newUser.tel) {
      dispatch(showMessage({
        message: '電話番号を入力してください',
        severity: 'error',
        duration: 3000,
      }))
      return;
    }
    if (newUser.tel.includes('-')) {
      dispatch(showMessage({
        message: '電話番号のハイフンは不要です',
        severity: 'error',
        duration: 3000,
      }))
      return;
    }
    if (!newUser.mail) {
      dispatch(showMessage({
        message: 'Emailを入力してください',
        severity: 'error',
        duration: 3000,
      }))
      return;
    }
    if (!newUser.mail.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) {
      dispatch(showMessage({
        message: '無効なメールアドレスです。Emailには正しいメールアドレスを入力してください',
        severity: 'error',
        duration: 3000,
      }))
      return;
    }

    const http = new HttpRequest<UpdateUserRequest, MessageResponse>();
    http.request = {
      outsourceId: outsourceId,
      userId: newUser.userId,
      name: newUser.name,
      nameHiragana: newUser.nameHiragana,
      familyName: newUser.familyName,
      familyNameHiragana: newUser.familyNameHiragana,
      fullName: `${newUser.familyName} ${newUser.name}`,
      fullNameHiragana: `${newUser.familyNameHiragana} ${newUser.nameHiragana}`,
      mail: newUser.mail,
      tel: newUser.tel,
      isEdit: false,
    };
    await http.post('/outsource/edit/user');
    const res = http.response;
    dispatch(showMessage({
      message: res?.message ?? '',
      severity: res?.severity ?? 'error',
      duration: 3000,
    }));
    if (res?.severity === 'success' && res.userInfo?.fullName) {
      setCompUserInfoDialogOpen({ open: true, userInfo: res.userInfo });
    }
    fetchInitData();

    // 登録/追加完了後、リセット
    setNewUser({
      userId: '',
      familyName: '',
      familyNameHiragana: '',
      name: '',
      nameHiragana: '',
      fullName: '',
      fullNameHiragana: '',
      mail: '',
      tel: '',
      isEdit: false,
    });
    setEditDialogOpen({ open: false, type: 'NOTHING' });
  };

  // メモ表示データ進む
  const memosHandleNext = () => {
    setMemosCurrentIndex((prevIndex) => (prevIndex - 1 + memos.length) % memos.length);
  };

  // メモ表示データ戻る
  const memosHandleBack = () => {
    setMemosCurrentIndex((prevIndex) => (prevIndex + 1) % memos.length);
  };

  // メモ保存＋情報更新
  const memoHandleSave = async () => {
    if (editMemo.length === 0) {
      dispatch(showMessage({
        message: "新規登録用のメモ内容を入力してください",
        severity: 'error',
        duration: 3000,
      }));
      return;
    }
    const http = new HttpRequest<MemoAddRequest, MessageResponse>();
    http.request = {
      outsourceId: outsourceId,
      memo: editMemo,
    };
    await http.post('/outsource/register/memo');
    const res = http.response;
    dispatch(showMessage({
      message: res?.message ?? '',
      severity: res?.severity ?? 'error',
      duration: 3000,
    }));
    fetchInitData();
    setMemosCurrentIndex(memos.length !== 0 ? memos.length - 1 : 0);
    setEditMemo('');
    setIsEditMemo(false);
  };

  // メモ編集モード切替
  const renderMemoEditMode = () => {
    if (isEditMemo) {
      return (
        <ButtonGroup>
          <BasicButton
            startIcon={<SaveIcon elevation={5} />}
            displayName='保存'
            onClick={() => { memoHandleSave() }}
            backColor="white" fontColor="black" hoverBackColor="green" hoverFontColor="white"
          />
          <BasicButton
            displayName='キャンセル'
            onClick={() => {
              setIsEditMemo(false);
              fetchInitData();
            }}
            backColor="white" fontColor="black" hoverBackColor="red" hoverFontColor="white"
          />
        </ButtonGroup>
      );
    } else {
      return (
        <BasicButton
          startIcon={<AddIcon elevation={5} />}
          displayName='メモ追加'
          onClick={() => setIsEditMemo(true)}
          backColor="white" fontColor="black" hoverBackColor="green" hoverFontColor="white"
        />
      );
    };
  };

  // メモ表示データ
  const renderMemo = () => {
    if (isEditMemo) {
      return (
        <div style={{ width: '100%', border: '1px solid gray', borderRadius: '10px' }}>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '10px' }}>
            <Typography
              variant="caption"
              style={{ margin: '0 10px' }}
            >
              {isEditMemo ? new Date().toISOString().replaceAll('-', '/').split('T')[0] : memos[memosCurrentIndex]?.updateDate ?? ''}
            </Typography>
          </div>
          <div className="mx-1" style={{ wordWrap: 'break-word' }}>
            <BasicTextField
              label={'新規メモ'}
              fullwidth
              multiline
              setValue={setEditMemo}
            />
          </div>
        </div>
      );
    } else {
      return (
        <div style={{ width: '100%', border: '1px solid gray', borderRadius: '10px' }}>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '10px' }}>
            <Button onClick={memosHandleBack}>{'<'}</Button>
            <Typography variant="caption" style={{ margin: '10px 10px' }}>{memos[memosCurrentIndex]?.updateDate ?? ''}</Typography>
            <Button onClick={memosHandleNext}>{'>'}</Button>
          </div>
          <div style={{ wordWrap: 'break-word' }}>
            {memos[memosCurrentIndex]?.memo ?? ''}
          </div>
        </div>
      );
    }
  }


  return (
    <div>
      <div>
        <Header />
      </div>

      <div>
        {/* パンくずリスト */}
        <div className="d-flex" style={{ margin: '50px 50px 0px 50px' }}>
          <div onClick={() => gotoPage('/outsourcing_management')}>
            <Typography
              fontWeight={'bold'}
              fontSize={'large'}
              style={{ color: 'gray', cursor: 'pointer' }}
            >
              外注先管理
            </Typography>
          </div>

          <div style={{ width: '20px' }}></div>
          <ArrowForwardIcon />
          <div style={{ width: '20px' }}></div>
          <Typography fontWeight={'bold'} fontSize={'large'}>外注先詳細</Typography>
        </div>
      </div>

      <div className={'mx-5 my-5'}>
        <BasicTabs tabItems={['基本情報', '担当者', '発注']}
          tabContents={[
            // 基本情報タブ
            <div>
              {/* 基本情報 */}
              <div className="d-flex">
                {/* 左側 */}
                <div className="my-2 row" style={{ width: '50%', minWidth: '600px', borderRadius: '4px', padding: '' }}>
                  <div className="col-12" style={{ border: '1px solid gray', borderRadius: '10px' }}>
                    <BasicTable headers={[]} content={basicInfoTableData} />
                  </div>
                  <div className="col-12 text-end" style={{ marginTop: '3px' }}>
                    {renderBasicInfoEditMode()}
                  </div>
                  <div className="col-12 my-3">
                    <div><Typography variant='inherit'>依頼可能作業</Typography></div>
                    <BasicTable
                      headers={[
                        { name: '作業名', align: 'left', isNowrap: true, type: 'TEXT' },
                        { name: '単価', align: 'left', isNowrap: true, type: 'TEXT' },
                        { name: '', align: 'left', isNowrap: true, type: 'TEXT' },
                      ]}
                      content={operationsTableData}
                    />
                    <div className="col-12 my-2">
                      <BasicButton
                        fullWidth displayName="依頼可能作業を追加"
                        backColor="white" hoverBackColor="green" fontColor="black" hoverFontColor="white"
                        startIcon={<AddIcon />}
                        onClick={addOperation}
                      />
                    </div>
                  </div>
                </div>

                {/* 右側 */}
                <div className="row my-2" style={{ width: '50%', minWidth: '500px', marginLeft: '5%', padding: '' }} >
                  {renderMemo()}
                  <div className="" style={{ textAlign: 'right', marginTop: '3px' }}>
                    {renderMemoEditMode()}
                  </div>
                </div>
              </div>
            </div>,

            // 担当者タブ
            <div>
              <div style={{ /*minWidth: '1000px'*/ }}>
                <BasicTable
                  headers={[
                    { name: '氏名', align: 'left', isNowrap: true },
                    { name: '氏名（ふりがな）', align: 'left', isNowrap: true },
                    { name: '電話番号', align: 'left', isNowrap: true },
                    { name: 'Email', align: 'left', isNowrap: true },
                    { name: '', align: 'left', isNowrap: true },
                    { name: '', align: 'left', isNowrap: true },
                  ]}
                  content={outsourceUsersTableData}
                  setTableData={setOutsourceUsersTableData}
                />
              </div>
              <div className={'mx-5 my-5'}>
                <BasicButton
                  fullWidth displayName={"担当者を追加"}
                  backColor="white" hoverBackColor="green" fontColor="black" hoverFontColor="white"
                  startIcon={<AddIcon />}
                  onClick={() => addOperator()}
                />
              </div>
              {/* 担当者追加/編集ダイアログ */}
              <BasicDialog
                title={editDialogOpen.type === 'NEW' ? '担当者新規登録' : '担当者情報更新'}
                open={editDialogOpen.open}
                close={() => setEditDialogOpen({ open: false, type: 'NOTHING' })}
                content={
                  <div>
                    <div className="row my-2">
                      <div className="col">
                        <BasicTextField label={'氏'} value={newUser.familyName} setValue={(e: string) => setNewUser({ ...newUser, familyName: e })} />
                      </div>
                      <div className="col">
                        <BasicTextField label={'名'} value={newUser.name} setValue={(e: string) => setNewUser({ ...newUser, name: e })} />
                      </div>
                    </div>
                    <div className="row my-3">
                      <div className="col">
                        <BasicTextField label={'氏（ひらがな）'} value={newUser.familyNameHiragana} setValue={(e: string) => setNewUser({ ...newUser, familyNameHiragana: e })} />
                      </div>
                      <div className="col">
                        <BasicTextField label={'名（ひらがな）'} value={newUser.nameHiragana} setValue={(e: string) => setNewUser({ ...newUser, nameHiragana: e })} />
                      </div>
                    </div>
                    <div className="row my-3">
                      <div className="col">
                        <BasicTextField label={'電話番号'} value={newUser.tel} setValue={(e: string) => setNewUser({ ...newUser, tel: e })} />
                      </div>
                      <div className="col">
                        <BasicTextField label={'Email'} value={newUser.mail} setValue={(e: string) => setNewUser({ ...newUser, mail: e })} />
                      </div>
                    </div>
                  </div>
                }
                positiveButtonName={'保存'}
                positiveAction={() => saveOperatorInfo(editDialogOpen.type)}
                negativeButtonName={'キャンセル'}
              />
              {/* 担当者新規登録時の通知ダイアログ */}
              <BasicDialog
                open={compUserInfoDialogOpen.open}
                close={() => setCompUserInfoDialogOpen({ open: false })}
                title={'ユーザー登録完了通知'}
                content={
                  <div>
                    <Typography>パスワードは1度しか見れませんので、ご注意ください。</Typography>
                    <BasicTable
                      headers={[
                        { name: '氏名', align: 'center', isNowrap: true, type: 'TEXT' },
                        { name: 'email', align: 'center', isNowrap: true, type: 'TEXT' },
                        { name: 'パスワード', align: 'center', isNowrap: true, type: 'TEXT' },
                      ]}
                      content={[
                        {
                          content: [
                            { name: compUserInfoDialogOpen.userInfo?.fullName ?? '', align: 'center', type: 'TEXT' },
                            { name: compUserInfoDialogOpen.userInfo?.mail ?? '', align: 'center', type: 'TEXT' },
                            { name: compUserInfoDialogOpen.userInfo?.password ?? '', align: 'center', type: 'TEXT' },
                          ]
                        }
                      ]}
                    />
                  </div>
                }
                positiveButtonName={'OK'}
                positiveAction={() => setCompUserInfoDialogOpen({ open: false, userInfo: { id: '', fullName: '', mail: '', password: '' } })}
              />
            </div>,

            // 発注タブ
            <div>
              <div className={"d-flex"}>
                <div style={{ minWidth: '30%', maxWidth: '40%' }}>
                  <BasicTable
                    height={'60vh'}
                    backgroundColor={'whitesmoke'}
                    contentHoverColor={'whitesmoke'}
                    headers={[
                      { name: '案件名', align: 'left', isNowrap: true, type: 'TEXT' },
                      { name: '発注日', align: 'left', isNowrap: true, type: 'TEXT' },
                    ]}
                    content={orderTableData} />
                </div>
                <div>
                  <div className="">
                    {orderIdForReport && <OrderReport {...reportInfo} />}
                  </div>
                </div>
              </div>
            </div>,
          ]}
        />
      </div>
    </div>
  );
}