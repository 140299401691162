import React, { useState, useRef } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button } from '@mui/material';
import { TextField } from '@mui/material';


export type PagingTitle = {
  name: string | JSX.Element
  align: 'left' | 'center' | 'right'
  width?: string
  type?: 'TEXT_FIELD' | 'TEXT'
  value?: string
}

export type PagingRow = {
  content: PagingTitle[]
}

type Props = {
  headerColor?: 'midnightblue' | 'darkgreen' | 'white'
  headers: PagingTitle[]
  content: PagingRow[]
  scale?: number
  page: number;
  setPage: (page: number) => void;
  pageSize?: number;
  setTableData?: (data: PagingRow[]) => void
}

export default function PagingTable(props: Props) {
  const { headerColor, headers, content, scale, page, setPage, pageSize = 100 } = props;
  const rowsPerPage = 100;
  const totalPages = Math.ceil(content.length / rowsPerPage);


  const containerRef = useRef<HTMLDivElement>(null);

  const scrollToTop = () => {
    if (containerRef.current) {
      containerRef.current.scrollTop = 0; // スクロール位置をトップに設定
    }
  };

  const handlePrevious = () => {
    setPage(Math.max(0, page - 1));
    scrollToTop();
  };

  const handleNext = () => {
    setPage(Math.min(totalPages - 1, page + 1));
    scrollToTop();
  };

  const startIndex = page * rowsPerPage;
  const endIndex = Math.min(startIndex + rowsPerPage, content.length);
  const visibleData = content.slice(startIndex, endIndex);
  
  const handleValue = (val: string, index1: number, index2: number) => {
    const newContent = [...props.content];
    newContent[index1].content[index2].value = val;
    if (props.setTableData) {
      props.setTableData(newContent);
    }
  };

  return (
    <div>
      <TableContainer component={Paper} style={{ maxHeight: '440px', overflow: 'auto' }} ref={containerRef}>
        <Table stickyHeader aria-label="sticky table" style={{ transform: `scale(${scale || 1})` }}>
          <TableHead>
            <TableRow>
              {headers.map((header, index) => (
                <TableCell key={index} align={header.align} style={{ width: header.width, backgroundColor: headerColor }} sx={{ fontWeight: 'bold', color: 'white' }}>
                  {header.name}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {visibleData.map((row, rowIndex) => (
              <TableRow key={rowIndex + page * rowsPerPage}>
                {row.content.map((cell, cellIndex) => (
                  <TableCell key={cellIndex} align={cell.align}>
                    {cell.type === 'TEXT' ?
                      cell.name as string
                      :
                      <TextField fullWidth value={cell.value || (cell.name as React.ReactElement).props.value || ''}
                       size='small' onChange={(e: any) => handleValue(e.target.value as string, rowIndex + page * rowsPerPage, cellIndex)} />
                      
                    }
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {content.length > rowsPerPage && (
        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
          <Button onClick={handlePrevious} disabled={page === 0}>Previous</Button>
          <span>Page {page + 1} of {totalPages}</span>
          <Button onClick={handleNext} disabled={page === totalPages - 1}>Next</Button>
        </div>
      )}
    </div>
  );
}