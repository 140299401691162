import { Alert, Button, Slide } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import CloseIcon from '@mui/icons-material/Close';
import {SnackbarState} from "../../global/store/snackbar";

/**
 * open:              Snackbarの開閉用
 * onClose:           Snackbarを閉じるためのトリガー
 * message:           表示するメッセージ
 * autoHideDuration:  自動で非表示にするまでの時間
 * severity:          重大度
 */
type Props = {
  open: boolean
  onClose: () => void
  message: string
  autoHideDuration: number
  severity: SnackbarState['severity']
}

export default function CustomSnackbar(props: Props) {
  return (
    <Snackbar
      open={props.open}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      onClose={props.onClose}
      autoHideDuration={props.autoHideDuration}
      TransitionComponent={Slide}
      ClickAwayListenerProps={{ mouseEvent: false, touchEvent: false }}
    >
      <Alert
        severity={props.severity}
        action={
          <Button color="inherit" size="small" onClick={props.onClose}>
            <CloseIcon sx={{ paddingBottom: '3px' }} />
          </Button>
        }>
        {props.message}
      </Alert>
    </Snackbar>
  );
}