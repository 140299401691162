import { Typography } from "@mui/material";
import Header from "../../../components/header/header";
import BasicTextField from "../../../components/text/basicTextField";
import { useEffect, useState } from "react";
import BasicButton from "../../../components/button/basicButton";
import { useLocation, useNavigate } from "react-router-dom";
import BasicDialog from "../../../components/dialog/basicDialog";
import {
  OrderInput, OrderMailRequest, OrderMailResponse,
  OutsourceSearchRequest,
  OutsourceSearchResponse
} from "../../../@types/ib/clientManagement/OrderType";
import BasicTable, { Row, Title } from "../../../components/table/basicTable";
import HttpRequest from "../../../global/http/HttpRequest";
import BasicDatePicker from "../../../components/datapicker/basicDatePicker";
import IbUserLazyDropdown from "../../../components/dropdown/ibUserLazyDropdown";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import BasicDropdown, { Dropdown } from "../../../components/dropdown/basicDropdown";
import HttpData from "../../../global/http/HttpData";

interface SearchDropdownRequest extends HttpData {
  value: string
}

interface DropdownResponse extends HttpData {
  dropdownList: Dropdown[]
}

interface PriceResponse extends HttpData {
  label: string
  value: string
}

// 顧客詳細 -> 案件情報 -> 新規発注
export default function NewOrderPage() {
  const nav = useNavigate();
  const location = useLocation()

  const gotoPage = (url: string, subject: string, body: string) => {
    nav(url, {
      state: {
        order: {
          ...orderInput,
          subject: subject,
          body: body,
          operator: operator,
          operation: operation,
          price: price,
        },
        clientId: location.state.clientId, tabIndex: 0
      }
    })
  }

  // state(基本情報)
  const [orderInput, setOrderInput] = useState<OrderInput>(new OrderInput())
  const [outsourceSearchOpen, setOutsourceSearchOpen] = useState<boolean>(false)
  const [outsourceList, setOutsourceList] = useState<Row[]>([])
  const [outsourceSearchValue, setOutsourceSearchValue] = useState<string>('')

  const [outsourceId, setOutsourceId] = useState<string>('')
  const [operator, setOperator] = useState<Dropdown>({ label: '', value: '' })
  const [operatorList, setOperatorList] = useState<Dropdown[]>([])
  const [operation, setOperation] = useState<Dropdown>({ label: '', value: '' })
  const [operationList, setOperationList] = useState<Dropdown[]>([])
  const [price, setPrice] = useState<string>('')

  useEffect(() => {
    console.log(location.state)
    if (location.state) {
      location.state.projectId && setOrderInput(
        { ...orderInput,
          projectId: location.state.projectId,
        }
      )
      if (location.state.order) {
        setOutsourceId(location.state.order.outsourceId)
        setOrderInput(location.state.order)
        setOperator(location.state.order.operator)
        setOperation(location.state.order.operation)
        setPrice(location.state.order.price)
      }
    }
  }, []);

  useEffect(() => {
    console.log(outsourceId)
    if (!outsourceId || !orderInput.outsourceId) return
    const searchOperator = async () => {
      const http = new HttpRequest<SearchDropdownRequest, DropdownResponse>()
      http.request = {
        value: outsourceId
      }
      const res = await http.get('/client/order/search/operator')
      if (res) {
        setOperatorList(http.response!.dropdownList)
      }
    }
    const searchOperation = async () => {
      const http = new HttpRequest<SearchDropdownRequest, DropdownResponse>()
      http.request = {
        value: outsourceId
      }
      const res = await http.get('/client/order/search/operation')
      if (res) {
        setOperationList(http.response!.dropdownList)
      }
    }
    searchOperator()
    searchOperation()
  }, [outsourceId])

  useEffect(() => {
    if (operation.value === '') return
    const searchOperationPrice = async () => {
      const http = new HttpRequest<SearchDropdownRequest, PriceResponse>()
      http.request = {
        value: operation.value
      }
      const res = await http.get('/client/order/search/price')
      if (res) {
        setPrice(http.response!.label)
      }
    }
    searchOperationPrice()
  }, [operation])

  const outsourceHeader: Title[] = [
    { name: 'No.', align: 'center' },
    { name: '外注先ID', align: 'center' },
    { name: '外注先名', align: 'center' },
  ]

  function dialogContent() {
    return (
      <div className="container">
        <div className="row mt-3">
          <div className="offset-3 col-4 text-center">
            <BasicTextField
              label="外注先"
              setValue={(value) => setOutsourceSearchValue(value)}
            />
          </div>
          <div className="col-2 text-end">
            <BasicButton
              displayName="検索"
              fontColor="white"
              fullWidth
              onClick={searchOutsource}
            />
          </div>
          <div className="col-3"></div>
        </div>
        <div className="mt-3">
          <BasicTable
            headers={outsourceHeader}
            content={outsourceList}
          />
        </div>
      </div>
    )
  }

  function selectOutsource(outsourceId: string) {
    setOrderInput({ ...orderInput, outsourceId: outsourceId })
    setOutsourceSearchOpen(false)
  }

  async function searchOutsource() {
    if (!outsourceSearchValue) {
      return
    }
    const http = new HttpRequest<OutsourceSearchRequest, OutsourceSearchResponse>()
    http.request = {
      value: outsourceSearchValue,
    }
    if (await http.get('/client/order/searchOutsource')) {
      setOutsourceList(http.response!.outsources.map((outsource) => {
        return {
          id: outsource.outsourceId,
          content: [
            {
              name: '選択', align: 'center', type: 'BUTTON', buttonFunctions: [() => {
                selectOutsource(outsource.outsourceId)
                setOutsourceId(outsource.outsourceId)
              }],
            },
            { name: outsource.outsourceId, align: 'center', type: 'TEXT', value: '' },
            { name: outsource.outsourceName, align: 'center', type: 'TEXT', value: '' },
          ]
        }
      }))
    }
  }

  // 登録
  async function next() {
    const http = new HttpRequest<OrderMailRequest, OrderMailResponse>();
    http.request = {
      requestNum: orderInput.requestNum,
      startDate: orderInput.startDate,
      endDate: orderInput.endDate,
      outsourceId: orderInput.outsourceId,
      projectId: orderInput.projectId,
      ibUserId: orderInput.ibUserId,
      outsourceUserId: orderInput.operator,
    }
    if (await http.post('/client/order/mail')) {
      const response = http.response!
      gotoPage('/client_management/NewOrderConfPage', response.subject, response.body)
    }
  }

  const [isWideScreen, setIsWideScreen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsWideScreen(window.innerWidth >= 1450);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div>
      <div>
        <Header />
      </div>

      {/* パンくずリスト */}
      <div className="d-flex" style={{ margin: '50px 50px 0px 50px' }}>
        <div onClick={() => gotoPage('/client_management', '', '')}>
          <Typography fontWeight={'bold'} fontSize={'large'} style={{ color: 'gray', cursor: 'pointer' }}>
            {'顧客管理'}
          </Typography>
        </div>

        <div style={{ width: '20px' }}></div>
        <ArrowForwardIcon />
        <div style={{ width: '20px' }}></div>

        <div onClick={() => gotoPage('/client_management/detail', '', '')}>
          <Typography fontWeight={'bold'} fontSize={'large'} style={{ color: 'gray', cursor: 'pointer' }}>
            {'顧客詳細'}
          </Typography>
        </div>

        <div style={{ width: '20px' }}></div>
        <ArrowForwardIcon />
        <div style={{ width: '20px' }}></div>

        <Typography fontWeight={'bold'} fontSize={'large'}>
          {'新規発注'}
        </Typography>
      </div>


      <div className="container mx-5 my-3">
        <div className="row">
          <div className="col-7 d-flex" style={{ justifyContent: 'space-between' }}>
            <div className="my-3 w-100">
              <BasicTextField
                fullwidth
                label="外注先ID"
                value={orderInput.outsourceId}
                setValue={() => { }}
              />
            </div>
            <div className="d-flex align-items-center" style={{ marginLeft: '2%', whiteSpace: 'nowrap' }}>
              <BasicButton
                displayName="外注先検索"
                fontColor="white"
                onClick={() => setOutsourceSearchOpen(true)}
              />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-7 d-flex" style={{ justifyContent: 'space-between' }}>
            <div className="w-100">
              <BasicDropdown
                fullWidth
                titleName={'担当者'}
                value={operator}
                items={operatorList}
                onChange={(val: Dropdown) => {
                  const label = operatorList.find(f => f.value === val.value)?.label || ''
                  const ope = { label: label, value: val.value }
                  setOperator(ope)
                  setOrderInput({ ...orderInput, operator: ope })
                }}
              />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="my-3 col-7 d-flex" style={{ justifyContent: 'space-between' }}>
            <div className="w-100">
              <BasicDropdown
                fullWidth
                titleName={'作業'}
                value={operation}
                items={operationList}
                onChange={(val: Dropdown) => {
                  const label = operationList.find(f => f.value === val.value)?.label || ''
                  const value = val.value
                  setOperation({ label: label, value: value })
                }}
              />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-7">
            <BasicTextField
              fullwidth
              label="単価"
              value={price}
              type={'number'}
              setValue={setPrice}
            />
          </div>
        </div>

        <div className="row">
          <div className="my-3 col-7">
            <BasicTextField
              fullwidth
              label="件名"
              value={orderInput.title}
              setValue={(val: string) => setOrderInput({ ...orderInput, title: val })}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-7">
            <BasicTextField
              fullwidth
              label="件数"
              type={'number'}
              value={orderInput.requestNum}
              setValue={(value) => setOrderInput({ ...orderInput, requestNum: value })}
            />
          </div>
        </div>

        <div className="row">
          <div className="d-flex col-7" style={{ justifyContent: 'space-between' }}>
            <div className="my-3">
              <BasicDatePicker
                title={'送信期間（開始）'}
                value={orderInput.startDate}
                setValue={(value: string | Date | null) => setOrderInput({ ...orderInput, startDate: value as string })}
              />
            </div>

            <div className="my-3" style={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', minWidth: '40px' }}>{'~'}</div>

            <div className="my-3">
              <BasicDatePicker
                title={'送信期間（終了）'}
                value={orderInput.endDate}
                setValue={(value: string | Date | null) => setOrderInput({ ...orderInput, endDate: value as string })}
              />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="my-2 col-7">
            <IbUserLazyDropdown
              titleName="IB担当者"
              value={orderInput.ibUserId}
              setValue={(val) => setOrderInput({ ...orderInput, ibUserId: val })}
            />
          </div>
        </div>


        <div className="my-3 row">
          <div className="col-7">
            <BasicButton
              fullWidth
              displayName="確認"
              fontColor="white"
              onClick={next}
            />
          </div>
        </div>
        <BasicDialog
          fullWidth
          open={outsourceSearchOpen}
          close={() => setOutsourceSearchOpen(false)}
          title={'外注先検索'}
          content={dialogContent()}
        />

      </div>
    </div>
  );
}