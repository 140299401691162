import {
  Button,
  ClickAwayListener,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogProps,
  DialogTitle
} from "@mui/material";
import { useState } from "react";
import BasicButton from "../button/basicButton";

type Props = {
  open: boolean
  close: () => void
  maxWidth?: 'sm' | 'md' | 'lg'
  fullWidth?: boolean
  title: string
  content: JSX.Element
  positiveButtonName?: string
  positiveAction?: () => void
  negativeButtonName?: string
}

export default function BasicDialog(props: Props) {
  const [maxWidth, setMaxWidth] = useState<DialogProps['maxWidth']>(props.maxWidth ?? 'lg');

  return (
      <Dialog
        open={props.open}
        onClose={props.close}
        maxWidth={maxWidth}
        fullWidth={props.fullWidth}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {props.title}
        </DialogTitle>
        <DialogContent>
          {props.content}
        </DialogContent>
        <DialogActions>
          {
            props.positiveButtonName &&
            <BasicButton displayName={props.positiveButtonName} backColor="green" hoverBackColor="mediumseagreen" onClick={props.positiveAction} />
          }
          {typeof props.negativeButtonName == 'string' ? <BasicButton displayName={props.negativeButtonName} backColor="red" hoverBackColor="orangered" onClick={props.close} /> : <></>}
        </DialogActions>
      </Dialog>
  );
};