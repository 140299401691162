import { Box, Button, TextField } from "@mui/material"
import { useState } from "react"
import BasicButton from "../button/basicButton"

type Props = {
  label: string
  value: string
  setValue: (e: any) => void
  size?: 'small' | 'medium'
  type?: 'text' | 'email' | 'password' | 'date'
  fullwidth?: boolean
  onClick: () => void
}

export default function SearchTextField(props: Props) {
  const [value, setValue] = useState<string>('');

  return (
    <Box sx={{ display: 'flex' }}>
      <TextField
        id="outlined-basic"
        label={props.label}
        variant="outlined"
        size={props.size ?? 'small'}
        type={props.type ?? 'text'}
        fullWidth={props.fullwidth ?? false}
        value={props.value}
        onChange={e => props.setValue(e.target.value)}
      />
      <div style={{ marginLeft: '10px' }}>
        <Button variant="contained" onClick={props.onClick}>検索</Button>
      </div>
    </Box>

  );
}