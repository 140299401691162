import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Button, TextField, Typography } from '@mui/material';
import { useRef, useState } from 'react';
import BasicButton from '../button/basicButton';
import { v4 as uuidv4 } from 'uuid';
import PostCodeDropdown from '../dropdown/postCodeDropdown';
import { Dropdown } from '../dropdown/basicDropdown';

/** 要素 */
export type Title = {
  /** ID */
  id?: string
  /** 表示データ */
  name: string | string[] | JSX.Element | Dropdown
  /** 配置 */
  align: 'left' | 'center' | 'right'
  /** 上配置 */
  topPlacement?: boolean
  /** フォント種類 */
  fontWeight?: 'bold'
  /** 改行設定（true: ON, false: OFF） */
  isNowrap?: boolean
  /** 列範囲 */
  colspan?: number
  /** 幅 */
  width?: number
  /** データ種別 */
  type?: 'TEXT_FIELD' | 'TEXT' | 'BUTTON' | 'BUTTONS' | 'POSTCODE_DROPDOWN'
  /** ボタンイベント */
  buttonFunctions?: (() => void)[]
  /** 予備データ */
  value?: string
  /** 予備データ（ドロップダウン） */
  dropdownValue?: Dropdown
}

/** 行 */
export type Row = {
  /** 行リスト */
  id?: string
  content: Title[]
  rowColor?: 'white' | 'lightgoldenrodyellow' | 'lightcyan' | 'gainsboro'
}

type Props = {
  /** ヘッダーカラー */
  headerColor?: 'midnightblue' | 'darkgreen' | 'white'
  /** ヘッダーリスト */
  headers: Title[]
  /** ボディリスト */
  content: Row[]
  /** 高さ */
  height?: string
  /** ボディホバーカラー */
  contentHoverColor?: 'white' | 'whitesmoke'
  /** 背景カラー */
  backgroundColor?: 'white' | 'whitesmoke'
  /** データセットイベント */
  setTableData?: (data: Row[]) => void
  /** ページング（true: ON, false: OFF） */
  isPaging?: boolean
}

export function getValues(rows: Row[]): Record<string, string>[] {
  const values = []
  for (const row of rows) {
    const value: Record<string, string> = {}
    for (const cell of row.content) {
      value[cell.id ?? uuidv4()] = cell.value ?? ''
    }
    values.push(value)
  }
  return values
}

/** 汎用テーブル */
export default function BasicTable(props: Props) {
  // paging
  const containerRef = useRef<HTMLDivElement>(null);
  const [page, setPage] = useState<number>(0);
  const rowsPerPage = 100;
  const totalPages = Math.ceil(props.content.length / rowsPerPage);
  const startIndex = page * rowsPerPage;
  const endIndex = Math.min(startIndex + rowsPerPage, props.content.length);
  const visibleData = props.content.slice(startIndex, endIndex);

  const scrollToTop = () => {
    if (containerRef.current) {
      containerRef.current.scrollTop = 0;
    }
  };

  const handlePrevious = () => {
    setPage(Math.max(0, page - 1));
    scrollToTop();
  };

  const handleNext = () => {
    setPage(Math.min(totalPages - 1, page + 1));
    scrollToTop();
  };

  const handleValue = (val: string, index1: number, index2: number) => {
    const newContent = [...props.content];
    newContent[index1].content[index2].value = val;
    props.setTableData!(newContent);
  };

  const handleDropdown = (val: Dropdown, index1: number, index2: number) => {
    const newContent = [...props.content];
    newContent[index1].content[index2].dropdownValue = val;
    props.setTableData!(newContent);
  }

  /**
   * 行のレンダリング
   * @param row 行全体の情報
   * @param cell rowの中のセル
   * @param index1 rowインデックス
   * @param index2 cellインデックス
   */
  const renderCell = (row: Row, cell: Title, index1: number, index2: number) => {
    const key = `index-child-${index1}-${index2}`
    const whiteSpace = cell.isNowrap ? `nowrap` : 'pre-wrap'
    const fontWeight = cell.fontWeight ? `${cell.fontWeight}` : ''
    const display = cell.topPlacement ? `flex` : ''
    const style = { whiteSpace,
      width: cell.width ?? window.innerWidth / row.content.length,
      fontWeight, display,
    }

    return (
      <TableCell key={key} component="td" scope="row" align={cell.align} sx={style} colSpan={cell.colspan}>
        {cell.type === 'TEXT' &&
          <div>{cell.name as string}</div>
        }
        {cell.type === 'TEXT_FIELD' || cell.type === undefined &&
          <TextField size='small' fullWidth key={key} value={cell.value} onChange={(e: any) => handleValue(e.target.value as string, index1, index2)} />
        }
        {cell.type === 'BUTTON' &&
          <BasicButton displayName={cell.name as string} onClick={cell.buttonFunctions![0]} />
        }
        {cell.type === 'POSTCODE_DROPDOWN' &&
          <PostCodeDropdown value={cell.dropdownValue as Dropdown} setValue={(e: Dropdown) => handleDropdown(e, index1, index2)} />
        }
        {cell.type === 'BUTTONS' &&
          <div style={{ display: 'flex' }}>
            {
              (cell.name as string[]).map((b, index) => {
                return (
                  <div key={`${key}-parent-${b}`} style={{ margin: '0px 4px' }}>
                    <BasicButton key={`${key}-${b}`} displayName={b} onClick={cell.buttonFunctions![index]} />
                  </div>
                );
              })
            }
          </div>
        }
      </TableCell>
    );
  };

  return (
    <>
      <div style={{ backgroundColor: props.backgroundColor, height: `${props.height}` }}>
        <TableContainer ref={containerRef} sx={{ backgroundColor: props.backgroundColor, height: `${props.height}` }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead component={'thead'} sx={{ backgroundColor: props.headerColor ?? 'midnightblue' }}>
              <TableRow component={'tr'} sx={{zIndex: 1}}>
                {props.headers.map((header, index) => {
                  return (
                    <TableCell
                      component={'td'}
                      key={`index-parent-${index}`} align={header.align}
                      sx={{
                        fontWeight: 'bold', color: 'white',
                        backgroundColor: props.headerColor ?? 'midnightblue',
                        zIndex: 0,
                      }} >
                      {header.name as string}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody component={'tbody'} sx={{ '.table-row:hover': { backgroundColor: props.contentHoverColor ?? 'aliceblue' }, }}>
              {(props.isPaging ? visibleData : props.content).map((row, index1) => (
                <TableRow
                  className='table-row'
                  key={`index-${index1}`}
                  component={'tr'}
                  sx={{backgroundColor: row.rowColor ?? 'white'}}
                >
                  {row.content.map((item, index2) => {
                    return renderCell(row, item, index1, index2)
                  })}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer >
      </div>
      {
        props.isPaging && props.content.length !== 0 && (
          <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
            <Button onClick={handlePrevious} disabled={page === 0}>Previous</Button>
            <Typography variant='inherit'>{`Page ${page + 1} of ${totalPages}`}</Typography>
            <Button onClick={handleNext} disabled={page === totalPages - 1}>Next</Button>
          </div>
        )
      }
    </>
  );
}