import HttpData from "../../../global/http/HttpData";
import { Dropdown } from "../../../components/dropdown/basicDropdown";

// 外注先検索
export type Outsource = {
  outsourceId: string
  outsourceName: string
}

// 外注先検索レスポンス
export interface OutsourceSearchResponse extends HttpData {
  outsources: Outsource[]
}

// 外注先検索リクエスト
export interface OutsourceSearchRequest extends HttpData {
  value: string
}

// 発注画面入力
export class OrderInput {
  projectId: string = ''
  outsourceId: string = ''
  operator: Dropdown = { label: '', value: '' }
  operation: Dropdown = { label: '', value: '' }
  price: string = ''
  title: string = ''
  requestNum: string = ''
  startDate: string = ''
  endDate: string = ''
  ibUserId: Dropdown = { label: '', value: '' }

  // メール
  subject: string = ''
  body: string = ''
}

// 発注メールリクエスト
export interface OrderMailRequest extends HttpData {
  requestNum: string
  startDate: string
  endDate: string
  outsourceId: string
  projectId: string
  ibUserId: Dropdown
  outsourceUserId: Dropdown
}

// 発注メールレスポンス
export interface OrderMailResponse extends HttpData {
  subject: string
  body: string
}

// 発注リクエスト
export interface OrderRequest extends HttpData {
  projectId: string
  outsourceId: string
  operator: Dropdown
  operation: Dropdown
  price: string
  title: string
  requestNum: string
  startDate: string
  endDate: string
  ibUserId: Dropdown
  subject: string
  body: string
}

// 発注レスポンス
export interface MessageResponse extends HttpData {
  message: string
}

// 発注書情報リクエスト
export interface OrderInfoRequest extends HttpData {
  name: string
  subject: string
}

// 発注書情報レスポンス
export interface OrderInfoResponse extends HttpData {
  outsourceName: string
  listName: string
  companyName: string
  postNumber: string
  address: string
  tel: string
  fax: string
  tax: number
}

export interface OrderListRequest extends HttpData {
  subject_id: string
}

export interface OrderDetail {
  orderId: string
  outsourceName: string
  orderDate: string
  startDate: string
  endDate: string
  unitPrice: string
  qty: string
  progress: string
}

export interface OrderListResponse extends HttpData {
  orders: OrderDetail[]
}

export interface OrderReportRequest extends HttpData {
  order_id: string
}

export interface OrderReportResponse extends HttpData {
  outsourceName: string
  outsourcePicName: string
  orderDate: string
  startDate: string
  endDate: string
  listName: string
  title: string
  unitPrice: string
  qty: string
  subtotalPrice: string
  tax: string
  totalPrice: string
  companyName: string
  postalCode: string
  prefecture: string
  address: string
  tel: string
  fax: string
  ibPicName: string
}

export interface OrderReportInfo {
  /** 帳票番号 */
  reportNumber: number
  /** 外注先名 */
  outsourceName: string
  /** 件名 */
  title: string
  /** 送信期間（開始） */
  startDate: string
  /** 送信期間（完了） */
  endDate: string
  /** 会社名 */
  companyName: string
  /** 郵便番号 */
  postNumber: string
  /** 住所（都道府県＋市＋住所） */
  address: string
  /** TEL */
  phoneNumber: string
  /** FAX */
  faxNumber: string
  /** 外注先担当者名 */
  operatorName: string
  /** IB担当者名 */
  ibOperatorName: string
  /** リスト名 */
  listName: string
  /** 数量 */
  qty: string
  /** 単価 */
  price: string
  /** 小計 */
  subtotalAmount: string
  /** 消費税 */
  taxAmout: string
  /** 合計金額 */
  totalAmount: string
}