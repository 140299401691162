import { useNavigate, useLocation } from "react-router-dom";
import Header from "../../../components/header/header";
import { TextField, Typography } from "@mui/material";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import BasicTextField from "../../../components/text/basicTextField";
import { useState, forwardRef, useEffect } from "react";
import { useDispatch } from "react-redux";
import BasicButton from "../../../components/button/basicButton";
import AddIcon from '@mui/icons-material/Add';
import { Dropdown } from "../../../components/dropdown/basicDropdown";
import BasicTable, { getValues, Row, Title } from "../../../components/table/basicTable";
import "react-datepicker/dist/react-datepicker.css";
import { showMessage } from "../../../global/store/snackbar";
import { ResultResponse } from "../../../@types/client";
import {
  ClientRequest,
  ClientDetailRequest,
  ClientInfo,
  ClientUserInfo,
  ClientLocation,
  ClientResponse
} from "../../../@types/ib/clientManagement/ClientType";
import PrefectureDropdown from "../../../components/dropdown/prefectureDropdown";
import PostCodeDropdown from "../../../components/dropdown/postCodeDropdown";
import IbUserDropdown from "../../../components/dropdown/ibUserDropdown";
import HttpRequest from "../../../global/http/HttpRequest";
import BasicDatePicker from "../../../components/datapicker/basicDatePicker";
import { DateTime as LuxonDateTime } from 'luxon';
import BasicDialog from "../../../components/dialog/basicDialog";

// 顧客管理 -> 新規顧客登録ページ
export default function ClientRegistrationPage() {
  const nav = useNavigate();

  const locationState: ClientLocation = useLocation().state;
  const [clientId, setClientId] = useState<string | undefined>(locationState?.clientId);
  const [isUpdated, setIsUpdated] = useState(false);
  const [isOpenDialog, setIsOpenDialog] = useState<boolean>(false);
  const [createUserInfo, setCreateUserInfo] = useState<Row[]>([])

  const gotoPage = (url: string) => {
    nav(url);
  };

  const dispatch = useDispatch();

  // state
  const [clientInfo, setClientInfo] = useState<ClientInfo>(new ClientInfo())

  // sample data (担当者リスト)
  const headers: Title[] = [
    { name: '氏', align: 'left', width: 50, type: 'TEXT_FIELD' },
    { name: '名', align: 'left', width: 50 },
    { name: '氏（ひらがな）', align: 'left', width: 50 },
    { name: '名（ひらがな）', align: 'left', width: 50 },
    { name: 'Email', align: 'left', width: 50 },
    { name: '電話', align: 'left', width: 50 },
  ];
  // 担当者初期データ
  const clientUserRowTemplate: Row = {
    content: [
      { id: 'firstName', name: '', align: 'left', value: '' },
      { id: 'givenName', name: '', align: 'left', value: '' },
      { id: 'firstNameHiragana', name: '', align: 'left', value: '' },
      { id: 'givenNameHiragana', name: '', align: 'left', value: '' },
      { id: 'email', name: '', align: 'left', value: '' },
      { id: 'tel', name: '', align: 'left', value: '' },
    ],
  }
  const [rows, setRows] = useState<Row[]>([]);

  useEffect(() => {
    addClientUser()
  }, []);

  // 担当者を追加ボタン
  const addClientUser = () => {
    const temp = [...rows]
    const newRow = { ...clientUserRowTemplate, id: '' }
    temp.push(newRow)
    setRows(temp);
  }

  useEffect(() => {
    console.log('clientInfo', clientInfo.hearingDate)
  }, [clientInfo]);

  useEffect(() => {
    if (clientId) {
      const f = async () => {
        const http = new HttpRequest<ClientDetailRequest, ClientResponse>()
        http.request = {
          client_id: clientId!,
        }
        if (await http.get(`/client/details`)) {
          const info = { ...http.response! }
          setClientInfo({ ...info, hearingDate: info.hearingDate })
          const clientUserList: Row[] = []
          for (const row of info.clientUsers) {
            const clientUserRow: Row = {
              content: [
                { id: 'firstName', name: '', align: 'left', value: row.familyName },
                { id: 'givenName', name: '', align: 'left', value: row.givenName },
                { id: 'firstNameHiragana', name: '', align: 'left', value: row.familyNameHiragana },
                { id: 'givenNameHiragana', name: '', align: 'left', value: row.givenNameHiragana },
                { id: 'email', name: '', align: 'left', value: row.email },
                { id: 'tel', name: '', align: 'left', value: row.tel },
              ], id: row.clientUserId
            }
            clientUserList.push(clientUserRow)
            setRows(clientUserList);
          }
          setIsUpdated(true)
        }
      }
      f()
    }
  }, [])

  // 登録・編集
  const registration = async () => {
    const list: ClientUserInfo[] = []
    for (const row of rows) {
      const clientUserId = row.id ? row.id : '';
      getValues([row]).map(value => {
        list.push({
          clientUserId: clientUserId,
          familyName: value.firstName,
          givenName: value.givenName,
          familyNameHiragana: value.firstNameHiragana,
          givenNameHiragana: value.givenNameHiragana,
          email: value.email,
          tel: value.tel
        })
      })
    }

    const http = new HttpRequest<ClientRequest, ResultResponse>()
    http.request = {
      ...{ ...clientInfo, clientUsers: list },
    }
    const result = await http.post('/client/register')
    if (http.response?.errorMessage) {
      dispatch(showMessage({
        message: http.response!.errorMessage,
        severity: 'error',
        duration: 3000,
      }))
      return
    }
    if(http.response!.userInfo.length > 0) {
      const userData: Row[] = http.response!.userInfo.map(user => {
        const contents: Title[] = [];
        const fullName: Title = { name: user.full_name, align: 'center', isNowrap: true, type: 'TEXT' };
        const mail: Title = { name: user.mail, align: 'center', isNowrap: true, type: 'TEXT' };
        const password: Title = { name: user.password, align: 'center', isNowrap: true, type: 'TEXT' };
        contents.push(fullName);
        contents.push(mail);
        contents.push(password);
        return { content: contents };
      });
      setCreateUserInfo(userData);
      setClientId(http.response!.clientId)
      setIsOpenDialog(true)
    } else {
      nav('/client_management/detail', { state: { clientId: http.response!.clientId } })
    }
  }

  return (
    <div>
      <div>
        <Header />
      </div>

      <div>
        {/* パンくずリスト */}
        <div className="d-flex" style={{ margin: '50px 50px 0px 50px' }}>
          <div onClick={() => gotoPage('/client_management')}>
            <Typography fontWeight={'bold'} fontSize={'large'} style={{ color: 'gray', cursor: 'pointer' }}>
              {'顧客管理'}
            </Typography>
          </div>

          <div style={{ width: '20px' }}></div>
          <ArrowForwardIcon />
          <div style={{ width: '20px' }}></div>

          <Typography fontWeight={'bold'} fontSize={'large'}>{isUpdated ? '顧客編集' : '新規顧客登録'}</Typography>
        </div>
      </div>

      {/* 登録フォーム */}
      <div className="mx-5 my-3">
        <div>
          {/* 基本情報 */}
          <div className="d-flex container">
            <div className="my-2" style={{ width: '55%' }}>
              <div className="my-3">
                <BasicTextField
                  fullwidth
                  label="顧客名"
                  value={clientInfo.clientName}
                  setValue={(value: string) => setClientInfo({ ...clientInfo, clientName: value })}
                />
              </div>

              <div className="my-3 d-flex">
                <div className="w-100">
                  <PrefectureDropdown
                    value={clientInfo.prefecture}
                    setValue={(value: Dropdown) => setClientInfo({ ...clientInfo, prefecture: value })}
                  />
                </div>
                <div className="w-100" style={{ marginLeft: '5%' }}>
                  <PostCodeDropdown
                    value={clientInfo.postalCode}
                    setValue={(value: Dropdown) => setClientInfo({ ...clientInfo, postalCode: value })}
                  />
                </div>
              </div>

              <div className="my-3">
                <BasicTextField
                  fullwidth
                  label="市区町村"
                  value={clientInfo.city}
                  setValue={(value: string) => setClientInfo({ ...clientInfo, city: value })}
                />
              </div>

              <div className="my-3">
                <BasicTextField
                  fullwidth
                  label="住所"
                  value={clientInfo.address}
                  setValue={(value: string) => setClientInfo({ ...clientInfo, address: value })}
                />
              </div>

              <div className="my-3">
                <BasicTextField
                  fullwidth
                  label="TEL"
                  value={clientInfo.tel}
                  setValue={(value: string) => setClientInfo({ ...clientInfo, tel: value })}
                />
              </div>

              <div className="my-3">
                <BasicTextField
                  fullwidth
                  label="HP"
                  value={clientInfo.hp}
                  setValue={(value: string) => setClientInfo({ ...clientInfo, hp: value })}
                />
              </div>
            </div>

            <div className="my-2" style={{ width: '40%', marginLeft: '5%' }}>
              <div className="my-3">
                <BasicTextField
                  fullwidth
                  label="契約件数"
                  value={String(clientInfo.firstSendNum)}
                  setValue={(value: string) => setClientInfo({ ...clientInfo, firstSendNum: Number(value) } as any)}
                />
              </div>

              <div className="my-3">
                <IbUserDropdown
                  titleName="担当コンサル"
                  value={clientInfo.repConsultant}
                  setValue={(value: Dropdown) => setClientInfo({ ...clientInfo, repConsultant: value })}
                />
              </div>

              <div className="my-3">
                <IbUserDropdown
                  titleName="フォローコンサル"
                  value={clientInfo.followConsultant}
                  setValue={(value: Dropdown) => setClientInfo({ ...clientInfo, followConsultant: value })}
                />
              </div>

              <div className="my-3">
                <BasicDatePicker
                  title={'ヒアリング日時'}
                  isClearable iconPosition={'startAdornment'}
                  value={clientInfo.hearingDate}
                  setValue={(date: string) => {
                    setClientInfo({ ...clientInfo, hearingDate: date })
                  }}
                />
              </div>
            </div>
          </div>

          <div className="d-flex container">
            <div className="" style={{ width: '100%' }}>
              <div className="my-2">
                <BasicTextField
                  fullwidth
                  multiline
                  label="メモ"
                  value={clientInfo.memo}
                  setValue={(value: string) => setClientInfo({ ...clientInfo, memo: value })}
                />
              </div>
            </div>
          </div>

          {/* 担当者一覧 */}
          <div>
            <div className="my-3 container">
              <div><Typography>担当者</Typography></div>
              <div>
                <BasicTable
                  headerColor="midnightblue"
                  headers={headers}
                  content={rows}
                  setTableData={setRows}
                />
              </div>
            </div>
            <div className="container">
              <BasicButton
                fullWidth displayName="担当者を追加"
                backColor="white" hoverBackColor="green" fontColor="black" hoverFontColor="white"
                startIcon={<AddIcon />}
                onClick={addClientUser}
              />
            </div>
          </div>
          <div className="my-3" style={{ textAlign: 'center' }}>
            <BasicButton
              displayName="登録" width={'20%'}
              fontColor="white"
              onClick={registration}
            />
          </div>
        </div>
      </div>
      <BasicDialog
        open={isOpenDialog}
        close={() => setIsOpenDialog(false)}
        title={'担当者の登録情報'}
        content={
          <div>
            <Typography>パスワードは1度しか見れませんので、ご注意ください。</Typography>
            <BasicTable
              headers={[
                { name: '氏名', align: 'center', isNowrap: true, type: 'TEXT' },
                { name: 'email', align: 'center', isNowrap: true, type: 'TEXT' },
                { name: 'パスワード', align: 'center', isNowrap: true, type: 'TEXT' },
              ]}
              content={createUserInfo}
            />
          </div>
        }
        positiveButtonName={'OK'}
        positiveAction={() => {
          setIsOpenDialog(false);
          nav('/client_management/detail', { state: { clientId: clientId } })
        }}
      />
    </div >
  );
}