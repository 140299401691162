import React, {useCallback, useEffect, useRef, useState} from 'react';
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import './App.css';
import LoginPage from './pages/ib/LoginPage/LoginPage';
import TopPage from './pages/ib/TopPage/TopPage';
import CautionPage from './pages/ib/TopPage/CautionPage';
import CompanyDatabasePage from './pages/ib/CompanyDatabasePage/CompanyDatabaseMenuPage';
import SystemManagementPage from './pages/ib/SystemManagementPage/SystemManagementMenuPage';
import ListExtraction from './pages/ib/ClientManagementPage/ListExtraction';
import StandardManagementPage from './pages/ib/SystemManagementPage/StandardManagementMenu/StandardManagementPage';
import RequestableTaskManagementPage
  from './pages/ib/SystemManagementPage/StandardManagementMenu/RequestableTaskManagementPage';
import IndustryCategoryManagementPage
  from './pages/ib/SystemManagementPage/StandardManagementMenu/IndustryCategoryManagementPage';
import IndustrySubcategoryManagementPage
  from './pages/ib/SystemManagementPage/StandardManagementMenu/IndustrySubcategoryManagementPage';
import SubIndustryCategoryManagementPage
  from './pages/ib/SystemManagementPage/StandardManagementMenu/SubIndustryCategoryManagementPage';
import SubIndustrySubcategoryManagementPage
  from './pages/ib/SystemManagementPage/StandardManagementMenu/SubIndustrySubcategoryManagementPage';
import ListingClassManagementPage
  from './pages/ib/SystemManagementPage/StandardManagementMenu/ListingClassManagementPage';

import CompanySearchPage from './pages/ib/CompanyDatabasePage/CreateListMenu/CompanySearchPage';
import CompanyDetailPage from './pages/ib/CompanyDatabasePage/CreateListMenu/CompanyDetailPage';
import CompanyRegistrationPage from './pages/ib/CompanyDatabasePage/CreateListMenu/CompanyRegistrationPage';
import CompanySuggestionPage from './pages/ib/CompanyDatabasePage/CreateListMenu/CompanySuggestionPage';
import CompanyOverwritePage from './pages/ib/CompanyDatabasePage/CreateListMenu/CompanyOverwritePage';
import CompanyBlankOverwritePage from './pages/ib/CompanyDatabasePage/CreateListMenu/CompanyBlankOverwritePage';
import SenderInformationImportPage from './pages/ib/ClientManagementPage/SenderInformationImportPage';
import SendManuscriptImportPage from './pages/ib/ClientManagementPage/SendManuscriptImportPage';
import CustomerVerifiedListImportPage
  from './pages/ib/CompanyDatabasePage/CreateListMenu/CustomerVerifiedListImportPage';
import ProhibitedRegistratiionByCustomerImportPage
  from './pages/ib/ClientManagementPage/ProhibitedRegistratiionByCustomerImportPage';
import ProhibitedCancellationByCustomerImportPage
  from './pages/ib/ClientManagementPage/ProhibitedCancellationByCustomerImportPage';
import ProhibitedManagementPage from './pages/ib/ClientManagementPage/ProhibitedManagementPage';
import ClientManagementPage from './pages/ib/ClientManagementPage/ClientManagementPage';
import ClientRegistrationPage from './pages/ib/ClientManagementPage/ClientRegistrationPage';
import ClientDetailPage from "./pages/ib/ClientManagementPage/ClientDetailPage";
import ClientManagemetnListDetailPage from './pages/ib/ClientManagementPage/ClientListDetailPage';
import ClientListImportPage from './pages/ib/ClientManagementPage/ClientListImportPage';
import ProjectRegistrationPage from './pages/ib/ClientManagementPage/ProjectRegistrationPage';
import ProjectInformationPage from './pages/ib/ClientManagementPage/ProjectInformationPage';
import ListDetailPage from './pages/ib/ClientManagementPage/ListDetailPage';
import OrderTargetListPage from './pages/ib/ClientManagementPage/OrderTargetListPage';
import NewOrderPage from './pages/ib/ClientManagementPage/NewOrderPage';
import NewOrderConfPage from './pages/ib/ClientManagementPage/NewOrderConfPage';
import SenderRegistrationPage from './pages/ib/ClientManagementPage/SenderRegistrationPage';
import SenderDetailPage from './pages/ib/ClientManagementPage/SenderDetailPage';
import SubmissionDraftRegistrationPage from './pages/ib/ClientManagementPage/SubmissionDraftRegistrationPage';
import SubmissionDraftDetailPage from './pages/ib/ClientManagementPage/SubmissionDraftDetailPage';
import OutsourcingManagementPage from './pages/ib/OutsourcingManagementPage/OutsourcingManagementPage';
import OutsourcingRegistrationPage from './pages/ib/OutsourcingManagementPage/OutsourcingRegistrationPage';
import OutsourcingDetailPage from "./pages/ib/OutsourcingManagementPage/OutsourcingDetailPage";
import HttpRequest from "./global/http/HttpRequest";
import {ResultResponse, UserResponse} from "./@types/common";
import HttpData from "./global/http/HttpData";
import CustomSnackbar from "./components/snackbar/customSnackbar";
import {closeMessage, SnackbarState} from "./global/store/snackbar";
import store, {RootState} from "./global/store/store";
import {UserRole} from "./global/store/roleAuth";
import {connect, Provider, useDispatch} from "react-redux";
import BasicDialog from './components/dialog/basicDialog';
import OutsourceLoginPage from "./pages/outsource/OutSourceLoginPage";
import OutsourceTopPage from "./pages/outsource/TopPage";
import OutsourceUserManagementPage from './pages/outsource/OutsourceUserManagementPage';
import ClientLoginPage from "./pages/client/ClientLoginPage";
import {DateTime as LuxonDateTime} from 'luxon';
import ClientListPage from "./pages/client/ClientListPage";
import ClientListDetailPage from "./pages/client/ClientListDetailPage";
import ClientSendInfoPage from "./pages/client/ClientSendInfoPage";
import {login, User} from "./global/store/auth";
import {ClientRole, IbRole, OutsourceRole} from "./@types/roles";
import TaskManagementPage from "./pages/ib/SystemManagementPage/TaskManagement/List";
import MailManagementPage from "./pages/ib/SystemManagementPage/StandardManagementMenu/MailManagementPage";
import FormPathImportPage from "./pages/ib/CompanyDatabasePage/FormPathImportPage";
import OutsourceLocationPage from './pages/outsource/OutsourceLocationPage';
import IbUserManagementPage from "./pages/ib/SystemManagementPage/UserManagement";
import ContactPage from "./pages/outsource/report/ContactPage";
import SubjectManagementPage from "./pages/outsource/operation/SubjectManagementPage";
import SubjectDetailPage from "./pages/outsource/operation/SubjectDetailPage";
import {OperatorAssignmentPage} from "./pages/outsource/operation/OperatorAssignmentPage";
import LocationAssignmentPage from "./pages/outsource/operation/LocationAssignmentPage";
import AggregationPage from "./pages/ib/SystemManagementPage/Aggregation";
import LogPage from "./pages/ib/SystemManagementPage/Log";
import OrderReportDownload from "./pages/ib/ClientManagementPage/OrderReportDownload";
import ScrapingPage from './pages/ib/CompanyDatabasePage/CreateListMenu/ScrapingPage';

type StoreProps = {
  open: boolean
  message: string
  severity: SnackbarState['severity']
  duration: number
  user: User | null
}
type Props = StoreProps

function App(props: Props) {
  // let { isAuthenticated, userPermissions, isLoading } = useAuthState();
  const isAuthenticated = true
  const userPermissions = [UserRole.Admin]
  const noAuthPath = ['/', '/outsource/login', '/client/login']

  const validMinutes = 10
  const [expiredTime, setExpiredTime] = useState<LuxonDateTime>(LuxonDateTime.now().minus({minutes: 1}))
  const [display, setDisplay] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<boolean>(false);

  const init = useRef<boolean>(true);

  const getExpiredAt = useCallback(() => {
    return expiredTime
  }, [expiredTime])

  const checkAuth = async () => {
    if (expiredTime > LuxonDateTime.now()) {
      return
    }
    const expiredAt = LuxonDateTime.now().plus({ minutes: validMinutes })
    setExpiredTime(expiredAt)

    const no_auth = noAuthPath.some(s => s === window.location.pathname)
    const http = new HttpRequest<HttpData, ResultResponse>()
    const result = await http.get('/logged_in')

    if (!result && !no_auth) {
      setAlertMessage(true);
    }

    if (result || no_auth) {
      if (init.current) {
        const userHttp = new HttpRequest<HttpData, UserResponse>()
        const userResult = await userHttp.get('/user')
        if (userResult) {
          const user = {
            username: userHttp.response!.email,
            type: userHttp.response!.type,
            roles: userHttp.response!.roles,
          }
          store.dispatch(login(user))
        }
        init.current = false
        setDisplay(true)
      }
    } else {
      setDisplay(false)
    }
  }

  useEffect(() => {
    const f = async () => {
      await checkAuth()
    }
    f()
  }, [])

  useEffect(() => {
    window.addEventListener('focus', checkAuth)

    return () => {
      window.removeEventListener('focus', checkAuth)
    }
  }, [expiredTime]);

  const dispatch = useDispatch()
  const handleClose = () => {
    dispatch(closeMessage())
  }

  const hasIbRole = (requiredRole: IbRole[]) => {
    return props.user?.type === 'IB' &&
      (requiredRole.length == 0 || requiredRole.some(role => props.user?.roles.includes(role)))
  }

  const hasOutsourceRole = (requiredRole: OutsourceRole[]) => {
    return props.user?.type === 'OUTSOURCE' &&
      (requiredRole.length == 0 || requiredRole.some(role => props.user?.roles.includes(role)))
  }

  const hasClientRole = (requiredRole: ClientRole[]) => {
    return props.user?.type === 'CLIENT' &&
      (requiredRole.length == 0 || requiredRole.some(role => props.user?.roles.includes(role)))
  }

  return (
    <>
    <Router>
      <Routes>
        <Route path="/" element={<LoginPage />} />
        <Route path={"/outsource/login"} element={<OutsourceLoginPage />} />
        <Route path={"/client/login"} element={<ClientLoginPage />} />

      {display &&
          <>
            {hasIbRole([]) &&
                <Route path="/top" element={<TopPage />} />
            }
            {/** システム管理 */}
            {hasIbRole([IbRole.MASTER_READ]) &&
              <Route path="/system_management" element={<SystemManagementPage />} />
            }
            {hasIbRole([IbRole.MASTER_READ]) &&
              <Route path="/system_management/StandardManagementPage" element={<StandardManagementPage />} />
            }
            {hasIbRole([IbRole.MASTER_READ]) &&
              <Route path="/system_management/RequestableTaskManagementPage" element={<RequestableTaskManagementPage />} />
            }
            {hasIbRole([IbRole.MASTER_READ]) &&
              <Route path="/system_management/IndustryCategoryManagementPage" element={<IndustryCategoryManagementPage />} />
            }
            {hasIbRole([IbRole.MASTER_READ]) &&
              <Route path="/system_management/IndustrySubcategoryManagementPage" element={<IndustrySubcategoryManagementPage />} />
            }
            {hasIbRole([IbRole.MASTER_READ]) &&
              <Route path="/system_management/SubIndustryCategoryManagementPage" element={<SubIndustryCategoryManagementPage />} />
            }
            {hasIbRole([IbRole.MASTER_READ]) &&
              <Route path="/system_management/SubIndustrySubcategoryManagementPage" element={<SubIndustrySubcategoryManagementPage />} />
            }
            {hasIbRole([IbRole.MASTER_READ]) &&
              <Route path="/system_management/ListingClassManagementPage" element={<ListingClassManagementPage />} />
            }
            {hasIbRole([IbRole.MASTER_READ]) &&
              <Route path="/system_management/SubIndustryCategoryManagementPage" element={<SubIndustryCategoryManagementPage />} />
            }
            {hasIbRole([]) &&
                <Route path="/system_management/task" element={<TaskManagementPage />} />
            }
            {hasIbRole([IbRole.MASTER_READ]) &&
              <Route path="/system_management/mail" element={<MailManagementPage />} />
            }
            {
              hasIbRole([IbRole.ACCOUNT_MANAGEMENT_READ]) &&
              <Route path="/system_management/user_management" element={<IbUserManagementPage />} />
            }
            {
              hasIbRole([IbRole.TABULATION_READ]) &&
              <Route path="/system_management/tabulation" element={<AggregationPage />} />
            }
            {
              hasIbRole([IbRole.LOG_READ]) &&
                <Route path="/system_management/log" element={<LogPage />} />
            }

            {/** 企業データベース */}
            {
              hasIbRole([IbRole.COMPANY_READ]) &&
              <Route path="/company_database/" element={<CompanyDatabasePage />} />
            }
            {/* 企業データベース -> リスト作成 */
              hasIbRole([IbRole.LIST_READ]) &&
              <Route path="/company_database/create_list" element={
                isAuthenticated && userPermissions.includes(UserRole.Admin) ? <ListExtraction /> : <CautionPage />} />
            }
            {/* 企業データベース -> 企業１件登録 */
              hasIbRole([IbRole.COMPANY_READ]) &&
                <Route path="/company_database/company_new" element={<CompanyRegistrationPage />} />
            }
            {/* 企業データベース -> 企業修正対象検索 */
              hasIbRole([IbRole.COMPANY_READ]) &&
                <Route path="/company_database/company_search" element={<CompanySearchPage />} />
            }
            {/* 企業データベース -> 企業修正対象検索 -> 企業１件詳細 */
              hasIbRole([IbRole.COMPANY_READ]) &&
                <Route path="/company_database/company_detail" element={<CompanyDetailPage />} />
            }
            {/* 企業データベース -> 企業取り込み（補完） */
              hasIbRole([IbRole.COMPANY_READ]) &&
              <Route path="/company_database/company_suggestion" element={<CompanySuggestionPage />} />
            }
            {/* 企業データベース -> 企業取り込み（上書き） */
              hasIbRole([IbRole.COMPANY_READ]) &&
              <Route path="/company_database/company_overwrite" element={<CompanyOverwritePage />} />
            }
            {/* 企業データベース -> 企業取り込み（上書き） */
              hasIbRole([IbRole.COMPANY_READ]) &&
              <Route path="/company_database/companyBlankOverwrite" element={<CompanyBlankOverwritePage />} />
            }
            {/* 企業データベース -> 顧客確認済みリスト取り込み */
              hasIbRole([IbRole.COMPANY_READ]) &&
              <Route path="/company_database/CustomerVerifiedListImportPage" element={<CustomerVerifiedListImportPage />} />
            }
            {/* 企業データベース -> フォームパス取込 */
              hasIbRole([IbRole.COMPANY_READ]) &&
                <Route path="/company_database/form_path" element={<FormPathImportPage />} />
            }
            {/* 企業データベース -> 加盟情報・スクレイピング付与 */
              hasIbRole([IbRole.COMPANY_READ]) &&
                <Route path="/company_database/scraping" element={<ScrapingPage />} />
            }


            {/* 顧客管理 */
              hasIbRole([IbRole.CLIENT_READ]) &&
              <Route path="/client_management" element={<ClientManagementPage />} />
            }
            {/* 新規顧客登録 */
              hasIbRole([IbRole.CLIENT_CREATE]) &&
              <Route path="/client_management/new" element={<ClientRegistrationPage />} />
            }
            {/* 顧客詳細 */
              hasIbRole([IbRole.CLIENT_READ]) &&
              <Route path="/client_management/detail" element={<ClientDetailPage />} />
            }
            {/* 案件情報 */
              hasIbRole([IbRole.MATTER_READ]) &&
              <Route path="/client_management/ProjectInformationPage" element={<ProjectInformationPage />} />
            }
            {/* 案件情報登録 */
              hasIbRole([IbRole.MATTER_CREATE]) &&
              <Route path="/client_management/ProjectRegistrationPage" element={<ProjectRegistrationPage />} />
            }
            {/* リスト詳細 */
              hasIbRole([IbRole.MATTER_CREATE]) &&
                <Route path="/client_management/ListDetailPage" element={<ListDetailPage />} />
            }
            {/* 発注先一覧 */
              hasIbRole([IbRole.ORDER_READ]) &&
                <Route path="/client_management/OrderTargetListPage" element={<OrderTargetListPage />} />
            }
            {/* 発注書 */
              hasIbRole([IbRole.ORDER_READ]) &&
                <Route path="/client_management/order/report" element={<OrderReportDownload />} />
            }
            {/* 新規発注 */
              hasIbRole([IbRole.ORDER_CREATE]) &&
                <Route path="/client_management/NewOrderPage" element={<NewOrderPage />} />
            }
            {/* 新規発注 */
              hasIbRole([IbRole.ORDER_CREATE]) &&
              <Route path="/client_management/NewOrderPage" element={<NewOrderPage />} />
            }
            {/* 新規発注確認 */
              hasIbRole([IbRole.ORDER_CREATE]) &&
              <Route path="/client_management/NewOrderConfPage" element={<NewOrderConfPage />} />
            }
            {/* 新規送信者情報登録 */
              hasIbRole([IbRole.MATTER_CREATE]) &&
              <Route path="/client_management/newSenderPage" element={<SenderRegistrationPage />} />
            }
            {/* 新規送信者情報詳細 */}
            {
              hasIbRole([IbRole.MATTER_READ]) &&
              <Route path="/client_management/SenderDetailPage" element={<SenderDetailPage />} />
            }
            {/* 顧客詳細 -> 送信者情報取り込み */
              hasIbRole([IbRole.CLIENT_READ]) &&
              <Route path="/client_management/SenderInformationImportPage" element={<SenderInformationImportPage />} />
            }
            {/* 顧客詳細 -> 送信原稿取り込み */
              hasIbRole([IbRole.CLIENT_READ]) &&
              <Route path="/client_management/SendManuscriptImportPage" element={<SendManuscriptImportPage />} />
            }
            {/* 新規送信原稿登録 */
              hasIbRole([IbRole.MATTER_CREATE]) &&
                <Route path="/client_management/newSubmissionDraftPage" element={<SubmissionDraftRegistrationPage />} />
            }
            {/* 新規送信原稿詳細 */
              hasIbRole([IbRole.MATTER_READ]) &&
              <Route path="/client_management/SubmissionDraftDetailPage" element={<SubmissionDraftDetailPage />} />
            }
            {/* 顧客別禁止登録 */
              hasIbRole([IbRole.CLIENT_PROHIBITION_CREATE]) &&
              <Route path="/client_management/ProhibitedRegistratiionByCustomerImportPage" element={<ProhibitedRegistratiionByCustomerImportPage />} />
            }
            {/* 顧客別禁止解除 */
              hasIbRole([IbRole.CLIENT_PROHIBITION_CREATE]) &&
              <Route path="/client_management/ProhibitedCancellationByCustomerImportPage" element={<ProhibitedCancellationByCustomerImportPage />} />
            }
            {/* 顧客別禁止リスト */
              hasIbRole([IbRole.CLIENT_PROHIBITION_READ]) &&
              <Route path="/client_management/ProhibitedManagementPage" element={<ProhibitedManagementPage />} />
            }
            {/* 顧客リスト詳細 */
              hasIbRole([IbRole.CLIENT_READ]) &&
              <Route path="/client_management/ClientListDetailPage" element={<ClientManagemetnListDetailPage />} />
            }
            {/* 顧客確認済みリスト取り込み */
              hasIbRole([IbRole.CLIENT_CREATE]) &&
              <Route path="/client_management/ClientListImportPage" element={<ClientListImportPage />} />
            }

            {/** 外注先管理 */}
            {
              hasIbRole([IbRole.SUBCONTRACTOR_READ]) &&
              <Route path="/outsourcing_management" element={<OutsourcingManagementPage />} />
            }
            {/* 外注先詳細 */
              hasIbRole([IbRole.SUBCONTRACTOR_READ]) &&
              <Route path="/outsourcing_management/detail" element={<OutsourcingDetailPage />} />
            }
            {/* 外注先新規登録 */
              hasIbRole([IbRole.SUBCONTRACTOR_CREATE]) &&
                <Route path="/outsourcing_management/new" element={<OutsourcingRegistrationPage />} />
            }

            {/* 施設利用者 */}
            {hasOutsourceRole([]) &&
              <>
                <Route path={"/outsource"} element={<OutsourceTopPage />} />
                <Route path={"/outsource/user_management"} element={<OutsourceUserManagementPage />} />
                <Route path={"/outsource/report"} element={<ContactPage />} />
              </>
            }
            {/* 拠点管理 */}
            {hasOutsourceRole([OutsourceRole.BASE_READ]) &&
              <>
                <Route path={"/outsource/location"} element={<OutsourceLocationPage />} />
              </>
            }
            {hasOutsourceRole([OutsourceRole.USER_ACCOUNT_READ, OutsourceRole.BASE_ACCOUNT_READ]) &&
              <Route path={"/outsource/user_management"} element={<OutsourceUserManagementPage />} />
            }
            {hasOutsourceRole([OutsourceRole.USER_RATION_READ, OutsourceRole.BASE_RATION_READ]) &&
              <>
                <Route path={"/outsource/subject_management"} element={<SubjectManagementPage />} />
                <Route path={"/outsource/subject_management/detail"} element={<SubjectDetailPage />} />
                <Route path={"/outsource/subject_management/assignment/operator"} element={<OperatorAssignmentPage />} />
                <Route path={"/outsource/subject_management/assignment/location"} element={<LocationAssignmentPage />} />
              </>
            }

            {/* 顧客向け */}
            {/* 顧客向け案件一覧 */}
            {hasClientRole([ClientRole.CLIENT_READ]) &&
              <>
                <Route path={"/client/list"} element={<ClientListPage />} />
                {/* 顧客向け案件詳細 */}
                <Route path={"/client/list/detail"} element={<ClientListDetailPage />} />
                {/* 顧客向け送信情報 */}
                <Route path={"/client/list/send_info"} element={<ClientSendInfoPage />} />
              </>
            }

            <Route path="*" element={<CautionPage />} />
          </>
          }
        </Routes>
      </Router>


      <CustomSnackbar
        open={props.open}
        onClose={handleClose}
        message={props.message}
        autoHideDuration={props.duration}
        severity={props.severity}
      />

      <BasicDialog
        close={() => null}
        open={alertMessage} title={''}
        content={<>セッションが切れました。再度ログインしてください。</>}
      />
    </>
  );
}

const mapStateToProps = (state: RootState) => {
  return {
    open: state.snackbar.open,
    message: state.snackbar.message,
    severity: state.snackbar.severity,
    duration: state.snackbar.duration,
    user: state.auth.user,
  }
}

export default connect(mapStateToProps)(App);
