import * as React from 'react'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'
import {useEffect, useState} from 'react'

type PanelProps = {
  children?: React.ReactNode
  index: number
  value: number
}

function CustomTabPanel(props: PanelProps) {
  return (
    <div
      role="tabpanel"
      hidden={props.value !== props.index}
      id={`simple-tabpanel-${props.index}`}
      aria-labelledby={`simple-tab-${props.index}`}
    >
      {props.value === props.index && (
        <Box sx={{ p: 3 }}>
          <div>{props.children}</div>
        </Box>
      )}
    </div>
  )
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

type Props = {
  tabItems: string[]
  tabContents: JSX.Element[]
  tabIndex?: number
  onChange?:(value: number) => void
}

export default function BasicTabs(props: Props) {
  const [value, setValue] = useState<number>(props.tabIndex || 0)

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
    props.onChange && props.onChange(newValue)
  }

  useEffect(() => {
    props.tabIndex && setValue(props.tabIndex)
  }, [props.tabIndex])

  return (
    <Box component="div" sx={{ width: '100%' }}>
      <Box component="div" sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs component="div" value={value} onChange={handleChange} aria-label="basic tabs">
          {props.tabItems.map((tab, index) => {
            return <Tab key={`tab-${index}`} label={tab} {...a11yProps(index)} />
          })}
        </Tabs>
      </Box>
      {props.tabContents.map((content, index) => {
        return (
          <CustomTabPanel key={`tab-content-${index}`} value={value} index={index}>
            {content}
          </CustomTabPanel>
        )
      })}
    </Box>
  )
}