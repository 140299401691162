import React from 'react';
import { FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material';

interface RadioOption {
  value: string;
  label: string;
}

interface RadioButtonProps {
  name: string;
  options: RadioOption[];
  selectedValue: string;
  onChange: (selectedValue: string) => void;
}

export default function BasicRadioButton({ name, options, selectedValue, onChange }: RadioButtonProps) {
  return (
    <FormControl component="fieldset">
      <RadioGroup name={name} value={selectedValue} onChange={(e) => onChange(e.target.value)} row>
        {options.map((option) => (
          <FormControlLabel key={option.value} value={option.value} control={<Radio />} label={option.label} />
        ))}
      </RadioGroup>
    </FormControl>
  );
}
