import React from 'react';
import Header from '../../../components/header/header';
import { Grid, Paper, Typography, styled } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import GridViewMenu, { MenuItem } from '../../../components/menu/gridViewMenu';

export default function CompanyDatabaseMenuPage() {
  const items: MenuItem[] = [
    { title: '修正', gotoPageUrl: '/company_database/company_search' },
    { title: '登録', gotoPageUrl: '/company_database/company_new' },
    { title: '企業取り込み（上書き）', gotoPageUrl: '/company_database/company_overwrite' },
    { title: '企業取り込み（空欄以外上書き）', gotoPageUrl: '/company_database/companyBlankOverwrite' },
    { title: '企業取り込み（補完）', gotoPageUrl: '/company_database/company_suggestion' },
    { title: 'フォームパス取込', gotoPageUrl: '/company_database/form_path' },
    {title: '加盟情報・スクレイピング付与' , gotoPageUrl: '/company_database/scraping'},
  ];

  return (
    <div>
      <div>
        <Header />
      </div>

      <div style={{ margin: '50px 50px 0px 50px' }}>
        <Typography fontWeight={'bold'} fontSize={'large'}>企業データベース管理</Typography>
      </div>

      {/* メニュー */}
      <div className='mx-5 my-5'>
        <GridViewMenu menuItems={items} />
      </div>
    </div>
  );
}