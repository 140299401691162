import { PropaneSharp } from "@mui/icons-material";
import BasicDropdown, { Dropdown } from "./basicDropdown"

type Props = {
  value: Dropdown
  setValue: (val: Dropdown) => void
}
export default function PrefectureDropdown(props: Props) {
  return (
    <BasicDropdown
      fullWidth
      titleName={'都道府県'}
      value={props.value}
      items={[]}
      onChange={(val: Dropdown) => props.setValue(val)}
      initialRequestUrl={'/common/master/prefecture'}
    />
  );
};