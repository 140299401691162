import { Dropdown } from "../../../components/dropdown/basicDropdown";
import HttpData from "../../../global/http/HttpData";

export type SenderLocation = {
  clientId: string
  clientName: string
  senderId?: string
  pageTitle?: string
}

export class SenderInfo {
  clientId: string = ''
  title: string = ''
  companyName: string = ''
  companyNameHiragana: string = ''
  companyNameKatakana: string = ''
  familyName: string = ''
  givenName: string = ''
  familyNameHiragana: string = ''
  givenNameHiragana: string = ''
  familyNameKatakana: string = ''
  givenNameKatakana: string = ''
  gender: Dropdown = { value: '', label: '' }
  tel: string = ''
  fax: string = ''
  prefecture: Dropdown = { value: '', label: '' }
  postalCode: Dropdown = { value: '', label: '' }
  city: string = ''
  address: string = ''
  birthday: string = ''
  url: string = ''
  mobilePhone: string = ''
  email: string = ''
  department: string = ''
  position: string = ''
  businessDescription: string = ''
  age: string = ''
}

export interface SenderRequest extends HttpData {
  senderId?: string
  clientId: string
  title: string
  companyName: string
  companyNameHiragana: string
  companyNameKatakana: string
  familyName: string
  givenName: string
  familyNameHiragana: string
  givenNameHiragana: string
  familyNameKatakana: string
  givenNameKatakana: string
  gender: Dropdown
  tel: string
  fax: string
  prefecture: Dropdown
  postalCode: Dropdown
  city: string
  address: string
  birthday: string
  url: string
  mobilePhone: string
  email: string
  department: string
  position: string
  businessDescription: string
  age: string
}

export interface SenderResponse extends SenderRequest { }

export type SenderDetailRequest = {
  sender_id: string
}