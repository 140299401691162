import '../../../styles/create_list/CreateListPage.css'
import { Checkbox, FormControlLabel, Radio, RadioGroup, Typography } from "@mui/material";
import Header from "../../../components/header/header";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useNavigate, useLocation } from "react-router-dom";
import BasicTable, { getValues, Row, Title } from "../../../components/table/basicTable";
import BasicTextField from "../../../components/text/basicTextField";
import { useRef, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import BasicButton from "../../../components/button/basicButton";
import BasicDialog from '../../../components/dialog/basicDialog';
import HttpRequest from "../../../global/http/HttpRequest";
import {
  CompanyListCreateRequest, CompanyListCreateResponse,
  CompanySearchRequest, CompanySearchResponse
} from "../../../@types/ib/companyDatabasePage/CompanyType";
import { showMessage } from "../../../global/store/snackbar";
import BasicDatePicker from '../../../components/datapicker/basicDatePicker';
import {ListSearch, MatchType, SearchConditionRequest} from "../../../@types/ib/clientManagement/ListSearchType";
import {NormalResponse} from "../../../@types/common";

type CheckboxData = {
  label: string
  checked: boolean
}

export default function ListExtraction() {
  const nav = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const clientId = location.state!.clientId;

  const gotoPage = (url: string) => {
    nav(url);
  };

  // リスト名
  const [inputValue, setInputValue] = useState<string>('');
  const [searchCondition, setSearchCondition] = useState<ListSearch>(new ListSearch());

  // 検索テーブルデータ
  const searchFieldList: Row[] = [
    { // 企業名
      content: [
        { name: <Typography
            className="text-nowrap"
            sx={{ fontWeight: 'bold' }}
          >企業名</Typography>,
          align: 'right',
          width: 200,
          type: 'TEXT',
          value: ''
        },
        { name: <BasicTextField
            fullwidth
            label=""
            value={searchCondition.companyName.keyword}
            setValue={(value) => setSearchCondition({...searchCondition,
              companyName: {...searchCondition.companyName, keyword: value}}
              )}
          />,
          align: 'left',
          type: 'TEXT',
          value: ''
        },
        {
          name:
            <div style={{ display: 'flex' }}>
              <FormControlLabel
                className="text-nowrap"
                control={<Radio
                  checked={searchCondition.companyName.matchType == MatchType.PREFIX_MATCH}
                  onChange={() => setSearchCondition({...searchCondition,
                    companyName: {...searchCondition.companyName, matchType: MatchType.PREFIX_MATCH}}
                  )}
                />}
                label={'前方一致'}
              />
              <FormControlLabel
                className="text-nowrap"
                control={<Radio
                  checked={searchCondition.companyName.matchType == MatchType.PARTIAL_MATCH}
                  onChange={() => setSearchCondition({...searchCondition,
                    companyName: {...searchCondition.companyName, matchType: MatchType.PARTIAL_MATCH}}
                  )}
                />}
                label={'部分一致'}
              />
              <FormControlLabel
                className="text-nowrap"
                control={<Radio
                  checked={searchCondition.companyName.matchType == MatchType.SUFFIX_MATCH}
                  onChange={() => setSearchCondition({...searchCondition,
                    companyName: {...searchCondition.companyName, matchType: MatchType.SUFFIX_MATCH}}
                  )}
                />}
                label={'後方一致'}
              />
              <FormControlLabel
                className="text-nowrap"
                control={<Checkbox />}
                value={searchCondition.companyName.isNot}
                onChange={(_: unknown, checked: boolean) => setSearchCondition({...searchCondition,
                  companyName: {...searchCondition.companyName, isNot: checked}}
                )}
                label={'NOT'}
              />
            </div>,
          type: 'TEXT',
          value: '',
          align: 'center'
        }
      ]
    },
    { // ドメインHTTPなし
      content: [
        { name: <Typography
            className="text-nowrap"
            sx={{ fontWeight: 'bold' }}
          >ドメインHTTPなし</Typography>,
          align: 'right',
          width: 200,
          type: 'TEXT',
          value: ''
        },
        { name: <BasicTextField
            fullwidth
            label=""
            value={searchCondition.domainName.keyword}
            setValue={(value) => setSearchCondition({...searchCondition,
              domainName: {...searchCondition.domainName, keyword: value}}
            )}
          />,
          align: 'left',
          type: 'TEXT',
          value: ''
        },
        {
          name:
            <div style={{ display: 'flex' }}>
              <FormControlLabel
                className="text-nowrap"
                control={<Radio
                  checked={searchCondition.domainName.matchType == MatchType.PREFIX_MATCH}
                  onChange={() => setSearchCondition({...searchCondition,
                    domainName: {...searchCondition.domainName, matchType: MatchType.PREFIX_MATCH}}
                  )}
                />}
                label={'前方一致'}
              />
              <FormControlLabel
                className="text-nowrap"
                control={<Radio
                  checked={searchCondition.domainName.matchType == MatchType.PARTIAL_MATCH}
                  onChange={() => setSearchCondition({...searchCondition,
                    domainName: {...searchCondition.domainName, matchType: MatchType.PARTIAL_MATCH}}
                  )}
                />}
                label={'部分一致'}
              />
              <FormControlLabel
                className="text-nowrap"
                control={<Radio
                  checked={searchCondition.domainName.matchType == MatchType.SUFFIX_MATCH}
                  onChange={() => setSearchCondition({...searchCondition,
                    domainName: {...searchCondition.domainName, matchType: MatchType.SUFFIX_MATCH}}
                  )}
                />}
                label={'後方一致'}
              />
              <FormControlLabel
                className="text-nowrap"
                control={<Checkbox />}
                value={searchCondition.domainName.isNot}
                onChange={(_: unknown, checked: boolean) => setSearchCondition({...searchCondition,
                  domainName: {...searchCondition.domainName, isNot: checked}}
                )}
                label={'NOT'}
              />
            </div>,
          type: 'TEXT',
          value: '',
          align: 'center'
        }
      ]
    },
    { // トップページURL
      content: [
        { name: <Typography
            className="text-nowrap"
            sx={{ fontWeight: 'bold' }}
          >トップページURL</Typography>,
          align: 'right',
          width: 200,
          type: 'TEXT',
          value: ''
        },
        { name: <BasicTextField
            fullwidth
            label=""
            value={searchCondition.topPageUrl.keyword}
            setValue={(value) => setSearchCondition({...searchCondition,
              topPageUrl: {...searchCondition.topPageUrl, keyword: value}}
            )}
          />,
          align: 'left',
          type: 'TEXT',
          value: ''
        },
        {
          name:
            <div style={{ display: 'flex' }}>
              <FormControlLabel
                className="text-nowrap"
                control={<Radio
                  checked={searchCondition.topPageUrl.matchType == MatchType.PREFIX_MATCH}
                  onChange={() => setSearchCondition({...searchCondition,
                    topPageUrl: {...searchCondition.topPageUrl, matchType: MatchType.PREFIX_MATCH}}
                  )}
                />}
                label={'前方一致'}
              />
              <FormControlLabel
                className="text-nowrap"
                control={<Radio
                  checked={searchCondition.topPageUrl.matchType == MatchType.PARTIAL_MATCH}
                  onChange={() => setSearchCondition({...searchCondition,
                    topPageUrl: {...searchCondition.topPageUrl, matchType: MatchType.PARTIAL_MATCH}}
                  )}
                />}
                label={'部分一致'}
              />
              <FormControlLabel
                className="text-nowrap"
                control={<Radio
                  checked={searchCondition.topPageUrl.matchType == MatchType.SUFFIX_MATCH}
                  onChange={() => setSearchCondition({...searchCondition,
                    topPageUrl: {...searchCondition.topPageUrl, matchType: MatchType.SUFFIX_MATCH}}
                  )}
                />}
                label={'後方一致'}
              />
              <FormControlLabel
                className="text-nowrap"
                control={<Checkbox />}
                value={searchCondition.topPageUrl.isNot}
                onChange={(_: unknown, checked: boolean) => setSearchCondition({...searchCondition,
                  topPageUrl: {...searchCondition.topPageUrl, isNot: checked}}
                )}
                label={'NOT'}
              />
            </div>,
          type: 'TEXT',
          value: '',
          align: 'center'
        }
      ]
    },
    { // 問い合わせページURL
      content: [
        { name: <Typography
            className="text-nowrap"
            sx={{ fontWeight: 'bold' }}
          >問い合わせページURL</Typography>,
          align: 'right',
          width: 200,
          type: 'TEXT',
          value: ''
        },
        { name: <BasicTextField
            fullwidth
            label=""
            value={searchCondition.inquiryUrl.keyword}
            setValue={(value) => setSearchCondition({...searchCondition,
              inquiryUrl: {...searchCondition.inquiryUrl, keyword: value}}
            )}
          />,
          align: 'left',
          type: 'TEXT',
          value: ''
        },
        {
          name:
            <div style={{ display: 'flex' }}>
              <FormControlLabel
                className="text-nowrap"
                control={<Radio
                  checked={searchCondition.inquiryUrl.matchType == MatchType.PREFIX_MATCH}
                  onChange={() => setSearchCondition({...searchCondition,
                    inquiryUrl: {...searchCondition.inquiryUrl, matchType: MatchType.PREFIX_MATCH}}
                  )}
                />}
                label={'前方一致'}
              />
              <FormControlLabel
                className="text-nowrap"
                control={<Radio
                  checked={searchCondition.inquiryUrl.matchType == MatchType.PARTIAL_MATCH}
                  onChange={() => setSearchCondition({...searchCondition,
                    inquiryUrl: {...searchCondition.inquiryUrl, matchType: MatchType.PARTIAL_MATCH}}
                  )}
                />}
                label={'部分一致'}
              />
              <FormControlLabel
                className="text-nowrap"
                control={<Radio
                  checked={searchCondition.inquiryUrl.matchType == MatchType.SUFFIX_MATCH}
                  onChange={() => setSearchCondition({...searchCondition,
                    inquiryUrl: {...searchCondition.inquiryUrl, matchType: MatchType.SUFFIX_MATCH}}
                  )}
                />}
                label={'後方一致'}
              />
              <FormControlLabel
                className="text-nowrap"
                control={<Checkbox />}
                value={searchCondition.inquiryUrl.isNot}
                onChange={(_: unknown, checked: boolean) => setSearchCondition({...searchCondition,
                  inquiryUrl: {...searchCondition.inquiryUrl, isNot: checked}}
                )}
                label={'NOT'}
              />
            </div>,
          type: 'TEXT',
          value: '',
          align: 'center'
        }
      ]
    },
    { // 大業種
      content: [
        { name: <Typography
            className="text-nowrap"
            sx={{ fontWeight: 'bold' }}
          >大業種</Typography>,
          align: 'right',
          width: 200,
          type: 'TEXT',
          value: ''
        },
        { name: <BasicTextField
            fullwidth
            label=""
            value={searchCondition.industryCategory.keyword}
            setValue={(value) => setSearchCondition({...searchCondition,
              industryCategory: {...searchCondition.industryCategory, keyword: value}}
            )}
          />,
          align: 'left',
          type: 'TEXT',
          value: ''
        },
        {
          name:
            <div style={{ display: 'flex' }}>
              <FormControlLabel
                className="text-nowrap"
                control={<Radio
                  checked={searchCondition.industryCategory.matchType == MatchType.PREFIX_MATCH}
                  onChange={() => setSearchCondition({...searchCondition,
                    industryCategory: {...searchCondition.industryCategory, matchType: MatchType.PREFIX_MATCH}}
                  )}
                />}
                label={'前方一致'}
              />
              <FormControlLabel
                className="text-nowrap"
                control={<Radio
                  checked={searchCondition.industryCategory.matchType == MatchType.PARTIAL_MATCH}
                  onChange={() => setSearchCondition({...searchCondition,
                    industryCategory: {...searchCondition.industryCategory, matchType: MatchType.PARTIAL_MATCH}}
                  )}
                />}
                label={'部分一致'}
              />
              <FormControlLabel
                className="text-nowrap"
                control={<Radio
                  checked={searchCondition.industryCategory.matchType == MatchType.SUFFIX_MATCH}
                  onChange={() => setSearchCondition({...searchCondition,
                    industryCategory: {...searchCondition.industryCategory, matchType: MatchType.SUFFIX_MATCH}}
                  )}
                />}
                label={'後方一致'}
              />
              <FormControlLabel
                className="text-nowrap"
                control={<Checkbox />}
                value={searchCondition.industryCategory.isNot}
                onChange={() => setSearchCondition({...searchCondition,
                  industryCategory: {...searchCondition.industryCategory, isNot: !searchCondition.companyName.isNot}}
                )}
                label={'NOT'}
              />
            </div>,
          type: 'TEXT',
          value: '',
          align: 'center'
        }
      ]
    },
    { // 小業種
      content: [
        { name: <Typography
            className="text-nowrap"
            sx={{ fontWeight: 'bold' }}
          >小業種</Typography>,
          align: 'right',
          width: 200,
          type: 'TEXT',
          value: ''
        },
        { name: <BasicTextField
            fullwidth
            label=""
            value={searchCondition.industrySubCategory.keyword}
            setValue={(value) => setSearchCondition({...searchCondition,
              industrySubCategory: {...searchCondition.industrySubCategory, keyword: value}}
            )}
          />,
          align: 'left',
          type: 'TEXT',
          value: ''
        },
        {
          name:
            <div style={{ display: 'flex' }}>
              <FormControlLabel
                className="text-nowrap"
                control={<Radio
                  checked={searchCondition.industrySubCategory.matchType == MatchType.PREFIX_MATCH}
                  onChange={() => setSearchCondition({...searchCondition,
                    industrySubCategory: {...searchCondition.industrySubCategory, matchType: MatchType.PREFIX_MATCH}}
                  )}
                />}
                label={'前方一致'}
              />
              <FormControlLabel
                className="text-nowrap"
                control={<Radio
                  checked={searchCondition.industrySubCategory.matchType == MatchType.PARTIAL_MATCH}
                  onChange={() => setSearchCondition({...searchCondition,
                    industrySubCategory: {...searchCondition.industrySubCategory, matchType: MatchType.PARTIAL_MATCH}}
                  )}
                />}
                label={'部分一致'}
              />
              <FormControlLabel
                className="text-nowrap"
                control={<Radio
                  checked={searchCondition.industrySubCategory.matchType == MatchType.SUFFIX_MATCH}
                  onChange={() => setSearchCondition({...searchCondition,
                    industrySubCategory: {...searchCondition.industrySubCategory, matchType: MatchType.SUFFIX_MATCH}}
                  )}
                />}
                label={'後方一致'}
              />
              <FormControlLabel
                className="text-nowrap"
                control={<Checkbox />}
                value={searchCondition.industrySubCategory.isNot}
                onChange={() => setSearchCondition({...searchCondition,
                  industrySubCategory: {...searchCondition.industrySubCategory, isNot: !searchCondition.companyName.isNot}}
                )}
                label={'NOT'}
              />
            </div>,
          type: 'TEXT',
          value: '',
          align: 'center'
        }
      ]
    },
    { // 電話番号
      content: [
        { name: <Typography
            className="text-nowrap"
            sx={{ fontWeight: 'bold' }}
          >電話番号</Typography>,
          align: 'right',
          width: 200,
          type: 'TEXT',
          value: ''
        },
        { name: <BasicTextField
            fullwidth
            label=""
            value={searchCondition.phoneNumber.keyword}
            setValue={(value) => setSearchCondition({...searchCondition,
              phoneNumber: {...searchCondition.phoneNumber, keyword: value}}
            )}
          />,
          align: 'left',
          type: 'TEXT',
          value: ''
        },
        {
          name:
            <div style={{ display: 'flex' }}>
              <FormControlLabel
                className="text-nowrap"
                control={<Radio
                  checked={searchCondition.phoneNumber.matchType == MatchType.PREFIX_MATCH}
                  onChange={() => setSearchCondition({...searchCondition,
                    phoneNumber: {...searchCondition.phoneNumber, matchType: MatchType.PREFIX_MATCH}}
                  )}
                />}
                label={'前方一致'}
              />
              <FormControlLabel
                className="text-nowrap"
                control={<Radio
                  checked={searchCondition.phoneNumber.matchType == MatchType.PARTIAL_MATCH}
                  onChange={() => setSearchCondition({...searchCondition,
                    phoneNumber: {...searchCondition.phoneNumber, matchType: MatchType.PARTIAL_MATCH}}
                  )}
                />}
                label={'部分一致'}
              />
              <FormControlLabel
                className="text-nowrap"
                control={<Radio
                  checked={searchCondition.phoneNumber.matchType == MatchType.SUFFIX_MATCH}
                  onChange={() => setSearchCondition({...searchCondition,
                    phoneNumber: {...searchCondition.phoneNumber, matchType: MatchType.SUFFIX_MATCH}}
                  )}
                />}
                label={'後方一致'}
              />
              <FormControlLabel
                className="text-nowrap"
                control={<Checkbox />}
                value={searchCondition.phoneNumber.isNot}
                onChange={() => setSearchCondition({...searchCondition,
                  phoneNumber: {...searchCondition.phoneNumber, isNot: !searchCondition.companyName.isNot}}
                )}
                label={'NOT'}
              />
            </div>,
          type: 'TEXT',
          value: '',
          align: 'center'
        }
      ]
    },
    { // FAX番号
      content: [
        { name: <Typography
            className="text-nowrap"
            sx={{ fontWeight: 'bold' }}
          >FAX番号</Typography>,
          align: 'right',
          width: 200,
          type: 'TEXT',
          value: ''
        },
        { name: <BasicTextField
            fullwidth
            label=""
            value={searchCondition.faxNumber.keyword}
            setValue={(value) => setSearchCondition({...searchCondition,
              faxNumber: {...searchCondition.faxNumber, keyword: value}}
            )}
          />,
          align: 'left',
          type: 'TEXT',
          value: ''
        },
        {
          name:
            <div style={{ display: 'flex' }}>
              <FormControlLabel
                className="text-nowrap"
                control={<Radio
                  checked={searchCondition.faxNumber.matchType == MatchType.PREFIX_MATCH}
                  onChange={() => setSearchCondition({...searchCondition,
                    faxNumber: {...searchCondition.faxNumber, matchType: MatchType.PREFIX_MATCH}}
                  )}
                />}
                label={'前方一致'}
              />
              <FormControlLabel
                className="text-nowrap"
                control={<Radio
                  checked={searchCondition.faxNumber.matchType == MatchType.PARTIAL_MATCH}
                  onChange={() => setSearchCondition({...searchCondition,
                    faxNumber: {...searchCondition.faxNumber, matchType: MatchType.PARTIAL_MATCH}}
                  )}
                />}
                label={'部分一致'}
              />
              <FormControlLabel
                className="text-nowrap"
                control={<Radio
                  checked={searchCondition.faxNumber.matchType == MatchType.SUFFIX_MATCH}
                  onChange={() => setSearchCondition({...searchCondition,
                    faxNumber: {...searchCondition.faxNumber, matchType: MatchType.SUFFIX_MATCH}}
                  )}
                />}
                label={'後方一致'}
              />
              <FormControlLabel
                className="text-nowrap"
                control={<Checkbox />}
                value={searchCondition.faxNumber.isNot}
                onChange={() => setSearchCondition({...searchCondition,
                  faxNumber: {...searchCondition.faxNumber, isNot: !searchCondition.companyName.isNot}}
                )}
                label={'NOT'}
              />
            </div>,
          type: 'TEXT',
          value: '',
          align: 'center'
        }
      ]
    },
    { // 郵便番号
      content: [
        { name: <Typography
            className="text-nowrap"
            sx={{ fontWeight: 'bold' }}
          >郵便番号</Typography>,
          align: 'right',
          width: 200,
          type: 'TEXT',
          value: ''
        },
        { name: <BasicTextField
            fullwidth
            label=""
            value={searchCondition.postalCode.keyword}
            setValue={(value) => setSearchCondition({...searchCondition,
              postalCode: {...searchCondition.postalCode, keyword: value}}
            )}
          />,
          align: 'left',
          type: 'TEXT',
          value: ''
        },
        {
          name:
            <div style={{ display: 'flex' }}>
              <FormControlLabel
                className="text-nowrap"
                control={<Radio
                  checked={searchCondition.postalCode.matchType == MatchType.PREFIX_MATCH}
                  onChange={() => setSearchCondition({...searchCondition,
                    postalCode: {...searchCondition.postalCode, matchType: MatchType.PREFIX_MATCH}}
                  )}
                />}
                label={'前方一致'}
              />
              <FormControlLabel
                className="text-nowrap"
                control={<Radio
                  checked={searchCondition.postalCode.matchType == MatchType.PARTIAL_MATCH}
                  onChange={() => setSearchCondition({...searchCondition,
                    postalCode: {...searchCondition.postalCode, matchType: MatchType.PARTIAL_MATCH}}
                  )}
                />}
                label={'部分一致'}
              />
              <FormControlLabel
                className="text-nowrap"
                control={<Radio
                  checked={searchCondition.postalCode.matchType == MatchType.SUFFIX_MATCH}
                  onChange={() => setSearchCondition({...searchCondition,
                    postalCode: {...searchCondition.postalCode, matchType: MatchType.SUFFIX_MATCH}}
                  )}
                />}
                label={'後方一致'}
              />
              <FormControlLabel
                className="text-nowrap"
                control={<Checkbox />}
                value={searchCondition.postalCode.isNot}
                onChange={() => setSearchCondition({...searchCondition,
                  postalCode: {...searchCondition.postalCode, isNot: !searchCondition.companyName.isNot}}
                )}
                label={'NOT'}
              />
            </div>,
          type: 'TEXT',
          value: '',
          align: 'center'
        }
      ]
    },
    { // 都道府県
      content: [
        { name: <Typography
            className="text-nowrap"
            sx={{ fontWeight: 'bold' }}
          >都道府県</Typography>,
          align: 'right',
          width: 200,
          type: 'TEXT',
          value: ''
        },
        { name: <BasicTextField
            fullwidth
            label=""
            value={searchCondition.prefecture.keyword}
            setValue={(value) => setSearchCondition({...searchCondition,
              prefecture: {...searchCondition.prefecture, keyword: value}}
            )}
          />,
          align: 'left',
          type: 'TEXT',
          value: ''
        },
        {
          name:
            <div style={{ display: 'flex' }}>
              <FormControlLabel
                className="text-nowrap"
                control={<Radio
                  checked={searchCondition.prefecture.matchType == MatchType.PREFIX_MATCH}
                  onChange={() => setSearchCondition({...searchCondition,
                    prefecture: {...searchCondition.prefecture, matchType: MatchType.PREFIX_MATCH}}
                  )}
                />}
                label={'前方一致'}
              />
              <FormControlLabel
                className="text-nowrap"
                control={<Radio
                  checked={searchCondition.prefecture.matchType == MatchType.PARTIAL_MATCH}
                  onChange={() => setSearchCondition({...searchCondition,
                    prefecture: {...searchCondition.prefecture, matchType: MatchType.PARTIAL_MATCH}}
                  )}
                />}
                label={'部分一致'}
              />
              <FormControlLabel
                className="text-nowrap"
                control={<Radio
                  checked={searchCondition.prefecture.matchType == MatchType.SUFFIX_MATCH}
                  onChange={() => setSearchCondition({...searchCondition,
                    prefecture: {...searchCondition.prefecture, matchType: MatchType.SUFFIX_MATCH}}
                  )}
                />}
                label={'後方一致'}
              />
              <FormControlLabel
                className="text-nowrap"
                control={<Checkbox />}
                value={searchCondition.prefecture.isNot}
                onChange={() => setSearchCondition({...searchCondition,
                  prefecture: {...searchCondition.prefecture, isNot: !searchCondition.companyName.isNot}}
                )}
                label={'NOT'}
              />
            </div>,
          type: 'TEXT',
          value: '',
          align: 'center'
        }
      ]
    },
    { // 住所
      content: [
        { name: <Typography
            className="text-nowrap"
            sx={{ fontWeight: 'bold' }}
          >住所</Typography>,
          align: 'right',
          width: 200,
          type: 'TEXT',
          value: ''
        },
        { name: <BasicTextField
            fullwidth
            label=""
            value={searchCondition.address.keyword}
            setValue={(value) => setSearchCondition({...searchCondition,
              address: {...searchCondition.address, keyword: value}}
            )}
          />,
          align: 'left',
          type: 'TEXT',
          value: ''
        },
        {
          name:
            <div style={{ display: 'flex' }}>
              <FormControlLabel
                className="text-nowrap"
                control={<Radio
                  checked={searchCondition.address.matchType == MatchType.PREFIX_MATCH}
                  onChange={() => setSearchCondition({...searchCondition,
                    address: {...searchCondition.address, matchType: MatchType.PREFIX_MATCH}}
                  )}
                />}
                label={'前方一致'}
              />
              <FormControlLabel
                className="text-nowrap"
                control={<Radio
                  checked={searchCondition.address.matchType == MatchType.PARTIAL_MATCH}
                  onChange={() => setSearchCondition({...searchCondition,
                    address: {...searchCondition.address, matchType: MatchType.PARTIAL_MATCH}}
                  )}
                />}
                label={'部分一致'}
              />
              <FormControlLabel
                className="text-nowrap"
                control={<Radio
                  checked={searchCondition.address.matchType == MatchType.SUFFIX_MATCH}
                  onChange={() => setSearchCondition({...searchCondition,
                    address: {...searchCondition.address, matchType: MatchType.SUFFIX_MATCH}}
                  )}
                />}
                label={'後方一致'}
              />
              <FormControlLabel
                className="text-nowrap"
                control={<Checkbox />}
                value={searchCondition.address.isNot}
                onChange={() => setSearchCondition({...searchCondition,
                  address: {...searchCondition.address, isNot: !searchCondition.companyName.isNot}}
                )}
                label={'NOT'}
              />
            </div>,
          type: 'TEXT',
          value: '',
          align: 'center'
        }
      ]
    },
    { // 従業員数
      content: [
        { name: <Typography
            className="text-nowrap"
            sx={{ fontWeight: 'bold' }}
          >従業員数</Typography>,
          align: 'right',
          width: 200,
          type: 'TEXT',
          value: ''
        },
        {
          name: <div style={{ display: 'flex' }}>
            <BasicTextField
              fullwidth
              label=""
              value={searchCondition.minEmployee.keyword}
              setValue={(value) => setSearchCondition({...searchCondition,
                  minEmployee: {...searchCondition.minEmployee, keyword: value}}
              )}
            />
            <div style={{ whiteSpace: 'nowrap', margin: 'auto 10px' }}>以上</div>
            <BasicTextField
              fullwidth
              label=""
              value={searchCondition.maxEmployee.keyword}
              setValue={(value) => setSearchCondition({...searchCondition,
                  maxEmployee: {...searchCondition.maxEmployee, keyword: value}}
              )}
            />
            <div style={{ whiteSpace: 'nowrap', margin: 'auto 10px' }}>以下</div>
          </div>,
          type: 'TEXT',
          value: '',
          align: 'left'
        },
      ]
    },
    { // 事業内容
      content: [
        { name: <Typography
            className="text-nowrap"
            sx={{ fontWeight: 'bold' }}
          >事業内容</Typography>,
          align: 'right',
          width: 200,
          type: 'TEXT',
          value: ''
        },
        { name: <BasicTextField
            fullwidth
            label=""
            value={searchCondition.operation.keyword}
            setValue={(value) => setSearchCondition({...searchCondition,
              operation: {...searchCondition.operation, keyword: value}}
            )}
          />,
          align: 'left',
          type: 'TEXT',
          value: ''
        },
        {
          name:
            <div style={{ display: 'flex' }}>
              <FormControlLabel
                className="text-nowrap"
                control={<Radio
                  checked={searchCondition.operation.matchType == MatchType.PARTIAL_MATCH}
                  onChange={() => setSearchCondition({...searchCondition,
                    operation: {...searchCondition.operation, matchType: MatchType.PARTIAL_MATCH}}
                  )}
                />}
                label={'部分一致'}
              />
              <FormControlLabel
                className="text-nowrap"
                control={<Checkbox />}
                value={searchCondition.operation.isNot}
                onChange={() => setSearchCondition({...searchCondition,
                  operation: {...searchCondition.operation, isNot: !searchCondition.companyName.isNot}}
                )}
                label={'NOT'}
              />
            </div>,
          type: 'TEXT',
          value: '',
          align: 'center'
        }
      ]
    },
    { // 一言説明
      content: [
        { name: <Typography
            className="text-nowrap"
            sx={{ fontWeight: 'bold' }}
          >一言説明</Typography>,
          align: 'right',
          width: 200,
          type: 'TEXT',
          value: ''
        },
        { name: <BasicTextField
            fullwidth
            label=""
            value={searchCondition.description.keyword}
            setValue={(value) => setSearchCondition({...searchCondition,
              description: {...searchCondition.description, keyword: value}}
            )}
          />,
          align: 'left',
          type: 'TEXT',
          value: ''
        },
        {
          name:
            <div style={{ display: 'flex' }}>
              <FormControlLabel
                className="text-nowrap"
                control={<Radio
                  checked={searchCondition.description.matchType == MatchType.PARTIAL_MATCH}
                  onChange={() => setSearchCondition({...searchCondition,
                    description: {...searchCondition.description, matchType: MatchType.PARTIAL_MATCH}}
                  )}
                />}
                label={'部分一致'}
              />
              <FormControlLabel
                className="text-nowrap"
                control={<Checkbox />}
                value={searchCondition.description.isNot}
                onChange={() => setSearchCondition({...searchCondition,
                  description: {...searchCondition.description, isNot: !searchCondition.companyName.isNot}}
                )}
                label={'NOT'}
              />
            </div>,
          type: 'TEXT',
          value: '',
          align: 'center'
        }
      ]
    },
    { // 加盟情報
      content: [
        { name: <Typography
            className="text-nowrap"
            sx={{ fontWeight: 'bold' }}
          >加盟情報</Typography>,
          align: 'right',
          width: 200,
          type: 'TEXT',
          value: ''
        },
        { name: <BasicTextField
            fullwidth
            label=""
            value={searchCondition.joining.keyword}
            setValue={(value) => setSearchCondition({...searchCondition,
              joining: {...searchCondition.joining, keyword: value}}
            )}
          />,
          align: 'left',
          type: 'TEXT',
          value: ''
        },
        {
          name:
            <div style={{ display: 'flex' }}>
              <FormControlLabel
                className="text-nowrap"
                control={<Radio
                  checked={searchCondition.joining.matchType == MatchType.PARTIAL_MATCH}
                  onChange={() => setSearchCondition({...searchCondition,
                    joining: {...searchCondition.joining, matchType: MatchType.PARTIAL_MATCH}}
                  )}
                />}
                label={'部分一致'}
              />
              <FormControlLabel
                className="text-nowrap"
                control={<Checkbox />}
                value={searchCondition.joining.isNot}
                onChange={() => setSearchCondition({...searchCondition,
                  joining: {...searchCondition.joining, isNot: !searchCondition.companyName.isNot}}
                )}
                label={'NOT'}
              />
            </div>,
          type: 'TEXT',
          value: '',
          align: 'center'
        }
      ]
    },
    { // スクレイピングタブ
      content: [
        { name: <Typography
            className="text-nowrap"
            sx={{ fontWeight: 'bold' }}
          >スクレイピングタブ</Typography>,
          align: 'right',
          width: 200,
          type: 'TEXT',
          value: ''
        },
        { name: <BasicTextField
            fullwidth
            label=""
            value={searchCondition.scrapingTab.keyword}
            setValue={(value) => setSearchCondition({...searchCondition,
              scrapingTab: {...searchCondition.scrapingTab, keyword: value}}
            )}
          />,
          align: 'left',
          type: 'TEXT',
          value: ''
        },
        {
          name:
            <div style={{ display: 'flex' }}>
              <FormControlLabel
                className="text-nowrap"
                control={<Radio
                  checked={searchCondition.scrapingTab.matchType == MatchType.PARTIAL_MATCH}
                  onChange={() => setSearchCondition({...searchCondition,
                    scrapingTab: {...searchCondition.scrapingTab, matchType: MatchType.PARTIAL_MATCH}}
                  )}
                />}
                label={'部分一致'}
              />
              <FormControlLabel
                className="text-nowrap"
                control={<Checkbox />}
                value={searchCondition.scrapingTab.isNot}
                onChange={() => setSearchCondition({...searchCondition,
                  scrapingTab: {...searchCondition.scrapingTab, isNot: !searchCondition.companyName.isNot}}
                )}
                label={'NOT'}
              />
            </div>,
          type: 'TEXT',
          value: '',
          align: 'center'
        }
      ]
    },
    { // 資本金
      content: [
        { name: <Typography
            className="text-nowrap"
            sx={{ fontWeight: 'bold' }}
          >資本金</Typography>,
          align: 'right',
          width: 200,
          type: 'TEXT',
          value: ''
        },
        {
          name: <div style={{ display: 'flex' }}>
            <BasicTextField
              fullwidth
              label=""
              value={searchCondition.minCapital.keyword}
              setValue={(value) => setSearchCondition({...searchCondition,
                minCapital: {...searchCondition.minCapital, keyword: value}}
              )}
            />
            <div style={{ whiteSpace: 'nowrap', margin: 'auto 10px' }}>以上</div>
            <BasicTextField
              fullwidth
              label=""
              value={searchCondition.maxCapital.keyword}
              setValue={(value) => setSearchCondition({...searchCondition,
                maxCapital: {...searchCondition.maxCapital, keyword: value}}
              )}
            />
            <div style={{ whiteSpace: 'nowrap', margin: 'auto 10px' }}>以下</div>
          </div>,
          type: 'TEXT',
          value: '',
          align: 'left'
        },
      ]
    },
    { // 売上高
      content: [
        { name: <Typography
            className="text-nowrap"
            sx={{ fontWeight: 'bold' }}
          >売上高</Typography>,
          align: 'right',
          width: 200,
          type: 'TEXT',
          value: ''
        },
        {
          name: <div style={{ display: 'flex' }}>
            <BasicTextField
              fullwidth
              label=""
              value={searchCondition.minSales.keyword}
              setValue={(value) => setSearchCondition({...searchCondition,
                minSales: {...searchCondition.minSales, keyword: value}}
              )}
            />
            <div style={{ whiteSpace: 'nowrap', margin: 'auto 10px' }}>以上</div>
            <BasicTextField
              fullwidth
              label=""
              value={searchCondition.maxSales.keyword}
              setValue={(value) => setSearchCondition({...searchCondition,
                maxSales: {...searchCondition.maxSales, keyword: value}}
              )}
            />
            <div style={{ whiteSpace: 'nowrap', margin: 'auto 10px' }}>以下</div>
          </div>,
          type: 'TEXT',
          value: '',
          align: 'left'
        },
      ]
    },
    { // 上場区分
      content: [
        { name: <Typography
            className="text-nowrap"
            sx={{ fontWeight: 'bold' }}
          >上場区分</Typography>,
          align: 'right',
          width: 200,
          type: 'TEXT',
          value: ''
        },
        { name: <BasicTextField
            fullwidth
            label=""
            value={searchCondition.listingClass.keyword}
            setValue={(value) => setSearchCondition({...searchCondition,
              listingClass: {...searchCondition.listingClass, keyword: value}}
            )}
          />,
          align: 'left',
          type: 'TEXT',
          value: ''
        },
        {
          name:
            <div style={{ display: 'flex' }}>
              <FormControlLabel
                className="text-nowrap"
                control={<Radio
                  checked={searchCondition.listingClass.matchType == MatchType.PARTIAL_MATCH}
                  onChange={() => setSearchCondition({...searchCondition,
                    listingClass: {...searchCondition.listingClass, matchType: MatchType.PARTIAL_MATCH}}
                  )}
                />}
                label={'部分一致'}
              />
              <FormControlLabel
                className="text-nowrap"
                control={<Checkbox />}
                value={searchCondition.listingClass.isNot}
                onChange={() => setSearchCondition({...searchCondition,
                  listingClass: {...searchCondition.listingClass, isNot: !searchCondition.companyName.isNot}}
                )}
                label={'NOT'}
              />
            </div>,
          type: 'TEXT',
          value: '',
          align: 'center'
        }
      ]
    },
    { // 代表者
      content: [
        { name: <Typography
            className="text-nowrap"
            sx={{ fontWeight: 'bold' }}
          >代表者</Typography>,
          align: 'right',
          width: 200,
          type: 'TEXT',
          value: ''
        },
        { name: <BasicTextField
            fullwidth
            label=""
            value={searchCondition.representative.keyword}
            setValue={(value) => setSearchCondition({...searchCondition,
              representative: {...searchCondition.representative, keyword: value}}
            )}
          />,
          align: 'left',
          type: 'TEXT',
          value: ''
        },
        {
          name:
            <div style={{ display: 'flex' }}>
              <FormControlLabel
                className="text-nowrap"
                control={<Radio
                  checked={searchCondition.representative.matchType == MatchType.PARTIAL_MATCH}
                  onChange={() => setSearchCondition({...searchCondition,
                    representative: {...searchCondition.representative, matchType: MatchType.PARTIAL_MATCH}}
                  )}
                />}
                label={'部分一致'}
              />
              <FormControlLabel
                className="text-nowrap"
                control={<Checkbox />}
                value={searchCondition.representative.isNot}
                onChange={() => setSearchCondition({...searchCondition,
                  representative: {...searchCondition.representative, isNot: !searchCondition.companyName.isNot}}
                )}
                label={'NOT'}
              />
            </div>,
          type: 'TEXT',
          value: '',
          align: 'center'
        }
      ]
    },
    { // 設立年月
      content: [
        { name: <Typography
            className="text-nowrap"
            sx={{ fontWeight: 'bold' }}
          >設立年月</Typography>,
          align: 'right',
          width: 200,
          type: 'TEXT',
          value: ''
        },
        {
          name: <div className="d-flex">
            <div style={{ display: 'flex' }}>
              <BasicDatePicker
                title=""
                dateFormat={'yyyy/MM'}
                isClearable
                iconPosition={'startAdornment'}
                value={searchCondition.minEstablishmentDate.keyword}
                setValue={(value: string | Date | null) => setSearchCondition({...searchCondition,
                  minEstablishmentDate: {...searchCondition.minEstablishmentDate, keyword: value as string}}
                )}
              />
              <div style={{ whiteSpace: 'nowrap', margin: 'auto 30px' }}>{'～'}</div>
              <BasicDatePicker
                title=""
                dateFormat={'yyyy/MM'}
                isClearable
                iconPosition={'startAdornment'}
                value={searchCondition.maxEstablishmentDate.keyword}
                setValue={(value) => setSearchCondition({...searchCondition,
                  maxEstablishmentDate: {...searchCondition.maxEstablishmentDate, keyword: value as string}}
                )}
              />
            </div>
          </div>,
          type: 'TEXT',
          value: '',
          align: 'left'
        },
      ]
    },
    { // 決算月
      content: [
        { name: <Typography
            className="text-nowrap"
            sx={{ fontWeight: 'bold' }}
          >決算月</Typography>,
          align: 'right',
          width: 200,
          type: 'TEXT',
          value: ''
        },
        { name: <BasicTextField
            fullwidth
            label=""
            value={searchCondition.closingMonth.keyword}
            setValue={(value) => setSearchCondition({...searchCondition,
              closingMonth: {...searchCondition.closingMonth, keyword: value}}
            )}
          />,
          align: 'left',
          type: 'TEXT',
          value: ''
        },
        {
          name:
            <div style={{ display: 'flex' }}>
              <FormControlLabel
                className="text-nowrap"
                control={<Radio
                  checked={searchCondition.closingMonth.matchType == MatchType.PARTIAL_MATCH}
                  onChange={() => setSearchCondition({...searchCondition,
                    closingMonth: {...searchCondition.closingMonth, matchType: MatchType.PARTIAL_MATCH}}
                  )}
                />}
                label={'部分一致'}
              />
              <FormControlLabel
                className="text-nowrap"
                control={<Checkbox />}
                value={searchCondition.closingMonth.isNot}
                onChange={() => setSearchCondition({...searchCondition,
                  closingMonth: {...searchCondition.closingMonth, isNot: !searchCondition.companyName.isNot}}
                )}
                label={'NOT'}
              />
            </div>,
          type: 'TEXT',
          value: '',
          align: 'center'
        }
      ]
    },
    { // 事務所数
      content: [
        { name: <Typography
            className="text-nowrap"
            sx={{ fontWeight: 'bold' }}
          >事務所数</Typography>,
          align: 'right',
          width: 200,
          type: 'TEXT',
          value: ''
        },
        {
          name: <div style={{ display: 'flex' }}>
            <BasicTextField
              fullwidth
              label=""
              value={searchCondition.minOffices.keyword}
              setValue={(value) => setSearchCondition({...searchCondition,
                minOffices: {...searchCondition.minOffices, keyword: value}}
              )}
            />
            <div style={{ whiteSpace: 'nowrap', margin: 'auto 10px' }}>以上</div>
            <BasicTextField
              fullwidth
              label=""
              value={searchCondition.maxOffices.keyword}
              setValue={(value) => setSearchCondition({...searchCondition,
                maxOffices: {...searchCondition.maxOffices, keyword: value}}
              )}
            />
            <div style={{ whiteSpace: 'nowrap', margin: 'auto 10px' }}>以下</div>
          </div>,
          type: 'TEXT',
          value: '',
          align: 'left'
        },
      ]
    },
    { // 工場数
      content: [
        { name: <Typography
            className="text-nowrap"
            sx={{ fontWeight: 'bold' }}
          >工場数</Typography>,
          align: 'right',
          width: 200,
          type: 'TEXT',
          value: ''
        },
        {
          name: <div style={{ display: 'flex' }}>
            <BasicTextField
              fullwidth
              label=""
              value={searchCondition.minFactories.keyword}
              setValue={(value) => setSearchCondition({...searchCondition,
                minFactories: {...searchCondition.minFactories, keyword: value}}
              )}
            />
            <div style={{ whiteSpace: 'nowrap', margin: 'auto 10px' }}>以上</div>
            <BasicTextField
              fullwidth
              label=""
              value={searchCondition.maxFactories.keyword}
              setValue={(value) => setSearchCondition({...searchCondition,
                maxFactories: {...searchCondition.maxFactories, keyword: value}}
              )}
            />
            <div style={{ whiteSpace: 'nowrap', margin: 'auto 10px' }}>以下</div>
          </div>,
          type: 'TEXT',
          value: '',
          align: 'left'
        },
      ]
    },
    { // 店舗数
      content: [
        { name: <Typography
            className="text-nowrap"
            sx={{ fontWeight: 'bold' }}
          >店舗数</Typography>,
          align: 'right',
          width: 200,
          type: 'TEXT',
          value: ''
        },
        {
          name: <div style={{ display: 'flex' }}>
            <BasicTextField
              fullwidth
              label=""
              value={searchCondition.minStores.keyword}
              setValue={(value) => setSearchCondition({...searchCondition,
                minStores: {...searchCondition.minStores, keyword: value}}
              )}
            />
            <div style={{ whiteSpace: 'nowrap', margin: 'auto 10px' }}>以上</div>
            <BasicTextField
              fullwidth
              label=""
              value={searchCondition.maxStores.keyword}
              setValue={(value) => setSearchCondition({...searchCondition,
                maxStores: {...searchCondition.maxStores, keyword: value}}
              )}
            />
            <div style={{ whiteSpace: 'nowrap', margin: 'auto 10px' }}>以下</div>
          </div>,
          type: 'TEXT',
          value: '',
          align: 'left'
        },
      ]
    },
    { // 取引形態
      content: [
        { name: <Typography
            className="text-nowrap"
            sx={{ fontWeight: 'bold' }}
          >取引形態</Typography>,
          align: 'right',
          width: 200,
          type: 'TEXT',
          value: ''
        },
        { name: <BasicTextField
            fullwidth
            label=""
            value={searchCondition.dealForm.keyword}
            setValue={(value) => setSearchCondition({...searchCondition,
              dealForm: {...searchCondition.dealForm, keyword: value}}
            )}
          />,
          align: 'left',
          type: 'TEXT',
          value: ''
        },
        {
          name:
            <div style={{ display: 'flex' }}>
              <FormControlLabel
                className="text-nowrap"
                control={<Radio
                  checked={searchCondition.dealForm.matchType == MatchType.PARTIAL_MATCH}
                  onChange={() => setSearchCondition({...searchCondition,
                    dealForm: {...searchCondition.dealForm, matchType: MatchType.PARTIAL_MATCH}}
                  )}
                />}
                label={'部分一致'}
              />
              <FormControlLabel
                className="text-nowrap"
                control={<Checkbox />}
                value={searchCondition.dealForm.isNot}
                onChange={(_: unknown, checked: boolean) => setSearchCondition({...searchCondition,
                  dealForm: {...searchCondition.dealForm, isNot: checked}}
                )}
                label={'NOT'}
              />
            </div>,
          type: 'TEXT',
          value: '',
          align: 'center'
        }
      ]
    },
    { // サブ大業種
      content: [
        { name: <Typography
            className="text-nowrap"
            sx={{ fontWeight: 'bold' }}
          >サブ大業種</Typography>,
          align: 'right',
          width: 200,
          type: 'TEXT',
          value: ''
        },
        { name: <BasicTextField
            fullwidth
            label=""
            value={searchCondition.subIndustryCategory.keyword}
            setValue={(value) => setSearchCondition({...searchCondition,
              subIndustryCategory: {...searchCondition.subIndustryCategory, keyword: value}}
            )}
          />,
          align: 'left',
          type: 'TEXT',
          value: ''
        },
        {
          name:
            <div style={{ display: 'flex' }}>
              <FormControlLabel
                className="text-nowrap"
                control={<Radio
                  checked={searchCondition.subIndustryCategory.matchType == MatchType.PARTIAL_MATCH}
                  onChange={() => setSearchCondition({...searchCondition,
                    subIndustryCategory: {...searchCondition.subIndustryCategory, matchType: MatchType.PARTIAL_MATCH}}
                  )}
                />}
                label={'部分一致'}
              />
              <FormControlLabel
                className="text-nowrap"
                control={<Checkbox />}
                value={searchCondition.subIndustryCategory.isNot}
                onChange={() => setSearchCondition({...searchCondition,
                  subIndustryCategory: {...searchCondition.subIndustryCategory, isNot: !searchCondition.companyName.isNot}}
                )}
                label={'NOT'}
              />
            </div>,
          type: 'TEXT',
          value: '',
          align: 'center'
        }
      ]
    },
    { // サブ小業種
      content: [
        { name: <Typography
            className="text-nowrap"
            sx={{ fontWeight: 'bold' }}
          >サブ小業種</Typography>,
          align: 'right',
          width: 200,
          type: 'TEXT',
          value: ''
        },
        { name: <BasicTextField
            fullwidth
            label=""
            value={searchCondition.closingMonth.keyword}
            setValue={(value) => setSearchCondition({...searchCondition,
              closingMonth: {...searchCondition.closingMonth, keyword: value}}
            )}
          />,
          align: 'left',
          type: 'TEXT',
          value: ''
        },
        {
          name:
            <div style={{ display: 'flex' }}>
              <FormControlLabel
                className="text-nowrap"
                control={<Radio
                  checked={searchCondition.subIndustrySubCategory.matchType == MatchType.PARTIAL_MATCH}
                  onChange={() => setSearchCondition({...searchCondition,
                    subIndustrySubCategory: {...searchCondition.subIndustrySubCategory, matchType: MatchType.PARTIAL_MATCH}}
                  )}
                />}
                label={'部分一致'}
              />
              <FormControlLabel
                className="text-nowrap"
                control={<Checkbox />}
                value={searchCondition.subIndustrySubCategory.isNot}
                onChange={() => setSearchCondition({...searchCondition,
                  subIndustrySubCategory: {...searchCondition.subIndustrySubCategory, isNot: !searchCondition.companyName.isNot}}
                )}
                label={'NOT'}
              />
            </div>,
          type: 'TEXT',
          value: '',
          align: 'center'
        }
      ]
    },
    { // 送信方法
      content: [
        { name: <Typography
            className="text-nowrap"
            sx={{ fontWeight: 'bold' }}
          >送信方法</Typography>,
          align: 'right',
          width: 200,
          type: 'TEXT',
          value: ''
        },
        {
          name:
            <div style={{ display: 'flex' }}>
              <FormControlLabel
                className="text-nowrap"
                control={<Checkbox />}
                value={searchCondition.sendMethod.keyword.split(',').includes('AUTO')}
                onChange={() => {
                  const currentSendMethod = searchCondition.sendMethod.keyword.split(',')
                  if (currentSendMethod.includes('AUTO')) {
                    currentSendMethod.splice(currentSendMethod.indexOf('AUTO'), 1)
                  } else {
                    currentSendMethod.push('AUTO')
                  }
                  setSearchCondition({...searchCondition,
                    sendMethod: {...searchCondition.sendMethod, keyword: currentSendMethod.join(',')}
                  })
                }}
                label={'自動送信'}
              />
              <FormControlLabel
                className="text-nowrap"
                control={<Checkbox />}
                value={searchCondition.sendMethod.keyword.split(',').includes('HAND')}
                onChange={() => {
                  const currentSendMethod = searchCondition.sendMethod.keyword.split(',')
                  if (currentSendMethod.includes('HAND')) {
                    currentSendMethod.splice(currentSendMethod.indexOf('HAND'), 1)
                  } else {
                    currentSendMethod.push('HAND')
                  }
                  setSearchCondition({...searchCondition,
                    sendMethod: {...searchCondition.sendMethod, keyword: currentSendMethod.join(',')}
                  })
                }}
                label={'手送信'}
              />
            </div>,
          type: 'TEXT',
          value: '',
          align: 'center'
        }
      ]
    },
  ]

  // リスト作成ダイアログ
  const [open, setOpen] = useState<boolean>(false);
  const [dialogContentLength, setDialogContentLength] = useState<number>(0);

  useEffect(() => {
    setInputValue("")
  }, [open]);

  // ダイアログ用テーブルデータ
  const dialogHeader: Title[] = [
    { name: '企業名', align: 'left' },
    { name: '住所', align: 'left' },
    { name: '業種', align: 'left' },
  ];

  const [searchResult, setSearchResult] = useState<Row[]>([]);

  // リスト登録処理
  const registration = async () => {
      const http = new HttpRequest<SearchConditionRequest, NormalResponse>()
      http.request = {
        clientId: clientId,
        listName: inputValue,
        ...searchCondition,
      }

      if (await http.post('/client/company_list/register')) {
        setOpen(false)
        dispatch(showMessage({
          message: http.response!.message,
          severity: 'info',
          duration: 3000,
        }))
        return
      }
  }

  // 検索処理
  const handleSearchData = async () => {
    const http = new HttpRequest<SearchConditionRequest, CompanySearchResponse>()
    http.request = {...searchCondition, clientId: clientId}

    if (await http.post('/client/company_list/search')) {
      const info = { ...http.response! }
      const num: string = info.companies.length.toString()
      const clientUserList: Row[] = []
      for (const row of info.companies) {
        const clientUserRow: Row = {
          content: [
            { id: 'name', name: row.companyName, align: 'left', type: 'TEXT', value: row.companyId },
            { id: 'address', name: row.address, align: 'left', type: 'TEXT', value: row.address },
            { id: 'business', name: row.businessDescription, align: 'left', type: 'TEXT', value: row.businessDescription },
          ]
        }
        clientUserList.push(clientUserRow)
        setSearchResult(clientUserList);
      }
      setDialogContentLength(info.count)
      setOpen(true);
    }
  }

  return (
    <div>
      <div>
        <Header />
      </div>

      <div>
        {/* パンくずリスト */}
        <div>
          <div className="d-flex" style={{ margin: '50px 50px 0px 50px' }}>
            <div onClick={() => gotoPage('/company_database')}>
              <Typography fontWeight={'bold'} fontSize={'large'} style={{ color: 'gray', cursor: 'pointer' }}>
                {'企業データベース管理'}
              </Typography>
            </div>

            <div style={{ width: '20px' }}></div>
            <ArrowForwardIcon />
            <div style={{ width: '20px' }}></div>

            <Typography fontWeight={'bold'} fontSize={'large'}>
              {'リスト作成'}
            </Typography>
          </div>
        </div>

        {/* 検索エリア */}
        <div>
          <div className="mx-5 my-3 container">
            <BasicTable headers={[]} content={searchFieldList} />
            <div className="my-2 text-center">
              <BasicButton width={100} displayName="検索" onClick={handleSearchData} />
            </div>
          </div>

          {/* 検索ボタン */}
          <div className="text-center mx-5 my-3">
            <BasicDialog
              open={open}
              close={() => setOpen(false)}
              title={`検索結果：${dialogContentLength}件`}
              content={
                <div>
                  <div className="my-2">
                    <BasicTextField label="リスト名" value={inputValue} setValue={setInputValue} />
                  </div>
                  <BasicTable headers={dialogHeader} content={searchResult} />
                </div>
              }
              positiveAction={() => registration()}
              positiveButtonName='登録'
              negativeButtonName='キャンセル'
            />
          </div>
        </div>
      </div>
    </div>
  )
}