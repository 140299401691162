import { TextField, InputProps as MuiInputProps } from "@mui/material";
import { useState } from "react";

type Props = {
  label: string
  value?: string
  setValue: (e: any) => void
  size?: 'small' | 'medium'
  type?: 'text' | 'email' | 'password' | 'date' | 'tel' | 'number'
  fullwidth?: boolean
  multiline?: boolean
  rows?: number
  InputProps?: Partial<MuiInputProps>
  helperText?: string
  defaultValue?: string
}

export default function BasicTextField(props: Props) {
  const [value, setValue] = useState<string>();

  return (
    <TextField
      label={props.label}
      variant="outlined"
      size={props.size ?? 'small'}
      type={props.type ?? 'text'}
      multiline={props.multiline ?? false}
      rows={props.multiline ? (props.rows || 15) : undefined}
      fullWidth={props.fullwidth ?? false}
      value={typeof props.value == 'string' ? props.value : value}
      onChange={e => props.setValue(e.target.value)}
      InputProps={props.InputProps && { ...props.InputProps }}
      helperText={props.helperText}
      defaultValue={props.defaultValue}
    />
  );
};