import {useLocation} from "react-router-dom";
import Header from "../../../components/header/header";
import OrderReport from "../../../components/report/orderReport";
import {useEffect, useState} from "react";
import HttpRequest from "../../../global/http/HttpRequest";
import {OrderReportInfo, OrderReportRequest, OrderReportResponse} from "../../../@types/ib/clientManagement/OrderType";
import BasicButton from "../../../components/button/basicButton";
import DownloadExcelRequest from "../../../global/http/DownloadExcelRequest";

export default function OrderReportDownload() {
  const location = useLocation()
  const orderId = location.state?.orderId

  const [reportInfo, setReportInfo] = useState<OrderReportInfo>({
    reportNumber: 0,
    outsourceName: '',
    title: '',
    startDate: '',
    endDate: '',
    companyName: '',
    postNumber: '',
    address: '',
    phoneNumber: '',
    faxNumber: '',
    operatorName: '',
    ibOperatorName: '',
    listName: '',
    qty: '',
    price: '',
    subtotalAmount: '',
    taxAmout: '',
    totalAmount: ''
  })

  const handleDownload = async () => {
    try {
        const DownloadExcel = new DownloadExcelRequest();
        DownloadExcel.addStr('order_id', orderId);
        const response = await DownloadExcel.upload('/client/order/report/download?order_id');
        const blob = await response.blob();
        const downloadUrl = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.download = "発注書.pdf";  // ダウンロードするファイル名
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    } catch (error) {
        console.error('ファイルのダウンロード中にエラーが発生しました。', error);
    }
  };

  useEffect(() => {
    const f = async () => {
      const http = new HttpRequest<OrderReportRequest, OrderReportResponse>()
      http.request = {
        order_id: orderId
      }

      if (await http.get('/client/order/report')) {
        const res = http.response!
        setReportInfo({
          /** 帳票番号 */
          reportNumber: 0,
          /** 外注先名 */
          outsourceName: res.outsourceName,
          /** 件名 */
          title: res.title,
          /** 送信期間（開始） */
          startDate: res.startDate,
          /** 送信期間（完了） */
          endDate: res.endDate,
          /** 会社名 */
          companyName: res.companyName,
          /** 郵便番号 */
          postNumber: res.postalCode,
          /** 住所（都道府県＋市＋住所） */
          address: res.prefecture + res.address,
          /** TEL */
          phoneNumber: res.tel,
          /** FAX */
          faxNumber: res.fax,
          /** 外注先担当者名 */
          operatorName: res.outsourcePicName,
          /** IB担当者名 */
          ibOperatorName: res.ibPicName,
          /** リスト名 */
          listName: res.listName,
          /** 数量 */
          qty: res.qty,
          /** 単価 */
          price: res.unitPrice,
          /** 小計 */
          subtotalAmount: res.subtotalPrice,
          /** 消費税 */
          taxAmout: res.tax,
          /** 合計金額 */
          totalAmount: res.totalPrice
        })
      }
    }
    f()
  }, []);

  return <div>
    <div>
      <Header />
    </div>
    <div className={'mx-5'}>
      <OrderReport {...reportInfo} />
    </div>
    <div>
      <div className="container">
        <div className="row">
          <div>
            <BasicButton
              displayName="ダウンロード" onClick={() => handleDownload()}
            />
          </div>
        </div>
      </div>
    </div>
  </div>
}