import { useNavigate, useLocation } from "react-router-dom";
import Header from "../../../components/header/header";
import { TextField, Typography } from "@mui/material";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useEffect, useState, forwardRef } from "react";
import { useDispatch } from "react-redux";
import BasicButton from "../../../components/button/basicButton";
import BasicTable, { Row, Title } from "../../../components/table/basicTable";
import "react-datepicker/dist/react-datepicker.css";
import HttpRequest from "../../../global/http/HttpRequest";
import { SenderDetailRequest, SenderInfo, SenderResponse } from "../../../@types/ib/clientManagement/SenderType";

// 顧客詳細 -> 新規送信者情報登録ページ 																	
export default function SenderDetailPage() {
  const nav = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const clientId = location.state?.clientId;
  const senderId = location.state?.senderId;
  const clientName = location.state?.clientName;

  const gotoPage = (url: string) => {
    nav(url, { state: { clientId: clientId, senderId: senderId, pageTitle: '送信者情報編集', tabIndex: 1 } })
  }

  // state(基本情報)
  const [senderInfo, setSenderInfo] = useState<SenderInfo>(new SenderInfo())

  useEffect(() => {
    const f = async () => {
      const http = new HttpRequest<SenderDetailRequest, SenderResponse>()
      http.request = {
        sender_id: senderId,
      }
      if (await http.get(`/client/sender/detail`)) {
        const info = { ...http.response! }
        setSenderInfo(info)
      }
    }
    f()
  }, []);

  return (
    <div>
      <div>
        <Header />
      </div>

      {/* パンくずリスト */}
      <div className="d-flex" style={{ margin: '50px 50px 0px 50px' }}>
        <div onClick={() => gotoPage('/client_management')}>
          <Typography fontWeight={'bold'} fontSize={'large'} style={{ color: 'gray', cursor: 'pointer' }}>
            {'顧客管理'}
          </Typography>
        </div>

        <div style={{ width: '20px' }}></div>
        <ArrowForwardIcon />
        <div style={{ width: '20px' }}></div>

        <div onClick={() => gotoPage('/client_management/detail')}>
          <Typography fontWeight={'bold'} fontSize={'large'} style={{ color: 'gray', cursor: 'pointer' }}>
            {'顧客詳細'}
          </Typography>
        </div>

        <div style={{ width: '20px' }}></div>
        <ArrowForwardIcon />
        <div style={{ width: '20px' }}></div>

        <Typography fontWeight={'bold'} fontSize={'large'} style={{ color: 'gray' }}>{'案件情報'}</Typography>

        <div style={{ width: '20px' }}></div>
        <ArrowForwardIcon />
        <div style={{ width: '20px' }}></div>

        <Typography fontWeight={'bold'} fontSize={'large'}>{'送信者情報詳細'}</Typography>
      </div>

      {/* 顧客名 */}
      <div className="d-flex" style={{ margin: '30px 30px 0px 50px' }}>
        <Typography align="left" fontWeight={'bold'} fontSize={'large'}>{clientName}</Typography>
      </div>

      {/* 登録フォーム */}
      <div className="mx-5 my-3 container">
        <BasicTable
          contentHoverColor={'white'}
          headers={[]}
          content={[
            // 案件
            {
              content: [
                { name: <Typography align="left" fontWeight={'bold'} fontSize={'large'}>{'案件'}</Typography>, align: 'left', fontWeight: 'bold', isNowrap: true, type: 'TEXT', width: 80 },
                { name: '', align: 'left', isNowrap: true, type: 'TEXT', width: 10 },
                { name: '', align: 'left', isNowrap: true, type: 'TEXT', colspan: 5 },
              ]
            },
            {
              content: [
                { name: '案件名', align: 'left', isNowrap: true, type: 'TEXT', width: 80 },
                { name: '：', align: 'left', isNowrap: true, type: 'TEXT', width: 10 },
                { name: senderInfo.title, align: 'left', isNowrap: true, type: 'TEXT', colspan: 5 },
              ]
            },
            // 企業
            {
              content: [
                { name: <Typography align="left" fontWeight={'bold'} fontSize={'large'}>{'企業'}</Typography>, align: 'left', fontWeight: 'bold', isNowrap: true, type: 'TEXT', width: 80 },
                { name: '', align: 'left', isNowrap: true, type: 'TEXT', width: 10 },
                { name: '', align: 'left', isNowrap: true, type: 'TEXT', colspan: 5 },
              ]
            },
            {
              content: [
                { name: '企業名', align: 'left', isNowrap: true, type: 'TEXT', width: 80 },
                { name: '：', align: 'left', isNowrap: true, type: 'TEXT', width: 10 },
                { name: senderInfo.companyName, align: 'left', isNowrap: true, type: 'TEXT' },

                { name: '', align: 'left', isNowrap: true, type: 'TEXT' },

                { name: 'HP', align: 'left', isNowrap: true, type: 'TEXT', width: 80 },
                { name: '：', align: 'left', isNowrap: true, type: 'TEXT', width: 10 },
                { name: senderInfo.url, align: 'left', isNowrap: true, type: 'TEXT' },
              ]
            },
            {
              content: [
                { name: '企業名（かな）', align: 'left', isNowrap: true, type: 'TEXT', width: 80 },
                { name: '：', align: 'left', isNowrap: true, type: 'TEXT', width: 10 },
                { name: senderInfo.companyNameHiragana, align: 'left', isNowrap: true, type: 'TEXT' },

                { name: '', align: 'left', isNowrap: true, type: 'TEXT' },

                { name: '企業名（カナ）', align: 'left', isNowrap: true, type: 'TEXT', width: 80 },
                { name: '：', align: 'left', isNowrap: true, type: 'TEXT', width: 10 },
                { name: senderInfo.companyNameKatakana, align: 'left', isNowrap: true, type: 'TEXT' },
              ]
            },
            {
              content: [
                { name: '電話番号', align: 'left', isNowrap: true, type: 'TEXT', width: 80 },
                { name: '：', align: 'left', isNowrap: true, type: 'TEXT', width: 10 },
                { name: senderInfo.tel, align: 'left', isNowrap: true, type: 'TEXT' },

                { name: '', align: 'left', isNowrap: true, type: 'TEXT' },

                { name: 'FAX番号', align: 'left', isNowrap: true, type: 'TEXT', width: 80 },
                { name: '：', align: 'left', isNowrap: true, type: 'TEXT', width: 10 },
                { name: senderInfo.fax, align: 'left', isNowrap: true, type: 'TEXT' },
              ]
            },
            {
              content: [
                { name: '郵便番号', align: 'left', isNowrap: true, type: 'TEXT', width: 80 },
                { name: '：', align: 'left', isNowrap: true, type: 'TEXT', width: 10 },
                { name: senderInfo.postalCode.label, align: 'left', isNowrap: true, type: 'TEXT' },

                { name: '', align: 'left', isNowrap: true, type: 'TEXT' },

                { name: '都道府県', align: 'left', isNowrap: true, type: 'TEXT', width: 80 },
                { name: '：', align: 'left', isNowrap: true, type: 'TEXT', width: 10 },
                { name: senderInfo.prefecture.label, align: 'left', isNowrap: true, type: 'TEXT' },
              ]
            },
            {
              content: [
                { name: '市町村', align: 'left', isNowrap: true, type: 'TEXT', width: 80 },
                { name: '：', align: 'left', isNowrap: true, type: 'TEXT', width: 10 },
                { name: senderInfo.city, align: 'left', isNowrap: true, type: 'TEXT' },

                { name: '', align: 'left', isNowrap: true, type: 'TEXT' },

                { name: '住所', align: 'left', isNowrap: true, type: 'TEXT', width: 80 },
                { name: '：', align: 'left', isNowrap: true, type: 'TEXT', width: 10 },
                { name: senderInfo.address, align: 'left', isNowrap: true, type: 'TEXT' },
              ]
            },
            {
              content: [
                { name: '事業内容', align: 'left', isNowrap: true, type: 'TEXT', width: 80 },
                { name: '：', align: 'left', isNowrap: true, type: 'TEXT', width: 10 },
                { name: senderInfo.businessDescription, align: 'left', isNowrap: true, type: 'TEXT', colspan: 5 },
              ]
            },
            // 送信者
            {
              content: [
                { name: <Typography align="left" fontWeight={'bold'} fontSize={'large'}>{'送信者'}</Typography>, fontWeight: 'bold', align: 'left', isNowrap: true, type: 'TEXT', width: 80 },
                { name: '', align: 'left', isNowrap: true, type: 'TEXT', width: 10 },
                { name: '', align: 'left', isNowrap: true, type: 'TEXT', colspan: 5 },
              ]
            },
            {
              content: [
                { name: '氏', align: 'left', isNowrap: true, type: 'TEXT', width: 80 },
                { name: '：', align: 'left', isNowrap: true, type: 'TEXT', width: 10 },
                { name: senderInfo.familyName, align: 'left', isNowrap: true, type: 'TEXT' },

                { name: '', align: 'left', isNowrap: true, type: 'TEXT' },

                { name: '名', align: 'left', isNowrap: true, type: 'TEXT', width: 80 },
                { name: '：', align: 'left', isNowrap: true, type: 'TEXT', width: 10 },
                { name: senderInfo.givenName, align: 'left', isNowrap: true, type: 'TEXT' },
              ]
            },
            {
              content: [
                { name: '氏（かな）', align: 'left', isNowrap: true, type: 'TEXT', width: 80 },
                { name: '：', align: 'left', isNowrap: true, type: 'TEXT', width: 10 },
                { name: senderInfo.familyNameHiragana, align: 'left', isNowrap: true, type: 'TEXT' },

                { name: '', align: 'left', isNowrap: true, type: 'TEXT' },

                { name: '名（かな）', align: 'left', isNowrap: true, type: 'TEXT', width: 80 },
                { name: '：', align: 'left', isNowrap: true, type: 'TEXT', width: 10 },
                { name: senderInfo.givenNameHiragana, align: 'left', isNowrap: true, type: 'TEXT' },
              ]
            },
            {
              content: [
                { name: '氏（カナ）', align: 'left', isNowrap: true, type: 'TEXT', width: 80 },
                { name: '：', align: 'left', isNowrap: true, type: 'TEXT', width: 10 },
                { name: senderInfo.familyNameKatakana, align: 'left', isNowrap: true, type: 'TEXT' },

                { name: '', align: 'left', isNowrap: true, type: 'TEXT' },

                { name: '名（カナ）', align: 'left', isNowrap: true, type: 'TEXT', width: 80 },
                { name: '：', align: 'left', isNowrap: true, type: 'TEXT', width: 10 },
                { name: senderInfo.givenNameKatakana, align: 'left', isNowrap: true, type: 'TEXT' },
              ]
            },
            {
              content: [
                { name: '部署', align: 'left', isNowrap: true, type: 'TEXT', width: 80 },
                { name: '：', align: 'left', isNowrap: true, type: 'TEXT', width: 10 },
                { name: senderInfo.department, align: 'left', isNowrap: true, type: 'TEXT' },

                { name: '', align: 'left', isNowrap: true, type: 'TEXT' },

                { name: '役職', align: 'left', isNowrap: true, type: 'TEXT', width: 80 },
                { name: '：', align: 'left', isNowrap: true, type: 'TEXT', width: 10 },
                { name: senderInfo.position, align: 'left', isNowrap: true, type: 'TEXT' },
              ]
            },
            {
              content: [
                { name: '生年月日', align: 'left', isNowrap: true, type: 'TEXT', width: 80 },
                { name: '：', align: 'left', isNowrap: true, type: 'TEXT', width: 10 },
                { name: senderInfo.birthday.split(' ')[0], align: 'left', isNowrap: true, type: 'TEXT' },

                { name: '', align: 'left', isNowrap: true, type: 'TEXT' },

                { name: '年齢', align: 'left', isNowrap: true, type: 'TEXT', width: 80 },
                { name: '：', align: 'left', isNowrap: true, type: 'TEXT', width: 10 },
                { name: senderInfo.age, align: 'left', isNowrap: true, type: 'TEXT' },
              ]
            },
            {
              content: [
                { name: '携帯電話', align: 'left', isNowrap: true, type: 'TEXT', width: 80 },
                { name: '：', align: 'left', isNowrap: true, type: 'TEXT', width: 10 },
                { name: senderInfo.mobilePhone, align: 'left', isNowrap: true, type: 'TEXT' },

                { name: '', align: 'left', isNowrap: true, type: 'TEXT' },

                { name: 'メールアドレス', align: 'left', isNowrap: true, type: 'TEXT', width: 80 },
                { name: '：', align: 'left', isNowrap: true, type: 'TEXT', width: 10 },
                { name: senderInfo.email, align: 'left', isNowrap: true, type: 'TEXT' },
              ]
            },
            {
              content: [
                { name: '性別', align: 'left', isNowrap: true, type: 'TEXT', width: 80 },
                { name: '：', align: 'left', isNowrap: true, type: 'TEXT', width: 10 },
                { name: senderInfo.gender.label, align: 'left', isNowrap: true, type: 'TEXT', colspan: 5 },
              ]
            },
          ]}
        />
        <div className="my-3" style={{ textAlign: 'center' }}>
          <BasicButton
            displayName={'編集'}
            onClick={() => gotoPage('/client_management/newSenderPage')}
          />
        </div>
      </div>
    </div >
  );
}