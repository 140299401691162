import SecureHttp from "./SecureHttp";
import HttpData from "./HttpData";
import {useDispatch} from "react-redux";
import {showMessage} from "../store/snackbar";
import {NormalResponse} from "../../@types/common";
import store from "../store/store";
import {hideLoader, showLoader} from "../store/loaderSlice";

export default class HttpRequest<T extends HttpData, U extends HttpData> extends SecureHttp<T, U> {
  public constructor() {
    super()
  }

  /**
   * GET送信
   * @param path リクエストURL
   * @param isResponse true: return レスポンス, falseまたは指定なし: return ステータスコード===200
   */
  public async get(path: string): Promise<boolean> {
    let queryString = '';
    if (!!this._request) {
      const dict: Record<string, any> = {}
      const obj: Record<string, any> = this._request
      for (const key in obj) {
        if (Object.prototype.hasOwnProperty.call(this._request, key)) {
          dict[key] = String(obj[key])
        }
      }
      queryString = '?' + new URLSearchParams(dict).toString()
    }
    const url = this.getFullEndPoint(path + queryString)
    const response = await fetch(url, {
      credentials: 'include',
    })
    await this.handleError(response)
    this._response = await response.json()
    this.statusCode = response.status
    return this.statusCode === 200
  }

  /**
   * POST送信
   * @param path リクエストURL
   */
  public async post(path: string): Promise<boolean> {
    this.showLoader()
    const url = this.getFullEndPoint(path)
    const postData = !!this._request ? JSON.stringify(this._request) : ""
    const response = await fetch(url, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: postData,
    })
    await this.handleError(response)
    this._response = await response.json()
    this.statusCode = response.status
    return this.statusCode === 200
  }

  /**
   * POST送信（ダウンロード用）
   * @param path リクエストURL
   * @param fileName ダウンロードファイル名
   */
  public async download(path: string, fileName: string) {
    this.showLoader()
    const url = this.getFullEndPoint(path)
    const postData = !!this._request ? JSON.stringify(this._request) : ""
    try {
      const response = await fetch(url, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: postData,
      })
      const res = await response.blob()
      const downloadLink = window.URL.createObjectURL(new Blob([res]))
      const link = document.createElement('a');
      link.href = downloadLink;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      link.parentNode?.removeChild(link);
      this.dispatch(hideLoader())
      return true
    } catch (e) {
      this.dispatch(hideLoader())
      console.log(e)
      return false
    }
  }

  private async handleError(response: Response) {
    this.dispatch(hideLoader())
    const status = response.status
    switch(status) {
      case 200:
        break
      case 401:
        break
      default:
        const data = await response.json()
        this.dispatch(showMessage({
          message: data['errorMessage'] ?? 'エラーが発生しました。',
          severity: 'error',
          duration: 3000,
        }))
        throw new Error(data['errorType'])
    }
  }
}