import SecureHttp from "./SecureHttp";
import HttpData from "./HttpData";

export default class JsonRequest<T extends HttpData, U extends HttpData> extends SecureHttp<T, U> {
  public constructor() {
    super()
  }

  public async get(path: string) {
    let queryString = '';
    if (!!this._request) {
      const dict: Record<string, any> = {}
      const obj: Record<string, any> = this._request
      for (const key in obj) {
        if (Object.prototype.hasOwnProperty.call(this._request, key)) {
          dict[key] = String(obj[key])
        }
      }
      queryString = '?' + new URLSearchParams(dict).toString()
    }
    const url = this.getFullEndPoint(path + queryString)
    try {
      const response = await fetch(url, {
        credentials: 'include',
      })
      this._response = await response.json()
      this.statusCode = response.status
    } catch(e) {
      console.log(e)
    }
    return this.statusCode === 200
  }

  public async post(path: string) {
    const url = this.getFullEndPoint(path)
    const postData = !!this._request ? JSON.stringify(this._request) : ""
    try{
      const response = await fetch(url, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: postData,
      })
      this._response = await response.json()
      this.statusCode = response.status
      return this._response
    } catch(e) {
      console.log(e)
      return this.statusCode === 200
    }
  }
}