import HttpRequest from "../../../../global/http/HttpRequest";
import { AggregationResponse, AggregationSearchRequest } from "../../../../@types/ib/systemManagement/aggregationType";
import React, { useEffect, useState } from "react";
import Header from "../../../../components/header/header";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Typography } from "@mui/material";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import BasicTextField from "../../../../components/text/basicTextField";
import BasicButton from "../../../../components/button/basicButton";
import BasicDropdown, { Dropdown } from "../../../../components/dropdown/basicDropdown";
import BasicDatePicker from "../../../../components/datapicker/basicDatePicker";
import BasicTable, { Row } from "../../../../components/table/basicTable";
import IbUserDropdown from "../../../../components/dropdown/ibUserDropdown";
import {setFips} from "node:crypto";

export default function AggregationPage() {
  const nav = useNavigate();
  const dispatch = useDispatch();

  // 検索
  const [searchClientName, setSearchClientName] = useState<string>('')
  const [searchOperator, setSearchOperator] = useState<string>('')
  const [searchSendStartDate, setSearchSendStartDate] = useState<string>('')
  const [searchSendEndDate, setSearchSendEndDate] = useState<string>('')
  const [searchFollowOperator, setSearchFollowOperator] = useState<string>('')

  // const [followOperators, setFollowOperators] = useState<Dropdown[]>([])

  const [data, setData] = useState<AggregationResponse>()
  const [tableData, setTableData] = useState<Row[]>([])

  const gotoPage = (url: string) => {
    nav(url);
  };

  useEffect(() => {
    if (!data || data?.lists.length === 0) {
      setTableData([])
      return
    }
    setTableData(data!.lists.map((m, index) => {
      return {
        content: [
          { name: <Typography>{m.clientName}</Typography>, align: 'left', isNowrap: true, type: 'TEXT', },
          { name: <Typography>{m.subjectTotal.toString()}</Typography>, align: 'right', isNowrap: true, type: 'TEXT' },
          {
            name: <div>
              <Typography sx={{ fontWeight: 'bold' }}>{`${(m?.autoCompleteTotal || 0) + (m?.manualCompleteTotal || 0)}`}</Typography>
              <div className="d-flex" style={{ justifyContent: 'end', marginTop: '10px' }}>
                <div style={{ backgroundColor: 'mediumspringgreen', borderRadius: '20px', fontSize: '14px', color: 'white', padding: '2px 10px', marginRight: '10px' }}>自動</div>
                <Typography sx={{}}>{`${m?.autoCompleteTotal || 0}`}</Typography>
              </div>
              <div className="d-flex" style={{ justifyContent: 'end', marginTop: '10px' }}>
                <div style={{ backgroundColor: 'cornflowerblue', borderRadius: '20px', fontSize: '14px', color: 'white', padding: '2px 10px', marginRight: '10px' }}>手動</div>
                <Typography sx={{}}>{`${m?.manualCompleteTotal || 0}`}</Typography>
              </div>
            </div>, align: 'right', isNowrap: true, type: 'TEXT'
          },
          {
            name: <div>
              <Typography sx={{ fontWeight: 'bold' }}>{`${(m?.autoSuccessTotal || 0) + (m?.manualSuccessTotal || 0)}`}</Typography>
              <div className="d-flex" style={{ justifyContent: 'end', marginTop: '10px' }}>
                <div style={{ backgroundColor: 'mediumspringgreen', borderRadius: '20px', fontSize: '14px', color: 'white', padding: '2px 10px', marginRight: '10px' }}>自動</div>
                <Typography sx={{}}>{`${m?.autoSuccessTotal || 0}`}</Typography>
              </div>
              <div className="d-flex" style={{ justifyContent: 'end', marginTop: '10px' }}>
                <div style={{ backgroundColor: 'cornflowerblue', borderRadius: '20px', fontSize: '14px', color: 'white', padding: '2px 10px', marginRight: '10px' }}>手動</div>
                <Typography sx={{}}>{`${m?.manualSuccessTotal || 0}`}</Typography>
              </div>
            </div>, align: 'right', isNowrap: true, type: 'TEXT'
          },
          { name: <Typography>{m.picName}</Typography>, align: 'center', isNowrap: true, type: 'TEXT' },
          { name: <Typography>{m.followerName}</Typography>, align: 'center', isNowrap: true, type: 'TEXT' },
        ]
      }
    }))
  }, [data])

  async function search() {
    const http = new HttpRequest<AggregationSearchRequest, AggregationResponse>()
    http.request = {
      clientName: searchClientName,
      picName: searchOperator,
      followerName: searchFollowOperator,
      startDate: searchSendStartDate,
      endDate: searchSendEndDate
    }
    if (await http.post('/system/aggregation/search')) {
      setData(http.response)
    }
  }

  return <div>
    <div>
      <Header />
    </div>

    {/* パンくずリスト */}
    <div className="d-flex" style={{ margin: '50px 50px 0px 50px' }}>
      <div onClick={() => gotoPage('/system_management')}>
        <Typography fontWeight={'bold'} fontSize={'large'} style={{ color: 'gray', cursor: 'pointer' }}>
          {'システム管理'}
        </Typography>
      </div>

      <div style={{ width: '20px' }}></div>
      <ArrowForwardIcon />
      <div style={{ width: '20px' }}></div>

      <Typography fontWeight={'bold'} fontSize={'large'}>{'集計（帳票）'}</Typography>
    </div>

    <div className="mx-5 container">
      {/* ROW 1 */}
      <div className="my-4" style={{ backgroundColor: 'whitesmoke', padding: '20px', borderRadius: '10px' }}>
        <div className="row" style={{ marginBottom: '20px' }}>
          <div className="col-6"><BasicTextField fullwidth label="顧客名" value={searchClientName} setValue={setSearchClientName} /></div>
          <div className="col-6"><BasicTextField fullwidth label="直接担当" value={searchOperator} setValue={setSearchOperator} /></div>
        </div>
        <div className="row" style={{ marginBottom: '20px' }}>
          <div className="col-6">
            <div className="d-flex">
              <div className="col-5"><BasicDatePicker isClearable iconPosition="startAdornment" value={searchSendStartDate} setValue={setSearchSendStartDate} /></div>
              <div className="col-2" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>{'～'}</div>
              <div className="col-5"><BasicDatePicker isClearable iconPosition="startAdornment" value={searchSendEndDate} setValue={setSearchSendEndDate} /></div>
            </div>
          </div>
          <div className="col-6">
            <BasicTextField
              fullwidth
              label="フォロー担当"
              value={searchFollowOperator}
              setValue={setSearchFollowOperator}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-9"></div>
          <div className="col-3"><BasicButton fullWidth displayName="検索" onClick={search} /></div>
        </div>
      </div>
      {/* END ROW 1 */}

      {/* ROW 2 */}
      <div className="my-4" style={{ backgroundColor: 'lightyellow', padding: '20px', borderRadius: '10px' }}>
        <div className="row">
          {/* 総顧客数 */}
          <div className="col-2">
            <div style={{ fontWeight: 'bold' }}>総顧客数</div>
            <div className="d-flex" style={{}}>
              <Typography sx={{ fontSize: '24px', fontWeight: 'bold' }}>{`${data?.clientTotal || 0}`}</Typography>
              <Typography sx={{ paddingTop: '6px', paddingLeft: '10px' }}>{'件'}</Typography>
            </div>
          </div>
          {/* 総案件数 */}
          <div className="col-2">
            <div style={{ fontWeight: 'bold' }}>総案件数</div>
            <div className="d-flex" style={{}} >
              <Typography sx={{ fontSize: '24px', fontWeight: 'bold' }}>{`${data?.subjectTotal || 0}`}</Typography>
              <Typography sx={{ paddingTop: '6px', paddingLeft: '10px' }}>{'件'}</Typography>
            </div>
          </div>
          {/* 総送信完了数 */}
          <div className="col-4">
            <div className="d-flex" style={{ fontWeight: 'bold' }}>総送信完了数</div>
            <div className="d-flex" style={{}}>
              <Typography sx={{ fontSize: '24px', fontWeight: 'bold' }}>{`${(data?.autoCompleteTotal || 0) + (data?.manualCompleteTotal || 0)}`}</Typography>
              <Typography sx={{ paddingTop: '6px', paddingLeft: '10px' }}>{'件'}</Typography>
            </div>
            <div className="d-flex" style={{ marginTop: '10px' }}>
              <div style={{ backgroundColor: 'mediumspringgreen', borderRadius: '20px', fontSize: '14px', color: 'white', padding: '2px 10px', marginRight: '10px' }}>自動</div>
              <Typography sx={{}}>{`${data?.autoCompleteTotal || 0}`}</Typography>
              <Typography sx={{ paddingLeft: '10px' }}>{'件'}</Typography>
            </div>
            <div className="d-flex" style={{ marginTop: '10px' }}>
              <div style={{ backgroundColor: 'cornflowerblue', borderRadius: '20px', fontSize: '14px', color: 'white', padding: '2px 10px', marginRight: '10px' }}>手動</div>
              <Typography sx={{}}>{`${data?.manualCompleteTotal || 0}`}</Typography>
              <Typography sx={{ paddingLeft: '10px' }}>{'件'}</Typography>
            </div>
          </div>
          {/* 総送信成功数 */}
          <div className="col-4">
            <div className="d-flex" style={{ fontWeight: 'bold' }}>総送信完了数</div>
            <div className="d-flex" style={{}}>
              <Typography sx={{ fontSize: '24px', fontWeight: 'bold' }}>{`${(data?.autoCompleteTotal || 0) + (data?.manualCompleteTotal || 0)}`}</Typography>
              <Typography sx={{ paddingTop: '6px', paddingLeft: '10px' }}>{'件'}</Typography>
            </div>
            <div className="d-flex" style={{ marginTop: '10px' }}>
              <div style={{ backgroundColor: 'mediumspringgreen', borderRadius: '20px', fontSize: '14px', color: 'white', padding: '2px 10px', marginRight: '10px' }}>自動</div>
              <Typography sx={{}}>{`${data?.autoCompleteTotal || 0}`}</Typography>
              <Typography sx={{ paddingLeft: '10px' }}>{'件'}</Typography>
            </div>
            <div className="d-flex" style={{ marginTop: '10px' }}>
              <div style={{ backgroundColor: 'cornflowerblue', borderRadius: '20px', fontSize: '14px', color: 'white', padding: '2px 10px', marginRight: '10px' }}>手動</div>
              <Typography sx={{}}>{`${data?.manualCompleteTotal || 0}`}</Typography>
              <Typography sx={{ paddingLeft: '10px' }}>{'件'}</Typography>
            </div>
          </div>
        </div>
      </div>
      {/* END ROW 2 */}


      {/* ROW 3 */}
      <div className="my-4" style={{}}>
        <BasicTable
          headers={[
            { name: '顧客名', align: 'left', isNowrap: true, type: 'TEXT' },
            { name: '案件数', align: 'right', isNowrap: true, type: 'TEXT' },
            { name: '送信完了数', align: 'right', isNowrap: true, type: 'TEXT' },
            { name: '送信成功数', align: 'right', isNowrap: true, type: 'TEXT' },
            { name: '直接担当', align: 'center', isNowrap: true, type: 'TEXT' },
            { name: 'フォロー担当', align: 'center', isNowrap: true, type: 'TEXT' },
          ]}
          content={tableData}
          setTableData={setTableData}
        />
      </div>
      {/* END ROW 3 */}
    </div >

  </div >
}