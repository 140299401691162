import { Box, Button, Typography } from "@mui/material";
import { hover } from "@testing-library/user-event/dist/hover";
import React from "react";

type Props = {
  displayName: string
  fullWidth?: boolean
  width?: number | string
  backColor?: string
  hoverBackColor?: string
  fontColor?: string
  hoverFontColor?: string
  startIcon?: any
  endIcon?: any
  onClick?: () => void
  disableElevation?: boolean
  disabled?: boolean
}

export default function BasicButton(props: Props) {
  return (
    <Box sx={{
      color: props.fontColor ?? undefined,
      '#btn:hover': {
        backgroundColor: props.hoverBackColor ?? undefined,
        color: props.hoverFontColor ?? undefined,
      },
      '#btn-text:hover': {
        // backgroundColor: props.hoverBackColor ?? undefined,
        // color: props.hoverFontColor ?? undefined,
      }
    }}>
      <Button
        id='btn'
        variant="contained"
        size="small"
        disabled={props.disabled ?? false}
        disableElevation={props.disableElevation ?? false}
        fullWidth={props.fullWidth ?? undefined}
        sx={{
          width: props.width ?? undefined,
          backgroundColor: props.backColor ?? undefined,
          color: props.fontColor ?? undefined,
          '&:hover': {
            backgroundColor: props.hoverBackColor ?? undefined,
            color: props.hoverFontColor ?? undefined,
          }
        }}
        onClick={props.onClick ?? undefined}
      >
        {props.startIcon != undefined || null ?
          <Typography
            id='btn-text'
            sx={{
              marginRight: '3px',
            }}>
            {props.startIcon}
          </Typography>
          :
          ''
        }
        <Typography sx={{ paddingTop: '3px' }}>{props.displayName}</Typography>
        {props.endIcon != undefined || null ?
          <Typography
            id='btn-text'
            sx={{
              marginRight: '3px',
            }}>
            {props.endIcon}
          </Typography>
          :
          ''
        }
      </Button>
    </Box>

  );
};