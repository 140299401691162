import React, { useState } from "react";
import '../../../styles/login/LoginPage.css';
import BasicButton from "../../../components/button/basicButton";
import { useNavigate } from "react-router-dom";
import BasicTextField from "../../../components/text/basicTextField";
import { useDispatch } from "react-redux";
import { login } from "../../../global/store/auth";
import IbLoginRequest from "../../../@types/IbLogin";
import HttpRequest from "../../../global/http/HttpRequest";
import { ResultResponse } from "../../../@types/common";
import { showMessage } from "../../../global/store/snackbar";

export default function LoginPage() {
  const nav = useNavigate();
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  // Snackbarの開閉用
  const [open, setOpen] = useState<boolean>(false);

  const dispatch = useDispatch();
  const handleLogin = async (): Promise<void> => {
    if (!email || !password) {
      setOpen(true);
      return
    }
    const http = new HttpRequest<IbLoginRequest, ResultResponse>()
    http.request = {
      username: email,
      password: password,
    }

    const success = await http.post('/auth/ib/login')
    if (success) {
      window.location.href = '/top'
    } else {
      dispatch(showMessage({
        message: 'ログインに失敗しました',
        severity: 'error',
        duration: 3000,
      }))
    }
  };

  // Snackbarを閉じるための処理
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className="main-content text-center">

      <h3 className="title text-center">営業代行メール版 Ver0.0.2</h3>

      <div className="text text-center my-4">
        <BasicTextField
          fullwidth
          label="e-mail"
          setValue={setEmail}
        />
      </div>

      <div className="text text-center my-3">
        <BasicTextField
          fullwidth
          label="password"
          type="password"
          setValue={setPassword}
        />
      </div>

      <div className="text-center">
        <BasicButton
          displayName={'ログイン'}
          onClick={handleLogin}
        />
      </div>
    </div>
  );
};