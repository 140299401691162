import SecureHttp, {HttpBase} from "./SecureHttp";

export default class DataRequestSender extends HttpBase {
  readonly formData: FormData;
  public constructor() {
    super()
    this.formData = new FormData();
  }

  public addStr(key: string, data: string) {
    this.formData.append(key, data)
  }

  public addList(key: string, rows: { name: string, name_hiragana: string, email: string, tell: string }[]) {
    const rowsJson = JSON.stringify(rows);
    this.formData.append(key, rowsJson);
  }

  public addoperationField(key: string, rows: { selectedOpe: string, price: string}[]) {
    const rowsJson = JSON.stringify(rows);
    this.formData.append(key, rowsJson);
  }

  public async upload(endPoint: string) {
    const url = this.getFullEndPoint(endPoint)
    const response = await fetch(url, {
        method: 'POST',
        body: this.formData,
        credentials: 'include'
      }
    )
    return response.json()
  }
}