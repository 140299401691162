import {Dropdown} from "../../../components/dropdown/basicDropdown";
import HttpData from "../../../global/http/HttpData";

export type ClientLocation = {
  clientId?: string
}

export class ClientInfo {
  clientId?: string = ''
  clientName: string = ''
  prefecture: Dropdown = {label: '', value: ''}
  postalCode: Dropdown = {label: '', value: ''}
  city: string = ''
  address: string = ''
  tel: string = ''
  fax: string = ''
  hp: string = ''
  memo: string = ''
  firstSendNum: number = 0
  repConsultant: Dropdown = {label: '', value: ''}
  followConsultant: Dropdown = {label: '', value: ''}
  hearingDate: string = ''
  clientUsers: ClientUserInfo[] = [new ClientUserInfo()]
}

export interface ClientRequest extends HttpData {
  clientId?: string
  clientName: string
  prefecture: Dropdown
  postalCode: Dropdown
  city: string
  address: string
  tel: string
  fax: string
  hp: string
  memo: string
  firstSendNum: number
  repConsultant: Dropdown
  followConsultant: Dropdown
  hearingDate: string
  clientUsers: ClientUserInfo[]
}

export class ClientUserInfo {
  clientUserId?: string = ''
  familyName: string = ''
  givenName: string = ''
  familyNameHiragana: string = ''
  givenNameHiragana: string = ''
  email: string = ''
  tel: string = ''
}

export interface ClientResponse extends ClientRequest {}

export type ClientDetailRequest = {
  client_id: string
}

export interface MailInfoRequest extends HttpData {
  project_id: string
}

export interface MailInfoResponse extends HttpData {
  subject: string
  body: string
}

export type MailInput = {
  subject: string
  body: string
  toUsers: Dropdown[]
  ccUsers: Dropdown[]
}