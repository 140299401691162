import { OutsourceHeader } from "../../../components/header/outsourceHeader";
import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import BasicButton from "../../../components/button/basicButton";
import { useLocation, useNavigate } from "react-router-dom";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import BasicTable, { Row } from "../../../components/table/basicTable";
import BasicTextField from "../../../components/text/basicTextField";
import { ButtonGroup } from "@mui/material";
import HttpRequest from "../../../global/http/HttpRequest";
import {SubjectDetailRequest, SubjectDetailResponse} from "../../../@types/outsource/subjectManagementType";

class BasicInfo {
  subjectName: string = ''
  startDate: string = ''
  endDate: string = ''
  status: string = ''
  progress: string = ''
  assignResources: string = ''
  // memo: string = ''
}

// 案件管理 -> 詳細
export default function SubjectDetailPage() {
  const nav = useNavigate()
  const location = useLocation()
  const subjectId = location.state?.subjectId
  const subjectName = location.state?.subjectName
  const orderId = location.state?.orderId

  const [basic, setBasic] = useState<BasicInfo>(new BasicInfo)
  const [isEdit, setIsEdit] = useState<boolean>(false)

  const gotoPage = (url: string, param: Record<string, any>) => {
    nav(url, { state: param });
  };

  useEffect(() => {
    fetchData()
  }, [])

  const fetchData = async () => {
    const http = new HttpRequest<SubjectDetailRequest, SubjectDetailResponse>()
    http.request = {
      subject_id: subjectId,
      order_id: orderId,
    }
    if (await http.get('subject/detail')) {
      const response = http.response!
      setBasic({
        subjectName: response.subjectName,
        startDate: response.startDate,
        endDate: response.endDate,
        status: response.status,
        progress: response.progress,
        assignResources: response.assigned,
      })
    }
  }

  const memoSave = () => {
    setIsEdit(false)
  }

  const memoCancel = () => {
    setIsEdit(false)
    fetchData()
  }

  const tableRender = (data: BasicInfo): Row[] => {
    return (
      [
        {
          content: [
            { name: '期間', align: 'center', isNowrap: true, type: 'TEXT', width: 100 },
            { name: ':', align: 'left', isNowrap: true, type: 'TEXT', width: 10 },
            { name: `${data.startDate} ～ ${data.endDate}`, align: 'left', isNowrap: true, type: 'TEXT' },
          ]
        },
        {
          content: [
            { name: 'ステータス', align: 'center', isNowrap: true, type: 'TEXT', width: 100 },
            { name: ':', align: 'left', isNowrap: true, type: 'TEXT', width: 10 },
            { name: data.status, align: 'left', isNowrap: true, type: 'TEXT' },
          ]
        },
        {
          content: [
            { name: '進捗', align: 'center', isNowrap: true, type: 'TEXT', width: 100 },
            { name: ':', align: 'left', isNowrap: true, type: 'TEXT', width: 10 },
            { name: data.progress, align: 'left', isNowrap: true, type: 'TEXT' },
          ]
        },
        {
          content: [
            { name: '割り当て', align: 'center', isNowrap: true, type: 'TEXT', width: 100 },
            { name: ':', align: 'left', isNowrap: true, type: 'TEXT', width: 10 },
            { name: data.assignResources, align: 'left', isNowrap: true, type: 'TEXT' },
          ]
        },
        // {
        //   id: 'memo-parent',
        //   content: [
        //     { name: '施設メモ', align: 'center', isNowrap: true, type: 'TEXT', width: 100 },
        //     { name: ':', align: 'left', isNowrap: true, type: 'TEXT', width: 10 },
        //     {
        //       id: 'memo-content',
        //       name: isEdit ?
        //         <div className="d-flex">
        //           <BasicTextField fullwidth label='' multiline rows={5} value={data.memo} setValue={(e: string) => setBasic({ ...basic, memo: e })} />
        //           <ButtonGroup sx={{ whiteSpace: 'nowrap' }}>
        //             <div style={{ marginLeft: '10px', display: 'flex', alignItems: 'center' }}>
        //               <BasicButton fullWidth displayName={'保存'}
        //                 backColor={'white'} fontColor={'black'} hoverBackColor={'green'} hoverFontColor={'white'}
        //                 onClick={() => memoSave()}
        //               />
        //             </div>
        //             <div style={{ display: 'flex', alignItems: 'center' }}>
        //               <BasicButton fullWidth displayName={'キャンセル'}
        //                 backColor={'white'} fontColor={'black'} hoverBackColor={'red'} hoverFontColor={'white'}
        //                 onClick={() => memoCancel()}
        //               />
        //             </div>
        //           </ButtonGroup>
        //         </div>
        //         :
        //         <div style={{ maxWidth: '700px', overflowWrap: 'break-word' }}>{data.memo}</div>,
        //       align: 'left', type: 'TEXT'
        //     },
        //   ]
        // },
      ]
    )
  }

  return (
    <div>
      <div>
        <OutsourceHeader />
      </div>

      {/* パンくずリスト */}
      <div className="d-flex mx-5 align-items-end" style={{ margin: '50px 50px 0px 50px' }}>
        <div className="d-flex">
          <div onClick={() => nav(-1)}>
            <Typography
              fontWeight={'bold'}
              fontSize={'large'}
              style={{ color: 'gray', cursor: 'pointer' }}
            >
              {'案件管理'}
            </Typography>
          </div>

          <div style={{ width: '20px' }}></div>
          <ArrowForwardIcon />
          <div style={{ width: '20px' }}></div>

          <div>
            <Typography
              fontWeight={'bold'}
              fontSize={'large'}>
              {'詳細'}
            </Typography>
          </div>

        </div>
      </div>

      <div>
        <div className="container mx-5">
          <div className="my-4">
            <Typography sx={{ fontSize: 'large', fontWeight: 'bold' }}>{subjectName}</Typography>
          </div>

          <div className="" style={{ width: '' }}>
            <BasicTable
              headers={[]}
              content={tableRender(basic)}
            />
          </div>

          <div className="row my-5">
            <div className="col-3">
              <BasicButton fullWidth displayName={'利用者割り当て'}
                backColor={'white'} fontColor={'black'} hoverBackColor={'green'} hoverFontColor={'white'}
                onClick={() => gotoPage('/outsource/subject_management/assignment/operator', {
                  subjectId: subjectId,
                  subjectName: subjectName,
                  orderId: orderId,
                })}
              />
            </div>
            <div className="col-3">
              <BasicButton fullWidth displayName={'拠点割り当て'}
                backColor={'white'} fontColor={'black'} hoverBackColor={'green'} hoverFontColor={'white'}
                onClick={() => gotoPage('/outsource/subject_management/assignment/location', {
                  subjectId: subjectId,
                  subjectName: subjectName,
                  orderId: orderId,
                })}
              />
            </div>
            {/*<div className="col-3">*/}
            {/*  <BasicButton fullWidth displayName={'メモ編集'}*/}
            {/*    backColor={'white'} fontColor={'black'} hoverBackColor={'green'} hoverFontColor={'white'}*/}
            {/*    onClick={() => setIsEdit(true)}*/}
            {/*  />*/}
            {/*</div>*/}
          </div>
        </div>
      </div>
    </div>
  )
}