import {HttpBase} from "./SecureHttp";
import {hideLoader, showLoader} from "../store/loaderSlice";

export default class FileUploader extends HttpBase {
  readonly formData: FormData;
  public constructor() {
    super()
    this.formData = new FormData();
  }

  public addFile(key: string, file: File) {
    this.formData.append(key, file)
  }

  public addParameter(key: string, value: string) {
    this.formData.append(key, value)
  }

  public async upload(endPoint: string) {
    this.showLoader()
    const url = this.getFullEndPoint(endPoint)
    const response = await fetch(url, {
        method: 'POST',
        body: this.formData,
        credentials: 'include'
      }
    )
    this.dispatch(hideLoader())
    return await response.json()
  }
}