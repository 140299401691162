import {useStore} from "react-redux";
import {RootState} from "../store/store";
import {ClientRole, IbRole, OutsourceRole} from "../../@types/roles";
import {useState} from "react";
import {User} from "../store/auth";

export default function useRole() {
  const store = useStore<RootState>()
  const [ownRoles] = useState<string[]>(store.getState().auth.user?.roles || [])

  const hasRole = (roles: (IbRole | OutsourceRole | ClientRole)[]) => {
    return roles.some((r) => ownRoles.includes(r))
  }

  return hasRole
}
