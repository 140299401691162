import { Typography } from "@mui/material";
import Header from "../../../components/header/header";
import BasicTable, { Row, Title } from "../../../components/table/basicTable";
import {useEffect, useState} from "react";
import HttpRequest from "../../../global/http/HttpRequest";
import {OrderListRequest, OrderListResponse} from "../../../@types/ib/clientManagement/OrderType";
import {useLocation, useNavigate} from "react-router-dom";

export default function OrderTargetListPage() {
  const nav = useNavigate()
  const location = useLocation()
  const projectId = location.state?.projectId

  const headers: Title[] = [
    { name: '外注先', align: 'center' },
    { name: '件数', align: 'left' },
    { name: '単価', align: 'left' },
    { name: '送信期間', align: 'center' },
    { name: '進捗(%)', align: 'center' },
    { name:'', align: 'center'},
  ];
  // table data
  const [tableData, setTableData] = useState<Row[]>([])

  function goToReport(orderId: string) {
    nav('/client_management/order/report', {state: {orderId: orderId}})
  }

  useEffect(() => {
    const f = async () => {
      const http = new HttpRequest<OrderListRequest, OrderListResponse>()
      http.request = {
        subject_id: projectId
      }

      if (await http.get('/client/order/list')) {
        const data: Row[] = http.response!.orders.map(order => (
          {
            content: [
              {name: order.outsourceName, align: 'center', width: 30, type: 'TEXT', value: ''},
              {name: order.qty, align: 'left', width: 30, type: 'TEXT', value: ''},
              {name: order.unitPrice, align: 'left', width: 30, type: 'TEXT', value: ''},
              {name: `${order.startDate}～${order.endDate}`, align: 'center', width: 30, type: 'TEXT', value: ''},
              {name: order.progress, align: 'center', width: 30, type: 'TEXT', value: ''},
              {name: '発注書', align: 'center', width: 30, type: 'BUTTON', buttonFunctions: [
                  () => goToReport(order.orderId)
                ]
              },
            ]
          }
        ))
        setTableData(data)
      }
    }
    f()
  }, []);

  return (
    <div>
      <div>
        <Header />
      </div>

      {/* パンくずリスト */}
      <div className="d-flex justify-content-between mx-5 align-items-end">
        <div style={{ margin: '50px 0px 0px 0px' }}>
          <Typography fontWeight={'bold'} fontSize={'large'}>発注先一覧</Typography>
        </div>
      </div>


      {/* 発注先一覧テーブル */}
      <div className='mx-5 my-3'>
        <BasicTable
          headerColor="midnightblue"
          headers={headers}
          content={tableData}
        />
      </div>
    </div>
  );
}