import { Typography } from "@mui/material";
import React, {useEffect, useState} from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import BasicTable, { Row, Title } from "../../components/table/basicTable";
import SearchTextField from "../../components/text/searchTextField";
import HttpRequest from "../../global/http/HttpRequest";
import { showMessage } from "../../global/store/snackbar";
import ClientHeader from "../../components/header/clientHeader";
import {SearchRequest, SearchResponse} from "../../@types/client/subjectType";


/**
 * 顧客向け画面 案件一覧
 */
export default function ClientListPage() {
  const nav = useNavigate();
  const dispatch = useDispatch();
  const [headers, setHeaders] = useState<Title[]>([
    { name: '案件名', align: 'left' },
    { name: '進捗', align: 'left' },
    { name: 'ステータス', align: 'left' },
    { name: '期間', align: 'left' },
    { name: '', align: 'left' },
  ]);
  const [contents, setContents] = useState<Row[]>([]);
  const [searchValue, setSearchValue] = useState<string>('');

  // 送信者情報ページ遷移
  const gotoPageAddData = (url: string, subjectId: string) => {
    nav(url, { state: { subjectId: subjectId } });
  };

  // リスト詳細ページ遷移
  const gotoListDetailPageAddData = (url: string, subjectId: string, subjectName: string, status: string) => {
    nav(url, { state: { subjectId: subjectId, subjectName: subjectName, status: status } });
  };

  useEffect(() => {
    search()
  }, [])

  const search = async () => {
    const http = new HttpRequest<SearchRequest, SearchResponse>()
    http.request = {
      searchValue: searchValue
    }

    if (await http.post('/client/subject/list')) {
      const contents: Row[] = http.response!.subjects.map((item, index) => {
        return {
          content: [
            { name: item.subjectName, align: 'left', type: "TEXT" },
            { name: item.progress, align: 'left', width: 150, type: "TEXT" },
            { name: item.status, align: 'left', type: "TEXT", width: 150 },
            { name: `${item.startDate} - ${item.endDate}`, align: 'left', type: "TEXT" },
            {
              name: ['送信情報', 'リスト詳細'], align: 'left', type: "BUTTONS",
              buttonFunctions: [
                () => gotoPageAddData("/client/list/send_info", item.subjectId),
                () => gotoListDetailPageAddData("/client/list/detail", item.subjectId, item.subjectName, item.status)
              ]
            },
          ]
        }
      });
      setContents(contents);
    } else {
      dispatch(showMessage({
        message: '予期せぬエラーが発生しました。',
        severity: 'error',
        duration: 3000,
      }));
    };
  };

  return (
    <div>
      <div>
        <ClientHeader />
      </div>

      <div style={{ margin: '50px 50px 0px 50px' }}>
        <Typography fontWeight={'bold'} fontSize={'large'}>案件一覧</Typography>
      </div>

      {/* 検索フィールド */}
      <div className="d-flex justify-content-center" style={{ alignItems: 'center', margin: '20px 0px 0px 0px' }}>
        <SearchTextField fullwidth label="案件名" value={searchValue} setValue={setSearchValue} onClick={search} />
      </div>

      {/* 案件一覧 */}
      <div className='mx-5 my-3'>
        <BasicTable
          headers={headers}
          content={contents}
          isPaging={true}
        />
      </div>
    </div>
  );
};