import { Typography } from "@mui/material";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Header from "../../../../components/header/header";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import BasicButton from "../../../../components/button/basicButton";
import { showMessage } from "../../../../global/store/snackbar";
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import BasicTable, { getValues, Row, Title } from "../../../../components/table/basicTable"
import { StandardManagementItem } from "../../../../@types/ib/systemManagement/StandardManagementType";
import HttpRequest from "../../../../global/http/HttpRequest";
import { ResultResponse } from "../../../../@types/common";
import StandardManagementRequest, { SearchData, SearchResponse, DeleteResponse } from "../../../../@types/standardManagement";
import SearchTextField from "../../../../components/text/searchTextField";
import BasicDialog from '../../../../components/dialog/basicDialog';

export default function IndustryCategoryManagementPage() {
  const nav = useNavigate();
  const dispatch = useDispatch();

  const [rows, setRows] = useState<Row[]>([]);
  const [rowData, setRowData] = useState<Row[]>([])

  // search text
  const [searchValue, setSearchValue] = useState<string>('');
  const [alertMessage, setAlertMessage] = useState<boolean>(false);
  const [deletingId, setDeletingId] = useState<string>('');
  const [deletingIndex, setDeletingIndex] = useState<number>();

  const gotoPage = (url: string) => {
    nav(url);
  };

  const rowDelete = (rowIndex: number) => {
    let id = ""
    setRows(prevRows => {
      id = prevRows[rowIndex].content[0].value as string;
      return prevRows;
    });
    setDeletingId(id)
    setDeletingIndex(rowIndex)
    setAlertMessage(true)
  }

  const performDelete = async () => {
    if (deletingId) {
      const deleteItem: StandardManagementItem[] = []
      deleteItem.push({ id: deletingId, value: "" })
      const http = new HttpRequest<StandardManagementRequest, DeleteResponse>()
      http.request = {
        managementItems: deleteItem,
      }
      if (await http.post('/system/del/IndustryCategory') && http.response?.errorMessage) {
        dispatch(showMessage({
          message: http.response!.errorMessage,
          severity: 'error',
          duration: 3000,
        }))
        return
      }
    }
    setRows(prevRows => {
      const updatedRows = prevRows
        .filter((_, i) => i !== deletingIndex)
        .map((row, newIndex) => ({
          ...row,
          content: [
            { ...row.content[0], name: `${newIndex + 1}` }, // No を再設定
            ...row.content.slice(1, -1), // 最初と最後の要素以外をそのまま保持
            {
              ...row.content[row.content.length - 1], name: <BasicButton startIcon={<DeleteIcon elevation={5} />}
                displayName='削除' onClick={() => rowDelete(newIndex)} />
            }, // 削除ボタンを更新
          ]
        }));
      return updatedRows;
    });
  }

  const headers: Title[] = [
    { name: 'No', align: 'center', width: 20 },
    { name: '大業種名', align: 'left' },
    { name: '', align: 'center' },
  ];

  const addHuman = () => {
    const temp = [...rows]
    const lastRow = temp[temp.length - 1];
    const newRowIndex = temp.length;
    const tempcontent: Row[] = [
      {
        content: [
          { id: 'id', name: `${newRowIndex + 1}`, align: 'center', type: 'TEXT', width: 20, value: '' },
          { id: 'name', name: '', align: 'left', value: '' },
          {
            id: 'btn',
            name:
              <BasicButton
                displayName='削除'
                startIcon={<DeleteIcon elevation={5} />}
                onClick={() => rowDelete(newRowIndex)} />,
            align: 'center', type: 'TEXT', value: '', width: 50
          }
        ],
      }
    ];
    temp.push(tempcontent[0]);
    setRows(temp);
  }

  const search = async () => {
    if (searchValue) {
      const search: SearchData = {
        searchValue: searchValue,
      };
      const http = new HttpRequest<SearchData, SearchResponse>()
      http.request = search

      if (await http.post('/system/get/IndustryCategory')) {
        if (http.response?.result == "FAILURE") {
          dispatch(showMessage({
            message: http.response?.message ?? '',
            severity: 'error',
            duration: 3000,
          }))
          return
        }

        setRows(http.response!.standardManagementItems.map((item: StandardManagementItem, index: number) => ({
          content: [
            { id: 'id', name: (index + 1).toString(), align: 'center', type: 'TEXT', width: 20, value: item.id },
            { id: 'name', name: '', align: 'left', value: item.value },
            {
              id: 'btn',
              name:
                <BasicButton
                  displayName='削除'
                  startIcon={<DeleteIcon elevation={5} />}

                  onClick={() => rowDelete(index)} />,
              align: 'center', type: 'TEXT', value: '', width: 50
            },
          ],
        })));

      } else {
        dispatch(showMessage({
          message: '検索に失敗しました',
          severity: 'error',
          duration: 3000,
        }))
        return
      }
    }
  }

  const registration = async () => {
    const nonEmptyIdList: StandardManagementItem[] = []
    const emptyIdList: StandardManagementItem[] = []
    getValues(rows).map(value => {
      if (value.id) {
        nonEmptyIdList.push({
          id: value.id,
          value: value.name,
        })
      } else {
        emptyIdList.push({
          id: value.id,
          value: value.name,
        })
      }
    })
    const http = new HttpRequest<StandardManagementRequest, ResultResponse>()
    if (emptyIdList.length > 0) {
      http.request = {
        managementItems: emptyIdList,
      }
      const createResult = await http.post('/system/create/IndustryCategory')
      console.log(createResult)
      if (!createResult) {
        dispatch(showMessage({
          message: '登録に失敗しました',
          severity: 'error',
          duration: 3000,
        }))
        return
      }
    }

    if (nonEmptyIdList.length > 0) {
      http.request = {
        managementItems: nonEmptyIdList,
      }
      const updateResult = await http.post('/system/update/IndustryCategory')
      if (!updateResult) {
        dispatch(showMessage({
          message: '保存に失敗しました',
          severity: 'error',
          duration: 3000,
        }))
        return
      } else {
        dispatch(showMessage({
          message: '保存が完了しました',
          severity: 'success',
          duration: 3000,
        }))
      }
    }
    // setRows([]);
  }

  return (
    <div>
      <div>
        <Header />
      </div>

      {/* パンくずリスト */}
      <div className="d-flex" style={{ margin: '50px 50px 0px 50px' }}>
        <div onClick={() => gotoPage('/system_management')}>
          <Typography fontWeight={'bold'} fontSize={'large'} style={{ color: 'gray', cursor: 'pointer' }}>
            {'システム管理'}
          </Typography>
        </div>

        <div style={{ width: '20px' }}></div>
        <ArrowForwardIcon />
        <div style={{ width: '20px' }}></div>

        <div onClick={() => gotoPage('/system_management/StandardManagementPage')}>
          <Typography fontWeight={'bold'} fontSize={'large'} style={{ color: 'gray', cursor: 'pointer' }}>
            {'規定値管理'}
          </Typography>
        </div>

        <div style={{ width: '20px' }}></div>
        <ArrowForwardIcon />
        <div style={{ width: '20px' }}></div>

        <Typography fontWeight={'bold'} fontSize={'large'}>{'大業種'}</Typography>
      </div>

      <div className="mx-5 container">
        {/* 検索フィールド */}
        <div className="d-flex justify-content-center" style={{ alignItems: 'center', margin: '50px 0px 0px 0px' }}>
          <SearchTextField label="大業種名" value={searchValue} setValue={setSearchValue} onClick={search} />
        </div>

        {/* 外注先テーブル */}
        <div className='my-3'>
          <BasicTable
            headerColor="midnightblue"
            headers={headers}
            content={rows}
            setTableData={setRows}
          />
          <div>
            <BasicButton
              fullWidth displayName="新規追加"
              backColor="white" hoverBackColor="green" fontColor="black" hoverFontColor="white"
              startIcon={<AddIcon />}
              onClick={addHuman}
            />
          </div>
          <div className="my-3" style={{ textAlign: 'end' }}>
            <BasicButton
              displayName="保存" width={'20%'}
              fontColor="white"
              onClick={registration}
            />
          </div>
        </div>
      </div>
      <BasicDialog
        open={alertMessage}
        close={() => {
          setAlertMessage(false)
        }}
        title={'削除確認'}
        content={<div style={{ marginRight: '100px', whiteSpace: 'nowrap' }}>{'対象を削除してもよろしいですか?'}</div>}
        positiveButtonName="OK"
        negativeButtonName="キャンセル"
        positiveAction={() => {
          setAlertMessage(false)
          performDelete()
        }}
      />
    </div>
  );

}