import { OutsourceHeader } from "../../../components/header/outsourceHeader";
import Typography from "@mui/material/Typography";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import BasicTable, { Row } from "../../../components/table/basicTable";
import BasicTextField from "../../../components/text/basicTextField";
import BasicButton from "../../../components/button/basicButton";
import HttpRequest from "../../../global/http/HttpRequest";
import HttpData from "../../../global/http/HttpData";
import {
  Operator,
  OperatorListResponse,
  UserDetailRequest, UserDetail, AssignmentRequest, OperationType
} from "../../../@types/outsource/subjectManagementType";
import {NormalResponse} from "../../../@types/common";
import {showMessage} from "../../../global/store/snackbar";
import {useDispatch} from "react-redux";


// 案件管理 -> 詳細 -> 利用者割り当て
export function OperatorAssignmentPage() {
  const dispatch = useDispatch()
  const nav = useNavigate()
  const location = useLocation()
  const subjectId = location.state?.subjectId
  const subjectName = location.state?.subjectName
  const orderId = location.state?.orderId

  const [users, setUsers] = useState<Operator[]>([])
  const [assigned, setAssigned] = useState<string>('')
  const [userTableData, setUserTableData] = useState<Row[]>([])

  const [userDetail, setUserDetail] = useState<UserDetail>()
  const [userDetailTableData, setUserDetailTableData] = useState<Row[]>([])

  const [assign, setAssign] = useState<number>(0)
  const [notAssign, setNotAssign] = useState<number>(0)

  const gotoPage = (url: string, param: Record<string, any>) => {
    nav(url, { state: param })
  }

  useEffect(() => {
    fetchData()
  }, [])

  useEffect(() => {
    const temp: Row[] = users.map(m => {
      return {
        content: [
          {
            id: m.userId,
            name: <div style={{ cursor: 'pointer' }} onClick={() => fetchBaseData(m.userId, m.name)}>{m.name}</div>,
            align: 'left', isNowrap: true, type: 'TEXT'
          }
        ]
      }
    })
    setUserTableData(temp)
  }, [users])

  useEffect(() => {
    const temp: Row[] = [
      {
        content: [
          { name: '今月の完了数', align: 'left', isNowrap: true, type: 'TEXT', width: 100 },
          { name: '：', align: 'left', isNowrap: true, type: 'TEXT', width: 10 },
          { name: userDetail?.completedCount ?? '', align: 'left', isNowrap: true, type: 'TEXT' },
        ]
      },
      {
        content: [
          { name: '今月の未完了数', align: 'left', isNowrap: true, type: 'TEXT', width: 100 },
          { name: '：', align: 'left', isNowrap: true, type: 'TEXT', width: 10 },
          { name: userDetail?.uncompletedCount ?? '', align: 'left', isNowrap: true, type: 'TEXT' },
        ]
      },
    ]
    setUserDetailTableData(temp)
  }, [userDetail])

  const fetchData = async () => {
    const http = new HttpRequest<HttpData, OperatorListResponse>()
    if (await http.get(`/subject/users?order_id=${orderId}`)) {
      setUsers(http.response!.operators)
      setAssigned(http.response!.userAssigned)
    }
  }

  const fetchBaseData = async(userId: string, userName: string) => {
    const http = new HttpRequest<UserDetailRequest, UserDetail>()
    http.request = {
      user_id: userId
    }
    if (await http.get('/subject/user/detail')) {
      const res = http.response!
      setUserDetail({
        userId: res.userId,
        name: res.name,
        completedCount: res.completedCount,
        uncompletedCount: res.uncompletedCount,
      })
    }
  }

  const assignUser = async () => {
    if (!userDetail) {
      return
    }
    const http = new HttpRequest<AssignmentRequest, NormalResponse>()
    http.request = {
      subjectId: subjectId,
      operationId: userDetail.userId,
      operationType: OperationType.USER,
      qty: assign,
      orderId: orderId,
    }
    if (await http.post('/subject/assign')) {
      dispatch(showMessage({
        message: http.response!.message,
        severity: 'success',
        duration: 3000,
      }))
      await fetchBaseData(userDetail.userId, userDetail.name)
    }
  }

  const unassignUser = async () => {
    if (!userDetail) {
      return
    }
    const http = new HttpRequest<AssignmentRequest, NormalResponse>()
    http.request = {
      subjectId: subjectId,
      operationId: userDetail.userId,
      operationType: OperationType.USER,
      qty: notAssign,
      orderId: orderId,
    }
    if (await http.post('/subject/unassign')) {
      dispatch(showMessage({
        message: http.response!.message,
        severity: 'success',
        duration: 3000,
      }))
      await fetchBaseData(userDetail.userId, userDetail.name)
    }
  }

  return (
    <div>
      <div>
        <OutsourceHeader />
      </div>

      {/* パンくずリスト */}
      <div className="d-flex mx-5 align-items-end" style={{ margin: '50px 50px 0px 50px' }}>
        <div className="d-flex">
          <div onClick={() => nav('/outsource/subject_management')}>
            <Typography
              fontWeight={'bold'}
              fontSize={'large'}
              style={{ color: 'gray', cursor: 'pointer' }}
            >
              {'案件管理'}
            </Typography>
          </div>

          <div style={{ width: '20px' }}></div>
          <ArrowForwardIcon />
          <div style={{ width: '20px' }}></div>

          <div onClick={() => nav(-1)}>
            <Typography
              fontWeight={'bold'}
              fontSize={'large'}
              style={{ color: 'gray', cursor: 'pointer' }}
            >
              {'詳細'}
            </Typography>
          </div>

          <div style={{ width: '20px' }}></div>
          <ArrowForwardIcon />
          <div style={{ width: '20px' }}></div>

          <Typography
            fontWeight={'bold'}
            fontSize={'large'}>
            {'利用者割り当て'}
          </Typography>
        </div>
      </div>

      <div className="container mx-5">
        <div className="my-4 d-flex">
          <Typography sx={{ fontSize: 'large', fontWeight: 'bold' }}>{subjectName}</Typography>
          <Typography sx={{ fontSize: 'large', fontWeight: 'bold', marginLeft: '5%' }}>{`割り当て済み：${assigned} 件`}</Typography>
        </div>

        <div className="d-flex">
          <div style={{ width: '30%' }}>
            {/* 拠点リスト */}
            <BasicTable
              height={'65vh'}
              headers={[{ name: '利用者名', align: 'left', isNowrap: true }]}
              content={userTableData}
            />
          </div>
          <div className="" style={{ width: '60%', marginLeft: '5%', height: '65vh' }}>
            {/* 拠点詳細 */}
            <BasicTable
              headerColor={'white'}
              headers={[{ name: <Typography sx={{ color: 'black', fontWeight: 'bold' }}>{userDetail?.name ?? '利用者名'}</Typography>, align: 'left', isNowrap: true, type: 'TEXT' }]}
              content={userDetailTableData}
            />
            <div className="">
              <div className="d-flex my-4">
                <div>
                  <BasicTextField label={'この利用者に割り当てる'} value={assign.toString()} setValue={setAssign} type={'number'} />
                </div>
                <div style={{ display: 'flex', alignItems: 'center', marginLeft: '5%' }}>
                  <BasicButton fullWidth displayName={'保存'}
                    backColor={'white'} fontColor={'black'} hoverBackColor={'green'} hoverFontColor={'white'}
                    onClick={assignUser}
                  />
                </div>
              </div>
              <div className="d-flex my-4">
                <div>
                  <BasicTextField
                    label={'この利用者から割り当てを外す'}
                    value={notAssign.toString()}
                    setValue={setNotAssign} type={'number'} />
                </div>
                <div style={{ display: 'flex', alignItems: 'center', marginLeft: '5%' }}>
                  <BasicButton fullWidth displayName={'保存'}
                    backColor={'white'} fontColor={'black'} hoverBackColor={'green'} hoverFontColor={'white'}
                    onClick={unassignUser}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}