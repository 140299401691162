import React, { useState } from "react";
import { List, ListItemText, ListItemButton, Collapse } from "@mui/material";
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

type Props = {
  btnName: string;
  contents: { url: string; id: string; name: string; }[];
  onItemClick: (url: string, id: string) => void;
  minWidth?: number;
};

export default function NavigationAccordion(props: Props) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <List
      sx={{
        minWidth: props.minWidth ?? '',
        backgroundColor: 'white',
        color: 'black',
      }}>
      <ListItemButton onClick={() => setIsOpen(!isOpen)}>
        <ListItemText sx={{ textAlign: 'left' }} primary={props.btnName} />
        {isOpen ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={isOpen} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {props.contents.map((item, index) => (
            <div key={`list-${index}`}>
              <ListItemButton sx={{ pl: 4 }} onClick={() => props.onItemClick(item.url, item.id)}>
                <ListItemText sx={{ textAlign: 'left' }}>{item.name}</ListItemText>
              </ListItemButton>
            </div>
          ))}
        </List>
      </Collapse>
    </List>
  );
};