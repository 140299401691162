import { Box, IconButton, Typography, styled } from "@mui/material";
import React, { useEffect, useState } from "react";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Accept, useDropzone } from "react-dropzone";
import BasicButton from "../button/basicButton";
import BackspaceIcon from '@mui/icons-material/Backspace';
import FileUploader from "../../global/http/FileUploader";
import { useDispatch } from "react-redux";
import { closeMessage, showMessage } from "../../global/store/snackbar";
import BasicTextField from "../text/basicTextField";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import  '../../styles/loader/loader.css'

type Props = {
  httpUrl: string
  method: 'GET' | 'POST'
  hideAffiliationRow?: boolean
  hideScrapingRow?: boolean
}

export default function FileUploadBox(props: Props) {
  const dispatch = useDispatch();
  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(80%)',
    height: 1,
    overflow: 'hidden',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });

  let { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/*': ['.jpg', '.png', '.gif'],
      'application/pdf': ['.pdf'],
      'text/plain': ['.txt', '.csv'],
    },
    noClick: true,
  });
  const [file, setFile] = useState<JSX.Element[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const uploadExecute = async () => {
    setIsLoading(true);
    if (!acceptedFiles || acceptedFiles.length === 0) {
      dispatch(showMessage({
        message: '対象ファイルが見つかりません。',
        severity: 'warning',
        duration: 3000,
      }));
    } else {
      const uploader = new FileUploader()
      acceptedFiles.forEach(file => {
        uploader.addFile('file', file)
      })

      if (!props.hideAffiliationRow) {
        affiliationRow.map(m => {
          uploader.addParameter('affiliations', m)
        })
      }

      if (!props.hideScrapingRow) {
        scrapingRow.map(m => {
          uploader.addParameter('scrapings', m)
        })
      }

      const response: Record<string, string> = await uploader.upload(props.httpUrl)
      setIsLoading(false);
      if (response.errorMessage) {
        dispatch(showMessage({
          message: response.errorMessage,
          severity: 'error',
          duration: 6000,
        }))
      } else {
        dispatch(showMessage({
          message: response.message,
          severity: 'success',
          duration: 6000,
        }))
      }
      setFile([]);
      setAffiliationRow(['']);
      setScrapingRow(['']);
    }
  };

  useEffect(() => {
    console.log(acceptedFiles);
    let temp: JSX.Element[] = [];
    temp = mapping();
    setFile(temp);
  }, [acceptedFiles]);

  const mapping = () => {
    return acceptedFiles.map((file, index) => {
      return (
        <div className="d-flex" style={{ textAlign: 'center', justifyContent: 'center' }}>
          <Typography key={`fileIndex-${index}`} width={'5vh'} align="right">{`${index + 1}.`}</Typography>
          <div style={{ width: '5vh' }}></div>
          <div style={{ width: '50vh' }}>
            <Typography key={`file-${index}`} align="left" textOverflow={'hidden'}>{`${file.name}`}</Typography>
          </div>
          <div style={{ width: '5vh' }}></div>
          <BackspaceIcon color="error" onClick={() => deleteFile(index)} />
        </div >
      );
    });
  }

  const deleteFile = (index: number) => {
    const temp = [...acceptedFiles];
    temp.splice(index, 1);
    acceptedFiles = temp;
    const temp2 = mapping();
    setFile(temp2);
  };

  // 加盟行情報
  const [affiliationRow, setAffiliationRow] = useState<string[]>(['']);
  // スクレイピング行情報
  const [scrapingRow, setScrapingRow] = useState<string[]>(['']);

  // 加盟情報＋ボタン押下処理
  const affiliationInfoAddHandler = (index: number) => {
    const temp = [...affiliationRow];
    temp.push('');
    setAffiliationRow(temp);
  };
  // スクレイピング＋ボタン押下処理
  const scrapingInfoAddHandler = (index: number) => {
    const temp = [...scrapingRow];
    temp.push('');
    setScrapingRow(temp);
  };

  // 加盟情報-ボタン押下処理
  const affiliationInfoDelHandler = (index: number) => {
    if (affiliationRow.length === 1) return;
    const temp = [...affiliationRow];
    temp.splice(index, 1);
    setAffiliationRow(temp);
  };
  // スクレイピング-ボタン押下処理
  const scrapingInfoDelHandler = (index: number) => {
    if (scrapingRow.length === 1) return;
    const temp = [...scrapingRow];
    temp.splice(index, 1);
    setScrapingRow(temp);
  };

  // 加盟情報テキスト処理
  const affiTextHandler = (index: number, value: any) => {
    const temp = [...affiliationRow];
    temp[index] = value as string;
    setAffiliationRow(temp);
  };
  // 加盟情報テキスト処理
  const scrapingTextHandler = (index: number, value: any) => {
    const temp = [...scrapingRow];
    temp[index] = value as string;
    setScrapingRow(temp);
  };

  return (
    <Box className="container">
      <div style={{ textAlign: 'center', margin: '10px' }}>
        <BasicButton displayName="ファイルをアップロード" startIcon={<CloudUploadIcon />} onClick={uploadExecute} />
      </div>
      <div
        {...getRootProps({ className: 'dropzone parent' })}
        style={{
          width: '100%', minHeight: '50vh', border: '2px dashed skyblue',
          textAlign: 'center', padding: '10px', flexWrap: 'wrap', justifyContent: 'space-between',
        }}
      >
        {isLoading && (
        <div className="loaderWrapper">
          <span className="loader"></span>
        </div>
        )}
        <VisuallyHiddenInput {...getInputProps()} />
        <Typography sx={{ margin: '10px' }} color={'gray'}>{file.length == 0 ? 'ここにファイルをドラッグ＆ドロップ' : `${file.length}件選択中`}</Typography>
        <div style={{ flex: '0 0 calc(50% - 10px)', boxSizing: 'border-box' }}>
          <ul style={{ padding: '10px', textAlign: 'center', margin: '5%' }}>
            {file.map((f, index) => {
              return (
                <div key={`file-${index}`} className="child">
                  {f}
                </div>
              );
            })}
          </ul>
        </div>
      </div>

      <div style={{ height: '50px' }}></div>

      <div style={{ display: 'flex', justifyContent: 'center' }}>
        {/* 加盟情報 */}
        {!props.hideAffiliationRow && (
        <div style={{ marginRight: '10%' }}>
          <Typography sx={{ fontWeight: 'bold', fontSize: 'large', color: 'black', marginBottom: '30px' }}>加盟情報</Typography>
          {affiliationRow.map((affi, index) => {
            return (
              <div key={`affi-${index}`}>
                <div style={{ display: 'flex', marginBottom: '10px' }}>
                  <BasicTextField
                    key={`affi-text-${index}`}
                    label=""
                    value={affi}
                    setValue={(e: any) => {
                      affi = e as string
                      affiTextHandler(index, e);
                    }}
                  />
                  <IconButton
                    key={`affi-add-button-${index}`}
                    sx={{ backgroundColor: 'green', scale: '0.8', ":hover": { backgroundColor: 'lightgreen' } }}
                    onClick={() => affiliationInfoAddHandler(index)}
                  >
                    <AddIcon key={`key-add-icon-${index}`} sx={{ color: 'white' }} />
                  </IconButton>
                  <IconButton
                    key={`affi-remove-button-${index}`}
                    sx={{ backgroundColor: 'red', scale: '0.8', ":hover": { backgroundColor: 'lightpink' } }}
                    onClick={() => affiliationInfoDelHandler(index)}
                  >
                    <RemoveIcon key={`key-remove-icon-${index}`} sx={{ color: 'white' }} />
                  </IconButton>
                </div>
              </div>
            );
          })}
        </div>
        )}

        {/* スクレイピング */}
        {!props.hideScrapingRow && (
        <div>
          <Typography sx={{ fontWeight: 'bold', fontSize: 'large', color: 'black', marginBottom: '30px' }}>スクレイピング</Typography>
          {scrapingRow.map((sc, index) => {
            return (
              <div key={`sc-${index}`}>
                <div style={{ display: 'flex', marginBottom: '10px' }}>
                  <BasicTextField
                    key={`sc-text-${index}`}
                    label=""
                    value={sc}
                    setValue={(e: any) => {
                      sc = e as string
                      scrapingTextHandler(index, e);
                    }}
                  />
                  <IconButton
                    key={`sc-add-button-${index}`}
                    sx={{ backgroundColor: 'green', scale: '0.8', ":hover": { backgroundColor: 'lightgreen' } }}
                    onClick={() => scrapingInfoAddHandler(index)}
                  >
                    <AddIcon key={`key-sc-add-icon-${index}`} sx={{ color: 'white' }} />
                  </IconButton>
                  <IconButton
                    key={`sc-remove-button-${index}`}
                    sx={{ backgroundColor: 'red', scale: '0.8', ":hover": { backgroundColor: 'lightpink' } }}
                    onClick={() => scrapingInfoDelHandler(index)}
                  >
                    <RemoveIcon key={`key-sc-remove-icon-${index}`} sx={{ color: 'white' }} />
                  </IconButton>
                </div>
              </div>
            );
          })}
        </div>
        )}
      </div>
    </Box>
  );
}
