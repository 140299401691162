import { Typography } from "@mui/material";
import Header from "../../../components/header/header";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useNavigate, useLocation } from "react-router-dom";
import React from "react";
import FileUploadBox from "../../../components/file/fileSendBox";

// 顧客情報 -> 顧客別禁止登録ページ
export default function ProhibitedRegistratiionByCustomerImportPage() {
  const nav = useNavigate();
  const location = useLocation();
  const clientId = location.state?.clientId;

  
  const gotoPage = (url: string) => {
    nav(url, { state: { clientId: clientId, tabIndex: 2 } });
  };

  return (
    <div>
      <div>
        <Header />
      </div>

      <div>
        {/* パンくずリスト */}
        <div className="d-flex" style={{ margin: '50px 50px 0px 50px' }}>
          <div onClick={() => gotoPage('/client_management/detail')}>
            <Typography
              fontWeight={'bold'}
              fontSize={'large'}
              style={{ color: 'gray', cursor: 'pointer' }}
            >
              顧客情報
            </Typography>
          </div>

          <div style={{ width: '20px' }}></div>
          <ArrowForwardIcon />
          <div style={{ width: '20px' }}></div>
          <Typography fontWeight={'bold'} fontSize={'large'}>顧客別禁止登録</Typography>
        </div>

        {/* ファイルアップロード */}
        <div>
          <div className="mx-5 my-5">
            <FileUploadBox httpUrl="/client/prohibition/register/import" method="POST" />
          </div>
        </div>
      </div>
    </div >
  );
}