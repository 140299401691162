import HttpData from "../../global/http/HttpData";

export interface SubjectSearchRequest extends HttpData {
  /** 案件名 */
  name: string
}

export type Subject = {
  subjectId: string
  subjectName: string
  startDate: string
  endDate: string
  progress: string
  orderId: string
}

export interface SubjectSearchResponse extends HttpData {
  subjects: Subject[]
}

// 利用者割り当て
export interface OperatorListResponse extends HttpData {
  operators: Operator[]
  userAssigned: string
}

export interface Operator {
  userId: string
  name: string
}

export interface UserDetailRequest extends HttpData {
  user_id: string
}

export interface UserDetail extends HttpData {
  userId: string
  name: string
  completedCount: string
  uncompletedCount: string
}

// 拠点割り当て
export interface LocationListResponse extends HttpData {
  locations: OutsourceLocation[]
  assigned: string
}

export interface OutsourceLocation {
  locationId: string
  locationName: string
}

export interface LocationDetailRequest extends HttpData {
  location_id: string
}

export interface LocationDetail extends HttpData {
  locationId: string
  locationName: string
  memo: string
  userCount: string
  completedCount: string
  uncompletedCount: string
}

export enum OperationType {
  USER = 'USER',
  LOCATION = 'LOCATION',
}

export interface AssignmentRequest extends HttpData {
  subjectId: string
  operationId: string
  operationType: OperationType
  qty: number
  orderId: string
}

export interface SubjectDetailRequest extends HttpData {
  subject_id: string
  order_id: string
}

export interface SubjectDetailResponse extends HttpData {
  subjectName: string
  startDate: string
  endDate: string
  status: string
  progress: string
  assigned: string
}