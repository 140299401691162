import HttpData from "./HttpData";
import store from "../store/store";
import {showLoader} from "../store/loaderSlice";

export class HttpBase {
  readonly dispatch = store.dispatch
  protected _noLoader: boolean = false
  protected domain: string = process.env.REACT_APP_DOMAIN as string
  protected getFullEndPoint(path: string) {
    const adjustedPath = path.startsWith('/') ? path.slice(1) : path;
    return new URL(adjustedPath, this.domain + '/').toString();
  }

  set noLoader(noLoader: boolean) {
    this._noLoader = noLoader
  }

  protected showLoader() {
    !this._noLoader && this.dispatch(showLoader())
  }
}

export default abstract class SecureHttp<T extends HttpData, U extends HttpData>
  extends HttpBase {
  protected _request?: T
  protected _response?: U
  public statusCode?: number

  set request(request: T) {
    this._request = request
  }

  get response(): U | undefined {
    return this._response
  }
}