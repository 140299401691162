
import { configureStore } from '@reduxjs/toolkit';
import authReducer, { AuthState } from './auth'
import snackbarReducer, {SnackbarState, initialState as snackbarInit} from './snackbar'
import loaderReducer from './loaderSlice'

const initialState: {
  auth: AuthState,
  snackbar: SnackbarState,
} = {
  auth: {
    isLoggedIn: false,
    user: null,
    // roles: [],
  },
  snackbar: snackbarInit,
};

const store = configureStore({
  reducer: {
    auth: authReducer,
    snackbar: snackbarReducer,
    loader: loaderReducer,
  },
  preloadedState: initialState
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;

