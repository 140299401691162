import HttpData from "../../../global/http/HttpData";
import {IbRole, OutsourceRole} from "../../roles";

export interface IbUser {
  userId: string
  name: string
}
export interface IbUserListResponse extends HttpData {
  users: IbUser[]
}

export interface IbUserRole {
  label: string; value: string
}

export interface IbUserRoleResponse extends HttpData {
  dropdownList: IbUserRole[]
}

export interface IbUserRequest extends HttpData {
  user_id: string
}

export interface IbUserDataResponse extends HttpData {
  userId?: string
  familyName: string
  givenName: string
  familyNameHurigana: string
  givenNameHurigana: string
  email: string
  password: string
  locked: boolean
  roles: IbRole[]
  ibRole: IbUserRole
}

export class IbUserInput {
  userId?: string
  familyName: string = ''
  givenName: string = ''
  familyNameHurigana: string = ''
  givenNameHurigana: string = ''
  email: string = ''
  password: string = ''
  locked: boolean = false
  roles: IbRole[] = []
  ibRole: IbUserRole = {value: '', label: ''}; 
}

export interface IbUserRegistrationRequest extends HttpData {
  userId?: string
  familyName: string
  givenName: string
  familyNameHurigana: string
  givenNameHurigana: string
  email: string
  password: string
  locked: boolean
  roles: IbRole[]
  ibRole: string
}

// 利用者管理(child)
export const IB_USER_ROLE = [
  IbRole.ACCOUNT_MANAGEMENT_CREATE,
  IbRole.ACCOUNT_MANAGEMENT_READ,
  IbRole.ACCOUNT_MANAGEMENT_UPDATE,
  IbRole.ACCOUNT_MANAGEMENT_DELETE
]

// ログ閲覧(child)
export const LOG_ROLE = [
  IbRole.LOG_READ
]

// 規定値管理(child)
export const MASTER_ROLE = [
  IbRole.MASTER_CREATE,
  IbRole.MASTER_READ,
  IbRole.MASTER_UPDATE,
  IbRole.MASTER_DELETE
]

// 帳票(child)
export const REPORT_ROLE = [
  IbRole.TABULATION_READ,
]

// システム管理(parent)
export const SYSTEM_MANAGEMENT_ROLE = [
  ...IB_USER_ROLE,
  ...LOG_ROLE,
  ...MASTER_ROLE,
  ...REPORT_ROLE
]

// 企業取込(child)
export const IMPORT_ROLE = [
  IbRole.COMPANY_CREATE,
  IbRole.COMPANY_READ,
  IbRole.COMPANY_UPDATE,
  IbRole.COMPANY_DELETE
]

// リスト作成(child)
export const LIST_ROLE = [
  IbRole.LIST_CREATE,
  IbRole.LIST_READ,
  IbRole.LIST_UPDATE,
  IbRole.LIST_DELETE
]

// 全体禁止(child)
export const WHOLE_PROHIBITION_ROLE = [
  IbRole.WHOLE_PROHIBITION_CREATE,
  IbRole.WHOLE_PROHIBITION_READ,
  IbRole.WHOLE_PROHIBITION_UPDATE,
  IbRole.WHOLE_PROHIBITION_DELETE
]

// 企業データベース(parent)
export const COMPANY_DATABASE_ROLE = [
  ...IMPORT_ROLE,
  ...LIST_ROLE,
  ...WHOLE_PROHIBITION_ROLE
]


// 顧客情報閲覧(child)
export const CLIENT_ROLE = [
  IbRole.CLIENT_READ
]

// 顧客登録・修正(child)
export const CLIENT_MANAGEMENT_ROLE = [
  IbRole.CLIENT_CREATE,
  IbRole.CLIENT_READ,
  IbRole.CLIENT_UPDATE,
  IbRole.CLIENT_DELETE,
]

// 案件情報(child)
export const SUBJECT_ROLE = [
  IbRole.MATTER_CREATE,
  IbRole.MATTER_READ,
  IbRole.MATTER_UPDATE,
  IbRole.MATTER_DELETE,
]

// 顧客別禁止管理(child)
export const CLIENT_PROHIBITION_ROLE = [
  IbRole.CLIENT_PROHIBITION_CREATE,
  IbRole.CLIENT_PROHIBITION_READ,
  IbRole.CLIENT_PROHIBITION_UPDATE,
  IbRole.CLIENT_PROHIBITION_DELETE,
]

// 発注(child)
export const ORDER_ROLE = [
  IbRole.ORDER_CREATE,
  IbRole.ORDER_READ,
  IbRole.ORDER_UPDATE,
  IbRole.ORDER_DELETE,
]

// 結果変換(child)
export const RESULT_CHANGE_ROLE = [
  IbRole.RESULT_CHANGE_CREATE,
  IbRole.RESULT_CHANGE_READ,
  IbRole.RESULT_CHANGE_UPDATE,
]

// 顧客管理(parent)
export const CLIENT_MANAGEMENT_PARENT_ROLE = [
  ...CLIENT_MANAGEMENT_ROLE,
  ...SUBJECT_ROLE,
  ...CLIENT_PROHIBITION_ROLE,
  ...ORDER_ROLE,
  ...RESULT_CHANGE_ROLE
]

// 施設管理(child)
export const SUBCONTRACTOR_ROLE = [
  IbRole.SUBCONTRACTOR_CREATE,
  IbRole.SUBCONTRACTOR_READ,
  IbRole.SUBCONTRACTOR_UPDATE,
  IbRole.SUBCONTRACTOR_DELETE,
]

// 受発注管理(child)
export const SUBCONTRACTOR_ORDER_ROLE = [
  IbRole.SUBCONTRACTOR_ORDER_CREATE,
  IbRole.SUBCONTRACTOR_ORDER_READ,
  IbRole.SUBCONTRACTOR_ORDER_UPDATE,
  IbRole.SUBCONTRACTOR_ORDER_DELETE,
]

// 施設管理(parent)
export const SUBCONTRACTOR_MANAGEMENT_ROLE = [
  ...SUBCONTRACTOR_ROLE,
  ...SUBCONTRACTOR_ORDER_ROLE
]