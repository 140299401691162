import ja from "date-fns/locale/ja";
import DatePicker from "react-datepicker";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { TextField } from "@mui/material";
import { forwardRef, useEffect, useState } from "react";
import { DateTime } from "luxon";

interface CustomInputProps {
  value?: string;
  onClick?: () => void;
}

type Props = {
  title?: string
  size?: 'small' | 'medium'
  value: string
  dateFormat?: 'yyyy/MM/dd' | 'yyyy/MM'
  isClearable?: boolean
  iconPosition?: 'startAdornment' | 'endAdornment'
  setValue: (value: string) => void
}
export default function BasicDatePicker(props: Props) {
  const [date, setDate] = useState<string>(props.value);

  const CustomInput = forwardRef<HTMLInputElement, CustomInputProps>(
    ({ value, onClick }, ref) => (
      <TextField
        fullWidth
        label={props.title}
        value={props.value}
        onClick={onClick}
        ref={ref}
        size={props.size ?? 'small'}
        InputProps={{
          [`${props.iconPosition ?? 'endAdornment'}`]: <CalendarMonthIcon />,
        }}
        autoComplete="off"
      />
    )
  )

  return <div style={{ display: 'grid', position: 'relative', zIndex: 2 }}>
    <DatePicker
      selected={props.value ? new Date(props.value) : null}
      onChange={(date: Date | string | [Date, Date] | null) => {
        if (date && props.setValue) {
          props.setValue(DateTime.fromJSDate((date as Date)).toFormat(props.dateFormat ?? 'yyyy/MM/dd'))
          return
        }
        props.setValue!('')
      }}
      locale={ja}
      isClearable={props.isClearable}
      dateFormat={props.dateFormat ?? 'yyyy/MM/dd'}
      showMonthYearPicker={props.dateFormat === 'yyyy/MM'}
      customInput={<CustomInput />}
    />
  </div>
}