import React from 'react';
import { Checkbox, FormControlLabel } from '@mui/material';

interface CheckboxProps {
  label: string;
  checked: boolean;
  readOnly?: boolean
  labelPlacement?: 'end' | 'start' | 'top' | 'bottom';
  onChange: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
}

export default function BasicCheckbox({ label, checked, readOnly, labelPlacement, onChange }: CheckboxProps) {
  return (
    <FormControlLabel
      control={<Checkbox checked={checked} onChange={onChange} />}
      label={label}
      style={readOnly ? { pointerEvents: 'none' } : {}}
      labelPlacement={labelPlacement}sx={{
        margin: '0', 
        '& .MuiFormControlLabel-label': {
          margin: '0',
        },
        '& .MuiCheckbox-root': {
          padding: '1',
        }
      }}
    />
  );
}
