import Header from "../../../components/header/header";
import {Typography} from "@mui/material";
import GridViewMenu from "../../../components/menu/gridViewMenu";
import React from "react";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import FileUploadBox from "../../../components/file/fileUploadBox";
import {useNavigate} from "react-router-dom";

export default function FormPathImportPage() {
  const nav = useNavigate();

  const gotoPage = (url: string) => {
    nav(url);
  }

  return (
    <div>
      <div>
        <Header/>
      </div>

      <div>
        {/* パンくずリスト */}
        <div className="d-flex" style={{margin: '50px 50px 0px 50px'}}>
          <div onClick={() => gotoPage('/company_database')}>
            <Typography
              fontWeight={'bold'}
              fontSize={'large'}
              style={{color: 'gray', cursor: 'pointer'}}
            >
              企業データベース管理
            </Typography>
          </div>

          <div style={{width: '20px'}}></div>
          <ArrowForwardIcon/>
          <div style={{width: '20px'}}></div>
          <Typography fontWeight={'bold'} fontSize={'large'}>企業取り込み（空欄以外上書き）</Typography>
        </div>

        {/* ファイルアップロード */}
        <div>
          <div className="mx-5 my-5">
            <FileUploadBox
              httpUrl="/database/import/form_path"
              method="POST"
              hideAffiliationRow={true}
              hideScrapingRow={true}
            />
          </div>
        </div>
      </div>
    </div>
  )
}