import '../../../../styles/create_list/CreateListPage.css'
import { Checkbox, FormControlLabel, Radio, Typography } from "@mui/material";
import Header from "../../../../components/header/header";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useNavigate } from "react-router-dom";
import BasicTable, { Row, Title } from "../../../../components/table/basicTable";
import BasicTextField from "../../../../components/text/basicTextField";
import { useState } from "react";
import { useDispatch } from "react-redux";
import BasicButton from "../../../../components/button/basicButton";
import HttpRequest from "../../../../global/http/HttpRequest";
import { CompanySearchRequest, CompanySearchDetailResponse } from "../../../../@types/ib/companyDatabasePage/CompanyType";
import { showMessage } from "../../../../global/store/snackbar";

type CheckboxData = {
  label: string
  checked: boolean
}

export default function CompanySearchPage() {
  const nav = useNavigate();

  const dispatch = useDispatch();

  const gotoPage = (url: string) => {
    nav(url);
  };

  const gotoDetailPage = (id: string) => {
    nav('/company_database/company_detail', { state: { companyId: id } });
  }

  // 検索データ管理
  // チェックボックスラベル
  const companyInitData: CheckboxData[] = [{ label: '前方', checked: true }, { label: '後方', checked: false }, { label: '部分', checked: false }, { label: 'NOT', checked: false }];
  const bigCategoryInitData: CheckboxData[] = [{ label: '前方', checked: true }, { label: '後方', checked: false }, { label: '部分', checked: false }, { label: 'NOT', checked: false }];
  const smallCategoryInitData: CheckboxData[] = [{ label: '前方', checked: true }, { label: '後方', checked: false }, { label: '部分', checked: false }, { label: 'NOT', checked: false }];

  // 企業名
  const [companyName, setCompanyName] = useState<string>('');
  const [companyCheck, setCompanyCheck] = useState<CheckboxData[]>(companyInitData);
  // 大業種
  const [bigCategory, setBigCategory] = useState<string>('');
  const [bigCategoryCheck, setBigCategoryCheck] = useState<CheckboxData[]>(bigCategoryInitData);
  // 小業種
  const [smallCategory, setSmallCategory] = useState<string>('');
  const [smallCategoryCheck, setSmallCategoryCheck] = useState<CheckboxData[]>(smallCategoryInitData);
  // 従業員数
  const [minEmployees, setMinEmployees] = useState<string>('');
  const [maxEmployees, setMaxEmployees] = useState<string>('');

  // テーブルヘッダー
  const headers: Title[] = [
    { name: 'ID', align: 'center' },
    { name: '法人名', align: 'left' },
    { name: '住所', align: 'left' },
    { name: '大業種', align: 'left' },
    { name: 'ドメイン', align: 'left' },
    { name: '', align: 'center' },
  ];
  
  // 表示データ格納変数
  const [tableData, setTableData] = useState<Row[]>([]);

  // 検索テーブルデータ
  const searchFieldList: Row[] = [
    {
      content: [
        { name: <Typography className="text-nowrap" sx={{ fontWeight: 'bold' }}>企業名</Typography>, align: 'right', width: 200, type: 'TEXT', value: '' },
        { name: <BasicTextField fullwidth label="" value={companyName} setValue={setCompanyName} />, align: 'left', type: 'TEXT', value: '' },
        {
          name:
            <div style={{ display: 'flex' }}>
              {companyCheck.map((item, index) => {
                if (item.label !== 'NOT') {
                  return (
                    <FormControlLabel
                      key={`company-check-${index}`}
                      className="text-nowrap"
                      control={<Radio checked={item.checked} onChange={() => handleRadioCheckData(index, companyCheck, setCompanyCheck)} />}
                      label={item.label}
                    />
                  );
                }
                if (item.label === 'NOT') {
                  return (
                    <FormControlLabel
                      key={`company-check-${index}`}
                      className="text-nowrap"
                      control={<Checkbox />}
                      value={item.checked} onChange={() => handleCheckData(index, companyCheck, setCompanyCheck)} label={item.label}
                    />
                  );
                }
                return null;
              })}
            </div>,
          type: 'TEXT',
          value: '',
          align: 'center'
        }
      ]
    },
    {
      content: [
        { name: <Typography className="text-nowrap" sx={{ fontWeight: 'bold' }}>大業種</Typography>, align: 'right', width: 200, type: 'TEXT', value: '' },
        { name: <BasicTextField fullwidth label="" value={bigCategory} setValue={setBigCategory} />, align: 'left', type: 'TEXT', value: '' },
        {
          name:
            <div style={{ display: 'flex' }}>
              {bigCategoryCheck.map((item, index) => {
                if (item.label !== 'NOT') {
                  return (
                    <FormControlLabel
                      key={`big-category-check-${index}`}
                      className="text-nowrap"
                      control={<Radio checked={item.checked} onChange={() => handleRadioCheckData(index, bigCategoryCheck, setBigCategoryCheck)} />}
                      label={item.label}
                    />
                  );
                }else{
                  return (
                    <FormControlLabel
                      key={`big-category-check-${index}`}
                      className="text-nowrap"
                      control={<Checkbox />}
                      value={item.checked} onChange={() => handleCheckData(index, bigCategoryCheck, setBigCategoryCheck)} label={item.label}
                    />
                  );
                }
              })}
            </div>,
          type: 'TEXT',
          value: '',
          align: 'center'
        }
      ]
    },
    {
      content: [
        { name: <Typography className="text-nowrap" sx={{ fontWeight: 'bold' }}>小業種</Typography>, align: 'right', width: 200, type: 'TEXT', value: '' },
        { name: <BasicTextField fullwidth label="" value={smallCategory} setValue={setSmallCategory} />, align: 'left', type: 'TEXT', value: '' },
        {
          name:
            <div style={{ display: 'flex' }}>
              {smallCategoryCheck.map((item, index) => {
                if (item.label !== 'NOT') {
                  return (
                    <FormControlLabel
                      key={`small-category-check-${index}`}
                      className="text-nowrap"
                      control={<Radio checked={item.checked} onChange={() => handleRadioCheckData(index, smallCategoryCheck, setSmallCategoryCheck)} />}
                      onChange={() => handleCheckData(index, smallCategoryCheck, setSmallCategoryCheck)} label={item.label}
                    />
                  );
                }else{
                  return (
                    <FormControlLabel
                      key={`small-category-check-${index}`}
                      className="text-nowrap"
                      control={<Checkbox />}
                      value={item.checked} onChange={() => handleCheckData(index, smallCategoryCheck, setSmallCategoryCheck)} label={item.label}
                    />
                  );
                }
              })}
            </div>,
          type: 'TEXT',
          value: '',
          align: 'center'
        }
      ]
    },
    {
      content: [
        { name: <Typography className="text-nowrap" sx={{ fontWeight: 'bold' }}>従業員数</Typography>, align: 'right', width: 200, type: 'TEXT', value: '' },
        {
          name: <div style={{ display: 'flex' }}>
            <BasicTextField fullwidth label="" value={minEmployees} setValue={setMinEmployees} />
            <div style={{ whiteSpace: 'nowrap', margin: 'auto 10px' }}>以上</div>
            <BasicTextField fullwidth label="" value={maxEmployees} setValue={setMaxEmployees} />
            <div style={{ whiteSpace: 'nowrap', margin: 'auto 10px' }}>以下</div>
          </div>,
          type: 'TEXT',
          value: '',
          align: 'left'
        },
      ]
    },
  ];

  // 検索処理
  const handleSearchData = async () => {

    const companyCheckItems = companyCheck.filter(item => item.checked).map(item => item.label)
    const bigCategoryCheckItems = bigCategoryCheck.filter(item => item.checked).map(item => item.label)
    const smallCategoryCheckItems = smallCategoryCheck.filter(item => item.checked).map(item => item.label)    

    const http = new HttpRequest<CompanySearchRequest, CompanySearchDetailResponse>()
    http.request = {
      clientId:"",
      companyName:companyName,
      companyCheckItems:companyCheckItems,
      bigCategory:bigCategory,
      bigCategoryCheckItems:bigCategoryCheckItems,
      smallCategory:smallCategory,
      smallCategoryCheckItems:smallCategoryCheckItems,
      minEmployees:minEmployees,
      maxEmployees:maxEmployees,
    }
    
    await http.post('/company/edit/targets/search')

    if (http.response?.errorMessage) {
      dispatch(showMessage({
        message: http.response!.errorMessage,
        severity: 'error',
        duration: 3000,
      }))
      return
    }
    
    const info = { ...http.response! }
    
    let num: number = 1;

    const clientUserList: Row[] = []
    for(const row of info.companies){
      const clientUserRow: Row = {
        content: [
          { name: num.toString(), align: 'center', width: 30, type: 'TEXT', value: '' },
          { name: row.companyName, align: 'left', width: 30, type: 'TEXT', value: '' },
          { name: row.address, align: 'left', width: 30, type: 'TEXT', value: '' },
          { name: row.industryCategoryName, align: 'left', width: 30, type: 'TEXT', value: '' },
          { name: row.domain, align: 'left', width: 30, type: 'TEXT', value: '' },
          { name: <BasicButton displayName={'詳細'} onClick={() => gotoDetailPage(row.companyId)} />, align: 'center', width: 30, type: 'TEXT', value: '' }
        ]
      }
      num++
      clientUserList.push(clientUserRow)
    }
    setTableData(clientUserList);

  }

  // State更新
  const handleCheckData = (index: number, data: CheckboxData[], setter: React.Dispatch<React.SetStateAction<CheckboxData[]>>) => {
    const temp = [...data];
    temp[index].checked = !temp[index].checked;
    setter(temp);
  }
  const handleRadioCheckData = (index: number, data: CheckboxData[], setter: React.Dispatch<React.SetStateAction<CheckboxData[]>>) => {
    const temp = [...data];
    temp.forEach((item, i) => {
      if (item.label !== 'NOT') {
        temp[i].checked = false;
      }
    });
    temp[index].checked = true;
    setter(temp);
  }
  

  return (
    <div>
      <div>
        <Header />
      </div>

      <div>
        <div>
          {/* パンくずリスト */}
          <div className="d-flex" style={{ margin: '50px 50px 0px 50px' }}>
            <div onClick={() => gotoPage('/company_database')}>
              <Typography
                fontWeight={'bold'}
                fontSize={'large'}
                style={{ color: 'gray', cursor: 'pointer' }}
              >
                企業データベース管理
              </Typography>
            </div>

            <div style={{ width: '20px' }}></div>
            <ArrowForwardIcon />
            <div style={{ width: '20px' }}></div>
            <Typography fontWeight={'bold'} fontSize={'large'}>企業検索</Typography>
          </div>
        </div>

        {/* 検索エリア */}
        <div>
          <div className="mx-5" style={{ scale: '0.8' }}>
            <BasicTable headers={[]} content={searchFieldList} />
          </div>

          {/* 検索ボタン */}
          <div className="text-center">
            <BasicButton width={100} displayName="検索" onClick={() => {
              handleSearchData();
            }} />
          </div>
        </div>

        {/* 顧客リストテーブル */}
        <div className='mx-5 my-3'>
          <BasicTable
            headerColor="midnightblue"
            headers={headers}
            content={tableData}
            isPaging
          />
        </div>
      </div>
    </div>
  )
}