import { Typography } from "@mui/material";
import Header from "../../../components/header/header";
import BasicTable, { Row, Title } from "../../../components/table/basicTable";
import { useEffect, useState } from "react";
import BasicButton from "../../../components/button/basicButton";
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from "react-router-dom";
import DataRequestSender from "../../../global/http/DataRequestSender";
import SearchTextField from "../../../components/text/searchTextField";
import { useDispatch } from "react-redux";
import { showMessage } from "../../../global/store/snackbar";

export default function OutsourcingManagementPage() {
  const nav = useNavigate();
  const dispatch = useDispatch();

  const gotoPage = (url: string) => {
    nav(url);
  };

  const gotoDetailPage = (rowIndex: number) => {
    const id = orderData[rowIndex].content[0].value;
    nav('/outsourcing_management/detail', { state: { id: id } });
  }

  // search text
  const [searchValue, setSearchValue] = useState<string>('');

  const headers: Title[] = [
    { name: 'ID', align: 'center' },
    { name: '外注先名', align: 'left' },
    { name: '住所', align: 'left' },
    { name: '', align: 'center' },
  ];

  let [orderData, setOrderData] = useState<Row[]>([])

  const [tableData, setTableData] = useState<Row[]>([]);

  const search = async () => {
    const DataRequestItems = new DataRequestSender()
    DataRequestItems.addStr('search_value', searchValue)
    const response = await DataRequestItems.upload('/outsource/search')

    if (response['result'] === "FAILURE") {
      dispatch(showMessage({
        message: 'データ取得に失敗しました',
        severity: 'error',
        duration: 3000,
      }));
      return;
    }

    if (response['result'] === "SUCCESS" && response['data'].length >= 1) {
      type CompanyData = {
        name: string;
        address: string;
        outsource_id: string;
      };

      orderData = response['data'].map((item: CompanyData, index: number) => ({
        content: [
          { name: index + 1, align: 'center', width: 30, type: 'TEXT', value: item.outsource_id },
          { name: item.name, align: 'left', width: 30, type: 'TEXT', value: item.name },
          { name: item.address, align: 'left', width: 30, type: 'TEXT', value: item.address },
          { name: <BasicButton displayName={'詳細'} onClick={() => gotoDetailPage(index)} />, align: 'center', width: 30, type: 'TEXT', value: '' }
        ]
      }))
      setTableData(orderData)
    };
  };

  return (
    <div>
      <div>
        <Header />
      </div>

      {/* パンくずリストと新規登録ボタン */}
      <div className="d-flex justify-content-between mx-5 align-items-end">
        <div style={{ margin: '50px 0px 0px 0px' }}>
          <Typography fontWeight={'bold'} fontSize={'large'}>外注先管理</Typography>
        </div>
        <div style={{ margin: '50px 0px 0px 50px', textAlign: 'end' }}>
          <BasicButton displayName="新規登録" startIcon={<AddIcon />} onClick={() => gotoPage('/outsourcing_management/new')} />
        </div>
      </div>

      {/* 検索フィールド */}
      <div className="d-flex justify-content-center" style={{ alignItems: 'center', margin: '50px 0px 0px 0px' }}>
        <SearchTextField label="外注先名" value={searchValue} setValue={setSearchValue} onClick={search} />
      </div>


      {/* 外注先テーブル */}
      <div className='mx-5 my-3'>
        <BasicTable
          headerColor="midnightblue"
          headers={headers}
          content={tableData}
        />
      </div>
    </div>
  );
}