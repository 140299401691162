import HttpData from "../../global/http/HttpData";
import {Dropdown} from "../../components/dropdown/basicDropdown";

interface Item {
  name: string
  id: string
}

export class ProjectInfo {
  projectId?: string = ''
  clientId?: string = ''
  subjectName: string = ''
  senderId: string = ''
  senderName: string = ''
  draftId: string = ''
  draftName: string = ''
  listId: string = ''
  listName: string = ''
  startDate: string = ''
  endDate: string = ''
  startTime: string = ''
  endTime: string = ''
  sendableWeekday: string = ''
  subjectStatus:Dropdown = {label: '', value: '送信可'}
  parameterPresence: string = ''
  memo: string = ''
}

export default interface ProjectEditRequest extends HttpData {
  projectId?: string;
  clientId?: string;
  senderId: string;
  senderName: string;
  draftId: string;
  draftName: string;
  listId: string;
  listName: string;
  startDate: string;
  endDate: string;
  startTime: string;
  endTime: string;
  sendableWeekday: string;
  subjectStatus: Dropdown;
  parameterPresence: string;
  memo: string;
}

export interface ProjectDetail {
  projectName: string
  listNum: string
  requestedNum: string
  notRequestedNum: string
  sentNum: string
  unsentNum: string
  period: string
  status: string
  time: string
  memo: string
}

export interface ProjectResultResponse extends HttpData {
  result: string
  message: string
  project_id: string
  list_id: string
  sendable_weekday: string
  extracted: string
  project_detail: ProjectDetail
  client_items: Item[]
  errorMessage: string
  mail_send: boolean
}

export interface ResultResponse extends HttpData {
  errorMessage: string
  projectId: string
}

export type ProjectDropdownRequest = {
  client_id: string
}

export type ProjectEditDropdownRequest = {
  client_id: string
  extracted: string
}

export type ProjectDropdownResponse = {
  list_items: Dropdown[]
  sender_items: Dropdown[]
  draft_items: Dropdown[]
}

export type ProjectDetailRequest = {
  project_id: string
}

export interface ProjectDetailResponse extends HttpData {
  projectId?: string;
  subjectName: string;
  senderId: string;
  senderName: string;
  draftId: string;
  draftName: string;
  listId: string;
  listName: string;
  startDate: string;
  endDate: string;
  startTime: string;
  endTime: string;
  sendableWeekday: string;
  subjectStatus:Dropdown;
  parameterPresence: string;
  memo: string;
}

export type ExtractListRequest = {
  project_id: string
  client_id: string
}

export interface ExtractListResponse extends HttpData {
  errorMessage: string
  extracted: string
}
