import React from "react";
import { useSelector } from "react-redux";
import { CircularProgress } from "@mui/material";
import {RootState} from "../../global/store/store";

export default function Loader() {
    const isLoading = useSelector((state: RootState) => state.loader.isLoading);

    if (!isLoading) {
        return null;
    }
    
    const overlayStyle: React.CSSProperties = {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        background: 'rgba(0, 0, 0, 0.7)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 9999,
    }

    return (
        <div style={overlayStyle}>
            <CircularProgress />
        </div>
    )
    

}