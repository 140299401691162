import { useNavigate, useLocation } from "react-router-dom";
import Header from "../../../components/header/header";
import { Typography } from "@mui/material";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import React, { useEffect, useState, forwardRef } from "react";
import { useDispatch } from "react-redux";
import BasicButton from "../../../components/button/basicButton";
import "react-datepicker/dist/react-datepicker.css";
import HttpRequest from "../../../global/http/HttpRequest";
import { DraftInfo, DraftDetailRequest, DraftResponse } from "../../../@types/draft";
import BasicTextField from "../../../components/text/basicTextField";

// 顧客詳細 -> 新規送信原稿詳細
export default function SubmissionDraftDetailPage() {
  const nav = useNavigate();
  const dispatch = useDispatch();

  const location = useLocation();
  const clientId = location.state?.clientId;
  const draftId = location.state?.draftId;
  const clientName = location.state?.clientName;

  const gotoPage = (url: string) => {
    nav(url, { state: { clientId: clientId, draftId: draftId,
        clientName: clientName, pageTitle: '送信原稿編集', tabIndex: 1 } });
  };

  // state(基本情報)
  const [draftInfo, setDraftInfo] = useState<DraftInfo>(new DraftInfo())

  useEffect(() => {
    const fetchData = async () => {
      // 送信原稿情報取得
      const http = new HttpRequest<DraftDetailRequest, DraftResponse>()
      http.request = {
        draft_id: draftId!,
      }
      if (await http.get(`/client/draft/detail`)) {
        const info = { ...http.response! }
        setDraftInfo({ ...info })
      }
    }
    fetchData();
  }, []);

  return (
    <div>
      <div>
        <Header />
      </div>

      {/* パンくずリスト */}
      <div>
        <div className="d-flex" style={{ margin: '50px 50px 0px 50px' }}>
          <div onClick={() => gotoPage('/client_management')}>
            <Typography fontWeight={'bold'} fontSize={'large'} style={{ color: 'gray', cursor: 'pointer' }}>
              {'顧客管理'}
            </Typography>
          </div>

          <div style={{ width: '20px' }}></div>
          <ArrowForwardIcon />
          <div style={{ width: '20px' }}></div>

          <div onClick={() => gotoPage('/client_management/detail')}>
            <Typography fontWeight={'bold'} fontSize={'large'} style={{ color: 'gray', cursor: 'pointer' }}>
              {'顧客詳細'}
            </Typography>
          </div>

          <div style={{ width: '20px' }}></div>
          <ArrowForwardIcon />
          <div style={{ width: '20px' }}></div>

          <Typography fontWeight={'bold'} fontSize={'large'} style={{ color: 'gray' }}>{'案件情報'}</Typography>

          <div style={{ width: '20px' }}></div>
          <ArrowForwardIcon />
          <div style={{ width: '20px' }}></div>

          <Typography fontWeight={'bold'} fontSize={'large'}>{'送信原稿詳細'}</Typography>
        </div>
      </div>

      {/* 顧客名 */}
      <div className="d-flex" style={{ margin: '30px 30px 0px 50px' }}>
        <Typography align="left" fontWeight={'bold'} fontSize={'large'}>{clientName}</Typography>
      </div>

      <div>
        <div className="container" style={{ margin: '30px 50px 0px 50px' }}>
          <div className="d-flex my-4 col-8">
            <BasicTextField
              fullwidth
              label={'タイトル'}
              InputProps={{ readOnly: true }}
              value={draftInfo.draftName ?? ''}
              setValue={(e) => null}
            />
          </div>
          <div className="d-flex my-4 col-8">
            <BasicTextField
              fullwidth
              label={'件名'}
              InputProps={{ readOnly: true }}
              value={draftInfo.title ?? ''}
              setValue={(e) => null}
            />
          </div>
          <div className="d-flex my-4 col-12">
            <BasicTextField
              fullwidth
              label={'通常版'}
              multiline
              rows={5}
              InputProps={{ readOnly: true }}
              value={draftInfo.regulart ?? ''}
              setValue={(e) => null}
            />
          </div>
          <div className="d-flex my-4 col-12">
            <BasicTextField
              fullwidth
              label={'短縮版'}
              multiline
              rows={5}
              InputProps={{ readOnly: true }}
              value={draftInfo.short ?? ''}
              setValue={(e) => null}
            />
          </div>
          <div className="d-flex my-4 col-12">
            <BasicTextField
              fullwidth
              label={'URLなし版'}
              multiline
              rows={5}
              InputProps={{ readOnly: true }}
              value={draftInfo.noUrl ?? ''}
              setValue={(e) => null}
            />
          </div>
          {/* 基本情報 */}{/* 登録フォーム */}
          <div className="my-3 my-5" style={{ textAlign: 'center' }}>
            <BasicButton
              displayName={'編集'} width={'20%'}
              fontColor="white"
              onClick={() => gotoPage('/client_management/newSubmissionDraftPage')}
            />
          </div>
        </div>


      </div>

    </div >
  );
}