import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import BasicTabs from "../../components/tab/basicTab";
import BasicTable from "../../components/table/basicTable";
import HttpData from "../../global/http/HttpData";
import HttpRequest from "../../global/http/HttpRequest";
import ClientHeader from "../../components/header/clientHeader";
import {Manuscript, SenderInfoRequest, SenderInfoResponse, SendInfo} from "../../@types/client/senderType";

/**
 * 顧客向け画面 送信情報
 */
export default function ClientSendInfoPage() {
  const location = useLocation();
  const nav = useNavigate();
  const subjectId = location.state?.subjectId;

  // 送信者情報
  const [info, setInfo] = useState<SendInfo>(
    {
      companyName: '',
      companyNameHiragana: '',
      companyNameKatakana: '',
      lastName: '',
      lastNameHiragana: '',
      lastNameKatakana: '',
      firstName: '',
      firstNameHiragana: '',
      firstNameKatakana: '',
      gender: '',
      phoneNumber: '',
      faxNumber: '',
      prefecture: '',
      cities: '',
      address: '',
      mailAddress: '',
      birthday: '',
      url: '',
      mobilePhoneNumber: '',
      department: '',
      post: '',
      operation: '',
      age: '',
      postNumber: '',
    }
  );
  const [manuscript, setManuscript] = useState<Manuscript>({
    title: '', regularContent: '', shortContent: '', noUrlContent: ''
  });

  useEffect(() => {
    const fetch = async () => {
      const http = new HttpRequest<SenderInfoRequest, SenderInfoResponse>()
      http.request = {
        subjectId: subjectId,
      };

      if (await http.post('/client/subject/sender_info')) {
        setInfo(http.response!.senderInfo);
        setManuscript(http.response!.manuscript);
      }
    };
    fetch();
  }, []);

  const gotoPageAddData = (url: string, data: string) => {
    nav(url, { state: { uuid: data } });
  };

  return (
    <div>
      <div>
        <ClientHeader />
      </div>

      {/* パンくずリスト */}
      <div className="d-flex" style={{ margin: '50px 50px 0px 50px' }}>
        <div onClick={() => gotoPageAddData('/client/list', '')}>
          <Typography
            fontWeight={'bold'}
            fontSize={'large'}
            style={{ color: 'gray', cursor: 'pointer' }}
            component="p"
          >
            案件一覧
            </Typography>
        </div>

        <div style={{ width: '20px' }}></div>
        <ArrowForwardIcon />
        <div style={{ width: '20px' }}></div>
        <Typography fontWeight={'bold'} fontSize={'large'} component="p">送信情報</Typography>
      </div>

      {/* 送信者情報 */}
      <Box component="div" className='mx-5 my-4'>
        <BasicTabs tabItems={['送信者情報', '送信原稿']} tabContents={[
          // 送信者情報
          <div>
            <div className="" style={{ backgroundColor: '', padding: '0 0px' }}>
              <BasicTable headers={[]} contentHoverColor={'white'} content={[
                {
                  content: [
                    { name: '企業名', align: "left", type: "TEXT", width: 140, isNowrap: true, fontWeight: 'bold' },
                    { name: ':', align: "left", type: "TEXT", width: 10 },
                    { name: info.companyName, align: "left", type: "TEXT", isNowrap: true },

                    { name: '氏', align: "left", type: "TEXT", width: 100, isNowrap: true, fontWeight: 'bold' },
                    { name: ':', align: "left", type: "TEXT", width: 10 },
                    { name: info.lastName, align: "left", type: "TEXT", isNowrap: true },

                    { name: '名', align: "left", type: "TEXT", width: 100, isNowrap: true, fontWeight: 'bold' },
                    { name: ':', align: "left", type: "TEXT", width: 10 },
                    { name: info.firstName, align: "left", type: "TEXT", isNowrap: true },
                  ],
                },
                {
                  content: [
                    { name: '企業名（かな）', align: "left", type: "TEXT", width: 140, isNowrap: true, fontWeight: 'bold' },
                    { name: ':', align: "left", type: "TEXT", width: 10 },
                    { name: info.companyNameHiragana, align: "left", type: "TEXT", isNowrap: true },

                    { name: '氏（かな）', align: "left", type: "TEXT", width: 100, isNowrap: true, fontWeight: 'bold' },
                    { name: ':', align: "left", type: "TEXT", width: 10 },
                    { name: info.lastNameHiragana, align: "left", type: "TEXT", isNowrap: true },

                    { name: '名（かな）', align: "left", type: "TEXT", width: 100, isNowrap: true, fontWeight: 'bold' },
                    { name: ':', align: "left", type: "TEXT", width: 10 },
                    { name: info.firstNameHiragana, align: "left", type: "TEXT", isNowrap: true },
                  ],
                },
                {
                  content: [
                    { name: '企業名（カナ）', align: "left", type: "TEXT", width: 140, isNowrap: true, fontWeight: 'bold' },
                    { name: ':', align: "left", type: "TEXT", width: 10 },
                    { name: info.companyNameKatakana, align: "left", type: "TEXT", isNowrap: true },

                    { name: '氏（カナ）', align: "left", type: "TEXT", width: 100, isNowrap: true, fontWeight: 'bold' },
                    { name: ':', align: "left", type: "TEXT", width: 10 },
                    { name: info.lastNameKatakana, align: "left", type: "TEXT", isNowrap: true },

                    { name: '名（カナ）', align: "left", type: "TEXT", width: 100, isNowrap: true, fontWeight: 'bold' },
                    { name: ':', align: "left", type: "TEXT", width: 10 },
                    { name: info.firstNameKatakana, align: "left", type: "TEXT", isNowrap: true },
                  ],
                },
                {
                  content: [
                    { name: 'TEL', align: "left", type: "TEXT", width: 140, isNowrap: true, fontWeight: 'bold' },
                    { name: ':', align: "left", type: "TEXT", width: 10 },
                    { name: info.phoneNumber, align: "left", type: "TEXT", isNowrap: true },

                    { name: '性別', align: "left", type: "TEXT", width: 100, isNowrap: true, fontWeight: 'bold' },
                    { name: ':', align: "left", type: "TEXT", width: 10 },
                    { name: info.gender, align: "left", type: "TEXT", colspan: 4, isNowrap: true },
                  ],
                },
                {
                  content: [
                    { name: 'FAX', align: "left", type: "TEXT", width: 140, isNowrap: true, fontWeight: 'bold' },
                    { name: ':', align: "left", type: "TEXT", width: 10 },
                    { name: info.faxNumber, align: "left", type: "TEXT", isNowrap: true },

                    { name: '部署', align: "left", type: "TEXT", width: 100, isNowrap: true, fontWeight: 'bold' },
                    { name: ':', align: "left", type: "TEXT", width: 10 },
                    { name: info.department, align: "left", type: "TEXT", colspan: 4, isNowrap: true },
                  ],
                },
                {
                  content: [
                    { name: 'HP', align: "left", type: "TEXT", width: 140, isNowrap: true, fontWeight: 'bold' },
                    { name: ':', align: "left", type: "TEXT", width: 10 },
                    { name: info.url, align: "left", type: "TEXT", isNowrap: true },

                    { name: '役職', align: "left", type: "TEXT", width: 100, isNowrap: true, fontWeight: 'bold' },
                    { name: ':', align: "left", type: "TEXT", width: 10 },
                    { name: info.post, align: "left", type: "TEXT", colspan: 4, isNowrap: true },
                  ],
                },
                {
                  content: [
                    { name: '郵便番号', align: "left", type: "TEXT", width: 140, isNowrap: true, fontWeight: 'bold' },
                    { name: ':', align: "left", type: "TEXT", width: 10 },
                    { name: info.postNumber, align: "left", type: "TEXT", isNowrap: true },

                    { name: '携帯電話', align: "left", type: "TEXT", width: 100, isNowrap: true, fontWeight: 'bold' },
                    { name: ':', align: "left", type: "TEXT", width: 10 },
                    { name: info.mobilePhoneNumber, align: "left", type: "TEXT", colspan: 4, isNowrap: true },
                  ],
                },
                {
                  content: [
                    { name: '都道府県', align: "left", type: "TEXT", width: 140, isNowrap: true, fontWeight: 'bold' },
                    { name: ':', align: "left", type: "TEXT", width: 10 },
                    { name: info.prefecture, align: "left", type: "TEXT", isNowrap: true },

                    { name: 'Email', align: "left", type: "TEXT", width: 100, isNowrap: true, fontWeight: 'bold' },
                    { name: ':', align: "left", type: "TEXT", width: 10 },
                    { name: info.mailAddress, align: "left", type: "TEXT", colspan: 4, isNowrap: true },
                  ],
                },
                {
                  content: [
                    { name: '住所1', align: "left", type: "TEXT", width: 140, isNowrap: true, fontWeight: 'bold' },
                    { name: ':', align: "left", type: "TEXT", width: 10 },
                    { name: info.cities, align: "left", type: "TEXT", isNowrap: true },

                    { name: '生年月日', align: "left", type: "TEXT", width: 100, isNowrap: true, fontWeight: 'bold' },
                    { name: ':', align: "left", type: "TEXT", width: 10 },
                    { name: info.birthday, align: "left", type: "TEXT", colspan: 4, isNowrap: true },
                  ],
                },
                {
                  content: [
                    { name: '住所2', align: "left", type: "TEXT", width: 140, isNowrap: true, fontWeight: 'bold' },
                    { name: ':', align: "left", type: "TEXT", width: 10 },
                    { name: info.address, align: "left", type: "TEXT", isNowrap: true },

                    { name: '年齢', align: "left", type: "TEXT", width: 100, isNowrap: true, fontWeight: 'bold' },
                    { name: ':', align: "left", type: "TEXT", width: 10 },
                    { name: info.age, align: "left", type: "TEXT", colspan: 4, isNowrap: true },
                  ],
                },
                {
                  content: [
                    { name: '事業内容', align: "left", type: "TEXT", width: 140, fontWeight: 'bold' },
                    { name: ':', align: "left", type: "TEXT", width: 10 },
                    { name: info.operation, align: "left", type: "TEXT" },
                  ],
                },
              ]} />
            </div>
          </div>,

          // 送信原稿
          <div>
            <Typography sx={{ marginBottom: '5px' }} variant='h5'>{`${manuscript.title}`}</Typography>
            <div className="">
              <BasicTable headers={[{ name: '通常版', align: "left" }]} headerColor="midnightblue" content={[
                { content: [{ name: manuscript.regularContent, align: "left", type: "TEXT" }] }
              ]}
              />
            </div>
            <div className="my-5">
              <BasicTable headers={[{ name: '短縮版', align: "left" }]} headerColor="midnightblue" content={[
                { content: [{ name: manuscript.shortContent, align: "left", type: "TEXT" }] }
              ]}
              />
            </div>
            <div className="my-5">
              <BasicTable headers={[{ name: 'URLなし版', align: "left" }]} headerColor="midnightblue" content={[
                { content: [{ name: manuscript.noUrlContent, align: "left", type: "TEXT" }] }
              ]}
              />
            </div>
          </div>
        ]} />
      </Box>
    </div>
  )
}