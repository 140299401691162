import { useNavigate, useLocation } from "react-router-dom";
import Header from "../../../components/header/header";
import { TextField, Typography } from "@mui/material";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import BasicTextField from "../../../components/text/basicTextField";
import { useEffect, useState, forwardRef } from "react";
import { useDispatch } from "react-redux";
import BasicButton from "../../../components/button/basicButton";
import BasicDropdown, { Dropdown } from "../../../components/dropdown/basicDropdown";
import "react-datepicker/dist/react-datepicker.css";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { showMessage } from "../../../global/store/snackbar";
import { SenderResultResponse } from "../../../@types/matterEdit";

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import PrefectureDropdown from "../../../components/dropdown/prefectureDropdown";
import PostCodeDropdown from "../../../components/dropdown/postCodeDropdown";
import {
  SenderRequest,
  SenderDetailRequest,
  SenderInfo,
  SenderLocation, SenderResponse
} from "../../../@types/ib/clientManagement/SenderType";
import HttpRequest from "../../../global/http/HttpRequest";
import BasicDatePicker from "../../../components/datapicker/basicDatePicker";

// 顧客管理 -> 顧客詳細 -> 案件情報 -> 送信者情報新規登録ページ																	
export default function SenderRegistrationPage() {
  const nav = useNavigate();
  const dispatch = useDispatch();

  const locationState: SenderLocation = useLocation().state;
  const clientId = locationState?.clientId;
  const clientName = locationState?.clientName;
  const pageTitle = locationState?.pageTitle ?? '送信者情報新規登録';

  const gotoPage = (url: string) => {
    nav(url, { state: { clientId: clientId, tabIndex: 1 } });
  };

  const gotoDetailPage = (url: string, sender_id: string) => {
    nav(url, { state: { clientId: clientId, clientName: clientName, senderId: sender_id } });
  };

  // state
  const [isRender, setIsRender] = useState<boolean>(true);
  const [gender, setGender] = useState<Dropdown[]>([
    { label: '男', value: '1' },
    { label: '女', value: '2' },
  ]);
  const [selectedBirthDay, setSelectedBirthDay] = useState<string>('');
  const [btnTitle, setBtnTitle] = useState<string>('登録');
  const [senderId, setSenderId] = useState<string>('');
  const [senderInfo, setSenderInfo] = useState<SenderInfo>(new SenderInfo());

  useEffect(() => {
    if (locationState.senderId) {
      setSenderId(locationState.senderId);
      const f = async () => {
        const http = new HttpRequest<SenderDetailRequest, SenderResponse>()
        http.request = {
          sender_id: locationState.senderId!,
        }
        const res = await http.get(`/client/sender/detail`)
        if (res) {
          const info = { ...http.response! }
          setSenderInfo(info)
          setSelectedBirthDay(info.birthday)
        }
      }
      f()
    }
  }, [])

  useEffect(() => {
    if (selectedBirthDay !== null) {
      setIsRender(true)
    }
  }, [selectedBirthDay])

  // 登録
  const registration = async () => {
    const http = new HttpRequest<SenderRequest, SenderResultResponse>()
    http.request = {
      ...{ ...senderInfo, clientId: clientId },
    }

    if (await http.post('/client/sender/register')) {
      http.response && gotoDetailPage('/client_management/SenderDetailPage', http.response.senderId)
    } else {
      dispatch(showMessage({
        message: '登録に失敗しました',
        severity: 'error',
        duration: 3000,
      }))
    }
  }

  return (
    <div>
      <div>
        <Header />
      </div>

      {/* パンくずリスト */}
      <div className="d-flex" style={{ margin: '50px 50px 0px 50px' }}>
        <div onClick={() => gotoPage('/client_management')}>
          <Typography fontWeight={'bold'} fontSize={'large'} style={{ color: 'gray', cursor: 'pointer' }}>
            {'顧客管理'}
          </Typography>
        </div>

        <div style={{ width: '20px' }}></div>
        <ArrowForwardIcon />
        <div style={{ width: '20px' }}></div>

        <div onClick={() => gotoPage('/client_management/detail')}>
          <Typography fontWeight={'bold'} fontSize={'large'} style={{ color: 'gray', cursor: 'pointer' }}>
            {'顧客詳細'}
          </Typography>
        </div>

        <div style={{ width: '20px' }}></div>
        <ArrowForwardIcon />
        <div style={{ width: '20px' }}></div>

        <Typography fontWeight={'bold'} fontSize={'large'} style={{ color: 'gray' }}>{'案件情報'}</Typography>

        <div style={{ width: '20px' }}></div>
        <ArrowForwardIcon />
        <div style={{ width: '20px' }}></div>

        <Typography fontWeight={'bold'} fontSize={'large'}>{pageTitle}</Typography>
      </div>

      {/* 登録フォーム */}
      {isRender && (
        <div className="mx-5 my-3">
          <Table component={'table'} aria-label="simple table">
            <TableBody component={'tbody'} sx={{ '.table-row:hover': { backgroundColor: 'aliceblue' } }}>
              <TableRow className='breadcrumb-row' component={'tr'}>
                <TableCell component={'td'} sx={{ fontWeight: 'bold', paddingBottom: 0 }}>
                  {/* 顧客名 */}
                  <div className="d-flex my-3" >
                    <Typography align="left" fontWeight={'bold'} fontSize={'large'}>{clientName}</Typography>
                  </div>
                </TableCell>
              </TableRow>
              {/* 案件名 */}
              <TableRow className='title-row' component={'tr'}>
                <TableCell component={'td'} sx={{ paddingBottom: 3 }}>
                  <div className="d-flex my-3">
                    <Typography align="left" fontWeight={'bold'}>{'案件'}</Typography>
                  </div>
                  <div className="row">
                    <div className="col-4">
                      <BasicTextField
                        fullwidth
                        label="案件名"
                        value={senderInfo.title}
                        setValue={(value: string) => setSenderInfo({ ...senderInfo, title: value })}
                      />
                    </div>
                  </div>
                </TableCell>
              </TableRow>
              {/* 企業情報 */}
              <TableRow className='company-row' component={'tr'}>
                <TableCell component={'td'} sx={{ paddingBottom: 3 }}>
                  <div className="my-2">
                    <Typography align="left" fontWeight={'bold'}>{'企業'}</Typography>
                  </div>
                  <div className="row">
                    <div className="my-2 col-4">
                      <BasicTextField
                        fullwidth
                        label="企業名"
                        value={senderInfo.companyName}
                        setValue={(value: string) => setSenderInfo({ ...senderInfo, companyName: value })}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="my-2 col-4">
                      <BasicTextField
                        fullwidth
                        label="企業名（かな）"
                        value={senderInfo.companyNameHiragana}
                        setValue={(value: string) => setSenderInfo({ ...senderInfo, companyNameHiragana: value })}
                      />
                    </div>
                    <div className="my-2 col-4">
                      <BasicTextField
                        fullwidth
                        label="企業名（カナ）"
                        value={senderInfo.companyNameKatakana}
                        setValue={(value: string) => setSenderInfo({ ...senderInfo, companyNameKatakana: value })}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="my-2 col-8">
                      <BasicTextField
                        fullwidth
                        label="事業内容"
                        value={senderInfo.businessDescription}
                        setValue={(value: string) => setSenderInfo({ ...senderInfo, businessDescription: value })}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="my-2 col-4">
                      <BasicTextField
                        fullwidth
                        label="電話番号（-必須）"
                        value={senderInfo.tel}
                        setValue={(value: string) => setSenderInfo({ ...senderInfo, tel: value })}
                      />
                    </div>
                    <div className="my-2 col-4">
                      <BasicTextField
                        fullwidth
                        label="FAX番号（-必須）"
                        value={senderInfo.fax}
                        setValue={(value: string) => setSenderInfo({ ...senderInfo, fax: value })}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="my-2 col-8">
                      <BasicTextField
                        fullwidth
                        label="URL"
                        value={senderInfo.url}
                        setValue={(value: string) => setSenderInfo({ ...senderInfo, url: value })}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="my-2 col-4">
                      <PostCodeDropdown
                        value={senderInfo.postalCode}
                        setValue={(selectedValue: Dropdown) => setSenderInfo({ ...senderInfo, postalCode: selectedValue })}
                      />
                    </div>
                    <div className="my-2 col-4">
                      <PrefectureDropdown
                        value={senderInfo.prefecture}
                        setValue={(selectedValue: Dropdown) => setSenderInfo({ ...senderInfo, prefecture: selectedValue })}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="my-2 col-4">
                      <BasicTextField
                        fullwidth
                        label="市区町村"
                        value={senderInfo.city}
                        setValue={(value: string) => setSenderInfo({ ...senderInfo, city: value })}
                      />
                    </div>
                    <div className="my-2 col-4">
                      <BasicTextField
                        fullwidth
                        label="住所"
                        value={senderInfo.address}
                        setValue={(value: string) => setSenderInfo({ ...senderInfo, address: value })}
                      />
                    </div>
                  </div>
                </TableCell>
              </TableRow>
              {/* 送信者情報 */}
              <TableRow className='title-row' component={'tr'}>
                <TableCell component={'td'} sx={{ paddingBottom: 3 }}>
                  <div className="d-flex my-3">
                    <Typography align="left" fontWeight={'bold'}>{'送信者'}</Typography>
                  </div>
                  <div className="row">
                    <div className="my-2 col-4">
                      <BasicTextField
                        fullwidth
                        label="氏"
                        value={senderInfo.familyName}
                        setValue={(value: string) => setSenderInfo({ ...senderInfo, familyName: value })}
                      />
                    </div>
                    <div className="my-2 col-4">
                      <BasicTextField
                        fullwidth
                        label="名"
                        value={senderInfo.givenName}
                        setValue={(value: string) => setSenderInfo({ ...senderInfo, givenName: value })}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="my-2 col-4">
                      <BasicTextField
                        fullwidth
                        label="氏（かな）"
                        value={senderInfo.familyNameHiragana}
                        setValue={(value: string) => setSenderInfo({ ...senderInfo, familyNameHiragana: value })}
                      />
                    </div>
                    <div className="my-2 col-4">
                      <BasicTextField
                        fullwidth
                        label="名（かな）"
                        value={senderInfo.givenNameHiragana}
                        setValue={(value: string) => setSenderInfo({ ...senderInfo, givenNameHiragana: value })}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="my-2 col-4">
                      <BasicTextField
                        fullwidth
                        label="氏（カナ）"
                        value={senderInfo.familyNameKatakana}
                        setValue={(value: string) => setSenderInfo({ ...senderInfo, familyNameKatakana: value })}
                      />
                    </div>
                    <div className="my-2 col-4">
                      <BasicTextField
                        fullwidth
                        label="名（カナ）"
                        value={senderInfo.givenNameKatakana}
                        setValue={(value: string) => setSenderInfo({ ...senderInfo, givenNameKatakana: value })}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="my-2 col-4">
                      <BasicTextField
                        fullwidth
                        label="部署"
                        value={senderInfo.department}
                        setValue={(value: string) => setSenderInfo({ ...senderInfo, department: value })}
                      />
                    </div>
                    <div className="my-2 col-4">
                      <BasicTextField
                        fullwidth
                        label="役職"
                        value={senderInfo.position}
                        setValue={(value: string) => setSenderInfo({ ...senderInfo, position: value })}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="my-2 col-4">
                      <BasicTextField
                        fullwidth
                        label="携帯電話"
                        value={senderInfo.mobilePhone}
                        setValue={(value: string) => setSenderInfo({ ...senderInfo, mobilePhone: value })}
                      />
                    </div>
                    <div className="my-2 col-4">
                      <BasicTextField
                        fullwidth
                        label="メールアドレス"
                        value={senderInfo.email}
                        setValue={(value: string) => setSenderInfo({ ...senderInfo, email: value })}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="my-2 col-4">
                      <BasicDropdown
                        fullWidth
                        items={gender}
                        value={senderInfo.gender}
                        onChange={(selectedValue: Dropdown) => setSenderInfo({ ...senderInfo, gender: selectedValue })}
                        titleName="性別"
                      />
                    </div>
                    <div className="my-2 col-4">
                      <BasicTextField
                        fullwidth
                        label="年齢"
                        value={senderInfo.age}
                        setValue={(value: string) => setSenderInfo({ ...senderInfo, age: value })}
                      />
                    </div>
                    <div className="my-2 col-4">
                      <BasicDatePicker
                        title={'生年月日'}
                        value={selectedBirthDay ?? ''}
                        setValue={(date: string) => {
                          if (date) {
                            setSenderInfo({ ...senderInfo, birthday: date });
                            setSelectedBirthDay(date)
                          }
                        }}
                      />
                    </div>
                  </div>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
          <div className="my-3" style={{ textAlign: 'center' }}>
            <BasicButton
              displayName={btnTitle} width={'20%'}
              fontColor="white"
              onClick={registration}
            />
          </div>
        </div>
      )}
    </div >
  );
}