import BasicTable, {Row} from "../../../../components/table/basicTable";
import BasicTextField from "../../../../components/text/basicTextField";
import BasicMultipleDropdown, { Dropdown } from "../../../../components/dropdown/basicMultipleDropdown";
import {useState, useEffect} from "react";
import {
  TableData,
  ReadTableData,
  ReportTableData,
  FinishedTaskTableData, TaskDetail,
  TaskDto, MailDataRequest, TaskTopResponse, TaskStatusRequest, MemoRequest
} from "../../../../@types/ib/systemManagement/TaskManagementType";
import { MailData } from "../../../../@types/standardManagement";

import { SendMailRequest, SendMailResponse } from "../../../../@types/ib/sendEmailType"
import {Box, TextField, Typography} from "@mui/material";
import BasicButton from "../../../../components/button/basicButton";
import BasicDialog from "../../../../components/dialog/basicDialog";
import HttpRequest from "../../../../global/http/HttpRequest";
import HttpData from "../../../../global/http/HttpData";
import { showMessage } from "../../../../global/store/snackbar";
import { useDispatch } from "react-redux";

export default function TaskManagementPage() {
  
  const dispatch = useDispatch();

  const [unreadTableData, setUnreadTableData] = useState<TableData>(new ReadTableData())
  const [readTableData, setReadTableData] = useState<TableData>(new ReadTableData())
  const [mailSubject, setMailSubject] = useState<string>('')
  const [mailBody, setMailBody] = useState<string>('')
  const [reportTableData, setReportTableData] = useState<TableData>(new ReportTableData())
  const [finishedTaskTableData, setFinishedTaskTableData] = useState<TableData>(new FinishedTaskTableData())
  const [finishedTaskListOpen, setFinishedTaskListOpen] = useState<boolean>(false)
  // const [taskDetailOpen, setTaskDetailOpen] = useState<boolean>(true)
  const [sendMailOpen, setSendMailOpen] = useState<boolean>(false)
  const [selectedToUsers, setSelectedToUsers] = useState<Dropdown[]>([]);
  const [selectedCcUsers, setSelectedCcUsers] = useState<Dropdown[]>([]);
  const [taskDetail, setTaskDetail] = useState<TaskDetail| null>(null)
  
  useEffect(() => {
    f()
  }, [])

  const f = async () => {
    const http = new HttpRequest<HttpData, TaskTopResponse>()
    await http.get('/system/task')
    const response = http.response!
    const unreadTableDataList: Row[] = []
    const readTableDataList: Row[] = []
    for (const row of response.tasks) {
      const clientUserRow: Row = {
        content: [
          { id: 'taskName', name: <div onClick={async () => await displayTask(row)} style={{cursor: 'pointer'}}>{row.task_name}</div>, align: 'left', value: row.task_name, type: 'TEXT' },
          { id: 'deadline', name: <div onClick={async () => await displayTask(row)} style={{cursor: 'pointer'}}>{row.deadline}</div>, align: 'left', value: row.deadline, type: 'TEXT' },
          { id: 'customerName', name: <div onClick={async () => await displayTask(row)} style={{cursor: 'pointer'}}>{row.customer_name}</div>, align: 'left', value: row.customer_name, type: 'TEXT' },
        ], id: row.task_id
      }
      if(row.unread == '0'){
        unreadTableDataList.push(clientUserRow)
      } else {
        readTableDataList.push(clientUserRow)
      }
    }
    setUnreadTableData(prevState => ({
      ...prevState,
      body: unreadTableDataList,
    }));
    setReadTableData(prevState => ({
      ...prevState,
      body: readTableDataList,
    }));
    setSelectedToUsers([])
    setSelectedCcUsers([])
  }

  const displayTask = async (row: TaskDto) => {
    TaskDetailShow(row)
    await changeReadStatus(row.task_id, true)
  }

  const changeStatus = async (id: string, read: boolean, fin: boolean) => {
    const http = new HttpRequest<TaskStatusRequest, HttpData>()
    http.request = {
      taskId: id,
      read: read,
      fin: fin,
    }
    await http.post('/system/task/status')
  }

  useEffect(() => {
    if (finishedTaskListOpen) {
      const finished = async () => {
        const finDataList: Row[] = []
        const http = new HttpRequest<HttpData, TaskTopResponse>()
        await http.get('/system/task/fin')
        const response = http.response!
        for (const row of response.tasks) {
          const finishedRow: Row = {
            content: [
              {id: 'taskName',
                name: <div
                           style={{cursor: 'pointer'}}>{row.task_name}</div>,
                align: 'left',
                value: row.task_name,
                type: 'TEXT'
              },
              {id: 'customerName',
                name: <div
                           style={{cursor: 'pointer'}}>{row.customer_name}</div>,
                align: 'left',
                value: row.customer_name,
                type: 'TEXT'
              },
              {id: 'customerName',
                name: <div /*onClick={async () => await displayTask(row)}*/
                           style={{cursor: 'pointer'}}>{row.customer_name}</div>,
                align: 'left',
                value: row.customer_name,
                type: 'TEXT'
              },
              {id: 'finDate',
                name: <div /*onClick={async () => await displayTask(row)}*/
                  style={{cursor: 'pointer'}}>{row.finDate}</div>,
                align: 'left',
                value: row.customer_name,
                type: 'TEXT'
              },
            ], id: row.task_id
          }
          finDataList.push(finishedRow)
        }
        setFinishedTaskTableData({
          header: finishedTaskTableData.header,
          body: finDataList,
        })
      }
      finished()
    }

  }, [finishedTaskListOpen])

  const FinishedTaskList = (): JSX.Element => {
    return <>
      <BasicTable
        headers={finishedTaskTableData.header}
        content={finishedTaskTableData.body}
      />
    </>
  }

  // タスク詳細表示
  const TaskDetailShow = (TaskData: TaskDto) => {
    setTaskDetail({
      taskId: TaskData.task_id,
      title: TaskData.task_name,
      deadline: TaskData.deadline,
      rep: TaskData.rep,
      customer: TaskData.customer_name,
      subject: TaskData.subject,
      order: TaskData.order,
      memo: TaskData.memo,
    })
  };

  // タスクメールデータ取得
  const getMailData = async () => {
    const http = new HttpRequest<MailDataRequest, MailData>()
    http.request = {
      task_id: taskDetail!.taskId
    }
    await http.get('/system/task/mail/data')
    const response = http.response!

    if (!http.response?.mailId) {
      dispatch(showMessage({
        message: 'メールデータ取得に失敗しました',
        severity: 'error',
        duration: 3000,
      }))
      return
    } else {
      setMailSubject(http.response?.subject)
      setMailBody(http.response?.text)
      dispatch(showMessage({
        message: 'メールデータ取得に成功しました',
        severity: 'info',
        duration: 3000,
      }))
      setSendMailOpen(true)
      return
    }
  }
  
  // メール送信
  const send_mail = async () => {
    const http = new HttpRequest<SendMailRequest, SendMailResponse>()
    http.request = {
      projectId: '',
      title: mailSubject,
      body: mailBody,
      toUsers: selectedToUsers,
      ccUsers: selectedCcUsers
    }

    await http.post('/common/mail/send/client/ib')

    if (http.response?.errorMessage) {
      dispatch(showMessage({
        message: http.response!.errorMessage,
        severity: 'error',
        duration: 3000,
      }))
      return
    } else {
      dispatch(showMessage({
        message: 'メール送信に成功しました',
        severity: 'info',
        duration: 3000,
      }))
      setSendMailOpen(false)
      return
    }
  }

  const updateMemo = async () => {
    const http = new HttpRequest<MemoRequest, HttpData>()
    http.request = {
      taskId: taskDetail!.taskId,
      memo: taskDetail!.memo,
    }
    if (await http.post('/system/task/memo/save')) {
      dispatch(showMessage({
        message: 'メモを保存しました',
        severity: 'info',
        duration: 3000,
      }))
    }
  }

  const SendMail = (): JSX.Element => {
    return <Box className="container" width="600px" height="600px">
      <div className="d-flex my-1">
        <div className="border-bottom">
          <Typography align="right">To：</Typography>
        </div>
        <div className="col border-bottom">
          <BasicMultipleDropdown
            fullWidth
            titleName={''}
            value={selectedToUsers}
            items={[]}
            onChange={(val: Dropdown[]) => setSelectedToUsers(val)}
            lazyRequestUrl={'/system/task/client_user/filter'}
            requestItem={taskDetail?.taskId}
            border_none
          />
        </div>
      </div>
      <div className="d-flex my-1">
        <div className="border-bottom">
          <Typography align="right">Cc：</Typography>
        </div>
        <div className="col border-bottom">
          <BasicMultipleDropdown
            fullWidth
            titleName={''}
            value={selectedCcUsers}
            items={[]}
            onChange={(val: Dropdown[]) => setSelectedCcUsers(val)}
            lazyRequestUrl={'/common/master/ib_user/filter'}
            border_none
          />
        </div>
      </div>
      <div className="row my-3">
        <BasicTextField
          fullwidth
          label="件名"
          value={mailSubject}
          setValue={(value: string) => setMailSubject(value)}
        />
      </div>
      <div className="row my-3 text-center">
        <BasicTextField
          fullwidth
          multiline
          rows={15}
          label="文章"
          value={mailBody}
          setValue={(value: string) => setMailBody(value)}
        />
      </div>
      <div className="row mt-3 text-center">
        <BasicButton
          displayName="送信"
          onClick={() => {
            send_mail()
          }}
        />
      </div>
    </Box>
  }

  const TaskDetail = (): JSX.Element => {
    return <Box className="container" height="600px">
      <div className="row pb-5">
        <div className="col-4">
          {taskDetail?.title}
        </div>
        <div className="col-4">
          締切日：{taskDetail?.deadline}
        </div>
        <div className="col-2">
          担当者：{taskDetail?.rep}
        </div>
        <div className="col-2 text-end">
          <BasicButton displayName="未読にする"
              onClick={async () => {
                await changeStatus(taskDetail!.taskId, false, false)
              }} />
        </div>
      </div>{/* row */}
      <div className="row">
        <div className="col-7">
          <div className="row">
            <div className="col">
              <TextField
                multiline={true}
                rows={18}
                fullWidth={true}
                value={taskDetail?.memo}
                onChange={(e) => setTaskDetail({...taskDetail!, memo: e.target.value})}
              />
            </div>
          </div>
          <div className="row mt-3">
            <div className="col">
              <BasicButton
                fullWidth={true}
                displayName="メモを保存"
                onClick={updateMemo}
              />
            </div>
          </div>
        </div>
        {/* col-6 */}
        <div className="offset-1 col">
          <div className="col">
            顧客名：{taskDetail?.customer}
          </div>
          <div className="col">
            案件名：{taskDetail?.subject}
          </div>
          <div className="col">
            発注：{taskDetail?.order}
          </div>
          <Box height={"330px"} />
          {taskDetail?.title == "送信完了連絡" && (
            <div className="col mt-3">
              <BasicButton
                displayName="メール送信"
                fullWidth={true}
                onClick={() => {
                  getMailData()
                }}
              />
            </div>
          )}
          <div className="col mt-3">
            <BasicButton
              fullWidth={true}
              displayName="タスクを完了"
              onClick={finishTask}
            />
          </div>
        </div>{/* col-6 */}
      </div>{/* row */}
    </Box>
    {/* container */}
  }

  const changeReadStatus = async (id: string, unread: boolean) => {
    await changeStatus(id, unread, false)
    await f()
  }

  const finishTask = async () => {
    await changeStatus(taskDetail!.taskId, true, true)
    setTaskDetail(null)
    await f()
  }

  return <div>
    <div className="container-fluid">
      <div className="row">
        {/* left */}
        <div className="col-6">
            <div className="row">
              <div className="col">
                <Typography>未読</Typography>
                <div>
                  <BasicTable
                    headers={unreadTableData.header}
                    content={unreadTableData.body}
                  />
                </div>
            </div>
            <div className="row">
              <div className="col">
                <Typography>既読</Typography>
                <div>
                  <BasicTable
                    headers={readTableData.header}
                    content={readTableData.body}
                  />
                </div>
              </div>
            </div>{/* row */}
          </div>{/* container-fluid */}
        </div>{/* col-6 */}

        {/* right */}
        <div className="col-6">
            <div className="row">
              <div className="col">
                <Typography>帳票</Typography>
                <div>
                  <BasicTable
                    headers={reportTableData.header}
                    content={reportTableData.body}
                  />
                </div>
              </div>
            </div>{/* row */}
            <div className="row mt-3 align-items-end">
              <div className="col">
                <BasicButton
                  fullWidth={true}
                  displayName={"完了済みタスクを閲覧"}
                  onClick={() => {
                    setFinishedTaskListOpen(true)
                  }}
                />
              </div>
          </div>
          {/* container-fluid */}
        </div>
        {/* col-6 */}
      </div>
      {/* row */}
    </div>
    {/* container-fluid */}

    {/* タスク詳細 */}
    <BasicDialog
      open={!!taskDetail}
      fullWidth={true}
      title={''}
      content={TaskDetail()}
      close={() => {
        setTaskDetail(null)
      }}
    />

    <BasicDialog
      open={sendMailOpen}
      title={''}
      content={SendMail()}
      close={() => {
        setSendMailOpen(false)
      }}
    />

    {/*  */}
    <BasicDialog
      open={finishedTaskListOpen}
      title={''}
      content={FinishedTaskList()}
      close={() => {
        setFinishedTaskListOpen(false)
      }}
    />
  </div>
}