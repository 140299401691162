import React from "react";
import '../../../styles/top/TopPage.css'
import Header from "../../../components/header/header";
import BasicTable, { Row, Title } from "../../../components/table/basicTable";
import { Typography } from "@mui/material";
import { useSelector } from "react-redux";
import store, { RootState } from "../../../global/store/store";
import TaskManagementPage from "../SystemManagementPage/TaskManagement/List";

export default function TopPage() {
  // user info
  const isLoggedIn: boolean = useSelector((state: RootState) => state.auth.isLoggedIn);
  const user: { username: string } | null = useSelector((state: RootState) => state.auth.user);

  // sample header data
  const yetReadTableHeaders: Title[] = [
    { name: '受信日時', align: 'left' },
    { name: 'タイトル', align: 'left' },
    { name: '内容', align: 'left' },
  ];

  // samaple content data
  const yetReadTableContents: Row[] = [
    {
      content: [
        { name: '2023-04-14', align: 'left' },
        { name: 'aaa', align: 'left' },
        { name: 'aaaaaa', align: 'left' },
      ]
    },
    {
      content: [
        { name: '2023-04-17', align: 'left' },
        { name: 'bbb', align: 'left' },
        { name: 'bbbbbbbbb', align: 'left' },
      ]
    },
  ];

  return (
    <div>
      <div>
        <Header />
      </div>

      <div className="mx-3">
        <div className="container-fluid">
          <div className="row">
            <div className="py-3">
              <Typography fontSize={'large'}>{user?.username}</Typography>
            </div>
          </div>
        </div>
        <TaskManagementPage />
      </div>
    </div>
  );
};