import {Box, FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import {useState} from "react";
import BasicDropdown from "./basicDropdown";

export interface Dropdown {
  label: string;
  value: string;
}

type Props = {
  titleName?: string
  value: Dropdown
  setValue: (val: Dropdown) => void
  send_type: string
}

export default function ConversionDropdown(props: Props) {
  return <BasicDropdown
    fullWidth
    titleName={props.titleName ?? ''}
    value={props.value}
    items={[]}
    onChange={(val: Dropdown) => props.setValue(val)}
    initialRequestUrl={`/common/master/conversion?send_type=${props.send_type}`}
  />
}