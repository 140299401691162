import { useNavigate } from "react-router-dom";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { login } from "../../global/store/auth";
import HttpRequest from "../../global/http/HttpRequest";
import IbLoginRequest from "../../@types/IbLogin";
import { ResultResponse } from "../../@types/common";
import { showMessage } from "../../global/store/snackbar";
import BasicTextField from "../text/basicTextField";
import BasicButton from "../button/basicButton";

type Props = {
  title?: string
  requestUrl: string,
  topPageUrl: string,
}

export default function Login(props: Props) {
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  // Snackbarの開閉用
  const [open, setOpen] = useState<boolean>(false);

  const dispatch = useDispatch();
  const handleLogin = async (): Promise<void> => {
    const user = { username: email };
    dispatch(login(user));
    if (!email || !password) {
      setOpen(true);
      return
    }
    const http = new HttpRequest<IbLoginRequest, ResultResponse>()
    http.request = {
      username: email,
      password: password,
    }
    if (await http.post(props.requestUrl)) {
      window.location.href = props.topPageUrl
    } else {
      dispatch(showMessage({
        message: 'ログインに失敗しました',
        severity: 'error',
        duration: 3000,
      }))
    }
  };

  // Snackbarを閉じるための処理
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className="main-content text-center">

      <h3 className="title text-center">{props.title ?? '営業代行メール版'}</h3>

      <div className="text text-center my-4">
        <BasicTextField
          fullwidth
          label="e-mail"
          setValue={setEmail}
        />
      </div>

      <div className="text text-center my-3">
        <BasicTextField
          fullwidth
          label="password"
          type="password"
          setValue={setPassword}
        />
      </div>

      <div className="text-center">
        <BasicButton
          displayName={'ログイン'}
          onClick={handleLogin}
        />
      </div>
    </div>
  );
}