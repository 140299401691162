import Header from "../../../../components/header/header";
import {Typography, Select, MenuItem, InputLabel} from "@mui/material";
import React, {useEffect, useState} from "react";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import {useNavigate} from "react-router-dom";
import BasicTextField from "../../../../components/text/basicTextField";
import BasicCheckbox from "../../../../components/checkbox/basicCheckBox";
import {IbRole} from "../../../../@types/roles";
import BasicButton from "../../../../components/button/basicButton";
import BasicTable, {Row, Title} from "../../../../components/table/basicTable";
import {
  COMPANY_DATABASE_ROLE,
  IB_USER_ROLE,
  IbUser,
  IbUserDataResponse,
  IbUserInput,
  IbUserListResponse,
  IbUserRegistrationRequest,
  IbUserRequest,
  IMPORT_ROLE,
  LIST_ROLE,
  LOG_ROLE,
  MASTER_ROLE,
  REPORT_ROLE,
  SYSTEM_MANAGEMENT_ROLE,
  WHOLE_PROHIBITION_ROLE,
  CLIENT_MANAGEMENT_PARENT_ROLE,
  CLIENT_ROLE,
  CLIENT_MANAGEMENT_ROLE,
  SUBJECT_ROLE,
  CLIENT_PROHIBITION_ROLE,
  ORDER_ROLE,
  RESULT_CHANGE_ROLE,
  SUBCONTRACTOR_MANAGEMENT_ROLE,
  SUBCONTRACTOR_ROLE,
  SUBCONTRACTOR_ORDER_ROLE,
  IbUserRole,
  IbUserRoleResponse
} from "../../../../@types/ib/systemManagement/IbUserManagementType";
import useRole from "../../../../global/hook/roleHook";
import HttpRequest from "../../../../global/http/HttpRequest";
import HttpData from "../../../../global/http/HttpData";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import DoDisturbIcon from "@mui/icons-material/DoDisturb";

export default function IbUserManagementPage() {
  const nav = useNavigate();
  const hasRole = useRole()

  const headers: Title[] = [
    { name: '利用者氏名', align: 'left' },
  ];
  
  const gotoPage = (url: string) => {
    nav(url);
  }

  const [editingFlag, setEditingFlag] = useState(true);
  const [ibUserInput, setIbUserInput] = useState<IbUserInput>(new IbUserInput())
  const [tableData, setTableData] = useState<Row[]>([]);
  const [ibUserRoles, setIbUserRoles] = useState<IbUserRole[]>([]);

  useEffect(() => {
    getUserList();
    getIbUserRoles();
  }, [])

  async function getUserList() {
    const http = new HttpRequest<HttpData, IbUserListResponse>()
    if (await http.get('/system/user_management/list')) {
      const response = http.response!
      setTableData(response.users.map((p: IbUser) => ({
        content: [
          {
            name: <div
                onClick={() => handleIbUser(p.userId)}
                style={{ width: '100%', textAlign: 'left' }}
            >{p.name}</div>,
            align: 'left',
            type: 'TEXT'
          },
        ]
      })))
    }
  }

  async function getIbUserRoles() {
    const http = new HttpRequest<HttpData, IbUserRoleResponse>()
    if (await http.get('/common/master/ib_role')) {
      const response = http.response!
      setIbUserRoles(response.dropdownList)
    }
  }

  const handleIbUser = async (userId: string) => {
    // 選択したユーザーのユーザー名、ユーザーIDでDBを検索
    const http = new HttpRequest<IbUserRequest, IbUserDataResponse>()
    http.request = {
      user_id: userId,
    }
    if (await http.get('/system/user_management/user')) {
      setIbUserInput({...http.response!})
      setEditingFlag(false)
    }
  }

  function selectedSomeRole(childRoles: IbRole[]) {
    return ibUserInput.roles.some(roles => childRoles.includes(roles))
  }

  function selectedAllRole(roles: IbRole[]) {
    return roles.every(role => ibUserInput.roles.includes(role))
  }

  function updateRoles(roles: IbRole[], add: boolean) {
    add
      ? addRoles(roles)
      : removeRoles(roles)
  }

  function addRoles(roles: IbRole[]) {
    const currentRoles = ibUserInput.roles
    const newRoles = currentRoles.concat(roles)
    setIbUserInput({ ...ibUserInput, roles: newRoles })
  }

  function removeRoles(roles: IbRole[]) {
    const currentRoles = ibUserInput.roles
    const newRoles = currentRoles.filter(role => !roles.includes(role))
    setIbUserInput({ ...ibUserInput, roles: newRoles })
  }

  async function registration() {
    const http = new HttpRequest<IbUserRegistrationRequest, HttpData>()
    http.request = {
      ...ibUserInput, ibRole: ibUserInput.ibRole.value
    }
    if (await http.post('/system/user_management/registration')) {
      await getUserList()
      setIbUserInput(new IbUserInput())
    }
  }

  function foundIbUserRoleLabel (value: string) {
    return ibUserRoles.find((role) => role.value === value)?.label ?? ''
  }

  const renderAuthority = () => {
    return (
      <div>
        {/* アカウント作成・編集・削除 */}
        <div style={{marginTop: '0px'}}>
          <div className="row my-1">
            <div className="col">
              {selectedSomeRole(SYSTEM_MANAGEMENT_ROLE) ? <CheckCircleOutlineIcon color="success"/> :
                <DoDisturbIcon color="error"/>}
              {'システム管理'}
            </div>
          </div>
          <div className="row my-2">
            <div className="offset-1 col">
              {selectedAllRole(IB_USER_ROLE) ? <CheckCircleOutlineIcon color="success"/> :
                <DoDisturbIcon color="error"/>}
              {'利用者管理'}
            </div>
          </div>
          <div className="row my-2">
            <div className="offset-1 col">
              {selectedAllRole(LOG_ROLE) ? <CheckCircleOutlineIcon color="success"/> : <DoDisturbIcon color="error"/>}
              {'ログ閲覧'}
            </div>
          </div>
          <div className="row my-2">
            <div className="offset-1 col">
              {selectedAllRole(MASTER_ROLE) ? <CheckCircleOutlineIcon color="success"/> :
                <DoDisturbIcon color="error"/>}
              {'規定値管理'}
            </div>
          </div>
          <div className="row my-2">
            <div className="offset-1 col">
              {selectedAllRole(REPORT_ROLE) ? <CheckCircleOutlineIcon color="success"/> :
                <DoDisturbIcon color="error"/>}
              {'帳票'}
            </div>
          </div>
        </div>

        {/* 企業データベース */}
        <div style={{marginTop: '20px'}}>
          <div className="row my-2">
            <div className="col">
              {selectedSomeRole(COMPANY_DATABASE_ROLE) ? <CheckCircleOutlineIcon color="success"/> :
                <DoDisturbIcon color="error"/>}
              {'企業データベース'}
            </div>
          </div>
          <div className="row my-2">
            <div className="offset-1 col">
              {selectedAllRole(IMPORT_ROLE) ? <CheckCircleOutlineIcon color="success"/> : <DoDisturbIcon color="error"/>}
              {'企業取り込み'}
            </div>
          </div>
          <div className="row my-2">
            <div className="offset-1 col">
              {selectedAllRole(LIST_ROLE) ? <CheckCircleOutlineIcon color="success"/> : <DoDisturbIcon color="error"/>}
              {'リスト作成'}
            </div>
          </div>
          <div className="row my-2">
            <div className="offset-1 col">
              {selectedAllRole(WHOLE_PROHIBITION_ROLE) ? <CheckCircleOutlineIcon color="success"/> : <DoDisturbIcon color="error"/>}
              {'全体禁止'}
            </div>
          </div>
        </div>

        {/* 顧客管理 */}
        <div style={{marginTop: '20px'}}>
          <div className="row my-2">
            <div className="col">
              {selectedSomeRole(CLIENT_MANAGEMENT_PARENT_ROLE) ? <CheckCircleOutlineIcon color="success"/> :
                <DoDisturbIcon color="error"/>}
              {'顧客管理'}
            </div>
          </div>
          <div className="row my-2">
            <div className="offset-1 col">
              {selectedAllRole(CLIENT_ROLE) ? <CheckCircleOutlineIcon color="success"/> : <DoDisturbIcon color="error"/>}
              {'顧客情報閲覧'}
            </div>
          </div>
          <div className="row my-2">
            <div className="offset-1 col">
              {selectedAllRole(CLIENT_MANAGEMENT_ROLE) ? <CheckCircleOutlineIcon color="success"/> : <DoDisturbIcon color="error"/>}
              {'顧客登録・修正'}
            </div>
          </div>
          <div className="row my-2">
            <div className="offset-1 col">
              {selectedAllRole(SUBJECT_ROLE) ? <CheckCircleOutlineIcon color="success"/> : <DoDisturbIcon color="error"/>}
              {'案件情報'}
            </div>
          </div>
          <div className="row my-2">
            <div className="offset-1 col">
              {selectedAllRole(CLIENT_PROHIBITION_ROLE) ? <CheckCircleOutlineIcon color="success"/> : <DoDisturbIcon color="error"/>}
              {'顧客別禁止管理'}
            </div>
          </div>
          <div className="row my-2">
            <div className="offset-1 col">
              {selectedAllRole(ORDER_ROLE) ? <CheckCircleOutlineIcon color="success"/> : <DoDisturbIcon color="error"/>}
              {'発注'}
            </div>
          </div>
          <div className="row my-2">
            <div className="offset-1 col">
              {selectedAllRole(RESULT_CHANGE_ROLE) ? <CheckCircleOutlineIcon color="success"/> : <DoDisturbIcon color="error"/>}
              {'結果変換'}
            </div>
          </div>
        </div>

        {/* 施設管理 */}
        <div style={{marginTop: '20px'}}>
          <div className="row my-2">
            <div className="col">
              {selectedSomeRole(SUBCONTRACTOR_MANAGEMENT_ROLE) ? <CheckCircleOutlineIcon color="success"/> :
                <DoDisturbIcon color="error"/>}
              {'施設管理'}
            </div>
          </div>
          <div className="row my-2">
            <div className="offset-1 col">
              {selectedAllRole(SUBCONTRACTOR_ROLE) ? <CheckCircleOutlineIcon color="success"/> : <DoDisturbIcon color="error"/>}
              {'施設管理'}
            </div>
          </div>
          <div className="row my-2">
            <div className="offset-1 col">
              {selectedAllRole(SUBCONTRACTOR_ORDER_ROLE) ? <CheckCircleOutlineIcon color="success"/> : <DoDisturbIcon color="error"/>}
              {'受発注管理'}
            </div>
          </div>
        </div>

      </div>
    )
  }

  return (
    <div>
      <div>
        <Header/>
      </div>

      <div className="d-flex" style={{margin: '50px 50px 0px 50px'}}>
        <div onClick={() => gotoPage('/system_management')}>
          <Typography
            fontWeight={'bold'}
            fontSize={'large'}
            style={{color: 'gray', cursor: 'pointer'}}
          >
            システム管理
          </Typography>
        </div>

        <div style={{width: '20px'}}></div>
        <ArrowForwardIcon/>
        <div style={{width: '20px'}}></div>
        <div>
          <Typography
            fontWeight={'bold'}
            fontSize={'large'}
          >
            利用者管理
          </Typography>
        </div>
      </div>

      <div className="d-flex">
        <div style={{width: '40%', borderRadius: '4px', padding: '20px 30px 20px 30px'}}>
          <div className='my-2 form-control'>
            <BasicTable
              headerColor="midnightblue"
              headers={headers}
              content={tableData}
              setTableData={setTableData}
            />
          </div>
          <div style={{float: "right"}}>
            {!editingFlag && <BasicButton
                displayName={"新規登録"}
                onClick={() => {
                  setIbUserInput(new IbUserInput())
                  setEditingFlag(true)
                }}
            />}
          </div>
        </div>
        {/* 初期表示・新規登録 */}
        {editingFlag &&
            <div style={{width: '60%', height: '100%', padding: '20px 30px 20px 30px'}}>
                <div className='row my-2 form-control'>
                    <Typography variant="h5" sx={{width: '100%', marginBottom: '5px'}} align="left">
                      {'新規登録'}
                    </Typography>
                    <div className="row">
                        <div className="my-2 col">
                            <BasicTextField
                                label="氏"
                                fullwidth
                                size="small"
                                setValue={(value) => setIbUserInput({...ibUserInput, familyName: value})}
                                value={ibUserInput.familyName}
                            />
                        </div>
                        <div className="my-2 col">
                            <BasicTextField
                                label="名"
                                fullwidth
                                size="small"
                                setValue={(value) => setIbUserInput({...ibUserInput, givenName: value})}
                                value={ibUserInput.givenName}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="my-2 col">
                            <BasicTextField
                                label="氏(ふりがな)"
                                fullwidth
                                size="small"
                                setValue={(value) => setIbUserInput({
                                  ...ibUserInput,
                                  familyNameHurigana: value
                                })}
                                value={ibUserInput.familyNameHurigana}
                            />
                        </div>
                        <div className="my-2 col">
                            <BasicTextField
                                label="名(ふりがな)"
                                fullwidth
                                size="small"
                                setValue={(value) => setIbUserInput({...ibUserInput, givenNameHurigana: value})}
                                value={ibUserInput.givenNameHurigana}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="my-2 col">
                            <BasicTextField
                                label={"メールアドレス"}
                                fullwidth
                                size="small"
                                setValue={(value) => setIbUserInput({...ibUserInput, email: value})}
                                value={ibUserInput.email}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="my-2 col">
                            <BasicTextField
                                label={"パスワード"}
                                fullwidth
                                size="small"
                                setValue={(value) => setIbUserInput({...ibUserInput, password: value})}
                                value={ibUserInput.password}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="my-2 col">
                        <InputLabel id="ibRole">役割</InputLabel>
                            <Select
                              defaultValue={ibUserInput.ibRole.value}
                              fullWidth
                              labelId="ibRole"
                              label={"役割"}
                              onChange={(e) => setIbUserInput({...ibUserInput, ibRole: {value: e.target.value, label:foundIbUserRoleLabel(e.target.value) }})}>
                              {ibUserRoles.map((role) => (
                                <MenuItem key={role.value} value={role.value}>
                                  {role.label}
                                </MenuItem>
                              )
                              )}
                            </Select>
                        </div>
                    </div>
                    <div className="row">
                        <div className="my-2 col" style={{textAlign: 'left'}}>
                            <BasicCheckbox
                                label={'ログインロック'}
                                checked={ibUserInput.locked}
                                onChange={(event) => {
                                  setIbUserInput({...ibUserInput, locked: event.target.checked})
                                }}
                            />
                        </div>
                    </div>

                    <hr className="my-2"/>
                    <div className="container-fluid">
                        <div className="row align-items-center">
                            <div className="col">
                                <BasicCheckbox
                                    label={'システム管理'}
                                    checked={selectedSomeRole(SYSTEM_MANAGEMENT_ROLE)}
                                    onChange={(e: any, checked) => updateRoles(SYSTEM_MANAGEMENT_ROLE, checked)}
                                />
                            </div>
                        </div>
                        <div className="row align-items-center">
                            <div className="offset-1 col">
                                <BasicCheckbox
                                    label={'利用者管理'}
                                    checked={selectedAllRole(IB_USER_ROLE)}
                                    onChange={(e: any, checked) => updateRoles(IB_USER_ROLE, checked)}
                                />
                            </div>
                        </div>
                        <div className="row align-items-center">
                            <div className="offset-1 col">
                                <BasicCheckbox
                                    label={'ログ閲覧'}
                                    checked={selectedAllRole(LOG_ROLE)}
                                    onChange={(e: any, checked) => updateRoles(LOG_ROLE, checked)}
                                />
                            </div>
                        </div>
                        <div className="row align-items-center">
                            <div className="offset-1 col">
                                <BasicCheckbox
                                    label={'規定値管理'}
                                    checked={selectedAllRole(MASTER_ROLE)}
                                    onChange={(e: any, checked) => updateRoles(MASTER_ROLE, checked)}
                                />
                            </div>
                        </div>
                        <div className="row align-items-center">
                            <div className="offset-1 col">
                                <BasicCheckbox
                                    label={'帳票'}
                                    checked={selectedAllRole(REPORT_ROLE)}
                                    onChange={(e: any, checked) => updateRoles(REPORT_ROLE, checked)}
                                />
                            </div>
                        </div>

                        <hr className="my-2"/>
                        <div className="row align-items-center">
                            <div className="col">
                                <BasicCheckbox
                                    label={'企業データベース'}
                                    checked={selectedSomeRole(COMPANY_DATABASE_ROLE)}
                                    onChange={(e: any, checked) => updateRoles(COMPANY_DATABASE_ROLE, checked)}
                                />
                            </div>
                        </div>
                        <div className="row align-items-center">
                            <div className="offset-1 col">
                                <BasicCheckbox
                                    label={'企業取り込み'}
                                    checked={selectedAllRole(IMPORT_ROLE)}
                                    onChange={(e: any, checked) => updateRoles(IMPORT_ROLE, checked)}
                                />
                            </div>
                        </div>
                        <div className="row align-items-center">
                            <div className="offset-1 col">
                                <BasicCheckbox
                                    label={'リスト作成'}
                                    checked={selectedAllRole(LIST_ROLE)}
                                    onChange={(e: any, checked) => updateRoles(LIST_ROLE, checked)}
                                />
                            </div>
                        </div>
                        <div className="row align-items-center">
                            <div className="offset-1 col">
                                <BasicCheckbox
                                    label={'全体禁止'}
                                    checked={selectedAllRole(WHOLE_PROHIBITION_ROLE)}
                                    onChange={(e: any, checked) => updateRoles(WHOLE_PROHIBITION_ROLE, checked)}
                                />
                            </div>
                        </div>

                        <hr className="my-2"/>
                        <div className="row align-items-center">
                            <div className="col">
                                <BasicCheckbox
                                    label={'顧客管理'}
                                    checked={selectedSomeRole(CLIENT_MANAGEMENT_PARENT_ROLE)}
                                    onChange={(e: any, checked) => updateRoles(CLIENT_MANAGEMENT_PARENT_ROLE, checked)}
                                />
                            </div>
                        </div>
                        <div className="row align-items-center">
                            <div className="offset-1 col">
                                <BasicCheckbox
                                    label={'顧客情報閲覧'}
                                    checked={selectedAllRole(CLIENT_ROLE)}
                                    onChange={(e: any, checked) => updateRoles(CLIENT_ROLE, checked)}
                                />
                            </div>
                        </div>
                        <div className="row align-items-center">
                            <div className="offset-1 col">
                                <BasicCheckbox
                                    label={'顧客登録・修正'}
                                    checked={selectedAllRole(CLIENT_MANAGEMENT_ROLE)}
                                    onChange={(e: any, checked) => updateRoles(CLIENT_MANAGEMENT_ROLE, checked)}
                                />
                            </div>
                        </div>
                        <div className="row align-items-center">
                            <div className="offset-1 col">
                                <BasicCheckbox
                                    label={'案件情報'}
                                    checked={selectedAllRole(SUBJECT_ROLE)}
                                    onChange={(e: any, checked) => updateRoles(SUBJECT_ROLE, checked)}
                                />
                            </div>
                        </div>
                        <div className="row align-items-center">
                            <div className="offset-1 col">
                                <BasicCheckbox
                                    label={'顧客別禁止管理'}
                                    checked={selectedAllRole(CLIENT_PROHIBITION_ROLE)}
                                    onChange={(e: any, checked) => updateRoles(CLIENT_PROHIBITION_ROLE, checked)}
                                />
                            </div>
                        </div>
                        <div className="row align-items-center">
                            <div className="offset-1 col">
                                <BasicCheckbox
                                    label={'発注'}
                                    checked={selectedAllRole(ORDER_ROLE)}
                                    onChange={(e: any, checked) => updateRoles(ORDER_ROLE, checked)}
                                />
                            </div>
                        </div>
                        <div className="row align-items-center">
                            <div className="offset-1 col">
                                <BasicCheckbox
                                    label={'結果変換'}
                                    checked={selectedAllRole(RESULT_CHANGE_ROLE)}
                                    onChange={(e: any, checked) => updateRoles(RESULT_CHANGE_ROLE, checked)}
                                />
                            </div>
                        </div>

                        <hr className="my-2"/>
                        <div className="row align-items-center">
                            <div className="col">
                                <BasicCheckbox
                                    label={'施設管理'}
                                    checked={selectedSomeRole(SUBCONTRACTOR_MANAGEMENT_ROLE)}
                                    onChange={(e: any, checked) => updateRoles(SUBCONTRACTOR_MANAGEMENT_ROLE, checked)}
                                />
                            </div>
                        </div>
                        <div className="row align-items-center">
                            <div className="offset-1 col">
                                <BasicCheckbox
                                    label={'施設管理'}
                                    checked={selectedSomeRole(SUBCONTRACTOR_ROLE)}
                                    onChange={(e: any, checked) => updateRoles(SUBCONTRACTOR_ROLE, checked)}
                                />
                            </div>
                        </div>
                        <div className="row align-items-center">
                            <div className="offset-1 col">
                                <BasicCheckbox
                                    label={'受発注管理'}
                                    checked={selectedSomeRole(SUBCONTRACTOR_ORDER_ROLE)}
                                    onChange={(e: any, checked) => updateRoles(SUBCONTRACTOR_ORDER_ROLE, checked)}
                                />
                            </div>
                        </div>

                    </div>

                    <div className="row my-2 text-center">
                        <BasicButton
                            fullWidth
                            width={window.innerWidth * 0.6 * 0.5}
                            displayName="登録"
                            onClick={registration}
                        />
                    </div>
                </div>
            </div>
        }

        {/* ユーザー選択後 */}
        {!editingFlag && <div style={{width: '60%', height: '100%', padding: '20px 30px 20px 30px'}}>
            <div className='row my-2 form-control'>
                <div className="d-flex">
                    <div className="m-2">
                        <Typography variant="h4" style={{width: '100%'}} align="left">
                          {ibUserInput.familyName} {ibUserInput.givenName}
                        </Typography>
                    </div>
                </div>
                <div>
                    <BasicTable
                        headers={[]}
                        content={[
                          {
                            content: [
                              {name: 'メールアドレス', align: 'left', isNowrap: true, type: 'TEXT', width: 120},
                              {name: ':', align: 'left', isNowrap: true, type: 'TEXT', width: 20},
                              {name: ibUserInput.email, align: 'left', isNowrap: true, type: 'TEXT'},
                            ]
                          },
                          {
                            content: [
                              {name: 'パスワード', align: 'left', isNowrap: true, type: 'TEXT', width: 120},
                              {name: ':', align: 'left', isNowrap: true, type: 'TEXT', width: 20},
                              {name: '**************', align: 'left', isNowrap: true, type: 'TEXT'},
                            ]
                          },
                          {
                            content: [
                              {name: '役割', align: 'left', isNowrap: true, type: 'TEXT', width: 120},
                              {name: ':', align: 'left', isNowrap: true, type: 'TEXT', width: 20},
                              {name: ibUserInput.ibRole.label, align: 'left', isNowrap: true, type: 'TEXT'},
                            ]
                          },
                          {
                            content: [
                              {name: 'ログインロック', align: 'left', isNowrap: true, type: 'TEXT', width: 120},
                              {name: ':', align: 'left', isNowrap: true, type: 'TEXT', width: 20},
                              {name: ibUserInput.locked ? '有効' : '無効', align: 'left', isNowrap: true, type: 'TEXT'},
                            ]
                          },
                          {
                            content: [
                              { name: '権限', align: 'left', isNowrap: true, type: 'TEXT', width: 120, topPlacement: true },
                              { name: '', align: 'left', isNowrap: true, type: 'TEXT', width: 30 },
                              { name: renderAuthority(), align: 'left', isNowrap: true, type: 'TEXT' },
                            ]
                          },
                        ]}
                        setTableData={() => null}
                    />
                </div>
            </div>
            <div style={{float: "right"}}>
              {/* button */}
                <BasicButton
                    displayName="編集"
                    onClick={() => setEditingFlag(true)}
                />
            </div>
        </div>}
      </div>
    </div>
  )
}