import {OutsourceHeader} from "../../../components/header/outsourceHeader";
import Typography from "@mui/material/Typography";
import React, {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import BasicTable, {Row} from "../../../components/table/basicTable";
import BasicTextField from "../../../components/text/basicTextField";
import BasicButton from "../../../components/button/basicButton";
import HttpData from "../../../global/http/HttpData";
import HttpRequest from "../../../global/http/HttpRequest";
import {
  AssignmentRequest,
  LocationDetail,
  LocationDetailRequest,
  LocationListResponse,
  OperationType,
  OutsourceLocation
} from "../../../@types/outsource/subjectManagementType";
import {NormalResponse} from "../../../@types/common";
import {useDispatch} from "react-redux";
import {showMessage} from "../../../global/store/snackbar";

// 案件管理 -> 詳細 -> 拠点割り当て
export default function LocationAssignmentPage() {
  const dispatch = useDispatch()
  const nav = useNavigate()
  const location = useLocation()
  const subjectId = location.state?.subjectId
  const subjectName = location.state?.subjectName
  const orderId = location.state?.orderId

  const [bases, setBases] = useState<OutsourceLocation[]>([])
  const [baseTableData, setBaseTableData] = useState<Row[]>([])
  const [assigned, setAssigned] = useState<string>('');

  const [baseDetail, setBaseDetail] = useState<LocationDetail>()
  const [baseDetailTableData, setBaseDetailTableData] = useState<Row[]>([])

  const [assign, setAssign] = useState<number>(0)
  const [notAssign, setNotAssign] = useState<number>(0)

  const gotoPage = (url: string, param: Record<string, any>) => {
    nav(url, { state: param })
  }

  useEffect(() => {
    fetchData()
  }, [])

  useEffect(() => {
    const temp: Row[] = bases.map(m => {
      return {
        content: [
          {
            id: m.locationId,
            name: <div style={{ cursor: 'pointer' }} onClick={() => fetchBaseData(m.locationId, m.locationName)}>{m.locationName}</div>,
            align: 'left', isNowrap: true, type: 'TEXT'
          }
        ]
      }
    })
    setBaseTableData(temp)
  }, [bases])

  useEffect(() => {
    const temp: Row[] = [
      {
        content: [
          { name: '利用者数', align: 'left', isNowrap: true, type: 'TEXT', width: 100 },
          { name: '：', align: 'left', isNowrap: true, type: 'TEXT', width: 10 },
          { name: baseDetail?.userCount ?? '', align: 'left', isNowrap: true, type: 'TEXT' },
        ]
      },
      {
        content: [
          { name: '今月の完了数', align: 'left', isNowrap: true, type: 'TEXT', width: 100 },
          { name: '：', align: 'left', isNowrap: true, type: 'TEXT', width: 10 },
          { name: baseDetail?.completedCount ?? '', align: 'left', isNowrap: true, type: 'TEXT' },
        ]
      },
      {
        content: [
          { name: '今月の未完了数', align: 'left', isNowrap: true, type: 'TEXT', width: 100 },
          { name: '：', align: 'left', isNowrap: true, type: 'TEXT', width: 10 },
          { name: baseDetail?.uncompletedCount ?? '', align: 'left', isNowrap: true, type: 'TEXT' },
        ]
      },
      {
        content: [
          { name: 'メモ', align: 'left', isNowrap: true, type: 'TEXT', width: 100 },
          { name: '：', align: 'left', isNowrap: true, type: 'TEXT', width: 10 },
          { name: <div style={{ maxWidth: '45vh', overflowWrap: 'break-word' }}>{baseDetail?.memo ?? ''}</div>, align: 'left', type: 'TEXT' },
        ]
      },
    ]
    setBaseDetailTableData(temp)
  }, [baseDetail])

  const fetchData = async () => {
    const http = new HttpRequest<HttpData, LocationListResponse>()
    if (await http.get(`/subject/locations?order_id=${orderId}`)) {
      setBases(http.response!.locations)
      setAssigned(http.response!.assigned)
    }
  }

  const fetchBaseData = async (baseId: string, baseName: string) => {
    const http = new HttpRequest<LocationDetailRequest, LocationDetail>()
    http.request = {
      location_id: baseId
    }
    if (await http.get('/subject/location/detail')) {
      setBaseDetail(http.response!)
    }
  }

  const assignLocation = async () => {
    if (!baseDetail) {
      return
    }
    const http = new HttpRequest<AssignmentRequest, NormalResponse>()
    http.request = {
      subjectId: subjectId,
      operationId: baseDetail!.locationId,
      operationType: OperationType.LOCATION,
      qty: assign,
      orderId: orderId,
    }
    if (await http.post('/subject/assign')) {
      dispatch(showMessage({
        message: http.response!.message,
        severity: 'success',
        duration: 3000,
      }))
      await fetchBaseData(baseDetail!.locationId, baseDetail!.locationName)
    }
  }

  const unaasignLocation = async () => {
    if (!baseDetail) {
      return
    }
    const http = new HttpRequest<AssignmentRequest, NormalResponse>()
    http.request = {
      subjectId: subjectId,
      operationId: baseDetail!.locationId,
      operationType: OperationType.LOCATION,
      qty: notAssign,
      orderId: orderId,
    }
    if (await http.post('/subject/unassign')) {
      dispatch(showMessage({
        message: http.response!.message,
        severity: 'success',
        duration: 3000,
      }))
      await fetchBaseData(baseDetail!.locationId, baseDetail!.locationName)
    }
  }

  return (
    <div>
      <div>
        <OutsourceHeader />
      </div>

      {/* パンくずリスト */}
      <div className="d-flex mx-5 align-items-end" style={{ margin: '50px 50px 0px 50px' }}>
        <div className="d-flex">
          <div onClick={() => nav('/outsource/subject_management')}>
            <Typography
              fontWeight={'bold'}
              fontSize={'large'}
              style={{ color: 'gray', cursor: 'pointer' }}
            >
              {'案件管理'}
            </Typography>
          </div>

          <div style={{ width: '20px' }}></div>
          <ArrowForwardIcon />
          <div style={{ width: '20px' }}></div>

          <div onClick={() => nav(-1)}>
            <Typography
              fontWeight={'bold'}
              fontSize={'large'}
              style={{ color: 'gray', cursor: 'pointer' }}
            >
              {'詳細'}
            </Typography>
          </div>

          <div style={{ width: '20px' }}></div>
          <ArrowForwardIcon />
          <div style={{ width: '20px' }}></div>

          <Typography
            fontWeight={'bold'}
            fontSize={'large'}>
            {'拠点割り当て'}
          </Typography>
        </div>
      </div>

      <div className="container mx-5">
        <div className="my-4 d-flex">
          <Typography sx={{ fontSize: 'large', fontWeight: 'bold' }}>{subjectName}</Typography>
          <Typography sx={{ fontSize: 'large', fontWeight: 'bold', marginLeft: '5%' }}>{`割り当て済み：${assigned} 件`}</Typography>
        </div>

        <div className="d-flex">
          <div style={{ width: '30%' }}>
            {/* 拠点リスト */}
            <BasicTable
              height={'65vh'}
              headers={[{ name: '拠点名', align: 'left', isNowrap: true }]}
              content={baseTableData}
            />
          </div>
          <div className="" style={{ width: '60%', marginLeft: '5%', height: '65vh' }}>
            {/* 拠点詳細 */}
            <BasicTable
              headerColor={'white'}
              headers={[{ name: <Typography sx={{ color: 'black', fontWeight: 'bold' }}>{baseDetail?.locationName ?? '拠点名'}</Typography>, align: 'left', isNowrap: true, type: 'TEXT' }]}
              content={baseDetailTableData}
            />
            <div className="">
              <div className="d-flex my-4">
                <div>
                  <BasicTextField label={'この拠点に割り当てる'} value={assign.toString()} setValue={setAssign} type={'number'} />
                </div>
                <div style={{ display: 'flex', alignItems: 'center', marginLeft: '5%' }}>
                  <BasicButton fullWidth displayName={'保存'}
                    backColor={'white'} fontColor={'black'} hoverBackColor={'green'} hoverFontColor={'white'}
                    onClick={assignLocation}
                  />
                </div>
              </div>
              <div className="d-flex my-4">
                <div>
                  <BasicTextField label={'この拠点から割り当てを外す'} value={notAssign.toString()} setValue={setNotAssign} type={'number'} />
                </div>
                <div style={{ display: 'flex', alignItems: 'center', marginLeft: '5%' }}>
                  <BasicButton fullWidth displayName={'保存'}
                    backColor={'white'} fontColor={'black'} hoverBackColor={'green'} hoverFontColor={'white'}
                    onClick={unaasignLocation}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}