import Header from "../../../../components/header/header";
import React, { useEffect, useState } from "react";
import { Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import BasicDropdown, { Dropdown } from "../../../../components/dropdown/basicDropdown";
import BasicTextField from "../../../../components/text/basicTextField";
import HttpRequest from "../../../../global/http/HttpRequest";
import HttpData from "../../../../global/http/HttpData";
import { MailData, MailPurposeOptionsResponse } from "../../../../@types/standardManagement";
import BasicButton from "../../../../components/button/basicButton";
import useRole from "../../../../global/hook/roleHook";
import { IbRole } from "../../../../@types/roles";
import { useDispatch } from "react-redux";
import { showMessage } from "../../../../global/store/snackbar";

export default function MailManagementPage() {
  const nav = useNavigate()
  const hasRole = useRole()
  const dispath = useDispatch()

  const [usageOptions, setUsageOptions] = useState<Dropdown[]>([])
  const [usageSelected, setUsageSelected] = useState<Dropdown | null>(null)
  const [mailData, setMailData] = useState<MailData>({
    mailId: '',
    subject: '',
    text: ''
  })

  useEffect(() => {
    const f = async () => {
      const http = new HttpRequest<HttpData, MailPurposeOptionsResponse>()
      if (await http.get("/system/mail/options")) {
        const options = http.response?.mailPurposeOptions
        if (options) {
          setUsageOptions(options)
        }
      }
    }
    f()
  }, [])

  useEffect(() => {
    if (!usageSelected) return
    const f = async () => {
      const http = new HttpRequest<HttpData, MailData>()
      if (await http.get(`/system/mail/data/${usageSelected?.value}`)) {
        const data = http.response
        if (data) {
          setMailData(data)
        }
      }
    }
    f()
  }, [usageSelected]);

  const updateMailData = async () => {
    const http = new HttpRequest<MailData, HttpData>()
    http.request = mailData
    if (await http.post(`/system/mail/data/update`)) {
      dispath(showMessage({
        message: '更新しました。',
        severity: 'success',
        duration: 3000
      }))
    } else {
      dispath(showMessage({
        message: '更新に失敗しました。',
        severity: 'error',
        duration: 3000
      }))
    }
  }

  const gotoPage = (url: string) => {
    nav(url);
  };

  return (
    <div>
      <div>
        <Header />
      </div>

      {/* パンくずリスト */}
      <div className="d-flex" style={{ margin: '50px 50px 0px 50px' }}>
        <div onClick={() => gotoPage('/system_management')}>
          <Typography fontWeight={'bold'} fontSize={'large'} style={{ color: 'gray', cursor: 'pointer' }}>
            {'システム管理'}
          </Typography>
        </div>
        <div style={{ width: '20px' }}></div>
        <ArrowForwardIcon />
        <div style={{ width: '20px' }}></div>
        <div onClick={() => gotoPage('/system_management/StandardManagementPage')}>
          <Typography fontWeight={'bold'} fontSize={'large'} style={{ color: 'gray', cursor: 'pointer' }}>
            {'規定値管理'}
          </Typography>
        </div>
        <div style={{ width: '20px' }}></div>
        <ArrowForwardIcon />
        <div style={{ width: '20px' }}></div>
        <Typography fontWeight={'bold'} fontSize={'large'}>{'メール'}</Typography>
      </div>
      {/* パンくずリスト */}

      <div className="container mx-5">
        <div className="my-3">
          <div className="col-3">
            <BasicDropdown
              fullWidth={true}
              titleName="用途"
              value={usageSelected}
              items={usageOptions}
              onChange={(value: Dropdown) => {
                const selected = usageOptions.find(v => v.value === value.value)
                if (selected) {
                  setUsageSelected(selected)
                }
              }}
            />
          </div>
        </div>
        <div className="my-3">
          <div className="col">
            <BasicTextField
              fullwidth={true}
              label="件名"
              value={mailData.subject}
              setValue={(value) => {
                setMailData({ ...mailData, subject: value })
              }}
            />
          </div>
        </div>
        <div className="my-3">
          <div className="col">
            <BasicTextField
              fullwidth={true}
              label="本文"
              value={mailData.text}
              multiline={true}
              rows={20}
              setValue={(value) => {
                setMailData({ ...mailData, text: value })
              }}
            />
          </div>
        </div>
        {hasRole([IbRole.MASTER_UPDATE]) &&
          <div className="row">
            <div className="offset-9 col-3">
              <BasicButton
                fullWidth={true}
                displayName="変更を保存"
                onClick={updateMailData}
              />
            </div>
          </div>
        }
      </div>
    </div>
  )
}