import { Typography } from "@mui/material";
import BasicButton from "../../components/button/basicButton";
import { OutsourceHeader } from "../../components/header/outsourceHeader";
import BasicTable, { Row, Title } from "../../components/table/basicTable";
import { useEffect, useState } from "react";
import BasicTextField from "../../components/text/basicTextField";
import { OutsourceLocation, OutsourceLocationDeleteResponse, OutsourceLocationInput, OutsourceLocationInputRequest, OutsourceLocationListRequest, OutsourceLocationListResponse, OutsourceLocationRequest } from "../../@types/outsourceLocation";
import HttpRequest from "../../global/http/HttpRequest";
import HttpData from "../../global/http/HttpData";
import { showMessage } from "../../global/store/snackbar";
import { OutsourceRole } from "../../@types/roles";
import useRole from "../../global/hook/roleHook";
import { useDispatch } from "react-redux/es/hooks/useDispatch";


export default function OutsourceUserManagementPage() {
  const hasRole = useRole()
  const dispatch = useDispatch();

  const headers: Title[] = [
    { name: '施設名', align: 'left' },
  ];

  const managerHeader: Title[] = [
    { name: '担当者名', align: 'left' },
  ];
  
  const [editingFlag, setEditingFlag] = useState<boolean>(true);
  const [tableData, setTableData] = useState<Row[]>([]);
  const [outsourceLocationInput, setOutsourceLocationInput] = useState<OutsourceLocationInput>(new OutsourceLocationInput());
  const [locationManagerList, setLocationManagerList] = useState<Row[]>([]);
  const [dispStatus, setDispStatus] = useState<string>("新規登録");

  useEffect(() => {
    getLocationList();
  }, []);

  /** 
   * 拠点情報一覧取得
  */
  const getLocationList = async () => {
    const locationListHttp = new HttpRequest<OutsourceLocationListRequest, OutsourceLocationListResponse>();

    if (await locationListHttp.get('/outsource/location/get_list')) {
      const response = locationListHttp.response!

      // テーブルの初期データを設定
      setTableData(response.outsourceLocationNames.map((p) => ({
        content: [
          { name: <div onClick={() => handleOutsourceUser(p.locationId!)} style={{ width: '100%', textAlign: 'left' }}>{p.locationName}</div>, align: 'left', type: 'TEXT' },
        ]
      })));
    }
  }
  
  /**
   * 拠点詳細情報 取得
   */
  const handleOutsourceUser = async(locationId: string) => {
    outsourceLocationInput.locationManagerList = []
    // 選択した拠点のIDでDBを検索
    const locationHttp = new HttpRequest<OutsourceLocationRequest, OutsourceLocation>()
    locationHttp.request = {
      location_id: locationId,
    }

    // 選択したセルのデータを入力フォームに反映
    if (await locationHttp.get('/outsource/location/get_location_data')) {
      const response = locationHttp.response!

      const locaiton = {
        locationId: response.locationId,
        locationName: response.locationName,
        locationAddress: response.locationAddress,
        locationManagerList: response.locationManagerList,
      }
      setManager(response.locationManagerList)
      setOutsourceLocationInput({...outsourceLocationInput, ...locaiton})
      handleLocationManager()
      setEditingFlag(false)
    }
    return;
  }

  /**
   * 拠点情報を使用しているユーザーを右画面のリストに設定
   * @param managerList 
   */
  const setManager = (managerList: string[]) => {
    managerList.map((p:string) => {
      outsourceLocationInput.locationManagerList.push(p)
    })
  }

  /**
   * 新規登録用の入力フォームを設定
   */
  const newLocation = () => {
    setDispStatus("新規登録");
    setOutsourceLocationInput(new OutsourceLocationInput());

    console.log(outsourceLocationInput.locationId)

    setEditingFlag(true);
  }
  /**
   * 拠点担当者一覧 セット
   */
  const handleLocationManager = () => {
    if( outsourceLocationInput.locationManagerList ){
      const managerList:Row[] = outsourceLocationInput.locationManagerList.map((p) => ({
        content:[{name: p, align: 'left', type: 'TEXT'}]
      }))
      setLocationManagerList(managerList)
    }
  }
  /**
   * 拠点情報登録
   */
  const registration = async () => {
    const locationInputHttp = new HttpRequest<OutsourceLocationInputRequest, HttpData>();
    locationInputHttp.request = outsourceLocationInput
    if( await locationInputHttp.post('/outsource/location/registration_location')){
      dispatch(showMessage({
        message: '登録が完了しました。',
        severity: "success",
        duration: 3000,
      }))
      await getLocationList()
      newLocation();
    }
    return;
  }

  /**
   * 拠点情報削除(論理削除)
   * @returns 
   */
  const deleteLocation = async () => {
    const id = outsourceLocationInput.locationId!
    const locationHttp = new HttpRequest<OutsourceLocationRequest, OutsourceLocationDeleteResponse>();
    locationHttp.request = {
      location_id: id,
    }
    
    if( await locationHttp.post('/outsource/location/delete_location') ){
      const res = locationHttp.response!
      if( res.result ){
        dispatch(showMessage({
          message: '削除が完了しました。',
          severity: "success",
          duration: 3000,
        }))
        await getLocationList();
        newLocation();
      }else{
        dispatch(showMessage({
          message: '対象のデータは使用されているため、削除できません。',
          severity: "error",
          duration: 3000,
        }))
      }
    }
  }

  return(
    <div>
      <div>
        <OutsourceHeader />
      </div>
      <div>
        <div className="d-flex" style={{ margin: '50px 50px 0px 50px' }}>
          <div onClick={() => null}>
            <Typography variant="h3" >
              {'拠点管理'}
            </Typography>
          </div>
        </div>
      </div>
      {/*  */}
      <div className="d-flex">
        <div style={{ width: '40%', borderRadius: '4px', padding: '20px 30px 20px 30px' }}>
          <div className='my-2 form-control' >
            <BasicTable
              headerColor="midnightblue"
              headers={headers}
              content={tableData}
              setTableData={setTableData}
            />
          </div>
          <div style={{ float: "right" }}>
            {!editingFlag && hasRole([OutsourceRole.BASE_CREATE]) && <BasicButton
              displayName={"新規登録"}
              onClick={newLocation}
            />}
          </div>
        </div>
      
        {/* 初期表示・新規登録 */}
        {editingFlag && (
          <div style={{ width: '60%', height: '100%', padding: '20px 30px 20px 30px' }}>
            <div className='row my-2 form-control'>
              <Typography variant="h5" sx={{ width: '100%', marginBottom: '5px' }} align="left">
                {dispStatus}
              </Typography>
              <div className="my-2 col">
                <BasicTextField
                  label="拠点名"
                  fullwidth
                  size="small"
                  setValue={(value) => setOutsourceLocationInput({ ...outsourceLocationInput, locationName: value })}
                  value={outsourceLocationInput.locationName}
                />
              </div>
              <div className="my-2 col">
                <BasicTextField
                  label="住所"
                  fullwidth
                  size="small"
                  setValue={(value) => setOutsourceLocationInput({ ...outsourceLocationInput, locationAddress: value })}
                  value={outsourceLocationInput.locationAddress}
                />
              </div>
            </div>
            <div className="row my-2 text-center">
              {hasRole([OutsourceRole.BASE_CREATE, OutsourceRole.BASE_UPDATE]) && <BasicButton
                fullWidth
                width={window.innerWidth * 0.6 * 0.5}
                displayName="登録"
                onClick={registration}
              />}
            </div>
          </div>
        )}
        {/* 拠点情報表示時 */}
        {!editingFlag && (
          <div style={{ width: '60%', height: '100%', padding: '20px 30px 20px 30px' }}>
            <div className='row my-2 form-control'>
              <div className="d-flex">
                <div className="m-2">
                  <Typography variant="h3" style={{ width: '100%' }} align="left">
                    {outsourceLocationInput.locationName}
                  </Typography>
                </div>
                {hasRole([OutsourceRole.BASE_DELETE]) && <BasicButton
                  backColor="white"
                  fontColor="black"
                  displayName="削除"
                  onClick={deleteLocation}
                />}
              </div>
              <div className="m-2">
                <Typography variant="h4" style={{ width: '100%' }} align="left">
                  {"住所："}{outsourceLocationInput.locationAddress}
                </Typography>
              </div>
              <hr className="my-2" />
              <BasicTable
                headerColor="midnightblue"
                headers={managerHeader}
                content={locationManagerList}
                setTableData={handleLocationManager}
              />
            </div>
            <div className="row my-2 text-center">
              {hasRole([OutsourceRole.BASE_UPDATE]) && <BasicButton
                fullWidth
                width={window.innerWidth * 0.6 * 0.5}
                displayName="編集"
                onClick={() => {setEditingFlag(true);setDispStatus("編集");console.log(outsourceLocationInput.locationId)}}
              />}
            </div>
          </div>
        )} 
      </div>
    </div>
  )
}
