import Header from "../../../components/header/header";
import { Typography } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useNavigate, useLocation } from "react-router-dom";
import BasicTabs from "../../../components/tab/basicTab";
import BasicButton from "../../../components/button/basicButton";
import { useEffect, useState } from "react";
import BasicTable, { Row, Title } from "../../../components/table/basicTable";
import LinkOffIcon from '@mui/icons-material/LinkOff';
import AddIcon from '@mui/icons-material/Add';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import NavigationAccordion from "../../../components/accordion/navigationAccordion";
import ClientRequest from "../../../global/http/ClientRequest";
import ConversionDropdown from "../../../components/dropdown/conversionDropdown";
import {
  ConversionRequest,
  ConversionResponse,
  Conversions,
  ConversionSaveRequest
} from "../../../@types/ib/clientManagement/ConversionType";
import HttpRequest from "../../../global/http/HttpRequest";
import {useDispatch} from "react-redux";
import {showMessage} from "../../../global/store/snackbar";
import {NormalResponse} from "../../../@types/common";
import HttpData from "../../../global/http/HttpData";
import BasicDialog from "../../../components/dialog/basicDialog";

interface InitData {
  clientName: string
  postNumber: string
  address: string
  tel: string
  city: string
  hp: string
  firstSendNum: string
  sendCompNum: string
  test: string
  selectedRepConsultant: string
  selectedFollowConsultant: string
  hearingAt: string
  memo: string
}

interface UserInfo {
  id: string
  fullName: string
  mail: string
  password: string
}

interface CompDialog {
  open: boolean,
  userInfo?: UserInfo
}

interface DeleteUserRequest extends HttpData {
  userId: string
}

interface MessageResponse extends HttpData {
  message: string
  severity: "info" | "success" | "warning" | "error"
  userInfo?: UserInfo
}

export default function ClientDetailPage() {
  const nav = useNavigate();
  const location = useLocation();
  const clientId = location.state?.clientId;
  const tabIndex = location.state?.tabIndex;
  const dispatch = useDispatch()

  const [compUserInfoDialogOpen, setCompUserInfoDialogOpen] = useState<CompDialog>({ open: false, userInfo: { id: '', fullName: '', mail: '', password: '' } });


  const gotoPage = (url: string) => {
    nav(url, { state: { clientId: clientId, clientName: initData.clientName } });
  };

  const listGotoPage = (url: string, id: string) => {
    nav(url, { state: { clientId: clientId, Id: id } });
  };

  const gotoSenderDetailPage = (url: string, id: string) => {
    nav(url, { state: { clientId: clientId, senderId: id, clientName: initData.clientName } });
  };

  const gotoDraftDetailPage = (url: string, id: string) => {
    nav(url, { state: { clientId: clientId, draftId: id, clientName: initData.clientName } });
  };

  const gotoOrderPage = (projectId: string) => {
    nav('/client_management/NewOrderPage', { state: { projectId: projectId, clientId: clientId } });
  }

  const gotoDetailPage = (id: string) => {
    nav('/client_management/ProjectInformationPage',
      { state: { clientId: clientId, projectId: id } });
  }

  const edit = () => {
    nav('/client_management/new', { state: { clientId: clientId } })
  }

  // 担当者 body
  const [tableData, setTableData] = useState<Row[]>([]);
  // state(基本情報)
  const [initData, setInitData] = useState<InitData>(
    {
      clientName: '',
      postNumber: '',
      address: '',
      tel: '',
      city: '',
      hp: '',
      firstSendNum: '',
      sendCompNum: '',
      test: '',
      selectedRepConsultant: '',
      selectedFollowConsultant: '',
      memo: '',
      hearingAt: ''
    }
  );
  // 送信者情報一覧
  const [senderInfoItems, setSenderInfoItems] = useState([]);
  // 送信原稿一覧
  const [sendManuscriptItems, setSendManuscriptItems] = useState([]);
  // リスト一覧
  const [listItems, setListItems] = useState([]);
  // リスト一覧(temp)
  let [Items, setItems] = useState([]);
  // 担当者リスト header
  const headers: Title[] = [
    { name: '名前', align: 'left' },
    { name: 'なまえ', align: 'left' },
    { name: 'Email', align: 'left' },
    { name: '電話', align: 'left' },
  ];
  // 案件情報リスト body
  const projectInformationHeaders: Title[] = [
    { name: 'ID', align: 'center' },
    { name: '案件名', align: 'left' },
    { name: '送信者情報', align: 'left' },
    { name: '送信原稿', align: 'left' },
    { name: 'ステータス', align: 'left' },
    { name: '進捗', align: 'left' },
    { name: '送信期間', align: 'left' },
    { name: '', align: 'center' },
    { name: '', align: 'center' },
  ];
  // 案件情報
  const [projectInformationData, setProjectInformationData] = useState<Row[]>([]);

  // 変換
  const [conversion, setConversion] = useState<Conversions>(new Conversions())

  const fetchInitData = async () => {
    const http = new HttpRequest<ConversionRequest, ConversionResponse>()
      http.request = {
        client_id: clientId
      }
      if (await http.get('/client/conversion/get')) {
        const response = http.response!
        const obj = new Conversions()
        obj.manualSuccess = response.manualSuccess
        obj.manualFailure = response.manualFailure
        obj.manualStop = response.manualStop
        obj.manualDedicatedForm = response.manualDedicatedForm
        obj.manualNoForm = response.manualNoForm
        obj.manualNotFound = response.manualNotFound
        obj.autoSuccess = response.autoSuccess
        obj.autoFailure = response.autoFailure
        setConversion(obj)
      }
  }


  const updatePassword = async (userId: string, fullName: string, mail: string) => {
    const http = new HttpRequest<DeleteUserRequest, MessageResponse>();
    http.request = {
      userId: userId
    };
    await http.post('/client/reissue/pass');
    const res = http.response;
    dispatch(showMessage({
      message: res?.message ?? '',
      severity: res?.severity ?? 'error',
      duration: 3000,
    }));
    if (res?.severity === 'success' && res.userInfo?.password) {
      const data: UserInfo = {
        id: '',
        fullName: fullName,
        mail: mail,
        password: res.userInfo.password
      }
      setCompUserInfoDialogOpen({ open: true, userInfo: data });
    }
    fetchInitData();
  };

  useEffect(() => {
    fetchInitData()
  }, [])

  useEffect(() => {
    const getRowColor = (status: number) => {
      switch (status) {
        case 0:
          return 'lightcyan'
        case 1:
          return 'gainsboro'
        case 2:
          return 'lightgoldenrodyellow'
        default:
          return 'white'
      }
    }
    const fetchData = async () => {
      console.log('clientId', clientId)
      const ClientItems = new ClientRequest();
      ClientItems.addStr('client_id', clientId);
      const response = await ClientItems.upload('/client/details');
      const client = response['client_details'];
      setInitData(client)

      setTableData(response.client_users.map((p: any) => ({
        content: [
          { name: p.name, align: 'left', type: 'TEXT', value: '' },
          { name: p.name_hiragana, align: 'left', type: 'TEXT', value: '' },
          { name: p.email, align: 'left', type: 'TEXT', value: '' },
          { name: p.phone_number, align: 'left', type: 'TEXT', value: '' },
          {
            id: 'regeneratePassword',
            name: <BasicButton
              fullWidth
              displayName={'パスワード再発行'}
              backColor="white" hoverBackColor="green" fontColor="black" hoverFontColor="white"
              onClick={() => updatePassword(p.id, p.name, p.email)}
            />,
            align: 'center',
            isNowrap: true,
            type: 'TEXT',
          }
        ]
      })));

      setProjectInformationData(response.subject_list.map((p: any) => ({
        content: [
          { name: '11', align: 'center', type: 'TEXT', value: '' },
          { name: p.name, align: 'left', type: 'TEXT', value: '' },
          { name: p.title, align: 'left', type: 'TEXT', value: '' },
          { name: p.draft_name, align: 'left', type: 'TEXT', value: '' },
          { name: p.status, align: 'center', type: 'TEXT', value: '' },
          { name: `${p.sended_num}/` + p.item_count, align: 'left', type: 'TEXT', value: '' },
          { name: p.start_date + '~' + p.end_date, align: 'left', type: 'TEXT', value: '' },
          { name: <BasicButton displayName={'詳細'} onClick={() => gotoDetailPage(p.id)} />, align: 'center', type: 'TEXT', value: '' },
          { name: p.extracted == 1
              ? <BasicButton displayName={'発注'} onClick={() => gotoOrderPage(p.id)} />
              : <></>, align: 'center', type: 'TEXT', value: '' },
        ],
        rowColor: getRowColor(p.order_status)
      })));

      Items = response.client_list.map((p: any) => (
        { url: '/client_management/ClientListDetailPage', id: p.id, name: p.name }
      ));
      setListItems(Items)

      Items = response.client_sender.map((p: any) => (
        { url: '/client_management/SenderDetailPage', id: p.id, name: p.name }
      ));
      setSenderInfoItems(Items)

      Items = response.client_draft.map((p: any) => (
        { url: '/client_management/SubmissionDraftDetailPage', id: p.id, name: p.name }
      ));
      setSendManuscriptItems(Items)
    }
    fetchData();
  }, []);

  const saveConversions = async () => {
    const http = new HttpRequest<ConversionSaveRequest, NormalResponse>()
    http.request = {
      clientId: clientId,
      ...conversion,
    }

    if (await http.post('/client/conversion/save')) {
      dispatch(showMessage({
        message: http.response!.message,
        severity: 'success',
        duration: 3000,
      }))
    }
  }


  return (
    <div>
      <div>
        <Header />
      </div>

      {/* パンくずリスト */}
      <div>
        <div className="d-flex" style={{ margin: '50px 50px 0px 50px' }}>
          <div onClick={() => gotoPage('/client_management')}>
            <Typography
              fontWeight={'bold'}
              fontSize={'large'}
              style={{ color: 'gray', cursor: 'pointer' }}
            >
              顧客管理
            </Typography>
          </div>

          <div style={{ width: '20px' }}></div>
          <ArrowForwardIcon />
          <div style={{ width: '20px' }}></div>
          <Typography fontWeight={'bold'} fontSize={'large'}>顧客詳細</Typography>
        </div>

        {/* 顧客名 */}
        <div className="d-flex" style={{ margin: '30px 30px 0px 50px' }}>
          <Typography align="left" fontWeight={'bold'} fontSize={'large'}>{initData.clientName}</Typography>
        </div>
      </div>

      <div className={'mx-5 my-3'}>
        <BasicTabs
          tabIndex={tabIndex}
          tabItems={['顧客情報', '案件情報', '顧客別禁止', '変換']}
          tabContents={[
            // 顧客情報タブ
            <div>
              {/* 顧客情報 */}
              <div className="container">
                <BasicTable
                  contentHoverColor={'white'}
                  headers={[]}
                  content={[
                    {
                      content: [
                        { name: '郵便番号', align: 'left', isNowrap: true, type: 'TEXT', width: 60 },
                        { name: ':', align: 'left', type: 'TEXT', width: 10 },
                        { name: initData.postNumber, align: 'left', isNowrap: true, type: 'TEXT' },

                        { name: '初回送信件数', align: 'left', isNowrap: true, type: 'TEXT', width: 60 },
                        { name: ':', align: 'left', type: 'TEXT', width: 10 },
                        { name: initData.firstSendNum, align: 'left', isNowrap: true, type: 'TEXT' },
                      ]
                    },
                    {
                      content: [
                        { name: '市区町村', align: 'left', isNowrap: true, type: 'TEXT', width: 60 },
                        { name: ':', align: 'left', type: 'TEXT', width: 10 },
                        { name: initData.city, align: 'left', isNowrap: true, type: 'TEXT' },

                        { name: '送信完了件数', align: 'left', isNowrap: true, type: 'TEXT', width: 60 },
                        { name: ':', align: 'left', type: 'TEXT', width: 10 },
                        { name: initData.sendCompNum, align: 'left', isNowrap: true, type: 'TEXT' },
                      ]
                    },
                    {
                      content: [
                        { name: '住所', align: 'left', isNowrap: true, type: 'TEXT', width: 60 },
                        { name: ':', align: 'left', type: 'TEXT', width: 10 },
                        { name: initData.address, align: 'left', isNowrap: true, type: 'TEXT' },

                        { name: '担当コンサル', align: 'left', isNowrap: true, type: 'TEXT', width: 60 },
                        { name: ':', align: 'left', type: 'TEXT', width: 10 },
                        { name: initData.selectedRepConsultant, align: 'left', isNowrap: true, type: 'TEXT' },
                      ]
                    },
                    {
                      content: [
                        { name: 'TEL', align: 'left', isNowrap: true, type: 'TEXT', width: 60 },
                        { name: ':', align: 'left', type: 'TEXT', width: 10 },
                        { name: initData.tel, align: 'left', isNowrap: true, type: 'TEXT' },

                        { name: 'フォローコンサル', align: 'left', isNowrap: true, type: 'TEXT', width: 60 },
                        { name: ':', align: 'left', type: 'TEXT', width: 10 },
                        { name: initData.selectedFollowConsultant, align: 'left', isNowrap: true, type: 'TEXT' },
                      ]
                    },
                    {
                      content: [
                        { name: 'HP', align: 'left', isNowrap: true, type: 'TEXT', width: 60 },
                        { name: ':', align: 'left', type: 'TEXT', width: 10 },
                        { name: initData.hp, align: 'left', isNowrap: true, type: 'TEXT' },

                        { name: 'ヒアリング日時', align: 'left', isNowrap: true, type: 'TEXT', width: 60 },
                        { name: ':', align: 'left', type: 'TEXT', width: 10 },
                        { name: initData.hearingAt, align: 'left', isNowrap: true, type: 'TEXT' },
                      ]
                    },
                    {
                      content: [
                        { name: 'メモ', align: 'left', isNowrap: true, type: 'TEXT', width: 60 },
                        { name: ':', align: 'left', type: 'TEXT', width: 10 },
                        { name: initData.memo, align: 'left', isNowrap: true, type: 'TEXT', colspan: 4 },
                      ]
                    },
                  ]}
                />
              </div>
              <div className="my-4" style={{ marginTop: '10px' }}>
                <div><Typography>担当者</Typography></div>
                <div>
                  <BasicTable
                    headerColor="midnightblue"
                    headers={headers}
                    content={tableData}
                  />
                </div>
              </div>
              <div className="my-3" style={{ textAlign: 'end' }}>
                <BasicButton
                  displayName="編集" width={'20%'}
                  onClick={edit}
                />
              </div>
            </div>,

            // 案件情報タブ
            <div>
              <div className="container">
                <div className="row">
                  <div className="col-6">
                    <NavigationAccordion
                      btnName="送信者情報一覧"
                      contents={senderInfoItems}
                      onItemClick={gotoSenderDetailPage} />
                  </div>
                  <div className="col-3" style={{ display: 'block', margin: 'auto 0' }}>
                    <BasicButton
                      fullWidth
                      displayName="登録"
                      backColor="white" hoverBackColor="green" fontColor="black" hoverFontColor="white"
                      startIcon={<AddIcon />} onClick={() => gotoPage('/client_management/newSenderPage')} />
                  </div>
                  <div className="col-3" style={{ display: 'block', margin: 'auto 0' }}>
                    <BasicButton
                      fullWidth
                      displayName="取込"
                      backColor="white" hoverBackColor="green" fontColor="black" hoverFontColor="white"
                      startIcon={<UploadFileIcon />} onClick={() => gotoPage('/client_management/SenderInformationImportPage')} />
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <NavigationAccordion
                      btnName="送信原稿一覧"
                      contents={sendManuscriptItems}
                      onItemClick={gotoDraftDetailPage} />
                  </div>
                  <div className="col-3" style={{ display: 'block', margin: 'auto 0' }}>
                    <BasicButton
                      fullWidth
                      displayName="登録"
                      backColor="white" hoverBackColor="green" fontColor="black" hoverFontColor="white"
                      startIcon={<AddIcon />} onClick={() => gotoPage('/client_management/newSubmissionDraftPage')} />
                  </div>
                  <div className="col-3" style={{ display: 'block', margin: 'auto 0' }}>
                    <BasicButton
                      fullWidth
                      displayName="取込"
                      backColor="white" hoverBackColor="green" fontColor="black" hoverFontColor="white"
                      startIcon={<UploadFileIcon />} onClick={() => gotoPage('/client_management/SendManuscriptImportPage')} />
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <NavigationAccordion
                      btnName="リスト一覧"
                      contents={listItems}
                      onItemClick={listGotoPage} />
                  </div>
                  <div className="col-3" style={{ display: 'block', margin: 'auto 0' }}>
                    <BasicButton
                      fullWidth
                      displayName="作成"
                      backColor="white" hoverBackColor="green" fontColor="black" hoverFontColor="white"
                      startIcon={<AddIcon />} onClick={() => gotoPage('/company_database/create_list')} />
                  </div>
                  <div className="col-3" style={{ display: 'block', margin: 'auto 0' }}>
                    <BasicButton
                      fullWidth
                      displayName="確認済取込"
                      backColor="white" hoverBackColor="green" fontColor="black" hoverFontColor="white"
                      startIcon={<UploadFileIcon />} onClick={() => gotoPage('/company_database/CustomerVerifiedListImportPage')} />
                  </div>
                </div>
              </div>
              <div className="row my-5">
                <div className="col-12 text-end" style={{ marginBottom: '5px' }}>
                  <BasicButton
                    displayName="案件新規作成"
                    backColor="white" hoverBackColor="green" fontColor="black" hoverFontColor="white"
                    startIcon={<AddIcon />} onClick={() => gotoPage('/client_management/ProjectRegistrationPage')} />
                </div>
                <div>
                  <BasicTable
                    headerColor="midnightblue"
                    headers={projectInformationHeaders}
                    content={projectInformationData}
                  />
                </div>
              </div>
            </div>,

            // 顧客別禁止タブ
            <div>
              <div className="container row">
                <div className="col-6">
                  <div style={{ textAlign: 'center', margin: '10px' }}>
                    <BasicButton
                      fullWidth
                      backColor="white" hoverBackColor="green" fontColor="black" hoverFontColor="white"
                      displayName="顧客別禁止一覧" endIcon={<KeyboardArrowRight />} onClick={() => gotoPage('/client_management/ProhibitedManagementPage')} />
                  </div>
                </div>
                <div className="col-3">
                  <div style={{ textAlign: 'center', margin: '10px' }}>
                    <BasicButton
                      fullWidth
                      backColor="white" hoverBackColor="green" fontColor="black" hoverFontColor="white"
                      displayName="取込" startIcon={<AddIcon />} onClick={() => gotoPage('/client_management/ProhibitedRegistratiionByCustomerImportPage')} />
                  </div>
                </div>
                <div className="col-3">
                  <div style={{ textAlign: 'center', margin: '10px' }}>
                    <BasicButton
                      fullWidth
                      backColor="white" hoverBackColor="green" fontColor="black" hoverFontColor="white"
                      displayName="解除" startIcon={<LinkOffIcon />} onClick={() => gotoPage('/client_management/ProhibitedCancellationByCustomerImportPage')} />
                  </div>
                </div>
              </div>
            </div>,
            <div>
              <div className="container">
                <div className="row">
                  <div className="col-4">
                    <div className="row">
                      <h1>手送信結果</h1>
                    </div>
                    <div className="row mt-3">
                      <ConversionDropdown
                        value={conversion.manualSuccess}
                        setValue={(val) => val.value !== '' && setConversion({...conversion, manualSuccess: val})}
                        titleName={'送信成功'}
                        send_type={'2'}
                      />
                    </div>
                    <div className="row mt-3">
                      <ConversionDropdown
                        value={conversion.manualFailure}
                        setValue={(val) => val.value !== '' && setConversion({...conversion, manualFailure: val})}
                        titleName={'送信失敗'}
                        send_type={'2'}
                      />
                    </div>
                    <div className="row mt-3">
                      <ConversionDropdown
                        value={conversion.manualStop}
                        setValue={(val) => val.value !== '' && setConversion({...conversion, manualStop: val})}
                        titleName={'営業禁止'}
                        send_type={'2'}
                      />
                    </div>
                    <div className="row mt-3">
                      <ConversionDropdown
                        value={conversion.manualDedicatedForm}
                        setValue={(val) => val.value !== '' && setConversion({...conversion, manualDedicatedForm: val})}
                        titleName={'専用フォーム'}
                        send_type={'2'}
                      />
                    </div>
                    <div className="row mt-3">
                      <ConversionDropdown
                        value={conversion.manualNoForm}
                        setValue={(val) => val.value !== '' && setConversion({...conversion, manualNoForm: val})}
                        titleName={'フォームなし'}
                        send_type={'2'}
                      />
                    </div>
                    <div className="row mt-3">
                      <ConversionDropdown
                        value={conversion.manualNotFound}
                        setValue={(val) => val.value !== '' && setConversion({...conversion, manualNotFound: val})}
                        titleName={'URLが開かない'}
                        send_type={'2'}
                      />
                    </div>
                  </div>
                  <div className="offset-2 col-4">
                    <h1>自動送信結果</h1>
                    <div className="row mt-3">
                      <ConversionDropdown
                        value={conversion.autoSuccess}
                        setValue={(val) =>  val.value !== '' && setConversion({...conversion, autoSuccess: val})}
                        titleName={'送信成功'}
                        send_type={'1'}
                      />
                    </div>
                    <div className="row mt-3">
                      <ConversionDropdown
                        value={conversion.autoFailure}
                        // setValue={(val) => setConversion({...conversion, autoFailure: val})}
                        setValue={(val) => val.value !== '' && setConversion({...conversion, autoFailure: val})}
                        titleName={'送信失敗'}
                        send_type={'1'}
                      />
                    </div>
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="offset-4 col-3">
                    <BasicButton
                      fullWidth={true}
                      displayName={'保存'}
                      onClick={saveConversions}
                    />
                  </div>
                </div>
              </div>
            </div>
          ]
          }
        />
      </div>
      <BasicDialog
                open={compUserInfoDialogOpen.open}
                close={() => setCompUserInfoDialogOpen({ open: false })}
                title={'パスワードを再発行しました'}
                content={
                  <div>
                    <Typography>パスワードは1度しか見れませんので、ご注意ください。</Typography>
                    <BasicTable
                      headers={[
                        { name: '氏名', align: 'center', isNowrap: true, type: 'TEXT' },
                        { name: 'email', align: 'center', isNowrap: true, type: 'TEXT' },
                        { name: 'パスワード', align: 'center', isNowrap: true, type: 'TEXT' },
                      ]}
                      content={[
                        {
                          content: [
                            { name: compUserInfoDialogOpen.userInfo?.fullName ?? '', align: 'center', type: 'TEXT' },
                            { name: compUserInfoDialogOpen.userInfo?.mail ?? '', align: 'center', type: 'TEXT' },
                            { name: compUserInfoDialogOpen.userInfo?.password ?? '', align: 'center', type: 'TEXT' },
                          ]
                        }
                      ]}
                    />
                  </div>
                }
                positiveButtonName={'OK'}
                positiveAction={() => setCompUserInfoDialogOpen({ open: false, userInfo: { id: '', fullName: '', mail: '', password: '' } })}
              />
    </div>
  );
}