export enum IbRole {
    ACCOUNT_MANAGEMENT_CREATE = 'account_management_create',
    ACCOUNT_MANAGEMENT_READ = 'account_management_read',
    ACCOUNT_MANAGEMENT_UPDATE = 'account_management_update',
    ACCOUNT_MANAGEMENT_DELETE = 'account_management_delete',
    LOG_READ = 'log_read',
    MASTER_CREATE = 'master_create',
    MASTER_READ = 'master_read',
    MASTER_UPDATE = 'master_update',
    MASTER_DELETE = 'master_delete',
    TABULATION_READ = 'tabulation_read',
    COMPANY_CREATE = 'company_create',
    COMPANY_READ = 'company_read',
    COMPANY_UPDATE = 'company_update',
    COMPANY_DELETE = 'company_delete',
    LIST_CREATE = 'list_create',
    LIST_READ = 'list_read',
    LIST_UPDATE = 'list_update',
    LIST_DELETE = 'list_delete',
    WHOLE_PROHIBITION_CREATE = 'whole_prohibition_create',
    WHOLE_PROHIBITION_READ = 'whole_prohibition_read',
    WHOLE_PROHIBITION_UPDATE = 'whole_prohibition_update',
    WHOLE_PROHIBITION_DELETE = 'whole_prohibition_delete',
    CLIENT_CREATE = 'client_create',
    CLIENT_READ = 'client_read',
    CLIENT_UPDATE = 'client_update',
    CLIENT_DELETE = 'client_delete',
    MATTER_CREATE = 'matter_create',
    MATTER_READ = 'matter_read',
    MATTER_UPDATE = 'matter_update',
    MATTER_DELETE = 'matter_delete',
    CLIENT_PROHIBITION_CREATE = 'client_prohibition_create',
    CLIENT_PROHIBITION_READ = 'client_prohibition_read',
    CLIENT_PROHIBITION_UPDATE = 'client_prohibition_update',
    CLIENT_PROHIBITION_DELETE = 'client_prohibition_delete',
    ORDER_CREATE = 'order_create',
    ORDER_READ = 'order_read',
    ORDER_UPDATE = 'order_update',
    ORDER_DELETE = 'order_delete',
    RESULT_CHANGE_CREATE = 'result_change_create',
    RESULT_CHANGE_READ = 'result_change_read',
    RESULT_CHANGE_UPDATE = 'result_change_update',
    SUBCONTRACTOR_CREATE = 'subcontractor_create',
    SUBCONTRACTOR_READ = 'subcontractor_read',
    SUBCONTRACTOR_UPDATE = 'subcontractor_update',
    SUBCONTRACTOR_DELETE = 'subcontractor_delete',
    SUBCONTRACTOR_ORDER_CREATE = 'subcontractor_order_create',
    SUBCONTRACTOR_ORDER_READ = 'subcontractor_order_read',
    SUBCONTRACTOR_ORDER_UPDATE = 'subcontractor_order_update',
    SUBCONTRACTOR_ORDER_DELETE = 'subcontractor_order_delete',
}

export enum OutsourceRole {
    ALL_ACCOUNT_CREATE = 'all_account_create',
    ALL_ACCOUNT_READ = 'all_account_read',
    ALL_ACCOUNT_UPDATE = 'all_account_update',
    ALL_ACCOUNT_DELETE = 'all_account_delete',
    BASE_ACCOUNT_CREATE = 'base_account_create',
    BASE_ACCOUNT_READ = 'base_account_read',
    BASE_ACCOUNT_UPDATE = 'base_account_update',
    BASE_ACCOUNT_DELETE = 'base_account_delete',
    USER_ACCOUNT_CREATE = 'user_account_create',
    USER_ACCOUNT_READ = 'user_account_read',
    USER_ACCOUNT_UPDATE = 'user_account_update',
    USER_ACCOUNT_DELETE = 'user_account_delete',
    BASE_CREATE = 'base_create',
    BASE_READ = 'base_read',
    BASE_UPDATE = 'base_update',
    BASE_DELETE = 'base_delete',
    BASE_RATION_CREATE = 'base_ration_create',
    BASE_RATION_READ = 'base_ration_read',
    BASE_RATION_UPDATE = 'base_ration_update',
    BASE_RATION_DELETE = 'base_ration_delete',
    USER_RATION_CREATE = 'user_ration_create',
    USER_RATION_READ = 'user_ration_read',
    USER_RATION_UPDATE = 'user_ration_update',
    USER_RATION_DELETE = 'user_ration_delete',
    SELECT_ALL_CREATE = 'select_all_create',
    SELECT_ALL_READ = 'select_all_read',
    SELECT_ALL_UPDATE = 'select_all_update',
    SELECT_ALL_DELETE = 'select_all_delete',
    SELECT_BASE_CREATE = 'select_base_create',
    SELECT_BASE_READ = 'select_base_read',
    SELECT_BASE_UPDATE = 'select_base_update',
    SELECT_BASE_DELETE = 'select_base_delete',
    SELECT_USER_CREATE = 'select_user_create',
    SELECT_USER_READ = 'select_user_read',
    SELECT_USER_UPDATE = 'select_user_update',
    SELECT_USER_DELETE = 'select_user_delete',
}

export enum ClientRole {
    CLIENT_READ = 'client_read',
}

