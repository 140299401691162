import React from "react";
import { Grid, Paper, Typography, styled } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Header from "../../../components/header/header";
import GridViewMenu, { MenuItem } from "../../../components/menu/gridViewMenu";

export default function SystemManagementMenuPage() {
  const items: MenuItem[] = [
    { title: '利用者管理', gotoPageUrl: '/system_management/user_management' },
    { title: '集計（帳票）', gotoPageUrl: '/system_management/tabulation' },
    { title: 'セキュリティログ', gotoPageUrl: '/system_management/log' },
    { title: '規定値管理', gotoPageUrl: '/system_management/StandardManagementPage' },
  ];

  return (
    <div>
      <div>
        <Header />
      </div>

      <div style={{ margin: '50px 50px 0px 50px' }}>
        <Typography fontWeight={'bold'} fontSize={'large'}>システム管理</Typography>
      </div>

      {/* メニュー */}
      <div className='mx-5 my-5'>
        <GridViewMenu menuItems={items} />
      </div>
    </div>
  )
}