import HttpData from "../global/http/HttpData"
import {OutsourceRole} from "./roles";



// Request
// ユーザーデータ取得用
export interface OutsourceUserRequest extends HttpData {
  outsource_user_id: string
}
// ユーザーデータ登録、更新用
export interface OutsourceUserInputRequest extends OutsourceUserData {
}
export interface OutsourceUserListRequest extends HttpData{
  operatorLocationSid: number
}

// Response
export class OutsourceUserListResponce{
  result: string = ''
  message: string = ''
  outsourceUserNames: OutsourceUser[] = []
}
export class OutsourceUserDataResponce{
  result: string = ''
  message: string = ''
  outsourceUserDatas: OutsourceUserData[] = []
}
export class  OutsourceUserResultResponce{
  result: string = ''
  message: string = ''
}

// Model
export interface OutsourceUser{
  createUser: string
  outsourceUserName: string
  outsourceUserId: string
}
export interface OutsourceUserData extends HttpData {
  userId?: string
  userFamilyName: string
  userName: string
  userFamilyNameHira: string
  userNameHira: string
  mailAddress: string
  password: string
  phoneNumber: string
  location: string
  locked: boolean
  roles: OutsourceRole[]
  memo: string
  canDelete: boolean
  roleKey: OutsourceRoleKey
}

export class OutsourceUserInput {
  userId?: string
  userFamilyName: string = ''
  userName: string = ''
  userFamilyNameHira: string = ''
  userNameHira: string = ''
  mailAddress: string = ''
  password: string = ''
  phoneNumber: string = ''
  location: string = ''
  locked: boolean = false
  roles: OutsourceRole[] = []
  memo: string = ''
  canDelete: boolean = false
  roleKey: OutsourceRoleKey = OUTSOURCE_ROLE_KEY.RK_OUTSOURCE}

export type OutsourceRoleKey = typeof OUTSOURCE_ROLE_KEY[keyof typeof OUTSOURCE_ROLE_KEY];

export const OUTSOURCE_ROLE_KEY = {
  RK_OUTSOURCE: 'RK_OUTSOURCE',
  SUBCONSTORACTOR_USER: 'subconstoractor_user'
} as const

// 全体管理者アカウント(child)
export const ALL_ACCOUNT_ROLE = [
  OutsourceRole.ALL_ACCOUNT_CREATE,
  OutsourceRole.ALL_ACCOUNT_READ,
  OutsourceRole.ALL_ACCOUNT_UPDATE,
  OutsourceRole.ALL_ACCOUNT_DELETE
]

// 拠点管理者アカウント(child)
export const BASE_ACCOUNT_ROLE = [
  OutsourceRole.BASE_ACCOUNT_CREATE,
  OutsourceRole.BASE_ACCOUNT_READ,
  OutsourceRole.BASE_ACCOUNT_UPDATE,
  OutsourceRole.BASE_ACCOUNT_DELETE
]

// 利用者アカウント(child)
export const USER_ACCOUNT_ROLE = [
  OutsourceRole.USER_ACCOUNT_CREATE,
  OutsourceRole.USER_ACCOUNT_READ,
  OutsourceRole.USER_ACCOUNT_UPDATE,
  OutsourceRole.USER_ACCOUNT_DELETE
]

// 全体管理(parent)
export const ACCOUNT_SUPER_ROLE = [
  ...ALL_ACCOUNT_ROLE,
  ...BASE_ACCOUNT_ROLE,
  ...USER_ACCOUNT_ROLE,
]

export const ACCOUNT_ROLE = [
  ...BASE_ACCOUNT_ROLE,
  ...USER_ACCOUNT_ROLE,
]

// 拠点作成・編集(parent)
export const BASE_ROLE = [
  OutsourceRole.BASE_CREATE,
  OutsourceRole.BASE_READ,
  OutsourceRole.BASE_UPDATE,
  OutsourceRole.BASE_DELETE
]

// 拠点割り当て(child)
export const BASE_RATION_ROLE = [
  OutsourceRole.BASE_RATION_CREATE,
  OutsourceRole.BASE_RATION_READ,
  OutsourceRole.BASE_RATION_UPDATE,
  OutsourceRole.BASE_RATION_DELETE
]

// 利用者割り当て(child)
export const USER_RATION_ROLE = [
  OutsourceRole.USER_RATION_CREATE,
  OutsourceRole.USER_RATION_READ,
  OutsourceRole.USER_RATION_UPDATE,
  OutsourceRole.USER_RATION_DELETE
]

// 案件割り当て(parent)
export const SUBJECT_RATION_ROLE = [
  ...BASE_RATION_ROLE,
  ...USER_RATION_ROLE,
]


// 帳票表示全体(child)
export const REPORT_SELECT_ALL_ROLE = [
  OutsourceRole.SELECT_ALL_CREATE,
  OutsourceRole.SELECT_ALL_READ,
  OutsourceRole.SELECT_ALL_UPDATE,
  OutsourceRole.SELECT_ALL_DELETE
]

// 帳票表示拠点ごと(child)
export const REPORT_SELECT_BASE_ROLE = [
  OutsourceRole.SELECT_BASE_CREATE,
  OutsourceRole.SELECT_BASE_READ,
  OutsourceRole.SELECT_BASE_UPDATE,
  OutsourceRole.SELECT_BASE_DELETE
]

// 帳票表示利用者ごと(child)
export const REPORT_SELECT_USER_ROLE = [
  OutsourceRole.SELECT_USER_CREATE,
  OutsourceRole.SELECT_USER_READ,
  OutsourceRole.SELECT_USER_UPDATE,
  OutsourceRole.SELECT_USER_DELETE
]

// 帳票表示(parent)
export const REPORT_SELECT_SUPER_ROLE = [
  ...REPORT_SELECT_ALL_ROLE,
  ...REPORT_SELECT_BASE_ROLE,
  ...REPORT_SELECT_USER_ROLE,
]

export const REPORT_SELECT_ROLE = [
  ...REPORT_SELECT_BASE_ROLE,
  ...REPORT_SELECT_USER_ROLE,
]

