import { Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  LogDetail,
  LogSearchRequest,
  LogSearchResponse,
  LogTotalRequest,
  LogTotalResponse
} from "../../../../@types/ib/systemManagement/logType";
import Header from "../../../../components/header/header";
import HttpRequest from "../../../../global/http/HttpRequest";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import SearchTextField from "../../../../components/text/searchTextField";
import BasicTable, { Row } from "../../../../components/table/basicTable";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';

export default function LogPage() {
  const nav = useNavigate();
  const dispatch = useDispatch();

  const [searchValue, setSearchValue] = useState<string>('')
  const [logs, setLogs] = useState<LogDetail[]>([])
  const [logTableData, setLogTableData] = useState<Row[]>([])

  const [userData, setUserData] = useState<LogTotalResponse>()

  useEffect(() => {
    const f = async () => {
      const http = new HttpRequest<LogTotalRequest, LogTotalResponse>()
      http.request = {
        page: 1
      }
      if (await http.get('/system/log/total')) {
        console.log(http.response)
        setUserData(http.response)
      }
    }
    f()
  }, [])

  useEffect(() => {
    setLogTableData(logs.map((m, index) => {
      return {
        content: [
          { name: <Typography>{m.name}</Typography>, align: 'left', isNowrap: true, type: 'TEXT' },
          { name: <Typography>{m.email}</Typography>, align: 'left', isNowrap: true, type: 'TEXT' },
          {
            name: m.isSuccess ?
              <div className="d-flex">
                <CheckCircleIcon sx={{ color: 'green' }} />
                <Typography>{'ログイン成功'}</Typography>
              </div>
              :
              <div className="d-flex">
                <CancelIcon sx={{ color: 'red' }} />
                <Typography>{'ログイン失敗'}</Typography>
              </div>,
            align: 'left', isNowrap: true, type: 'TEXT'
          },
          { name: <Typography>{m.loginDate}</Typography>, align: 'left', isNowrap: true, type: 'TEXT' },
        ]
      }
    }))
  }, [logs])

  const gotoPage = (url: string) => {
    nav(url);
  };

  async function search() {
    const http = new HttpRequest<LogSearchRequest, LogSearchResponse>()
    http.request = {
      email: searchValue
    }
    if (await http.post('/system/log/search')) {
      console.log(http.response)
      setLogs(http.response!.lists)
    }
  }

  return <div>
    <div>
      <Header />
    </div>
    {/* パンくずリスト */}
    <div className="d-flex" style={{ margin: '50px 50px 0px 50px' }}>
      <div onClick={() => gotoPage('/system_management')}>
        <Typography fontWeight={'bold'} fontSize={'large'} style={{ color: 'gray', cursor: 'pointer' }}>
          {'システム管理'}
        </Typography>
      </div>

      <div style={{ width: '20px' }}></div>
      <ArrowForwardIcon />
      <div style={{ width: '20px' }}></div>

      <Typography fontWeight={'bold'} fontSize={'large'}>{'セキュリティログ'}</Typography>
    </div>

    <div className="mx-5 container">
      <div className="d-flex">
        {/* LEFT */}
        <div style={{ marginRight: '5%' }}>
          <div className="d-flex" style={{ margin: '50px 0px 0px 0px', height: '30px' }}>
            <Typography>{'ユーザー数'}</Typography>
          </div>
          <div className="my-3">
            <BasicTable
              headerColor="midnightblue"
              backgroundColor='whitesmoke'
              headers={[]}
              content={[
                {
                  content: [
                    { name: <Typography>{'IB'}</Typography>, align: 'left', isNowrap: true, type: 'TEXT', width: 60 },
                    { name: <Typography>{'：'}</Typography>, align: 'left', isNowrap: true, type: 'TEXT', width: 10 },
                    { name: <Typography>{`${userData?.ibTotal[0] || 0} (${userData?.ibTotal[1] || 0})`}</Typography>, align: 'left', isNowrap: true, type: 'TEXT', width: 60 },
                  ]
                },
                {
                  content: [
                    { name: <Typography>{'顧客'}</Typography>, align: 'left', isNowrap: true, type: 'TEXT', width: 60 },
                    { name: <Typography>{'：'}</Typography>, align: 'left', isNowrap: true, type: 'TEXT', width: 10 },
                    { name: <Typography>{`${userData?.clientTotal[0] || 0} (${userData?.clientTotal[1] || 0})`}</Typography>, align: 'left', isNowrap: true, type: 'TEXT', width: 60 },
                  ]
                },
                {
                  content: [
                    { name: <Typography>{'外注先担当者'}</Typography>, align: 'left', isNowrap: true, type: 'TEXT', width: 60 },
                    { name: <Typography>{'：'}</Typography>, align: 'left', isNowrap: true, type: 'TEXT', width: 10 },
                    { name: <Typography>{`${userData?.outsourceSuperTotal[0] || 0} (${userData?.outsourceSuperTotal[1] || 0})`}</Typography>, align: 'left', isNowrap: true, type: 'TEXT', width: 60 },
                  ]
                },
                {
                  content: [
                    { name: <Typography>{'外注先利用者'}</Typography>, align: 'left', isNowrap: true, type: 'TEXT', width: 60 },
                    { name: <Typography>{'：'}</Typography>, align: 'left', isNowrap: true, type: 'TEXT', width: 10 },
                    { name: <Typography>{`${userData?.outsourceUserTotal[0] || 0} (${userData?.outsourceUserTotal[1] || 0})`}</Typography>, align: 'left', isNowrap: true, type: 'TEXT', width: 60 },
                  ]
                },
              ]}
              setTableData={() => null}
            />
          </div>
        </div>


        {/* RIGHT */}
        <div style={{ minWidth: '750px' }}>
          {/* 検索フィールド */}
          <div className="d-flex justify-content-center" style={{ alignItems: 'center', margin: '50px 0px 0px 0px', height: '30px' }}>
            <SearchTextField label="メールアドレス" value={searchValue} setValue={setSearchValue} onClick={search} />
          </div>

          {/* ログテーブル */}
          <div className='my-3'>
            <BasicTable
              headerColor="midnightblue"
              headers={[
                // { name: '氏名', align: 'left', isNowrap: true, type: 'TEXT' },
                // { name: 'メールアドレス', align: 'left', isNowrap: true, type: 'TEXT' },
                // { name: 'ステータス', align: 'left', isNowrap: true, type: 'TEXT' },
                // { name: '日時', align: 'left', isNowrap: true, type: 'TEXT' },
              ]}
              content={logTableData}
              setTableData={setLogTableData}
            />
          </div>
        </div>
      </div>
    </div>

  </div >
}