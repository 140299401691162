import { Typography } from "@mui/material";
import Header from "../../../components/header/header";
import BasicTable, { Row, Title } from "../../../components/table/basicTable";
import {useEffect, useState} from "react";
import HttpRequest from "../../../global/http/HttpRequest";
import {ListDetailRequest, ListDetailResponse} from "../../../@types/ib/clientManagement/ExtractedListType";
import {useLocation} from "react-router-dom";
import BasicButton from "../../../components/button/basicButton";

export default function ListDetailPage() {
  const location = useLocation();
  const projectId = location.state?.projectId;

  const headers: Title[] = [
    { name: '会社名', align: 'center' },
    { name: 'ドメイン', align: 'center'},
    { name: '作業者', align: 'center'},
    { name: '送信結果', align: 'center' },
    { name: '送信日時', align: 'center' },
  ];

  const [page, setPage] = useState<number>(1)

  // sample data
  const [tableData, setTableData] = useState<Row[]>([]);

  useEffect(() => {
    fetchData(1)
  }, []);

  const fetchData = async (page: number) => {
    const http = new HttpRequest<ListDetailRequest, ListDetailResponse>()
    http.request = {
      list_id: projectId,
      page: page,
    }
    if (await http.get('/client/extract_list/details')) {
      setPage(page)
      setTableData(http.response!.data.map(m => (
        {
          content: [
            { name: m.companyName, align: 'center', width: 30, type: 'TEXT', value: '' },
            { name: m.domain, align: 'center', width: 30, type: 'TEXT', value: '' },
            { name: m.sender, align: 'center', width: 30, type: 'TEXT', value: '' },
            { name: m.result, align: 'center', width: 100, type: 'TEXT', value: '' },
            { name: m.resultAt, align: 'center', width: 30, type: 'TEXT', value: '' }
          ]
        }
      ),))
    }
  }

  const prev = async() => {
    if (page == 1) return;
    await fetchData(page - 1)
  }

  const next = async() => {
    await fetchData(page + 1)
  }

  return (
    <div>
      <div>
        <Header />
      </div>

      {/* パンくずリスト */}
      <div className="d-flex justify-content-between mx-5 align-items-end">
        <div style={{ margin: '50px 0px 0px 0px' }}>
          <Typography fontWeight={'bold'} fontSize={'large'}>リスト詳細</Typography>
        </div>
      </div>

      {/* リスト詳細テーブル */}
      <div className='mx-5 my-3'>
        <div className='d-flex justify-content-between'>
          <BasicButton
            displayName={'<<'}
            onClick={prev}
          />
          <BasicButton
            displayName={'>>'}
            onClick={next}
          />
        </div>
        <BasicTable
          headerColor="midnightblue"
          headers={headers}
          content={tableData}
        />
      </div>
    </div>
  );
}