import { Autocomplete, Box, FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import React, { useState, useEffect } from "react";
import HttpData from "../../global/http/HttpData";
import HttpRequest from "../../global/http/HttpRequest";

export interface Dropdown {
  label: string;
  value: string;
}

interface DropdownRequest extends HttpData {
  value: string
}

export interface DropdownResponse extends HttpData {
  dropdownList: Dropdown[]
}

/** 引き渡しデータ */
type Props = {
  /** 最大幅（true: ON, false: OFF） */
  fullWidth?: boolean
  /** キー */
  itemKey?: string
  /** サイズ */
  size?: 'small' | 'medium'
  /** タイトル（表示名） */
  titleName: string
  /** 選択済みドロップダウンリストデータ */
  value: Dropdown | null
  /** ドロップダウンリストデータ */
  items: Dropdown[]
  /** ドロップダウンリスト変更イベント */
  onChange: (selectedValue: Dropdown) => void
  /** 初期リクエスト用URL（エンドポイントを格納、指定する場合はコンポーネント内でデータ取得まで完結） */
  initialRequestUrl?: string
  /** LazyLoad用リクエスト用URL（エンドポイントを格納、指定する場合はコンポーネント内でデータ取得まで完結） */
  lazyRequestUrl?: string
};

export default function BasicDropdown(props: Props) {
  // ドロップダウンリスト（表示名）
  const [strValue, setStrValue] = useState<string>('');
  const [value, setValue] = useState<Dropdown>({ label: '', value: '' })
  // ドロップダウンリスト（initialRequestUrlまたはlazyRequestUrlの指定がある場合のみ使用）
  const [dropdownItem, setDropdownItem] = useState<Dropdown[]>([])

  useEffect(() => {
    // 初期データ取得用URLの指定がある場合のみ実行
    const f = async () => {
      if (props.initialRequestUrl) {
        await fetchData(props.initialRequestUrl)
      }
    }
    f()
  }, []);

  useEffect(() => {
    setStrValue(props.value?.value ?? '')
  }, [props.value])

  useEffect(() => {
    props.onChange(value)
  }, [value])

  const lazyLoadingHandler = async (event: React.ChangeEvent<{ value: unknown }>) => {
    const value = event.target.value as string;
    if (!value) return;
    if (value.length > 0 && props.lazyRequestUrl) {
      await fetchData(props.lazyRequestUrl, value);
    } else if (value.length === 0) {
      setDropdownItem([]);
    }
  }

  // ドロップダウンリストデータ取得用リクエスト
  const fetchData = async (url: string, searchValue?: string) => {
    // let http: HttpRequest<HttpData, HttpData> = new HttpRequest<Request, DropdownResponse>()
    if (searchValue) {
      const http = new HttpRequest<DropdownRequest, DropdownResponse>()
      http.request = {
        value: searchValue
      }
      if (await http.get(url)) {
        const response = http.response!
        setDropdownItem(response.dropdownList);
      }
    } else {
      const http = new HttpRequest<HttpData, DropdownResponse>()
      if (await http.get(url)) {
        const response = http.response!
        setDropdownItem(response.dropdownList);
      }
    }
  }

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>, dropdown: Dropdown) => {
    const selectedValue = event.target.value as string;
    const selectedDropdown: Dropdown = {
      label: dropdownItem.find(f => f.value === selectedValue)?.label ?? '',
      value: selectedValue,
    };
    setStrValue(selectedValue)
    setValue(selectedDropdown);
    props.onChange(selectedDropdown);
  }

  const renderDropdownList = () => {
    if (props.lazyRequestUrl) {
      return (
        <Autocomplete
          disablePortal
          size={props.size ?? 'small'}
          id="combo-box-demo"
          value={props.value}
          options={dropdownItem}
          onChange={(e: any, newValue: Dropdown | null) => {
            if (newValue) {
              setValue(newValue);
            }
          }
          }
          renderInput={(params) => <TextField onChange={(e: any) => lazyLoadingHandler(e)} {...params} label={props.titleName} inputProps={{ ...params.inputProps }} />}
        />
      );
    } else if (props.initialRequestUrl) {
      return (
        <FormControl fullWidth={props.fullWidth ?? undefined} size={props.size ?? 'small'}>
          <InputLabel id="simple-select-label">{props.titleName}</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="simple-select"
            value={strValue}
            label={props.titleName}
            onChange={(e: any) => handleChange(e, props.value!)}
          >
            {dropdownItem.map((item, index) => {
              return (
                <MenuItem key={`item-${index}-${item.value}`} value={item.value}>{item.label}</MenuItem>
              );
            })}
          </Select>
        </FormControl>
      );
    } else {
      return (
        <FormControl fullWidth={props.fullWidth ?? undefined} size={props.size ?? 'small'}>
          <InputLabel id="simple-select-label">{props.titleName}</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="simple-select"
            value={strValue}
            label={props.titleName}
            onChange={(e: any) => handleChange(e, props.value!)}
          >
            {props.items.map((item, index) => {
              return (
                <MenuItem key={`item-${index}-${item.value}`} value={item.value}>{item.label}</MenuItem>
              );
            })}
          </Select>
        </FormControl>
      )
    }
  }

  return (
    <Box sx={{ minWidth: 120 }}>
      {renderDropdownList()}
    </Box>
  )
}
