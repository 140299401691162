import { Grid, Paper, styled, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import Header from "../../../../components/header/header";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import GridViewMenu, { MenuItem } from "../../../../components/menu/gridViewMenu";

export default function StandardManagementPage() {
  const nav = useNavigate();
  const gotoPage = (url: string) => {
    nav(url);
  };

  const items: MenuItem[] = [
    { title: '企業DB/大業種', gotoPageUrl: '/system_management/IndustryCategoryManagementPage' },
    { title: '企業DB/小業種', gotoPageUrl: '/system_management/IndustrySubcategoryManagementPage' },
    { title: '企業DB/サブ大業種', gotoPageUrl: '/system_management/SubIndustryCategoryManagementPage' },
    { title: '企業DB/サブ小業種', gotoPageUrl: '/system_management/SubIndustrySubcategoryManagementPage' },
    { title: '企業DB/上場区分', gotoPageUrl: '/system_management/ListingClassManagementPage' },
    { title: '企業DB/依頼可能作業', gotoPageUrl: '/system_management/RequestableTaskManagementPage' },
    { title: 'メール', gotoPageUrl: '/system_management/mail' },
  ];

  return (
    <div>
      <div>
        <Header />
      </div>

      {/* パンくずリスト */}
      <div className="d-flex" style={{ margin: '50px 50px 0px 50px' }}>
        <div onClick={() => gotoPage('/system_management')}>
          <Typography
            fontWeight={'bold'}
            fontSize={'large'}
            style={{ color: 'gray', cursor: 'pointer' }}
          >
            システム管理
          </Typography>
        </div>

        <div style={{ width: '20px' }}></div>
        <ArrowForwardIcon />
        <div style={{ width: '20px' }}></div>
        <Typography fontWeight={'bold'} fontSize={'large'}>規定値管理</Typography>
      </div>

      {/* メニュー */}
      <div className='mx-5 my-5'>
        <GridViewMenu menuItems={items} />
      </div>
    </div>
  );
}