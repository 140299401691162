import { useNavigate, useLocation } from "react-router-dom";
import { ListItemText, ListItemButton, MenuItem, MenuList, Paper, TextField, Typography } from '@mui/material';
import BasicDropdown, { Dropdown } from "../../../components/dropdown/basicDropdown";
import Header from "../../../components/header/header";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowRight from '@mui/icons-material/ArrowRight';
import BasicTextField from "../../../components/text/basicTextField";
import { useEffect, useState, useRef, forwardRef } from "react";
import { useDispatch } from "react-redux";
import BasicButton from "../../../components/button/basicButton";
import "react-datepicker/dist/react-datepicker.css";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { showMessage } from "../../../global/store/snackbar";
import ProjectEditRequest, {
  ProjectInfo, ProjectEditDropdownRequest, ResultResponse,
  ProjectDropdownRequest, ProjectDropdownResponse, ProjectDetailRequest, ProjectDetailResponse
} from "../../../@types/ib/projectEdit";
import BasicCheckbox from "../../../components/checkbox/basicCheckBox";
import HttpRequest from "../../../global/http/HttpRequest";
import BasicDatePicker from "../../../components/datapicker/basicDatePicker";

// 顧客管理 -> 顧客詳細 -> 案件情報 -> 新規作成
export default function ProjectRegistrationPage() {
  const nav = useNavigate();

  const location = useLocation();
  const clientId = location.state?.clientId;
  const clientName = location.state?.clientName;
  const projectId = location.state?.projectId;
  const extracted = location.state?.extracted;

  const gotoPage = (url: string) => {
    nav(url, { state: { clientId: clientId, tabIndex: 1 } });
  };

  const gotoDetailPage = (url: string, project_id: string) => {
    nav(url, { state: { clientId: clientId, clientName: clientName, projectId: project_id } });
  };

  const dispatch = useDispatch();

  // ドロップダウン
  const [listItems, setListItems] = useState<Dropdown[]>([]);
  const [senderItems, setSenderItems] = useState<Dropdown[]>([]);
  const [draftItems, setDraftItems] = useState<Dropdown[]>([]);
  const [subjectStatus, setSubjectStatus] = useState<Dropdown[]>([
    { label: '送信可', value: '送信可' },
    { label: '一時停止（資料の差し替えの為一時的に送信不可状態）', value: '一時停止' },
    { label: '停止（再開の見込みがなく完全に送信できない状態）', value: '停止' },
    { label: '完了', value: '完了' },
  ]);

  // state(基本情報)
  const [projectInfo, setProjectInfo] = useState<ProjectInfo>(new ProjectInfo())
  const [parameter, setParameter] = useState(false);
  const [selectedSubjectStatus, setSelectedSubjectStatus] = useState<Dropdown>({ label: '送信可', value: '送信可' });

  type Day = { name: string, isCheck: boolean }
  const [days, setDays] = useState<Day[]>([
    { name: '月曜日', isCheck: false },
    { name: '火曜日', isCheck: false },
    { name: '水曜日', isCheck: false },
    { name: '木曜日', isCheck: false },
    { name: '金曜日', isCheck: false },
    { name: '土曜日', isCheck: false },
    { name: '日曜日', isCheck: false },
  ]);

  //選択リスト部分
  const [currentItems, setCurrentItems] = useState<Dropdown[]>([]);
  const [menuCategory, setMenuCategory] = useState<string>('');
  const buttonGroupRef = useRef<HTMLDivElement>(null); // ボタン群のref
  const [buttonGroupHeight, setButtonGroupHeight] = useState(0);

  useEffect(() => {
    const observer = new ResizeObserver(entries => {
      for (let entry of entries) {
        setButtonGroupHeight(entry.contentRect.height);
      }
    });
    if (buttonGroupRef.current) {
      observer.observe(buttonGroupRef.current);
    }
    return () => observer.disconnect();
  }, []);

  const handleButtonClick = (items: Dropdown[], category: string) => {
    setCurrentItems(items);
    setMenuCategory(category);
  };

  const handleMenuItemClick = (label: string, value: string) => {
    if (menuCategory === 'List') {
      setProjectInfo({ ...projectInfo, listId: value, listName: label })
    }
    else if (menuCategory === 'Sender') {
      setProjectInfo({ ...projectInfo, senderId: value, senderName: label })
    }
    else if (menuCategory === 'Draft') {
      setProjectInfo({ ...projectInfo, draftId: value, draftName: label })
    }
  };

  const isSelected = (item: Dropdown) => {
    if (menuCategory === 'List' && projectInfo.listId === item.value) return true;
    if (menuCategory === 'Sender' && projectInfo.senderId === item.value) return true;
    if (menuCategory === 'Draft' && projectInfo.draftId === item.value) return true;
    return false;
  };

  useEffect(() => {
    const fetchData = async () => {
      const http = new HttpRequest<ProjectDropdownRequest, ProjectDropdownResponse>()
      http.request = {
        client_id: clientId,
      }
      if (await http.get('/client/project/drop/create')) {
        const info = { ...http.response! }
        setListItems(info.list_items)
        setSenderItems(info.sender_items)
        setDraftItems(info.draft_items);
      }
      setProjectInfo({ ...projectInfo, clientId: clientId })
      setSelectedSubjectStatus({ label: '', value: '送信可' })
    }
    const editFetchData = async () => {
      const dropHttp = new HttpRequest<ProjectEditDropdownRequest, ProjectDropdownResponse>()
      dropHttp.request = {
        client_id: clientId,
        extracted: extracted ? "1" : "0"
      }
      if (await dropHttp.get('/client/project/drop/edit')) {
        const info = { ...dropHttp.response! }
        setListItems(info.list_items)
        setSenderItems(info.sender_items)
        setDraftItems(info.draft_items);
      }

      const http = new HttpRequest<ProjectDetailRequest, ProjectDetailResponse>()
      http.request = {
        project_id: projectId,
      }
      const response = await http.get('/client/project/edit/detail')
      const info = { ...http.response! }
      setProjectInfo({
        ...info,
        projectId: projectId,
        clientId: clientId,
        startDate: new Date(info.startDate).toLocaleString(),
        endDate: new Date(info.endDate).toLocaleString(),
      })

      // 初期値設定
      const sendableWeekday = info.sendableWeekday.split(',');

      setDays(pre => pre.map(m => {
        return { name: m.name, isCheck: sendableWeekday.includes(m.name) }
      }))

      setParameter(info.parameterPresence == '1')
    }
    if (projectId) {
      editFetchData();
    } else {
      fetchData();
    }
  }, []);

  //カレンダーの表示形式修正
  interface CustomInputProps {
    value?: string;
    onClick?: () => void;
  }
  const CustomInput = forwardRef<HTMLInputElement, CustomInputProps>(
    ({ value, onClick }, ref) => (
      <TextField
        size="small"
        value={value}
        onClick={onClick}
        ref={ref}
        InputProps={{
          endAdornment: <CalendarMonthIcon />,
        }}
      />
    )
  );

  // 登録
  const registration = async () => {

    const trueDays = days.filter(day => day.isCheck).map(day => day.name);

    const http = new HttpRequest<ProjectEditRequest, ResultResponse>()
    http.request = {
      ...{ ...projectInfo, sendableWeekday: trueDays.join(','), parameterPresence: parameter ? '1' : '0' },
    }

    const response = await http.post('/client/project/create/new')

    if (http.response?.errorMessage) {
      dispatch(showMessage({
        message: http.response!.errorMessage,
        severity: 'error',
        duration: 3000,
      }))
      return
    }
    gotoDetailPage('/client_management/ProjectInformationPage', http.response!.projectId)
  }

  return (
    <div>
      <div>
        <Header />
      </div>

      {/* パンくずリスト */}
      <div>
        <div className="d-flex" style={{ margin: '50px 50px 0px 50px' }}>
          <div onClick={() => gotoPage('/client_management')}>
            <Typography fontWeight={'bold'} fontSize={'large'} style={{ color: 'gray', cursor: 'pointer' }}>
              {'顧客管理'}
            </Typography>
          </div>

          <div style={{ width: '20px' }}></div>
          <ArrowForwardIcon />
          <div style={{ width: '20px' }}></div>

          <div onClick={() => gotoPage('/client_management/detail')}>
            <Typography fontWeight={'bold'} fontSize={'large'} style={{ color: 'gray', cursor: 'pointer' }}>
              {'顧客詳細'}
            </Typography>
          </div>

          <div style={{ width: '20px' }}></div>
          <ArrowForwardIcon />
          <div style={{ width: '20px' }}></div>

          {projectId ?
            <div onClick={() => gotoDetailPage('/client_management/ProjectInformationPage', projectId)}>
              <Typography fontWeight={'bold'} fontSize={'large'} style={{ color: 'gray', cursor: 'pointer' }}>
                {'案件情報'}
              </Typography>
            </div>
            :
            <Typography fontWeight={'bold'} fontSize={'large'} style={{ color: 'gray' }}>{'案件情報'}</Typography>
          }

          <div style={{ width: '20px' }}></div>
          <ArrowForwardIcon />
          <div style={{ width: '20px' }}></div>

          <Typography fontWeight={'bold'} fontSize={'large'}>{projectId ? '編集' : '新規作成'}</Typography>
        </div>
      </div>

      {/* 登録フォーム */}
      <div className="mx-5 my-5">
        <div>
          {/* 基本情報 */}
          <div className="container row">
            <div ref={buttonGroupRef} className="col-6 row mx-1 my-2">
              <div className="my-2">
                <BasicTextField
                  fullwidth
                  label="案件名"
                  value={projectInfo.subjectName}
                  setValue={(value: string) => setProjectInfo({ ...projectInfo, subjectName: value })} />
              </div>
              <div className="my-2">
                <ListItemButton
                  sx={{
                    border: 1, borderColor: 'grey.400', borderRadius: '6px',
                    padding: '4px 8px',
                    '& .MuiListItemText-primary': {
                      fontSize: '0.875rem',
                    }
                  }}
                  onClick={() => handleButtonClick(listItems, 'List')}>
                  <ListItemText sx={{ textAlign: 'left' }}
                    primary={projectInfo.listName ? "リスト：" + projectInfo.listName : "リスト"}
                  />
                  {<ArrowRight />}
                </ListItemButton>
              </div>
              <div className="my-2">
                <ListItemButton
                  sx={{
                    border: 1, borderColor: 'grey.400', borderRadius: '6px',
                    padding: '4px 8px',
                    '& .MuiListItemText-primary': {
                      fontSize: '0.875rem',
                    }
                  }}
                  onClick={() => handleButtonClick(senderItems, 'Sender')}>
                  <ListItemText sx={{ textAlign: 'left' }}
                    primary={projectInfo.senderName ? "送信者情報：" + projectInfo.senderName : "送信者情報"}
                  />
                  {<ArrowRight />}
                </ListItemButton>
              </div>
              <div className="my-2">
                <ListItemButton
                  sx={{
                    border: 1, borderColor: 'grey.400', borderRadius: '6px',
                    padding: '4px 8px',
                    '& .MuiListItemText-primary': {
                      fontSize: '0.875rem',
                    }
                  }}
                  onClick={() => handleButtonClick(draftItems, 'Draft')}>
                  <ListItemText sx={{ textAlign: 'left' }}
                    primary={projectInfo.draftName ? "送信原稿：" + projectInfo.draftName : "送信原稿"}
                  />
                  {<ArrowRight />}
                </ListItemButton>
              </div>

              <div className="d-flex my-2" style={{ justifyContent: 'space-between', whiteSpace: 'nowrap' }}>
                <Typography sx={{ margin: 'auto 0' }} fontSize={'large'}>{'送信期間：'}</Typography>
                <div>
                  <BasicDatePicker
                    title={'開始日'}
                    value={projectInfo.startDate}
                    setValue={(date: string) => {
                      setProjectInfo({ ...projectInfo, startDate: date })
                    }}
                  />
                </div>
                <Typography fontSize={'large'} sx={{ margin: 'auto 0', textAlign: 'center', minWidth: '20px' }}>{'～'}</Typography>
                <div>
                  <BasicDatePicker
                    title={'終了日'}
                    value={projectInfo.endDate}
                    setValue={(date: string) => {
                      setProjectInfo({ ...projectInfo, endDate: date })
                    }}
                  />
                </div>
              </div>

              <div className="d-flex my-2" style={{ justifyContent: 'space-between', whiteSpace: 'nowrap' }}>
                <Typography fontSize={'large'} sx={{ margin: 'auto 0' }}>{'送信可能時間：'}</Typography>
                <div>
                  <BasicTextField
                    fullwidth
                    label="開始時間"
                    value={projectInfo.startTime}
                    setValue={(value: string) => setProjectInfo({ ...projectInfo, startTime: value })}
                    InputProps={{
                      inputProps: { maxLength: 2 }
                    }} />
                </div>
                <Typography fontSize={'large'} sx={{ margin: 'auto 0', textAlign: 'center', minWidth: '20px' }}>{'～'}</Typography>
                <div>
                  <BasicTextField fullwidth
                    label=""
                    value={projectInfo.endTime}
                    setValue={(value: string) => setProjectInfo({ ...projectInfo, endTime: value })}
                    InputProps={{
                      inputProps: { maxLength: 2 }
                    }} />
                </div>
              </div>

              <div className="d-flex align-items-center my-2" style={{ gap: '10px', display: 'flex', alignItems: 'center', justifyContent: 'space-between', whiteSpace: 'nowrap' }}>
                <Typography fontSize={'large'}>{'送信可能曜日：'}</Typography>
                {days.map((day, index) => {
                  return (
                    <div key={`day-${index}`}>
                      <BasicCheckbox
                        label={day.name.substring(0, 1)}
                        checked={days.find(f => f.name === day.name)!.isCheck}
                        labelPlacement="top"
                        onChange={(e, checked) => setDays(pre => {
                          const temp = [...days]
                          temp.find(f => f.name === day.name)!.isCheck = checked
                          return temp
                        })}
                      />
                    </div>
                  )
                })}
              </div>

              <div className="d-flex align-items-center my-2" style={{ gap: '10px', display: 'flex', alignItems: 'center' }}>
                <Typography fontSize={'large'}>{'パラメータ有無：'}</Typography>
                <BasicCheckbox
                  label=""
                  checked={parameter}
                  labelPlacement="end"
                  readOnly={!!extracted}
                  onChange={(e, checked) => setParameter(checked)}
                />
              </div>

              <div className="my-2">
                <BasicDropdown
                  fullWidth
                  value={projectInfo.subjectStatus}
                  items={subjectStatus}
                  onChange={(selectedValue: Dropdown) => setProjectInfo({ ...projectInfo, subjectStatus: selectedValue })}
                  titleName="ステータス"
                />
              </div>

              <div className="my-2">
                <BasicTextField fullwidth multiline label="メモ" value={projectInfo.memo}
                  setValue={(value: string) => setProjectInfo({ ...projectInfo, memo: value })} />
              </div>

            </div>

            <div className="col-5 row mx-1 my-2" style={{ maxHeight: buttonGroupHeight - 10, zIndex: 99 }}>
              <Paper style={{ maxHeight: '100%', overflow: 'auto' }}>
                <MenuList>
                  {currentItems.map((item, index) => (
                    <div key={`list-item-${index}`}>
                      <MenuItem
                        disabled={(menuCategory === 'List' || menuCategory === 'Sender') && extracted}
                        key={item.value}
                        onClick={() => handleMenuItemClick(item.label, item.value)}
                        style={{
                          backgroundColor: isSelected(item) ? '#add8e6' : (index % 2 === 0 ? 'white' : '#f0f0f0'),
                        }}
                      >
                        {`${index + 1}. ${item.label}`}
                      </MenuItem>
                    </div>
                  ))}
                </MenuList>
              </Paper>
            </div>
          </div>

          <div className="my-3" style={{ textAlign: 'center' }}>
            <BasicButton
              displayName="登録" width={'20%'}
              fontColor="white"
              onClick={registration}
            />
          </div>
        </div>
      </div>

    </div >
  );
}