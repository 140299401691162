import { useNavigate, useLocation } from "react-router-dom";
import Header from "../../../../components/header/header";
import { Typography } from "@mui/material";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import BasicButton from "../../../../components/button/basicButton";
import { showMessage } from "../../../../global/store/snackbar";
import JsonRequest from "../../../../global/http/JsonRequest";
import { CompanyDetailInfo,
         CompanyDetailRequest, CompanyDetailResponse } from "../../../../@types/ib/companyDatabasePage/CompanyType";

// 企業DB管理 -> 企業修正対象検索 -> 企業詳細
export default function CompanyDetailPage() {
  const nav = useNavigate();
  
  const location = useLocation();
  const companyId = location.state?.companyId;

  const gotoPage = (url: string) => {
    nav(url);
  };

  // 編集画面へ遷移
  const gotoEditPage = () => {
    nav('/company_database/company_new', { state: { companyId: companyId } })
  }

  const [companyInfo, setCompanyInfo] = useState<CompanyDetailInfo>(new CompanyDetailInfo());

  const dispatch = useDispatch();

  // state(基本情報)
  useEffect(() => {
    const fetchData = async () => {
      const http = new JsonRequest<CompanyDetailRequest, CompanyDetailResponse>()
      http.request = {
        companyId: companyId,
      }

      const res = await http.post('/database/company/update/detail')
      const info = { ...http.response!.companyDetail }

      setCompanyInfo(info)
      if (http.response?.errorMessage) {
        dispatch(showMessage({
          message: http.response!.errorMessage,
          severity: 'error',
          duration: 3000,
        }))
        return
      }
    }
    fetchData();
  }, []);

  return (
    <div>
      <div>
        <Header />
      </div>

      <div>
        {/* パンくずリスト */}
        <div className="d-flex mx-5 align-items-end w-100" style={{ margin: '50px 0px 0px 50px' }}>
          <div className="d-flex w-75">
            <div onClick={() => gotoPage('/company_database')}>
              <Typography
                fontWeight={'bold'}
                fontSize={'large'}
                style={{ color: 'gray', cursor: 'pointer' }}
              >
                企業データベース管理
              </Typography>
            </div>

            <div style={{ width: '20px' }}></div>
            <ArrowForwardIcon />
            <div style={{ width: '20px' }}></div>
            <div onClick={() => gotoPage('/company_database/company_search')}>
              <Typography
                fontWeight={'bold'}
                fontSize={'large'}
                style={{ color: 'gray', cursor: 'pointer' }}
              >
                企業検索
              </Typography>
            </div>

            <div style={{ width: '20px' }}></div>
            <ArrowForwardIcon />
            <div style={{ width: '20px' }}></div>
            <Typography fontWeight={'bold'} fontSize={'large'}>企業詳細</Typography>
          </div>
          <div className="w-25">
            <BasicButton displayName="編集" onClick={() => gotoEditPage()} />
          </div>
        </div>
      </div>

      <div>
        <div style={{ margin: '30px 50px 0px 50px' }}>
          <div className="d-flex my-1 w-75">
            <div className="col-4">
              <Typography align="right">法人名：</Typography>
            </div>
            <div className="col">
              <Typography align="left" fontWeight={'bold'}>{companyInfo.companyName}</Typography>
            </div>
          </div>
          <div className="d-flex my-1 w-75">
            <div className="col-4">
              <Typography align="right">ドメインHTTPなし：</Typography>
            </div>
            <div className="col">
              <Typography align="left" fontWeight={'bold'}>{companyInfo.domain}</Typography>
            </div>
          </div>
          <div className="d-flex my-1 w-75">
            <div className="col-4">
              <Typography align="right">トップページURL：</Typography>
            </div>
            <div className="col">
              <Typography align="left" fontWeight={'bold'}>{companyInfo.url}</Typography>
            </div>
          </div>
          <div className="d-flex my-1 w-75">
            <div className="col-4">
              <Typography align="right">問い合わせページURL：</Typography>
            </div>
            <div className="col">
              <Typography align="left" fontWeight={'bold'}>{companyInfo.inquiryUrl}</Typography>
            </div>
          </div>
          <div className="d-flex my-1 w-75">
            <div className="col-4">
              <Typography align="right">大業種：</Typography>
            </div>
            <div className="col">
              <Typography align="left" fontWeight={'bold'}>{companyInfo.industryCategory.label}</Typography>
            </div>
          </div>
          <div className="d-flex my-1 w-75">
            <div className="col-4">
              <Typography align="right">小業種：</Typography>
            </div>
            <div className="col">
              <Typography align="left" fontWeight={'bold'}>{companyInfo.industrySubcategory.map(item => item.label).join('、')}</Typography>
            </div>
          </div>
          <div className="d-flex my-1 w-75">
            <div className="col-4">
              <Typography align="right">電話番号：</Typography>
            </div>
            <div className="col">
              <Typography align="left" fontWeight={'bold'}>{companyInfo.phoneNumber}</Typography>
            </div>
          </div>
          <div className="d-flex my-1 w-75">
            <div className="col-4">
              <Typography align="right">FAX：</Typography>
            </div>
            <div className="col">
              <Typography align="left" fontWeight={'bold'}>{companyInfo.faxNumber}</Typography>
            </div>
          </div>
          <div className="d-flex my-1 w-75">
            <div className="col-4">
              <Typography align="right">郵便番号：</Typography>
            </div>
            <div className="col">
              <Typography align="left" fontWeight={'bold'}>{companyInfo.postal.label}</Typography>
            </div>
          </div>
          <div className="d-flex my-1 w-75">
            <div className="col-4">
              <Typography align="right">都道府県：</Typography>
            </div>
            <div className="col">
              <Typography align="left" fontWeight={'bold'}>{companyInfo.prefecture.label}</Typography>
            </div>
          </div>
          <div className="d-flex my-1 w-75">
            <div className="col-4">
              <Typography align="right">従業員数：</Typography>
            </div>
            <div className="col">
              <Typography align="left" fontWeight={'bold'}>{companyInfo.employeesNum}</Typography>
            </div>
          </div>
          <div className="d-flex my-1 w-75">
            <div className="col-4">
              <Typography align="right">住所：</Typography>
            </div>
            <div className="col">
              <Typography align="left" fontWeight={'bold'}>{companyInfo.address}</Typography>
            </div>
          </div>
          <div className="d-flex my-1 w-75">
            <div className="col-4">
              <Typography align="right">事業内容：</Typography>
            </div>
            <div className="col">
              <Typography align="left" fontWeight={'bold'}>{companyInfo.businessDescription}</Typography>
            </div>
          </div>
          <div className="d-flex my-1 w-75">
            <div className="col-4">
              <Typography align="right">一言説明：</Typography>
            </div>
            <div className="col">
              <Typography align="left" fontWeight={'bold'}>{companyInfo.description}</Typography>
            </div>
          </div>
          <div className="d-flex my-1 w-75">
            <div className="col-4">
              <Typography align="right">加盟情報：</Typography>
            </div>
            <div className="col">
              <Typography align="left" fontWeight={'bold'}>{companyInfo.joining}</Typography>
            </div>
          </div>
          <div className="d-flex my-1 w-75">
            <div className="col-4">
              <Typography align="right">スクレイピング：</Typography>
            </div>
            <div className="col">
              <Typography align="left" fontWeight={'bold'}>{companyInfo.scraping}</Typography>
            </div>
          </div>
          <div className="d-flex my-1 w-75">
            <div className="col-4">
              <Typography align="right">法人番号：</Typography>
            </div>
            <div className="col">
              <Typography align="left" fontWeight={'bold'}>{companyInfo.corpNo}</Typography>
            </div>
          </div>
          <div className="d-flex my-1 w-75">
            <div className="col-4">
              <Typography align="right">資本金：</Typography>
            </div>
            <div className="col">
              <Typography align="left" fontWeight={'bold'}>{companyInfo.capital}</Typography>
            </div>
          </div>
          <div className="d-flex my-1 w-75">
            <div className="col-4">
              <Typography align="right">売上金：</Typography>
            </div>
            <div className="col">
              <Typography align="left" fontWeight={'bold'}>{companyInfo.revenue}</Typography>
            </div>
          </div>
          <div className="d-flex my-1 w-75">
            <div className="col-4">
              <Typography align="right">上場区分：</Typography>
            </div>
            <div className="col">
              <Typography align="left" fontWeight={'bold'}>{companyInfo.listing.label}</Typography>
            </div>
          </div>
          <div className="d-flex my-1 w-75">
            <div className="col-4">
              <Typography align="right">代表者：</Typography>
            </div>
            <div className="col">
              <Typography align="left" fontWeight={'bold'}>{companyInfo.representativeName}</Typography>
            </div>
          </div>
          <div className="d-flex my-1 w-75">
            <div className="col-4">
              <Typography align="right">設立年月日：</Typography>
            </div>
            <div className="col">
              <Typography align="left" fontWeight={'bold'}>{companyInfo.establishDate}</Typography>
            </div>
          </div>
          <div className="d-flex my-1 w-75">
            <div className="col-4">
              <Typography align="right">決算月：</Typography>
            </div>
            <div className="col">
              <Typography align="left" fontWeight={'bold'}>{companyInfo.settlementMonth}</Typography>
            </div>
          </div>
          <div className="d-flex my-1 w-75">
            <div className="col-4">
              <Typography align="right">事業所数：</Typography>
            </div>
            <div className="col">
              <Typography align="left" fontWeight={'bold'}>{companyInfo.officeNum}</Typography>
            </div>
          </div>
          <div className="d-flex my-1 w-75">
            <div className="col-4">
              <Typography align="right">工場数：</Typography>
            </div>
            <div className="col">
              <Typography align="left" fontWeight={'bold'}>{companyInfo.factoryNum}</Typography>
            </div>
          </div>
          <div className="d-flex my-1 w-75">
            <div className="col-4">
              <Typography align="right">店舗数：</Typography>
            </div>
            <div className="col">
              <Typography align="left" fontWeight={'bold'}>{companyInfo.storeNum}</Typography>
            </div>
          </div>
          <div className="d-flex my-1 w-75">
            <div className="col-4">
              <Typography align="right">取引形態：</Typography>
            </div>
            <div className="col">
              <Typography align="left" fontWeight={'bold'}>{companyInfo.dealForm.map(item => item.label).join('、')}</Typography>
            </div>
          </div>
          <div className="d-flex my-1 w-75">
            <div className="col-4">
              <Typography align="right">サブ大業種：</Typography>
            </div>
            <div className="col">
              <Typography align="left" fontWeight={'bold'}>{companyInfo.subIndustryCategory.map(item => item.label).join('、')}</Typography>
            </div>
          </div>
          <div className="d-flex my-1 w-75">
            <div className="col-4">
              <Typography align="right">サブ小業種：</Typography>
            </div>
            <div className="col">
              <Typography align="left" fontWeight={'bold'}>{companyInfo.subIndustrySubcategory.map(item => item.label).join('、')}</Typography>
            </div>
          </div>
          <div className="d-flex my-1 w-75">
            <div className="col-4">
              <Typography align="right">全体禁止：</Typography>
            </div>
            <div className="col">
              <Typography align="left" fontWeight={'bold'}>{companyInfo.wholeProhibition == '0' ? '全体禁止でない' : '全体禁止'}</Typography>
            </div>
          </div>
          <div className="d-flex my-1 w-75">
            <div className="col-4">
              <Typography align="right">送信方法：</Typography>
            </div>
            <div className="col">
              <Typography align="left" fontWeight={'bold'}>{companyInfo.sendType == '1' ? 'Automatic' : 'Manual'}</Typography>
            </div>
          </div>
        </div>
      </div>

    </div >
  );
}