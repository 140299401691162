import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { TextField } from '@mui/material';
import React, { useState, useRef } from 'react';

export type Title = {
  name: string | JSX.Element
  align: 'left' | 'center' | 'right'
  width?: string
  type?: 'TEXT_FIELD' | 'TEXT'
  value?: string
}

export type Row = {
  content: Title[]
}

type Props = {
  headerColor?: 'midnightblue' | 'darkgreen' | 'white'
  headers: Title[]
  content: Row[]
  scale?: number
  setTableData?: (data: Row[]) => void
}

export default function EditTable(props: Props) {

  const { headerColor, headers, content, scale, setTableData } = props;
  const [displayCount, setDisplayCount] = useState(20);
  const pageSize = 20;
  const containerRef = useRef<HTMLDivElement>(null);

  const handleScroll = () => {
    if (containerRef.current) {
      const { scrollTop, clientHeight, scrollHeight } = containerRef.current;
      if (scrollHeight - scrollTop <= clientHeight + 100 && displayCount < content.length) {
        const newDisplayCount = Math.min(displayCount + pageSize, content.length);
        setDisplayCount(newDisplayCount);
      }
    }
  };

  const handleValue = (val: string, index1: number, index2: number) => {
    const newContent = [...props.content];
    newContent[index1].content[index2].value = val;
    props.setTableData!(newContent);
  }

  return (
    <TableContainer component={Paper} onScroll={handleScroll} 
    style={{ maxHeight: '440px', overflow: 'auto' }} ref={containerRef}>
      <Table stickyHeader aria-label="sticky table" component={'table'} sx={{ minWidth: 650 }} >
        <TableHead component={'thead'} sx={{ backgroundColor: props.headerColor ?? 'midnightblue' }}>
          <TableRow component={'tr'}>
            {props.headers.map((header, index) => {
              return (
                <TableCell component={'td'} style={{ width: '10%' }} align={header.align} sx={{ fontWeight: 'bold' }}>
                  {header.name as string}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody component={'tbody'} sx={{ '.table-row:hover': { backgroundColor: 'aliceblue' } }}>
          {content.slice(0, displayCount).map((row, rowIndex) => (
            <TableRow
              className='table-row'
              key={`index-${rowIndex}`}
              component={'tr'}
            >
              {row.content.map((item, index2) => {
                return (

                  <TableCell key={`index-child-${rowIndex}-${index2}`} component="th" scope="row" align={item.align} sx={{ width: item.width ?? window.innerWidth / row.content.length }}>
                    {item.type === 'TEXT' ?
                      item.name as string
                      :
                      <TextField fullWidth value={item.value || (item.name as React.ReactElement).props.value || ''}
                       size='small' onChange={(e: any) => handleValue(e.target.value as string, rowIndex, index2)} />
                      
                    }
                  </TableCell>
                );
              })}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer >
  );
}