import { Typography, ListItemText, ListItemButton } from "@mui/material";
import Header from "../../../components/header/header";
import { useEffect, useState, useRef, forwardRef } from "react";
import { useDispatch } from "react-redux";
import BasicButton from "../../../components/button/basicButton";
import { useNavigate, useLocation } from "react-router-dom";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import BasicCheckbox from "../../../components/checkbox/basicCheckBox";
import BasicTextField from "../../../components/text/basicTextField";
import HttpRequest from "../../../global/http/HttpRequest";
import { showMessage } from "../../../global/store/snackbar";
import BasicMultipleDropdown, { Dropdown } from "../../../components/dropdown/basicMultipleDropdown";
import {
  ProjectDetailRequest, ExtractListRequest, ExtractListResponse,
  ProjectResultResponse
} from "../../../@types/ib/projectEdit";
import { SendMailRequest, SendMailResponse } from "../../../@types/ib/sendEmailType"
import {NormalResponse} from "../../../@types/common";
import {MailInfoRequest, MailInfoResponse, MailInput} from "../../../@types/ib/clientManagement/ClientType";

interface InitData {
  projectName: string
  listNum: string
  requestedNum: string
  notRequestedNum: string
  sentNum: string
  unsentNum: string
  period: string
  status: string
  time: string
  memo: string
}

export default function ProjectInformationPage() {
  const nav = useNavigate();

  const dispatch = useDispatch();

  const location = useLocation();
  const clientId = location.state?.clientId;
  const projectId = location.state?.projectId;

  const gotoPage = (url: string, id: string) => {
    nav(url, { state: { clientId: clientId, projectId: projectId, tabIndex: 1 } });
  };

  const gotoEditPage = (url: string, id: string) => {
    nav(url, { state: { clientId: clientId, projectId: projectId, extracted: extracted } });
  };

  const [isLoading, setIsLoading] = useState(true);

  const [options, setOptions] = useState<Dropdown[]>([]);
  const [selectedToUsers, setSelectedToUsers] = useState<Dropdown[]>([]);
  const [selectedCcUsers, setSelectedCcUsers] = useState<Dropdown[]>([]);

  const [monday, setMonday] = useState(false);
  const [tuesday, setTuesday] = useState(false);
  const [wednesday, setWednesday] = useState(false);
  const [thursday, setThursday] = useState(false);
  const [friday, setFriday] = useState(false);
  const [saturday, setSaturday] = useState(false);
  const [sunday, setSunday] = useState(false);
  const [memo, setMemo] = useState<string>('');
  const [listId, setListId] = useState<string>('');
  const [extracted, setExtracted] = useState(true);
  const [mailInfo, setMailInfo] = useState<MailInput>({
    subject: '',
    body: '',
    toUsers: [],
    ccUsers: [],
  })

  // state(基本情報)
  const [initData, setInitData] = useState<InitData>(
    {
      projectName: '',
      listNum: '',
      requestedNum: '',
      notRequestedNum: '',
      sentNum: '',
      unsentNum: '',
      period: '',
      status: '',
      time: '',
      memo: ''
    }
  );

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);

      const http = new HttpRequest<ProjectDetailRequest, ProjectResultResponse>()
      http.request = {
        project_id: projectId,
      }
      if (await http.get('/client/project/detaile')) {
        if (http.response?.result === 'FAILURE') {
          dispatch(showMessage({
            message: http.response!.message,
            severity: 'error',
            duration: 3000,
          }))
          return
        }
        const info = { ...http.response! }

        setInitData(info.project_detail)

        const sendable_weekday = info.sendable_weekday.split(',');

        // 曜日の状態を更新
        setMonday(sendable_weekday.includes('月曜日'));
        setTuesday(sendable_weekday.includes('火曜日'));
        setWednesday(sendable_weekday.includes('水曜日'));
        setThursday(sendable_weekday.includes('木曜日'));
        setFriday(sendable_weekday.includes('金曜日'));
        setSaturday(sendable_weekday.includes('土曜日'));
        setSunday(sendable_weekday.includes('日曜日'));

        setListId(info.list_id)

        setExtracted(info.extracted == '1')
        interface Item {
          name: string;
          id: string;
        }
        // ドロップダウンアイテム設定
        setOptions(info.client_items.map((p: Item) => ({ label: p.name, value: p.id })))
        setSelectedToUsers([])
        setSelectedCcUsers([])
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
   const fetchData = async() => {
     if (extracted) {
       const mailRequest = new HttpRequest<MailInfoRequest, MailInfoResponse>()
       mailRequest.request = {
         project_id: projectId
       }
       if (await mailRequest.get('/client/mail_info')) {
         setMailInfo({
           subject: mailRequest.response!.subject,
           body: mailRequest.response!.body,
           toUsers: [],
           ccUsers: [],
         })
       }
     }
   }
   fetchData();
  }, [extracted])


  // リスト抽出
  const extract = async () => {

    const http = new HttpRequest<ExtractListRequest, NormalResponse>()
    http.request = {
      client_id: clientId,
      project_id: projectId
    }

    if (await http.post('/client/company_list/extract')) {
      dispatch(showMessage({
        message: http.response!.message,
        severity: 'success',
        duration: 3000,
      }))
      setExtracted(true)
      return
    }
  }


  // メール送信
  const send_mail = async () => {
    const http = new HttpRequest<SendMailRequest, SendMailResponse>()
    http.request = {
      projectId: projectId,
      title: mailInfo.subject,
      body: mailInfo.body,
      toUsers: mailInfo.toUsers,
      ccUsers: mailInfo.ccUsers,
    }

    await http.post('/client/send_mail')

    if (http.response?.errorMessage) {
      dispatch(showMessage({
        message: http.response!.errorMessage,
        severity: 'error',
        duration: 3000,
      }))
      return
    } else {
      dispatch(showMessage({
        message: 'メール送信に成功しました',
        severity: 'info',
        duration: 3000,
      }))
      return
    }
  }

  return (
    <div>
      <div>
        <Header />
      </div>

      {/* パンくずリストと新規登録ボタン */}
      <div className="d-flex" style={{ margin: '50px 50px 0px 50px' }}>
        <div onClick={() => gotoPage('/client_management', '')}>
          <Typography
            fontWeight={'bold'}
            fontSize={'large'}
            style={{ color: 'gray', cursor: 'pointer' }}
          >
            顧客管理
          </Typography>
        </div>

        <div style={{ width: '20px' }}></div>
        <ArrowForwardIcon />
        <div style={{ width: '20px' }}></div>
        <div onClick={() => gotoPage('/client_management/detail', '')}>
          <Typography
            fontWeight={'bold'}
            fontSize={'large'}
            style={{ color: 'gray', cursor: 'pointer' }}
          >
            顧客詳細
          </Typography>
        </div>

        <div style={{ width: '20px' }}></div>
        <ArrowForwardIcon />
        <div style={{ width: '20px' }}></div>
        <Typography fontWeight={'bold'} fontSize={'large'}>案件情報</Typography>
      </div>

      <div className="d-flex" style={{ margin: '0px 30px 0px 100px' }}>
        <div className="row my-2" style={{ width: '70%' }}>
          <div className="d-flex" style={{ gap: '10px', display: 'flex', alignItems: 'center' }}>
            <Typography align="left" fontWeight={'bold'} fontSize={'large'}>案件名：{initData.projectName}</Typography>
          </div>
          <div className="d-flex my-2" style={{ display: 'flex', alignItems: 'center' }}>
            <Typography>リスト件数：{initData.listNum}</Typography>
          </div>
          <div className="d-flex my-2" style={{ display: 'flex', alignItems: 'center' }}>
            <Typography>依頼済：{initData.requestedNum}</Typography>
            <Typography style={{ margin: '0px 0px 0px 50px' }}>未依頼：{initData.notRequestedNum}</Typography>
          </div>
          <div className="d-flex my-2" style={{ display: 'flex', alignItems: 'center' }}>
            <Typography>送信済：{initData.sentNum}</Typography>
            <Typography style={{ margin: '0px 0px 0px 50px' }}>未送信：{initData.unsentNum}</Typography>
          </div>
          <div className="row my-2" style={{ display: 'flex', alignItems: 'center' }}>
            <Typography>期間：{initData.period}</Typography>
          </div>
          <div className="row my-2" style={{ display: 'flex', alignItems: 'center' }}>
            <Typography>ステータス：{initData.status}</Typography>
          </div>
          <div className="d-flex" style={{ display: 'flex', alignItems: 'center' }}>
            <Typography fontWeight={'bold'}>送信可能日時</Typography>
          </div>
          <Typography >時間：{initData.time}</Typography>
          <div className="d-flex" style={{ display: 'flex', alignItems: 'center' }}>
            <Typography >曜日：</Typography>
            <BasicCheckbox
              label="月"
              checked={monday}
              readOnly
              labelPlacement="top"
              onChange={(e, checked) => setMonday(checked)}
            />
            <BasicCheckbox
              label="火"
              checked={tuesday}
              readOnly
              labelPlacement="top"
              onChange={(e, checked) => setTuesday(checked)}
            />
            <BasicCheckbox
              label="水"
              checked={wednesday}
              readOnly
              labelPlacement="top"
              onChange={(e, checked) => setWednesday(checked)}
            />
            <BasicCheckbox
              label="木"
              checked={thursday}
              readOnly
              labelPlacement="top"
              onChange={(e, checked) => setThursday(checked)}
            />
            <BasicCheckbox
              label="金"
              checked={friday}
              readOnly
              labelPlacement="top"
              onChange={(e, checked) => setFriday(checked)}
            />
            <BasicCheckbox
              label="土"
              checked={saturday}
              readOnly
              labelPlacement="top"
              onChange={(e, checked) => setSaturday(checked)}
            />
            <BasicCheckbox
              label="日"
              checked={sunday}
              readOnly
              labelPlacement="top"
              onChange={(e, checked) => setSunday(checked)}
            />
          </div>
        </div>
        <div className="row my-2" style={{ width: '30%', textAlign: 'end' }}>
          <div className="row my-3">
            <BasicButton displayName="編集" width={'60%'} onClick={() => gotoEditPage('/client_management/ProjectRegistrationPage', '')} />
          </div>
          {!extracted && (
            <div className="row my-3">
              <BasicButton displayName="リスト抽出" width={'50%'} onClick={() => extract()} />
            </div>
          )}
          <div className="row my-3" style={{ width: '65%', marginLeft: '30%' }}>
            <ListItemButton
              sx={{
                border: 1, borderColor: 'grey.400',
                padding: '4px 8px',
                '& .MuiListItemText-primary': {
                  fontSize: '0.875rem',
                }
              }}
              onClick={() => gotoPage('/client_management/ListDetailPage', '')}>
              <ListItemText sx={{ textAlign: 'center' }}
                primary={"リスト詳細"}
              />
              {<ArrowRightIcon />}
            </ListItemButton>
          </div>
          {
            extracted &&
              <>
                <div className="row my-3" style={{ width: '65%', marginLeft: '30%' }}>
                  <ListItemButton
                    sx={{
                      border: 1, borderColor: 'grey.400',
                      padding: '4px 8px',
                      '& .MuiListItemText-primary': {
                        fontSize: '0.875rem',
                      }
                    }}
                    onClick={() => gotoPage('/client_management/OrderTargetListPage', '')}>
                    <ListItemText sx={{ textAlign: 'center' }}
                      primary={"発注先一覧"}
                    />
                    {<ArrowRightIcon />}
                  </ListItemButton>
                </div>
                <div className="row my-3" style={{ width: '65%', marginLeft: '30%' }}>
                  <ListItemButton
                    sx={{
                      border: 1, borderColor: 'grey.400',
                      padding: '4px 8px',
                      '& .MuiListItemText-primary': {
                        fontSize: '0.875rem',
                      }
                    }}
                    onClick={() => gotoPage('/client_management/NewOrderPage', '')}>
                    <ListItemText sx={{ textAlign: 'center' }}
                      primary={"新規発注"}
                    />
                    {<ArrowRightIcon />}
                  </ListItemButton>
                </div>
              </>
          }
          <div className="row my-3">
            <BasicTextField type='text'
              fullwidth multiline label="メモ" rows={5}
              InputProps={{
                inputProps: {
                  readOnly: true,
                }
              }}
              value={initData.memo} setValue={setMemo} />
          </div>
        </div>
      </div>

      <div className="row" style={{ margin: '0px 30px 0px 100px' }}>
        <Typography>メール</Typography>
        <div>
          <div>
            <BasicMultipleDropdown
              fullWidth
              titleName={'To'}
              value={mailInfo.toUsers}
              items={[]}
              onChange={(val: Dropdown[]) => setMailInfo({ ...mailInfo, toUsers: val })}
              lazyRequestUrl={'/common/master/client/email/filter'}
            />
          </div>
        </div>
        <div className="mt-3">
          <div>
            <BasicMultipleDropdown
              fullWidth
              titleName={'Cc'}
              value={mailInfo.ccUsers}
              items={[]}
              onChange={(val: Dropdown[]) => setMailInfo({ ...mailInfo, ccUsers: val })}
              lazyRequestUrl={'/common/master/client/email/filter'}
            />
          </div>
        </div>
        <div className="mt-3">
          <BasicTextField
            type='text'
            fullwidth
            label="件名"
            value={mailInfo.subject}
            setValue={(e) => setMailInfo({ ...mailInfo, subject: e})}
          />
        </div>
        <div className="mt-3">
          <BasicTextField
            type='text'
            fullwidth={true}
            label="本文"
            multiline={true}
            value={mailInfo.body}
            setValue={(e) => setMailInfo({ ...mailInfo, body: e})}
          />
        </div>
        <div className="row mx-3 my-3" style={{ width: '90%', textAlign: 'end' }}>
          <BasicButton
            displayName="送信"
            width={'10%'}
            disabled={!extracted || mailInfo.toUsers.length == 0}
            onClick={() => send_mail()}
          />
        </div>
      </div>


      <div className="d-flex my-3" style={{ margin: '30px 30px 0px 100px' }}>
        <div className="row" style={{ width: '40%', borderBottom: '1px solid black' }}>
          <div className="d-flex" >
            <Typography fontSize={'large'} >送信者情報</Typography>
          </div>
        </div>
        <div style={{ width: '10%' }}></div>
        <div className="row" style={{ width: '40%', borderBottom: '1px solid black' }}>
          <div className="d-flex" >
            <Typography fontSize={'large'} >送信原稿</Typography>
          </div>
        </div>
      </div>

    </div>
  );
}