import { OutsourceHeader } from "../../../components/header/outsourceHeader";
import Typography from "@mui/material/Typography";
import React, { useEffect } from "react";
import BasicTable, { Row, Title } from "../../../components/table/basicTable";
import HttpRequest from "../../../global/http/HttpRequest";
import { SubjectSearchRequest, SubjectSearchResponse } from "../../../@types/outsource/subjectManagementType";
import BasicTextField from "../../../components/text/basicTextField";
import BasicButton from "../../../components/button/basicButton";
import { useNavigate } from "react-router-dom";
import { showMessage } from "../../../global/store/snackbar";
import { useDispatch } from "react-redux";

export default function SubjectManagementPage() {
  const nav = useNavigate();
  const dispatch = useDispatch();

  const gotoPage = (url: string, param: Record<string, any>) => {
    nav(url, { state: param });
  };

  const [subjectName, setSubjectName] = React.useState<string>('')
  const [rows, setRows] = React.useState<Row[]>([])

  useEffect(() => {
    search()
  }, []);

  async function search() {
    const http = new HttpRequest<SubjectSearchRequest, SubjectSearchResponse>()
    const request: SubjectSearchRequest = {
      name: subjectName
    }
    http.request = request
    if (await http.get('/subject/search')) {
      setRows(http.response!.subjects.map((subject) => {
        return {
          id: subject.subjectId,
          content: [
            {
              name: <div
                style={{ cursor: 'pointer' }}
                onClick={() => gotoPage('/outsource/subject_management/detail', {
                  subjectId: subject.subjectId,
                  subjectName: subject.subjectName,
                  orderId: subject.orderId,
                })}>
                {subject.subjectName}
              </div>,
              align: 'left', type: 'TEXT'
            },
            { name: subject.startDate + ' ～ ' + subject.endDate, align: 'center', type: 'TEXT' },
            { name: subject.progress, align: 'center', type: 'TEXT' },
          ]
        }
      }))
    }
    if (http.response?.subjects.length == 0) {
      dispatch(showMessage({
        message: '対象データが見つかりません',
        severity: 'info',
        duration: 3000,
      }))
      return
    }
  }

  const headers: Title[] = [
    { name: '案件名', align: 'left', fontWeight: 'bold' },
    { name: '期間', align: 'center', fontWeight: 'bold' },
    { name: '進捗', align: 'center', fontWeight: 'bold' },
  ]
  return (
    <div>
      <div>
        <OutsourceHeader />
      </div>

      <div>
        {/* パンくずリスト */}
        <div className="d-flex" style={{ margin: '50px 50px 0px 50px' }}>
          <div onClick={() => null}>
            <Typography
              fontWeight={'bold'}
              fontSize={'large'}
            >
              {'案件管理'}
            </Typography>
          </div>
        </div>

        <div>
          <div className="container my-3">
            <div className="row">
              <div className="col-5">
                <BasicTextField
                  fullwidth={true}
                  label={'案件名'}
                  setValue={setSubjectName}
                />
              </div>
              <div className="col">
                <BasicButton
                  displayName={'検索'}
                  onClick={search}
                />
              </div>
            </div>
            <div className="my-3">
              <BasicTable
                headers={headers}
                content={rows}
              />
            </div>
          </div>

        </div>
      </div>
    </div>
  )
}