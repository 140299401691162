import {Row, Title} from "../../../components/table/basicTable";
import HttpData from "../../../global/http/HttpData";


export type TaskDetail = {
  taskId: string
  title: string
  deadline: string
  rep: string
  customer: string
  subject: string
  order: string
  memo: string
}

export class TableData {
  header: Title[] = []
  body: Row[] = []
}

export class ReadTableData extends TableData {
  constructor() {
    super()
    this.header = [
      {
        name: 'タスクのタイトル',
        align: 'center'
      },
      {
        name: '締切',
        align: 'center'
      },
      {
        name: '顧客名',
        align: 'center'
      }
    ]
  }
}

export class ReportTableData extends TableData {
  constructor() {
    super()
    this.header = [
      {
        name: '顧客名',
        align: 'center'
      },
      {
        name: '案件名',
        align: 'center'
      },
      {
        name: '送信終了日',
        align: 'center'
      }
    ]
  }
}

export class FinishedTaskTableData extends TableData {
  constructor() {
    super()
    this.header = [
      {
        name: 'タスク名',
        align: 'center'
      },
      {
        name: '顧客名',
        align: 'center'
      },
      {
        name: '案件名',
        align: 'center'
      },
      {
        name: '完了日',
        align: 'center'
      }
    ]
  }
}

export type TaskDto = {
  task_id: string
  task_name: string
  finDate: string
  deadline: string
  unread: string
  status: string
  customer_name: string
  subject: string
  order: string
  rep: string
  memo: string
}

export type ReportDto = {
  reportId: string
  reportName: string
  sendDate: string
}

export interface TaskStatusRequest extends HttpData {
  taskId: string
  read: boolean
  fin: boolean
}

export interface TaskTopResponse extends HttpData {
  tasks: TaskDto[]
  reports: ReportDto[]
}

export interface MailDataRequest extends HttpData {
  task_id: string
}

export interface MemoRequest extends HttpData {
  taskId: string
  memo: string
}