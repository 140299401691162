import { Typography } from "@mui/material";
import Header from "../../../components/header/header";
import BasicTextField from "../../../components/text/basicTextField";
import React, { useEffect, useState } from "react";
import BasicButton from "../../../components/button/basicButton";
import { useLocation, useNavigate } from "react-router-dom";
import {
  OrderInput,
  OrderRequest,
  MessageResponse, OrderInfoRequest, OrderInfoResponse
} from "../../../@types/ib/clientManagement/OrderType";
import HttpRequest from "../../../global/http/HttpRequest";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useDispatch } from "react-redux/es/exports";
import { showMessage } from "../../../global/store/snackbar";
import OrderReport from "../../../components/report/orderReport"

interface ReportInfo {
  reportNumber: number
  companyName: string
  outsourceName: string
  postNumber: string
  address: string
  phoneNumber: string
  faxNumber: string
  listName: string
  tax: number
}
export default function NewOrderConfPage() {
  const nav = useNavigate()
  const dispatch = useDispatch()
  const location = useLocation()

  const gotoPage = (url: string) => {
    nav(url);
  }
  const goBack = () => {
    nav('/client_management/NewOrderPage', {
      state: { order: orderInput }
    })
  }
  //
  // if (!location.state) {
  //   gotoPage('/client_management')
  // }

  // state(基本情報)
  const [orderInput, setOrderInput] =
    useState<OrderInput>(location.state?.order ?? new OrderInput());

  const [reportInfo, setReportInfo] = useState<ReportInfo>({
    reportNumber: 1,
    companyName: '',
    outsourceName: '',
    postNumber: '',
    address: '',
    phoneNumber: '',
    faxNumber: '',
    listName: '',
    tax: 1
  })

  useEffect(() => {
    console.log(location.state?.order)
    if (location.state?.order) {
      setOrderInput(location.state.order)
    }
    const getInitData = async () => {
      const http = new HttpRequest<OrderInfoRequest, OrderInfoResponse>()
      http.request = {
        name: location.state.order.outsourceId,
        subject: location.state.order.projectId,
      }
      const res = await http.get('/client/order/info')
      if (http.statusCode === 200) {
        setReportInfo({
          reportNumber: 1,
          companyName: http.response?.companyName || '',
          outsourceName: http.response?.outsourceName || '',
          postNumber: http.response?.postNumber || '',
          address: http.response?.address || '',
          phoneNumber: http.response?.tel || '',
          faxNumber: http.response?.fax || '',
          listName: http.response?.listName || '',
          tax: http.response?.tax || 1
        })
      }
    }
    getInitData()
  }, []);

  // 発注
  const placeOrder = async () => {
    const http = new HttpRequest<OrderRequest, MessageResponse>()
    http.request = {
      projectId: orderInput.projectId,
      outsourceId: orderInput.outsourceId,
      operator: orderInput.operator,
      operation: orderInput.operation,
      price: orderInput.price,
      title: orderInput.title,
      requestNum: orderInput.requestNum,
      startDate: orderInput.startDate?.toLocaleString() ?? '',
      endDate: orderInput.endDate?.toLocaleString() ?? '',
      ibUserId: orderInput.ibUserId,
      subject: orderInput.subject,
      body: orderInput.body,
    }
    if (await http.post('/client/order/place')) {
      dispatch(showMessage({
        message: http.response?.message || '',
        severity: 'success',
        duration: 3000
      }))
      gotoPage('/client_management')
    }
  }

  const calc = (price: number, type: 'SUBTOTAL' | 'TOTAL' | 'TAX') => {
    const qty = Number(orderInput.requestNum)
    switch (type) {
      case 'SUBTOTAL':
        let subtotal = (Math.ceil(price * qty)).toString()
        return subtotal.length > 3 ?
          (subtotal.substring(0, subtotal.length - 3) + ',' +  subtotal.substring(subtotal.length - 3))
          :
          subtotal
      case 'TAX':
        let tax = (Math.ceil(price * qty * reportInfo.tax)).toString()
        return tax.length > 3 ?
          (tax.substring(0, tax.length - 3) + ',' +  tax.substring(tax.length - 3))
          :
          tax
      case 'TOTAL':
        let total = (Math.ceil(price * qty * reportInfo.tax + price * qty)).toString()
        return total.length > 3 ?
          (total.substring(0, total.length - 3) + ',' +  total.substring(total.length - 3))
          :
          total
      default:
        return 0
    }
  }

  return (
    <div>
      <div>
        <Header />
      </div>

      {/* パンくずリスト */}
      <div className="d-flex" style={{ margin: '50px 50px 0px 50px' }}>
        <Typography fontWeight={'bold'} fontSize={'large'}>
          {'新規発注'}
        </Typography>
      </div>

      <div className="container mx-5 my-4">
        <div className="row">
          {/* LEFT COL */}
          <div className="col">
            <div>
              <div className=""
                   style={{
                     scale: 0.5,
                     margin: '0 auto',
                     textAlign: 'center',
                     height: '1200px',
                     width: '900px',
                     border: '1px solid black'
                   }}>
                <div>
                  <OrderReport
                    reportNumber={1}
                    outsourceName={reportInfo.outsourceName}
                    title={orderInput.title}
                    startDate={orderInput.startDate}
                    endDate={orderInput.endDate}
                    companyName={reportInfo.companyName}
                    postNumber={reportInfo.postNumber}
                    address={reportInfo.address}
                    phoneNumber={reportInfo.phoneNumber}
                    faxNumber={reportInfo.faxNumber}
                    operatorName={orderInput.operator.label}
                    ibOperatorName={orderInput.ibUserId.label}
                    listName={reportInfo.listName}
                    qty={orderInput.requestNum}
                    price={orderInput.price}
                    subtotalAmount={calc(Number(orderInput.price), 'SUBTOTAL').toString()}
                    taxAmout={calc(Number(orderInput.price), 'TAX').toString()}
                    totalAmount={calc(Number(orderInput.price), 'TOTAL').toString()}
                  />
                </div>
              </div>
            </div>
          </div>
          {/* RIGHT COL */}
          <div className="col">
            <div className="mb-3">
              <BasicTextField
                fullwidth
                label="件名"
                value={orderInput.subject}
                setValue={() => {
                }}
              />
            </div>
            <div>
              <BasicTextField
                fullwidth
                label="本文"
                value={orderInput.body}
                setValue={() => {
                }}
                multiline={true}
              />
            </div>
          </div>
        </div>
        <div className="row text-center my-3">
          <div className="col">
            <BasicButton
              fullWidth={true}
              displayName="戻る" width={'20%'}
              fontColor="white"
              onClick={goBack}
            />
          </div>
          <div className="col">
            <BasicButton
              fullWidth={true}
              displayName="発注を確定" width={'20%'}
              fontColor="white"
              onClick={placeOrder}
            />
          </div>
        </div>
      </div>
    </div>
  );
}