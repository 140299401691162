import { Typography, Grid, Box, TextField } from "@mui/material";
import React from "react";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import Header from "../../../components/header/header";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import BasicButton from "../../../components/button/basicButton";
import DataRequestSender from "../../../global/http/DataRequestSender";
import { showMessage } from "../../../global/store/snackbar";
import EditTable, { Row, Title } from "../../../components/table/mtaskEditTable";
import SearchTextField from "../../../components/text/searchTextField";
import HttpRequest from "../../../global/http/HttpRequest";
import {
  ClientProhibitionRequest,
  ClientProhibitionResponse, ProhibitionData, ProhibitionRemovalRequest
} from "../../../@types/ib/clientManagement/ClientProhibitionType";
import {NormalResponse} from "../../../@types/common";
import BasicDialog from "../../../components/dialog/basicDialog";

// テーブルデータ
type rowData = {
  name: string;
  address: string;
  top_url: string;
  company_id: string;
};

export default function ProhibitedManagementPage() {
  const nav = useNavigate();
  
  const dispatch = useDispatch();
  const location = useLocation();
  const clientId = location.state!.clientId;

  
  // search text
  const [searchValue, setSearchValue] = useState<string>('');
  const [rows, setRows] = useState<Row[]>([]);
  const [prohibitionList, setProhibitionList] = useState<ProhibitionData[]>([]);
  const [removalTarget, setRemovalTarget] = useState<ProhibitionData | null>(null);

  const gotoPage = (url: string) => {
    nav(url, { state: { clientId: clientId, tabIndex: 2 } });
  };

  useEffect(() => {
    const rowData: Row[] = prohibitionList.map((item: ProhibitionData, index: number) => ({content: [
            { name: item.companyName, align: 'center',type: 'TEXT', value: '', width: '30%' },
            { name: item.address, align: 'center',type: 'TEXT', value: '', width: '30%' },
            { name: item.topUrl, align: 'center',type: 'TEXT', value: '', width: '30%' },
            { name: <BasicButton
                displayName='解除'
                onClick={() => confirmRemoval(item)}
              />,
              align: 'center', width: '10%', type: 'TEXT', value: '' },
          ],}))

    setRows(rowData)
  }, [prohibitionList])

  const confirmRemoval = (target: ProhibitionData) => {
      setRemovalTarget(target)
  }

  const removeProhibition = async (prohibitionId?: string) => {
    if (!prohibitionId) return

    const http = new HttpRequest<ProhibitionRemovalRequest, NormalResponse>()
    http.request = {
      prohibitionId: prohibitionId
    }
    if (await http.post('/client/prohibition/cancellation')) {
      setProhibitionList(prohibitionList.filter(item => item.prohibitionId !== prohibitionId))
      setRemovalTarget(null)
      dispatch(showMessage({
        message: http.response!.message,
        severity: 'success',
        duration: 3000,
      }))
    }
  }

  const headers: Title[] = [
    { name: '企業名', align: 'center', width: '30%' },
    { name: '住所', align: 'center', width: '30%' },
    { name: 'URL', align: 'center', width: '30%' },
    { name: '', align: 'center', width: '10%' },
  ];

  let [rowData, setRowData] = useState<Row[]>([])
  
  useEffect(() =>{
    search();
  }, []);

  // 画面情報csvダウンロード
  const handleDownload = async () => {
    try {
      let data = [];

      // リスト作成
      for(const row of rows){
        data.push({
          name: String(row.content[0].name),
          address: String(row.content[1].name),
          mail: String(row.content[2].name),
        });
      }

      interface Item {
        name: string;
        address: string;
        mail: string;
      }

      // リストをCSVに変換
      const convertToCSV = (data: Item[]): string => {
        const headers = '企業名,住所,URL';
        const rows = data.map(obj =>
          [obj.name, obj.address, obj.mail].join(',')
        ).join('\n');
    
        return `${headers}\n${rows}`;
      };
        
      const csvString = convertToCSV(data);
      const blob = new Blob(['\uFEFF' + csvString], { type: 'text/csv;charset=utf-8-sig;' });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.setAttribute('href', url);
      link.setAttribute('download', 'data.csv');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      dispatch(showMessage({
        message: 'CSVダウンロード完了',
        severity: 'error',
        duration: 3000,
      }))
      
    } catch (error) {
      dispatch(showMessage({
        message: 'CSVダウンロード失敗',
        severity: 'error',
        duration: 3000,
      }))
    }
  };
 

  // TODO:検索関数(API通信して結果をtableDataに反映させる)
  const search = async () => {

    const http = new HttpRequest<ClientProhibitionRequest, ClientProhibitionResponse>()
    http.request = {
      clientId: clientId!,
      searchValue: searchValue
    }
    if (await http.post('/client/prohibition/search')) {
      setProhibitionList(http.response!.prohibitionList)
    }
  }

  return (
    <div>
      <div>
        <Header />
      </div>

      {/* パンくずリスト */}
      <Box sx={{ flexGrow: 1, mb: 2, margin: '50px 50px 0px 50px' }} >
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>
            <Box display="flex" gap={1}>
              <div onClick={() => gotoPage('/client_management/detail')}>
                <Typography
                  fontWeight={'bold'}
                  fontSize={'large'}
                  style={{ color: 'gray', cursor: 'pointer' }}
                >
                  顧客情報
                </Typography>
              </div>
              <div style={{ width: '20px' }}></div>
              <ArrowForwardIcon />
              <div style={{ width: '20px' }}></div>
              <Typography fontWeight={'bold'} fontSize={'large'}>顧客別禁止一覧</Typography>

            </Box>
          </Grid>
          
          <Grid item>
            <Box display="flex" gap={1}>
              <BasicButton displayName="ダウンロード" onClick={() => handleDownload()} />
            </Box>
          </Grid>
        </Grid>
      </Box>

      {/* 検索フィールド */}
      <div className="d-flex" style={{ margin: '50px 50px 0px 50px' }}>
        <SearchTextField label="企業名" value={searchValue} setValue={setSearchValue} onClick={search} />
      </div>

      {/* 外注先テーブル */}
      <div className='mx-5 my-3'>
        <EditTable
          headerColor="midnightblue"
          headers={headers}
          content={rows}
          setTableData={setRows}
        />
      </div>
      <BasicDialog
        open={!!removalTarget}
        title="顧客別禁止の解除"
        content={<>{removalTarget?.companyName}の顧客別禁止を解除します。</>}
        positiveButtonName={"解除"}
        positiveAction={() => removeProhibition(removalTarget?.prohibitionId)}
        negativeButtonName={"キャンセル"}
        close={() => setRemovalTarget(null)}
      />
    </div>
  )
  
}