import { Grid, Paper, styled } from "@mui/material";
import { useNavigate } from "react-router-dom";
import {ClientRole, IbRole, OutsourceRole} from "../../@types/roles";
import useRole from "../../global/hook/roleHook";

export type MenuItem = {
  title: string
  gotoPageUrl: string,
  roles?: (IbRole | OutsourceRole | ClientRole)[]
}

type Props = {
  menuItems: MenuItem[]
}

export default function GridViewMenu(props: Props) {
  const nav = useNavigate();
  const hasRole = useRole()

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: '#EEEEEE',
    ...theme.typography.body2,
    padding: theme.spacing(4),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    cursor: 'pointer',
    userSelect: 'none',
    ":hover": { fontWeight: 'bold', scale: '1.05', backgroundColor: 'skyblue' }
  }));

  const gotoPage = (url: string) => {
    nav(url);
  };

  return (
    <div>
      <Grid container rowSpacing={6} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        {props.menuItems.map((item, index) => {
          return (
            // hasRole(item.roles || []) && (
            <Grid key={`index-${index}`} item xs={12} sm={4}>
              <Item className='text-nowrap' onClick={() => gotoPage(item.gotoPageUrl)}>
                {item.title}
              </Item>
            </Grid>
            // )
          )
        })}
      </Grid>
    </div>
  );
}