import { useNavigate, useLocation } from "react-router-dom";
import Header from "../../../components/header/header";
import { Typography } from "@mui/material";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import BasicTextField from "../../../components/text/basicTextField";
import { useEffect, useState, forwardRef } from "react";
import { useDispatch } from "react-redux";
import BasicButton from "../../../components/button/basicButton";
import "react-datepicker/dist/react-datepicker.css";
import ClientRequest from "../../../global/http/ClientRequest";
import { showMessage } from "../../../global/store/snackbar";
import HttpRequest from "../../../global/http/HttpRequest";
import {
  DraftDetailRequest, DraftEditRequest, DraftResponse,
  DraftEditResponse, DraftInfo
} from "../../../@types/draft";

// 顧客詳細 -> 新規送信原稿登録ページ
export default function SubmissionDraftRegistrationPage() {
  const nav = useNavigate();

  const location = useLocation();
  const clientId = location.state?.clientId;
  const draftId = location.state?.draftId;
  const clientName = location.state?.clientName;
  const pageTitle = location.state?.pageTitle ?? '送信原稿新規登録';

  const gotoPage = (url: string) => {
    nav(url, { state: { clientId: clientId, tabIndex: 2 } });
  };

  const gotoDetailPage = (url: string, id: string) => {
    nav(url, { state: { clientId: clientId, draftId: id, clientName: clientName } });
  };

  const dispatch = useDispatch();

  // state
  const [draftInfo, setDraftInfo] = useState<DraftInfo>(new DraftInfo())


  useEffect(() => {
    if (draftId) {
      const fetchData = async () => {
        // 送信原稿情報取得
        const http = new HttpRequest<DraftDetailRequest, DraftResponse>()
        http.request = {
          draft_id: draftId!,
        }
        if (await http.get(`/client/draft/detail`)) {
          const info = { ...http.response! }
          setDraftInfo({ ...info })
        }
      }
      fetchData();
    }
  }, []);

  // 登録
  const registration = async () => {

    const http = new HttpRequest<DraftEditRequest, DraftEditResponse>()
    http.request = {
      ...{ ...draftInfo, clientId: clientId }
    }
    const response = await http.post('/client/draft/register')
    if (http.response?.errorMessage) {
      dispatch(showMessage({
        message: http.response!.errorMessage,
        severity: 'error',
        duration: 3000,
      }))
      return
    }
    const id = draftId ? draftId : http.response!.draftId
    gotoDetailPage('/client_management/SubmissionDraftDetailPage', id)
  }

  return (
    <div>
      <div>
        <Header />
      </div>

      {/* パンくずリスト */}
      <div>
        <div className="d-flex" style={{ margin: '50px 50px 0px 50px' }}>
          <div onClick={() => gotoPage('/client_management')}>
            <Typography fontWeight={'bold'} fontSize={'large'} style={{ color: 'gray', cursor: 'pointer' }}>
              {'顧客管理'}
            </Typography>
          </div>

          <div style={{ width: '20px' }}></div>
          <ArrowForwardIcon />
          <div style={{ width: '20px' }}></div>

          <div onClick={() => gotoPage('/client_management/detail')}>
            <Typography fontWeight={'bold'} fontSize={'large'} style={{ color: 'gray', cursor: 'pointer' }}>
              {'顧客詳細'}
            </Typography>
          </div>

          <div style={{ width: '20px' }}></div>
          <ArrowForwardIcon />
          <div style={{ width: '20px' }}></div>

          <Typography fontWeight={'bold'} fontSize={'large'} style={{ color: 'gray' }}>
            {'案件情報'}
          </Typography>

          <div style={{ width: '20px' }}></div>
          <ArrowForwardIcon />
          <div style={{ width: '20px' }}></div>

          <Typography fontWeight={'bold'} fontSize={'large'}>{pageTitle}</Typography>
        </div>
      </div>

      {/* 顧客名 */}
      <div className="d-flex" style={{ margin: '30px 30px 0px 50px' }}>
        <Typography align="left" fontWeight={'bold'} fontSize={'large'}>{clientName}</Typography>
      </div>

      {/* 登録フォーム */}
      <div >
        <div className="container" style={{ margin: '30px 50px 0px 50px' }}>
          {/* 基本情報 */}
          <div className="d-flex my-4 col-8">
            <BasicTextField fullwidth label="原稿タイトル"
              value={draftInfo.draftName}
              setValue={(value: string) => setDraftInfo({ ...draftInfo, draftName: value })} />
          </div>
          <div className="d-flex my-4 col-8">
            <BasicTextField fullwidth label="件名"
              value={draftInfo.title}
              setValue={(value: string) => setDraftInfo({ ...draftInfo, title: value })} />
          </div>
          <div className="d-flex my-4 col-12">
            <BasicTextField
              fullwidth label="通常版"
              multiline rows={5}
              value={draftInfo.regulart}
              setValue={(value: string) => setDraftInfo({ ...draftInfo, regulart: value })} />
          </div>
          <div className="d-flex my-4 col-12">
            <BasicTextField
              fullwidth label="短縮版"
              multiline rows={5}
              value={draftInfo.short}
              setValue={(value: string) => setDraftInfo({ ...draftInfo, short: value })} />
          </div>
          <div className="d-flex my-4 col-12">
            <BasicTextField
              fullwidth label="URLなし版"
              multiline rows={5}
              value={draftInfo.noUrl}
              setValue={(value: string) => setDraftInfo({ ...draftInfo, noUrl: value })} />
          </div>

          <div className="my-3 my-5" style={{ textAlign: 'center' }}>
            <BasicButton
              displayName={'登録'} width={'20%'}
              fontColor="white"
              onClick={registration}
            />
          </div>
        </div>
      </div>

    </div >
  );
}