import {Typography} from "@mui/material";
import {OutsourceRole} from "../../@types/roles";
import HttpRequest from "../../global/http/HttpRequest";
import HttpData from "../../global/http/HttpData";
import {logout as storeLogout} from "../../global/store/auth";
import {showMessage} from "../../global/store/snackbar";
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import useRole from "../../global/hook/roleHook";

export function OutsourceHeader() {
  const nav = useNavigate();
  const dispatch = useDispatch();
  const hasRole = useRole()

  /**
   * 画面遷移用関数
   * @param url 画面遷移URL
   */
  const gotoPage = (url: string): void => {
    nav(url)
  }

  const logout = async () => {
    const http = new HttpRequest<HttpData, HttpData>()
    await http.post('/logout')
    gotoPage('/outsource/login')
    dispatch(storeLogout())
    dispatch(showMessage({
      message: 'ログアウトしました',
      severity: 'success',
      duration: 3000,
    }))
  }

  return (
    <div className="d-flex" style={{backgroundColor: '#EEEEEE'}}>
      {/* タイトル */}
      <div className="mx-2 my-2" onClick={() => gotoPage('/outsource')}>
        <Typography className='menu' align='left' fontWeight='bold'
                    fontSize={'large'}>営業代行メール版管理システム</Typography>
      </div>

      {/* メニュー */}
      <div className="d-flex" style={{marginLeft: 'auto', paddingTop: '9px'}}>

        {hasRole([OutsourceRole.ALL_ACCOUNT_READ, OutsourceRole.BASE_ACCOUNT_READ]) &&
            <div className="my-1" onClick={() => gotoPage('/outsource/user_management')}>
                <Typography className="menu" fontSize={'small'}>利用者管理</Typography>
            </div>
        }

        {hasRole([OutsourceRole.BASE_READ]) &&
          <div className="my-1 mx-3" onClick={() => gotoPage('/outsource/location')}>
            <Typography className="menu" fontSize={'small'}>拠点管理</Typography>
          </div>
        }

        <div className="my-1 mx-3" onClick={() => gotoPage('/outsource/subject_management')}>
          <Typography className="menu" fontSize={'small'}>作業管理</Typography>
        </div>

        <div className="my-1 mx-3" onClick={() => gotoPage('/outsource/report')}>
          <Typography className="menu" fontSize={'small'}>帳票</Typography>
        </div>

        <div
          className="my-1 mx-3"
          style={{marginRight: '30px'}}
          onClick={() => logout()}>
          <Typography className="menu" fontSize={'small'}>ログアウト</Typography>
        </div>

      </div>
    </div>
  )
}