import GridViewMenu, { MenuItem } from "../../components/menu/gridViewMenu";
import React from "react";
import { OutsourceHeader } from "../../components/header/outsourceHeader";
import SubjectManagementPage from "./operation/SubjectManagementPage";

export default function OutsourceTopPage() {

  const items: MenuItem[] = [
    { title: '利用者管理', gotoPageUrl: '/outsource/user_management' },
    { title: '拠点管理', gotoPageUrl: '' },
    { title: '作業管理', gotoPageUrl: '' },
    { title: '帳票', gotoPageUrl: '/outsource/report' },
  ];

  return <SubjectManagementPage />
}