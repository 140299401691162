import {Dropdown} from "../../../components/dropdown/basicDropdown";

export class Conversions {
  manualSuccess: Dropdown = {label: '', value: ''}
  manualFailure: Dropdown = {label: '', value: ''}
  manualStop: Dropdown = {label: '', value: ''}
  manualDedicatedForm: Dropdown = {label: '', value: ''}
  manualNoForm: Dropdown = {label: '', value: ''}
  manualNotFound: Dropdown = {label: '', value: ''}
  autoSuccess: Dropdown = {label: '', value: ''}
  autoFailure: Dropdown = {label: '', value: ''}
}

export interface ConversionRequest {
  client_id: string
}

export interface ConversionResponse {
  manualSuccess: Dropdown
  manualFailure: Dropdown
  manualStop: Dropdown
  manualDedicatedForm: Dropdown
  manualNoForm: Dropdown
  manualNotFound: Dropdown
  autoSuccess: Dropdown
  autoFailure: Dropdown
}

export interface ConversionSaveRequest {
  clientId: string
  manualSuccess: Dropdown
  manualFailure: Dropdown
  manualStop: Dropdown
  manualDedicatedForm: Dropdown
  manualNoForm: Dropdown
  manualNotFound: Dropdown
  autoSuccess: Dropdown
  autoFailure: Dropdown
}